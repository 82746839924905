import React, { useEffect, useRef, useState } from "react";
import * as S from "./styled";
import {
  Box,
  Button,
  Chip,
  CircularProgress,
  Input,
  LinearProgress,
  Stack,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import {
  Handle,
  HandleType,
  NodeToolbar,
  Position,
  useReactFlow,
  useUpdateNodeInternals,
} from "@xyflow/react";
import { DatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { useAtomValue, useSetAtom } from "jotai";
import theme from "../../../../../../Styles/theme";
import { togetherDialogAtom } from "@/Atoms/Dialogs/Together";
import { editRoutineIntervalsDialogAtom } from "@/Atoms/Dialogs/Edit/RoutineOption";
import getRemainingDays from "@/Utils/GetRemainingDays";
import getProgressPercentage from "@/Utils/GetProgressPercentage";
import {
  careerPathAtom,
  currentZoomAtom,
  selectedNodeAtom,
} from "@/Atoms/Plan/MindmapAtom";
import { detachNodeInGroupAtom } from "@/ViewModels/Plan/Mindmap/GroupViewModel";
import {
  addHashtagNodeAtom,
  createJobDialogOpenAtom,
  recomendJobNodeDataAtom,
  recommendJobNodeNextPageAtom,
  recommendJobNodePrevPageAtom,
  removeHashtagNodeAtom,
  updateJobNodeDataAtom,
  updateJobNodeDateAtom,
  updateJobNodeLocationAtom,
  updateJobNodeRoutineOptionAtom,
  updateTaskStatusNodeAtom,
} from "@/ViewModels/Plan/Mindmap/JobNodeViewModel";
import jobNodeAtom, {
  alertNoJobDataModalAtom,
} from "@/Atoms/Nodes/JobNodeAtom";
import inactivateDialogAtom from "@/Atoms/Dialogs/Inactivate";
import getRandomColorWithText, {
  getTextColorBasedOnBackground,
} from "@/Utils/GetRandomColor";
import CustomBackgroundColorButton from "@/Components/Common/Buttons/CustomBackgroundColor";
import { lighten } from "polished";
import CustomColorButton from "@/Components/Common/Buttons/CustomColor";
import TextMarkdown from "@/Components/Common/Markdown";
import { languageAtom } from "@/Atoms/RootAtom";
import { textEditorOpenAtom } from "@/ViewModels/Dialogs/TextEditorViewModel";
import useIsMobile from "@/Hooks/useIsMobile";
import { useLocation } from "react-router-dom";
import { deleteTaskAtom } from "@/ViewModels/Plan/Mindmap/ViewModel";
import { NavigateBefore, NavigateNext } from "@mui/icons-material";
import { useTranslation } from "react-i18next";

const JobNode = (data: any) => {
  const { t } = useTranslation();
  const { isMobile } = useIsMobile();
  const jobNodes = useAtomValue(jobNodeAtom);
  const jobNode = jobNodes.find((node) => node.id === data.id) || null;
  const [maxHeight, setMaxHeight] = useState("200px"); // 기본 축소 상태
  const contentRef = useRef<HTMLDivElement>(null); // 확장된 높이를 참조할 수 있는 useRef
  const updateNodeInternals = useUpdateNodeInternals();
  const detachNodeInGroup = useSetAtom(detachNodeInGroupAtom);
  const updateValue = useSetAtom(updateJobNodeDataAtom);
  const updateDate = useSetAtom(updateJobNodeDateAtom);
  const updateLocation = useSetAtom(updateJobNodeLocationAtom);
  const updateRoutineOption = useSetAtom(updateJobNodeRoutineOptionAtom);
  const selectedNode = useAtomValue(selectedNodeAtom);
  const careerPath = useAtomValue(careerPathAtom);
  const type = data.id.split("-")[0];
  const setEditRoutineDialog = useSetAtom(editRoutineIntervalsDialogAtom);
  const setTogetherDialog = useSetAtom(togetherDialogAtom);
  const createJob = useSetAtom(createJobDialogOpenAtom);
  const recomendJob = useSetAtom(recomendJobNodeDataAtom);
  const setInactivateDialog = useSetAtom(inactivateDialogAtom);
  const openTextEditorDialog = useSetAtom(textEditorOpenAtom);
  const [inputValue, setInputValue] = useState("");
  const addHashtag = useSetAtom(addHashtagNodeAtom);
  const removeHashtag = useSetAtom(removeHashtagNodeAtom);
  const updateStatus = useSetAtom(updateTaskStatusNodeAtom);
  const today = dayjs().format("YYYY-MM-DD");
  const isExistTodayStatus =
    data.data.taskStatus.length !== 0 &&
    data.data.taskStatus.find((status: any) => status.date === today);
  const todayStatus = isExistTodayStatus
    ? data.data.taskStatus.find((status: any) => status.date === today)
    : null;
  const language = useAtomValue(languageAtom);
  const handleDeleteNode = useSetAtom(deleteTaskAtom);
  const path = useLocation().pathname;
  const jobPrevPage = useSetAtom(recommendJobNodePrevPageAtom);
  const jobNextPage = useSetAtom(recommendJobNodeNextPageAtom);
  const setNoJobDataModal = useSetAtom(alertNoJobDataModalAtom);
  const currentZoom = useAtomValue(currentZoomAtom);

  const handlePositions = [
    {
      id: "left",
      position: Position.Left,
      additionalStyle: {
        top: "50%",
        transform: "translateY(-50%)",
        width: isMobile ? "25px" : "10px",
        height: isMobile ? "25px" : "10px",
      },
    },
    {
      id: "left-top",
      position: Position.Left,
      additionalStyle: {
        left: "0",
        top: "0",
        transform: "translate(0%, 0%)",
        width: isMobile ? "25px" : "10px",
        height: isMobile ? "25px" : "10px",
      },
    },
    {
      id: "left-bottom",
      position: Position.Left,
      additionalStyle: {
        left: "0",
        bottom: "0",
        transform: "translate(0%, 0%)",
        right: "auto",
        top: "auto",
        width: isMobile ? "25px" : "10px",
        height: isMobile ? "25px" : "10px",
      },
    },
    {
      id: "top",
      position: Position.Top,
      additionalStyle: {
        left: "50%",
        transform: "translateX(-50%)",
        width: isMobile ? "25px" : "10px",
        height: isMobile ? "25px" : "10px",
      },
    },
    {
      id: "right",
      position: Position.Right,
      additionalStyle: {
        top: "50%",
        transform: "translateY(-50%)",
        width: isMobile ? "25px" : "10px",
        height: isMobile ? "25px" : "10px",
      },
    },
    {
      id: "right-top",
      position: Position.Right,
      additionalStyle: {
        right: "0",
        top: "0",
        transform: "translate(0%, 0%)",
        width: isMobile ? "25px" : "10px",
        height: isMobile ? "25px" : "10px",
      },
    },
    {
      id: "right-bottom",
      position: Position.Right,
      additionalStyle: {
        right: "0",
        bottom: "0",
        transform: "translate(0%, 0%)",
        left: "auto",
        top: "auto",
        width: isMobile ? "25px" : "10px",
        height: isMobile ? "25px" : "10px",
      },
    },
    {
      id: "bottom",
      position: Position.Bottom,
      additionalStyle: {
        left: "50%",
        transform: "translateX(-50%)",
        width: isMobile ? "25px" : "10px",
        height: isMobile ? "25px" : "10px",
      },
    },
  ];

  // 엔터키 입력 시 태그 추가
  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter" && inputValue.trim()) {
      event.preventDefault();
      const { backgroundColor, textColor } = getRandomColorWithText();
      if (
        !data.data.hashtags.find(
          (tag: {
            backgroundColor: string;
            textColor: string;
            value: string;
          }) => tag.value === inputValue.trim()
        )
      ) {
        addHashtag(data, {
          backgroundColor,
          textColor,
          value: inputValue.trim(),
        });
      }
      setInputValue(""); // 입력창 초기화
    } else if (event.key === "Backspace" && inputValue === "") {
      if (data.data.hashtags.length > 0) {
        event.preventDefault();
        removeHashtag(data);
      }
    }
  };

  useEffect(() => {
    if (contentRef.current) {
      if (selectedNode?.id === data.id || data.data.isNewCreated) {
        setMaxHeight(`800px`); // 확장 시 실제 높이를 기준으로 설정
      } else {
        setMaxHeight("200px"); // 축소 시 고정된 높이로 설정
      }
    }
    // updateNodeInternals(data.id);
  }, [updateNodeInternals, data.id, selectedNode, data.data.isNewCreated]); // expanded 상태 변경 시마다 실행

  // 경로에 있는지 여부에 따른 활성/비활성 스타일 결정
  const isPathActive = careerPath?.path.includes(data.id);
  const isCareerPathNull = careerPath === null;

  const handleDetachNodeInGroup = (e: React.MouseEvent) => {
    e.stopPropagation();
    detachNodeInGroup(data.id);
  };

  const getButtonSize = (baseSize: number) => {
    const scaledSize = baseSize * currentZoom;
    return scaledSize;
  };

  const JobButtonGroup = ({
    jobList,
  }: {
    jobList: { job: string; rank: number; status: boolean }[];
  }) => {
    const colorMap = [
      theme.colors.primary,
      theme.colors.customPink,
      theme.colors.customYellow,
      theme.colors.customSky,
      theme.colors.customPurple,
    ];
    const itemsPerPage = 5; // 한 페이지에 보여줄 항목 수
    const totalPages = Math.ceil(jobList.length / itemsPerPage); // 총 페이지 수

    const handleNextPage = (e: React.MouseEvent) => {
      e.stopPropagation();
      jobNextPage(data);
    };

    const handlePrevPage = (e: React.MouseEvent) => {
      e.stopPropagation();
      jobPrevPage(data);
    };

    const currentItems = jobNode
      ? jobList.slice(
          jobNode.state.currentPage * itemsPerPage,
          (jobNode.state.currentPage + 1) * itemsPerPage
        )
      : [];

    return (
      <div style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
        {jobList.length === 0 && (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "5px",
              padding: "5px 10px",
              borderRadius: "10px",
              backgroundColor: "white",
              border: `1px solid ${theme.colors.primary}`,
              fontSize: "0.8rem",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div>{t("plan.contents.mindmap.nodes.job.noRecommend")}</div>
            <div>{t("plan.contents.mindmap.nodes.job.noRecommend2")}</div>
          </div>
        )}
        {currentItems.map((job, index) => {
          return (
            <Chip
              key={job.job}
              label={`${job.job}`}
              sx={{
                backgroundColor: colorMap[index % colorMap.length],
                color: getTextColorBasedOnBackground(
                  colorMap[index % colorMap.length]
                ),
                "&:hover": {
                  backgroundColor: lighten(
                    0.1,
                    colorMap[index % colorMap.length]
                  ),
                },
              }}
              onClick={(e) => {
                e.stopPropagation();
                job.status
                  ? createJob(data, job.job)
                  : setNoJobDataModal(job.job);
              }}
            />
          );
        })}
        {jobNode && totalPages > 1 && (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              gap: "5px",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <NavigateBefore
              onClick={(e) => handlePrevPage(e)}
              sx={{
                fontSize: "1.1rem",
                fontWeight: "600",
                backgroundColor:
                  jobNode.state.currentPage === 0
                    ? "#e0e0e0"
                    : theme.colors.primary,
                color: jobNode.state.currentPage === 0 ? "#9e9e9e" : "white",
                cursor:
                  jobNode.state.currentPage === 0 ? "not-allowed" : "pointer",
                borderRadius: "50%",
                padding: "5px",
              }}
            />
            <Typography sx={{ fontSize: "0.8rem", fontWeight: "600" }}>
              {jobNode.state.currentPage + 1} / {totalPages}
            </Typography>
            <NavigateNext
              onClick={(e) => handleNextPage(e)}
              sx={{
                fontSize: "1.1rem",
                fontWeight: "600",
                backgroundColor:
                  jobNode.state.currentPage === totalPages - 1
                    ? "#e0e0e0"
                    : theme.colors.primary,
                color:
                  jobNode.state.currentPage === totalPages - 1
                    ? "#9e9e9e"
                    : "white",
                cursor:
                  jobNode.state.currentPage === totalPages - 1
                    ? "not-allowed"
                    : "pointer",
                borderRadius: "50%",
                padding: "5px",
              }}
            />
          </div>
        )}
      </div>
    );
  };

  return (
    <>
      <NodeToolbar
        isVisible={true}
        position={Position.Top}
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "10px",
        }}
      >
        {jobNode &&
          (jobNode.state.status === 0 ? (
            <CircularProgress size={40} />
          ) : (
            <JobButtonGroup jobList={jobNode.state.jobList} />
          ))}
        {jobNode === null &&
          data.data.label !== "" &&
          data.data.label !== "직업을 입력해주세요." &&
          path === "/plan" && (
            <Button
              onClick={(e) => {
                e.stopPropagation();
                recomendJob(data);
              }}
              sx={{
                fontSize: `${getButtonSize(2)}rem`,
                fontWeight: "600",
                backgroundColor: theme.colors.primary,
                color: "white",
                padding: `5px`,
              }}
            >
              {t("plan.contents.mindmap.nodes.job.buttons.recommend")}
            </Button>
          )}
        {selectedNode?.id === data.id && data.parentId !== undefined && (
          <Button
            onClick={handleDetachNodeInGroup}
            style={{
              fontSize: "0.8rem",
              fontWeight: "600",
              backgroundColor: theme.colors.primary,
              color: "white",
            }}
          >
            {t("plan.contents.mindmap.nodes.job.buttons.detachGroup")}
          </Button>
        )}
        {selectedNode?.id === data.id && isMobile && (
          <Button
            onClick={() => handleDeleteNode(data.id)}
            style={{
              fontSize: "0.8rem",
              fontWeight: "600",
              backgroundColor: theme.colors.primary,
              color: "white",
            }}
          >
            {t("plan.contents.mindmap.nodes.job.buttons.delete")}
          </Button>
        )}
      </NodeToolbar>
      <NodeToolbar isVisible={isExistTodayStatus} position={Position.Right}>
        <Button
          sx={{
            fontSize: "0.8rem",
            fontWeight: "600",
            backgroundColor: theme.colors.primary,
            color: "white",
            "&:hover": { backgroundColor: lighten(0.1, theme.colors.primary) },
          }}
          onClick={() =>
            updateStatus(
              data.id,
              todayStatus.taskStatusId as number,
              (todayStatus.status as number) === 1 ? 0 : 1
            )
          }
        >
          {todayStatus?.status === 1
            ? t("plan.contents.mindmap.nodes.job.wait")
            : t("plan.contents.mindmap.nodes.job.done")}
        </Button>
      </NodeToolbar>
      <Stack
        sx={{
          justifyContent: "center",
          alignItems: "center",
          gap: "10px",
          cursor: "pointer",
          transition: "filter 0.5s ease", // 어두워지는 효과
          filter: isCareerPathNull || isPathActive ? "none" : "brightness(50%)", // 경로에 없으면 어둡게
        }}
        onClick={(e) => {
          if (selectedNode?.id === data.id) {
            e.stopPropagation();
          }
        }}
      >
        <S.Container
          id={`${data.id}`}
          $isActivate={data.data.activate === 1}
          $isSelecting={selectedNode?.id === data.id}
          $color={theme.colors[type]}
          style={{
            transition: "max-height 0.5s ease", // 애니메이션 추가
            maxHeight: maxHeight, // 동적 max-height 설정
            overflow: "hidden", // 넘치는 내용 숨기기
          }}
          ref={contentRef} // contentRef를 참조
        >
          <Stack
            direction={"row"}
            sx={{
              width: "100%",
              alignItems: "end",
              justifyContent: "start",
            }}
          >
            <Box
              sx={{
                background: theme.colors[data.type],
                padding: "10px",
                color: "white",
                borderRadius: "30px",
              }}
            >
              {t(`plan.contents.mindmap.nodes.job.typeLabel.${data.type}`)}
            </Box>
          </Stack>
          {/* 핸들: source/target을 동적으로 설정 */}
          {handlePositions.map((handle) =>
            ["source", "target"].map((type) => (
              <Handle
                key={`${data.id}-${handle.id}-${type}`}
                type={type as HandleType}
                position={handle.position}
                id={`${data.id}-${handle.id}-${type}`}
                className="handle"
                style={{
                  ...handle.additionalStyle,
                  backgroundColor:
                    selectedNode && selectedNode.id === data.id
                      ? "#d9d9d9"
                      : "transparent",
                }}
              />
            ))
          )}
          {selectedNode?.id === data.id || data.data.isNewCreated ? (
            <>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  width: "100%",
                  alignItems: "center",
                }}
              >
                <Input
                  value={data.data.label}
                  onChange={(e) => updateValue(data, "label", e.target.value)}
                  style={{ display: "flex", width: "100%", fontSize: "1.3rem" }}
                />
              </div>

              <div
                style={{
                  display: "flex",
                  width: "100%",
                  fontSize: "1rem",
                  fontWeight: "bold",
                }}
              >
                {t("plan.contents.mindmap.nodes.job.setDate")}
              </div>
              <DatePicker
                label={t("plan.contents.mindmap.nodes.job.startDate")}
                sx={{ width: "100%" }}
                value={dayjs(data.data.startDate)}
                onChange={(date) => updateDate(data, date, "start")}
                shouldDisableDate={
                  data.data.endDate
                    ? (day) => day.isAfter(data.data.endDate)
                    : undefined
                }
                format="YYYY.MM.DD"
              />
              <DatePicker
                label={t("plan.contents.mindmap.nodes.job.endDate")}
                sx={{ width: "100%" }}
                value={dayjs(data.data.endDate)}
                onChange={(date) => updateDate(data, date, "end")}
                shouldDisableDate={
                  data.data.startDate
                    ? (day) => day.isBefore(data.data.startDate)
                    : undefined
                }
                format="YYYY.MM.DD"
              />
              <S.ContentContainer
                onClick={() =>
                  openTextEditorDialog(data.id, data.data.content as string)
                }
              >
                {data.data.content === "" ? (
                  <Typography sx={{ fontSize: "0.8rem" }}>
                    {t("plan.contents.mindmap.nodes.job.content")}
                  </Typography>
                ) : (
                  <TextMarkdown text={data.data.content as string} />
                )}
              </S.ContentContainer>

              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  width: "100%",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Typography
                  sx={{ width: "20%", fontSize: "1rem", fontWeight: "bold" }}
                >
                  {t("plan.contents.mindmap.nodes.job.routine.title")}
                </Typography>
                <ToggleButtonGroup
                  exclusive
                  value={data.data.termType}
                  onChange={(event, newViewType) =>
                    updateRoutineOption(data, newViewType)
                  }
                  color="primary"
                >
                  <ToggleButton
                    value={0}
                    selected={data.data.termType === 0}
                    onClick={() => updateRoutineOption(data, 0)}
                  >
                    {t("plan.contents.mindmap.nodes.job.routine.everyday")}
                  </ToggleButton>
                  <ToggleButton
                    value={1}
                    selected={data.data.termType === 1}
                    onClick={() => updateRoutineOption(data, 1)}
                  >
                    {t("plan.contents.mindmap.nodes.job.routine.everyweek")}
                  </ToggleButton>
                  <ToggleButton
                    value={2}
                    selected={data.data.termType === 2}
                    onClick={() => updateRoutineOption(data, 2)}
                  >
                    {t("plan.contents.mindmap.nodes.job.routine.everymonth")}
                  </ToggleButton>
                </ToggleButtonGroup>
              </Box>

              {(data.data.termType === 1 || data.data.termType === 2) && (
                <>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      width: "100%",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Typography sx={{ fontSize: "1rem", fontWeight: "bold" }}>
                      {t("plan.contents.mindmap.nodes.job.repeat")}
                    </Typography>
                    <CustomBackgroundColorButton
                      text={t("plan.contents.mindmap.nodes.job.buttons.edit")}
                      onClick={() =>
                        setEditRoutineDialog({
                          open: data.id,
                          termType: data.data.termType,
                          termData: data.data.termData,
                        })
                      }
                    />
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      width: "100%",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Typography sx={{ fontSize: "1rem", fontWeight: "bold" }}>
                      {data.data.termType === 1
                        ? t("plan.contents.mindmap.nodes.job.routine.everyweek")
                        : t(
                            "plan.contents.mindmap.nodes.job.routine.everymonth"
                          )}
                    </Typography>
                    <Box
                      sx={{
                        display: "grid",
                        gridTemplateColumns: `repeat(${
                          data.data.termData && data.data.termData.length < 7
                            ? data.data.termData.length
                            : 7
                        }, 1fr)`,
                        gap: "5px",
                      }}
                    >
                      {data.data.termData?.map((interval: string) => (
                        <Typography
                          key={interval}
                          sx={{ fontSize: "1rem", fontWeight: "bold" }}
                        >
                          {interval}
                        </Typography>
                      ))}
                    </Box>
                  </Box>
                </>
              )}

              <TextField
                id="outlined-basic"
                label={t("plan.contents.mindmap.nodes.job.location")}
                variant="outlined"
                value={data.data.location.address || ""}
                onChange={(e) => updateLocation(data, e.target.value)}
                style={{ width: "100%" }}
              />

              <S.TagInputContainer>
                {data.data.hashtags.map(
                  (
                    tag: {
                      backgroundColor: string;
                      textColor: string;
                      value: string;
                    },
                    idx: number
                  ) => {
                    return (
                      <S.TagItem
                        key={idx}
                        style={{
                          backgroundColor: tag.backgroundColor,
                          color: tag.textColor,
                        }}
                      >
                        <span>{tag.value}</span>
                      </S.TagItem>
                    );
                  }
                )}
                <S.TagInput
                  placeholder={
                    data.data.hashtags.length === 0
                      ? t("plan.contents.mindmap.nodes.job.hashtag")
                      : ""
                  }
                  value={inputValue}
                  onChange={(e) => setInputValue(e.target.value)}
                  onKeyDown={handleKeyDown}
                />
              </S.TagInputContainer>

              <div
                style={{
                  display: "flex",
                  width: "100%",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                {data.data.activate === 1 ? (
                  <CustomColorButton
                    text={t("plan.contents.mindmap.nodes.job.activate")}
                    onClick={() =>
                      setInactivateDialog({ open: true, node: data })
                    }
                    fontSize="1.2rem"
                  />
                ) : (
                  <CustomColorButton
                    text={t("plan.contents.mindmap.nodes.job.inactive")}
                    onClick={() =>
                      setInactivateDialog({ open: true, node: data })
                    }
                    fontSize="1.2rem"
                  />
                )}
                <CustomColorButton
                  text={t("plan.contents.mindmap.nodes.job.together")}
                  onClick={() => setTogetherDialog({ node: data })}
                  fontSize="1.2rem"
                />
              </div>
            </>
          ) : (
            <>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  width: "100%",
                  alignItems: "center",
                }}
              >
                {/* <Input value={data.data.label} onClick={(e) => e.stopPropagation()} onChange={(e) => updateValue(data, "label", e.target.value)} style={{ display: "flex", width: "100%", fontSize: "1.3rem" }} /> */}
                <Typography style={{ fontSize: "1.3rem" }}>
                  {data.data.label}
                </Typography>
              </div>
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  fontSize: "1rem",
                  justifyContent: isExistTodayStatus
                    ? "space-between"
                    : "flex-end",
                  color: `${
                    !data.data.startDate || !data.data.endDate
                      ? "black"
                      : theme.colors.primary
                  }`,
                  fontWeight: "bold",
                }}
              >
                <div style={{ color: "black" }}>
                  {isExistTodayStatus
                    ? todayStatus?.status === 1
                      ? t("plan.contents.mindmap.nodes.job.buttons.done")
                      : t("plan.contents.mindmap.nodes.job.buttons.wait")
                    : ""}
                </div>
                <div>
                  {!data.data.startDate || !data.data.endDate
                    ? t("plan.contents.mindmap.nodes.job.noSetDate")
                    : getRemainingDays(data.data.startDate, data.data.endDate)}
                </div>
              </div>
              <div style={{ marginTop: "10px", width: "100%" }}>
                <LinearProgress
                  variant="determinate"
                  value={getProgressPercentage(
                    data.data.startDate,
                    data.data.endDate
                  )}
                  sx={{
                    height: "20px",
                    borderRadius: "5px",
                    backgroundColor: "#e0e0e0",
                    "& .MuiLinearProgress-bar": {
                      backgroundColor: theme.colors.primary, // 커스텀 색상 적용
                    },
                  }}
                />
              </div>
            </>
          )}
        </S.Container>
      </Stack>
    </>
  );
};

export default JobNode;
