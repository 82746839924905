import FullCalendar from "@fullcalendar/react";
import timeGridPlugin from "@fullcalendar/timegrid";
import React from 'react';
import { getDailyCalendarDataAtom } from "@/ViewModels/Plan/Routine/DataViewModel";
import { useAtomValue, useSetAtom } from "jotai";
import { pushRoutineSidebarDataAtom } from "@/ViewModels/Plan/Routine/SidebarViewModel";
import { Node } from "@xyflow/react";
import { TaskStatus } from "@/Types/Plan";
import dayjs from "dayjs";
import { CheckCircle } from "@mui/icons-material";
import * as S from './styled';
import theme from "@/Styles/theme";
import koLocale from "@fullcalendar/core/locales/ko";
import enLocale from "@fullcalendar/core/locales/en-gb";
import { userLanguageAtom } from "@/ViewModels/UserViewModel";

interface WeeklyCalendarProps {
  calendarRef: React.RefObject<FullCalendar>;
  calendarHeight: number;
}

const WeeklyCalendar = ({ calendarRef, calendarHeight }: WeeklyCalendarProps) => {
  const calendarEvents = useAtomValue(getDailyCalendarDataAtom);
  const openSidebar = useSetAtom(pushRoutineSidebarDataAtom);
  const userLanguage = useAtomValue(userLanguageAtom);

  const handleDetailClick = (task: Node, date: string) => {
    const taskStatus = task.data.taskStatus as TaskStatus[];
    const dateTaskStatus = taskStatus.find((status) => status.date === dayjs(date).format("YYYY-MM-DD"));

    openSidebar({
      type: "task",
      key: task,
      tasks: [],
      taskStatus: dateTaskStatus,
    })
  }

  return (
    <FullCalendar
      ref={calendarRef}
      initialView="timeGridWeek"
      plugins={[timeGridPlugin]}
      events={calendarEvents}
      height={calendarHeight}
      eventClick={(info) => handleDetailClick(info.event.extendedProps.task as Node, info.event.extendedProps.date)}
      dayMaxEvents={true}
      dayMaxEventRows={1}
      headerToolbar={false}
      locale={userLanguage === "KR" ? koLocale : enLocale}
      slotLabelFormat={{
        hour: "numeric", // 시간만 표시
        omitZeroMinute: true, // 00분 생략
        meridiem: userLanguage === "KR" ? "short" : undefined // 한국어면 오전/오후
      }}
      allDaySlot={true} // 하루 종일 슬롯 활성화
      allDayText={userLanguage === "KR" ? "종일" : "All day"} // 하루 종일 슬롯 텍스트
      eventClassNames={(arg) => {
        if (arg.event.start && arg.event.end) {
          // 긴 이벤트인 경우 클래스 추가
          return ["fc-long-event"];
        }
        return [];
      }}
      eventContent={(eventInfo) => {
        const { colors, task, date } = eventInfo.event.extendedProps;
        const taskStatus = task.data.taskStatus as TaskStatus[];
        const dateTaskStatus = taskStatus.find((status) => status.date === dayjs(date).format("YYYY-MM-DD"));
        const isCompleted = dateTaskStatus?.status === 1;

        return (
          <S.CalenderEvent>
            {/* 동그라미 컬러 */}
            {
              colors.length > 0 &&
              <div style={{ display: "flex", alignItems: "center", flexDirection: "row" }}>
                {
                  (colors as string[]).slice(0, 3).map((color, index) => (
                    <div
                      key={index}
                      style={{
                        width: "10px",
                        height: "10px",
                        backgroundColor: color,
                        borderRadius: "50%",
                        marginLeft: index === 0 ? 0 : "-6px",
                      }}
                    ></div>
                  ))
                }
                {
                  (colors as string[]).length > 3 &&
                  <div style={{
                    width: "10px",
                    height: "10px",
                    backgroundColor: "gray",
                    borderRadius: "50%",
                    marginLeft: "-6px",
                    fontSize: "6px",
                  }}
                  >
                    +{(colors as string[]).length - 3}
                  </div>
                }
              </div>
            }
            {/* 타이틀 */}
            <div
              style={{
                flex: 1,
                display: "flex",
                alignItems: "center",
                gap: "4px",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap", // 한 줄로 유지
                fontSize: "10px",
              }}
            >
              {eventInfo.event.title}
              {isCompleted && (
                <CheckCircle
                  sx={{ color: theme.colors.primary, width: "10px", height: "10px" }}
                />
              )}
            </div>
          </S.CalenderEvent>
        );
      }}
    />
  )
}

export default WeeklyCalendar;
