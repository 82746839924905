import React from 'react';
import { RecommendPlanItem } from '@/Atoms/RecommendPlan/Atom';
import theme from '@/Styles/theme';
import { getRecommendPlanListAtom } from '@/ViewModels/RecommendPlan/PlanListViewModel';
import { useAtom, useSetAtom } from 'jotai';
import { recommendPlanModalOpenAtom } from '@/ViewModels/RecommendPlan/PlanInfoModalViewModel';
import { Chip, Grid, useMediaQuery } from '@mui/material';
import * as S from './styled';

interface SortConfig {
  key: keyof RecommendPlanItem | null; // Plan의 속성 중 하나
  direction: "asc" | "desc";
}

const RecommendPlanTable = () => {
  const isXl = useMediaQuery("(min-width:1200px)");
  const isLg = useMediaQuery("(min-width:992px) and (max-width:1199px)");
  const isMd = useMediaQuery("(min-width:768px) and (max-width:991px)");
  const isSm = useMediaQuery("(max-width:767px) and (min-width:600px)");
  const isXs = useMediaQuery("(max-width:599px)");
  const [itemsPerRow, setItemsPerRow] = React.useState(0);
  const [plans, setPlans] = useAtom(getRecommendPlanListAtom);
  const openModal = useSetAtom(recommendPlanModalOpenAtom);


  // 반응형 항목 수 설정
  React.useEffect(() => {
    if (isXl) {
      setItemsPerRow(7);
    } else if (isLg) {
      setItemsPerRow(5);
    } else if (isMd) {
      setItemsPerRow(3);
    } else if (isSm) {
      setItemsPerRow(2);
    } else if (isXs) {
      setItemsPerRow(1);
    }
  }, [isXl, isLg, isMd, isSm, isXs]);

  return (
    <Grid
      container
      spacing={2} // 간격 추가
      sx={{ flex: 1, overflowY: "auto", overflowX: "hidden", padding: "10px" }}
      justifyContent="flex-start" // 왼쪽 정렬
      display="flex"
    >
      {plans.map((item, index) => (
        <Grid
          item
          xs={12 / itemsPerRow} // 한 줄당 항목 수를 컨트롤
          key={item.planId}
          sx={{ display: "flex", flexDirection: "column", alignItems: "center", cursor: "pointer", height: "250px", maxHeight: "300px" }}
          onClick={() => openModal(item)}
        >
          <S.Image
            src={
              item.imageUrl
                ? `${process.env.REACT_APP_CAREER_PLANNER_PLAN_MEDIA_ADDRESS}${item.imageUrl.split("/media")[1]}`
                : "https://media.istockphoto.com/id/1054315098/ko/%EB%B2%A1%ED%84%B0/%EA%B0%84%EB%8B%A8%ED%95%9C-%EB%B8%94%EB%9E%99-%EC%86%90%EC%9C%BC%EB%A1%9C-%EA%B7%B8%EB%A6%B0%EB%90%9C-%EB%A7%88%EC%9D%B8%EB%93%9C-%EB%A7%B5.jpg?s=612x612&w=0&k=20&c=d0QonRWfSSL2jL2nWzHpTxxyUWDoY_X4_btGuSO2BzY="
            }
            alt="image"
            style={{
              width: "100%",
              height: "150px",
              objectFit: "cover",
              borderRadius: "8px",
            }}
          />
          <div
            style={{
              textOverflow: "ellipsis",
              overflow: "hidden",
              whiteSpace: "nowrap",
              width: "100%",
              textAlign: "center",
              fontSize: "14px",
              fontWeight: "bold",
              marginTop: "8px",
            }}
          >
            {item.planName}
          </div>
          {item.hashtag.length > 0 && (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                gap: "5px",
                flexWrap: "wrap",
                width: "100%",
                justifyContent: "center",
                marginTop: "5px",
              }}
            >
              {item.hashtag.slice(0, 1).map((tag, index) => (
                <Chip
                  key={index}
                  label={tag.value}
                  size="small"
                  sx={{
                    backgroundColor: tag.backgroundColor,
                    color: tag.textColor,
                    fontSize: "12px",
                    height: "20px",
                  }}
                />
              ))}
            </div>
          )}
        </Grid>
      ))}
    </Grid>
  );
}

export default RecommendPlanTable;
