import { atom } from "jotai";

interface CreateFeedModalProps {
  id: number;
  name: string;
  content: string;
  startDate: string | null;
  endDate: string | null;
  location: string;
  hashtags: {
    backgroundColor: string;
    textColor: string;
    value: string;
  }[];
  createdAt: string;
  imgSrcs: string[];
}

const createFeedModalAtom = atom<CreateFeedModalProps | null>(null);

export default createFeedModalAtom;
