import { deleteTaskModalOpenAtom } from '@/Atoms/Plan/RoutineAtom';
import CustomBackgroundColorButton from '@/Components/Common/Buttons/CustomBackgroundColor';
import CustomColorButton from '@/Components/Common/Buttons/CustomColor';
import { deleteTaskInRoutineAtom } from '@/ViewModels/Plan/Routine/TaskViewModel';
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { useAtom, useSetAtom } from 'jotai';
import React from 'react';
import { useTranslation } from 'react-i18next';

const DeleteTaskModal = () => {
  const [modal, setModal] = useAtom(deleteTaskModalOpenAtom);
  const { t } = useTranslation();
  const deleteRoutine = useSetAtom(deleteTaskInRoutineAtom);

  return (
    modal &&
    <Dialog open={modal.id !== null} onClose={() => setModal({ id: null, loading: false })} fullWidth maxWidth="xs">
      <DialogTitle sx={{ fontSize: "0.9rem", fontWeight: "bold" }}>{t("plan.contents.routine.modals.deleteTask.title")}</DialogTitle>
      <DialogContent sx={{ fontSize: "0.8rem", display: "flex", flexDirection: "column", gap: "10px" }}>
        <div>
          {t("plan.contents.routine.modals.deleteTask.description")}
        </div>
      </DialogContent>
      <DialogActions>
        <CustomColorButton onClick={() => setModal({ id: null, loading: false })} text={t("plan.contents.routine.modals.deleteTask.cancel")} fontSize="0.8rem" color="black" />
        <CustomBackgroundColorButton onClick={() => deleteRoutine(t)} text={t("plan.contents.routine.modals.deleteTask.delete")} fontSize="0.8rem" disabled={modal.loading} />
      </DialogActions>
    </Dialog>
  )
}

export default DeleteTaskModal;
