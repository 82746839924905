import { editCrewImageModalAtom } from '@/Atoms/Crew';
import crewAtom from '@/Atoms/CrewAtom';
import { error401ModalAtom } from '@/Atoms/Dialogs/Error/401Atom';
import { useCrewAtom } from '@/Atoms/HookControllerAtom';
import snackbarAtom from '@/Atoms/Snackbar';
import { updateCrewImage } from '@/Queries/CrewQueries';
import { handleReactQueryApiResponse } from '@/Utils/APIUtil';
import GetIdFromQuerystring from '@/Utils/GetIdFromQuerystring';
import { TFunction } from 'i18next';
import { atom } from 'jotai';

export const editCrewImageModalOpenAtom = atom(null, (get, set) => {
  set(editCrewImageModalAtom, { open: true, preview: null, file: null });
});

export const editCrewImageModalCloseAtom = atom(null, (get, set) => {
  set(editCrewImageModalAtom, { open: false, preview: null, file: null });
});

export const editCrewImageModalUpdatePreviewAtom = atom(null, (get, set, preview: string) => {
  set(editCrewImageModalAtom, (prev) => ({ ...prev, preview }));
});

export const editCrewImageModalUpdateFileAtom = atom(null, (get, set, file: File) => {
  set(editCrewImageModalAtom, (prev) => ({ ...prev, file }));
});

export const editCrewImageModalSaveImageAtom = atom(null, async (get, set, t: TFunction) => {
  const modal = get(editCrewImageModalAtom);
  const crewId = GetIdFromQuerystring("crew_id");
  const { file, preview } = modal;
  if (!file || !preview || !crewId) return;
  // Save Image
  const formData = new FormData();
  formData.append('imageFile', file);

  const handleError401Modal = () => set(error401ModalAtom, true);

  try {
    // 서버로 이미지 업로드 요청
    const response = await handleReactQueryApiResponse(updateCrewImage, handleError401Modal, crewId, formData);

    if (response.ok) {
      set(snackbarAtom, { open: true, message: t("crew.editImage.snackbar.success"), severity: 'success' });
      setTimeout(() => set(useCrewAtom, true), 1000);
    } else {
      set(snackbarAtom, { open: true, message: t("crew.editImage.snackbar.fail"), severity: 'error' });
    }
  } catch (error) {
    set(snackbarAtom, { open: true, message: t("crew.editImage.snackbar.fail"), severity: 'error' });
  }
  set(editCrewImageModalCloseAtom);
});
