import React from "react";
import * as S from "./styled";
import {
  Box,
  IconButton,
  ListItemIcon,
  MenuItem,
  Typography,
} from "@mui/material";
import ChatBubbleOutlineIcon from "@mui/icons-material/ChatBubbleOutline";
import SearchIcon from "@mui/icons-material/Search";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { ArrowForward, Pending } from "@mui/icons-material";
import { useAtom, useAtomValue, useSetAtom } from "jotai";
import { useTranslation } from "react-i18next";
import { ex } from "@fullcalendar/core/internal-common";
import { chatInputValueAtom, chattingTypeAtom, ChatType, isAIMakingResponseAtom, isChattingAskAtom, searchInputValueAtom } from "@/Atoms/DokgabiChat/Atom";
import { handleCloseAtom } from "@/ViewModels/HelperViewModel";
import { InputMessageHandleCloseAndChangeTypeAtom, InputMessageHandleInputFieldKeyDownAtom, InputMessageHandleInputValueAtom, InputMessageHandleSendBtnAtom, InputMessageWhenDownButtonClickAtom, InputMessageWhenUpButtonClickAtom } from "@/ViewModels/Chat/InputViewModel";

const InputMessage = () => {
  const { t } = useTranslation();
  const chattingType = useAtomValue(chattingTypeAtom);
  const [isChattingAsk, setIsChattingAsk] = useAtom(isChattingAskAtom);
  // const [, handleClick] = useAtom(handleClickAtom);
  const handleClick = (e: React.MouseEvent<HTMLDivElement>) => setIsChattingAsk(e.currentTarget);
  const handleClose = () => setIsChattingAsk(null);
  const handleCloseAndChange = useSetAtom(InputMessageHandleCloseAndChangeTypeAtom);
  const isAIMakingResponse = useAtomValue(isAIMakingResponseAtom);
  const chatInputValue = useAtomValue(chatInputValueAtom);
  const searchInputValue = useAtomValue(searchInputValueAtom);
  const handleInputValue = useSetAtom(InputMessageHandleInputValueAtom);
  const onKeyDown = useSetAtom(InputMessageHandleInputFieldKeyDownAtom);
  const sendMessage = useSetAtom(InputMessageHandleSendBtnAtom);
  const whenDownButtonClick = useSetAtom(InputMessageWhenDownButtonClickAtom);
  const whenUpButtonClick = useSetAtom(InputMessageWhenUpButtonClickAtom);

  return (
    <>
      <S.CustomMenu
        anchorEl={isChattingAsk}
        id="account-menu"
        open={Boolean(isChattingAsk)}
        onClose={handleClose}
        onClick={handleClose}
        transformOrigin={{ horizontal: "left", vertical: "top" }} // 변경
        anchorOrigin={{ horizontal: "left", vertical: "top" }} // 변경
      >
        <MenuItem onClick={() => handleCloseAndChange(ChatType.ASK)}>
          <ListItemIcon>
            <ChatBubbleOutlineIcon />
          </ListItemIcon>
          <Typography>{t("makeTextHelper.chatInput.searchQuestion")}</Typography>
        </MenuItem>
        <MenuItem onClick={() => handleCloseAndChange(ChatType.SEARCH)}>
          <ListItemIcon>
            <SearchIcon />
          </ListItemIcon>
          <Typography>{t("makeTextHelper.chatInput.searchConversation")}</Typography>
        </MenuItem>
      </S.CustomMenu>

      <S.InputContainer>
        <S.ChattingTypeButton onClick={handleClick}>
          {chattingType === ChatType.ASK ? (
            <ChatBubbleOutlineIcon sx={{ color: "white" }} />
          ) : (
            <SearchIcon sx={{ color: "white" }} />
          )}
          <KeyboardArrowDownIcon sx={{ color: "white" }} />
        </S.ChattingTypeButton>

        <S.CustomInputBase
          className="chatbot-inputbse-custom"
          placeholder={
            chattingType === ChatType.ASK
              ? isAIMakingResponse
                ? t("makeTextHelper.chatInput.creatingAnswer")
                : t("makeTextHelper.chatInput.inputQuestion")
              : t("makeTextHelper.chatInput.inputKeyword")
          }
          multiline
          maxRows={9}
          value={
            chattingType === ChatType.ASK ? chatInputValue : searchInputValue
          }
          onChange={
            isAIMakingResponse
              ? () => null
              : (e) => handleInputValue(e.target.value)
          }
          onKeyDown={
            chattingType === ChatType.ASK && !isAIMakingResponse
              ? (e) => onKeyDown(e)
              : () => null
          }
        />

        <Box
          sx={{
            background: isAIMakingResponse
              ? "#06C25E"
              : chattingType === ChatType.ASK
                ? chatInputValue === ""
                  ? "#8f8f8f"
                  : "#06C25E"
                : searchInputValue === ""
                  ? "#8f8f8f"
                  : "#06C25E",
            width: "auto",
            height: "40px",
            borderRadius: "20px",
            boxSizing: "border-box",
            padding: "8px",
            display: "flex",
            flexDirection: "row",
            gap: "5px",
          }}
        >
          {chattingType === ChatType.ASK ? (
            !isAIMakingResponse ? (
              <IconButton onClick={() => sendMessage()} sx={{ padding: 0 }}>
                <ArrowForward sx={{ color: "white" }} />
              </IconButton>
            ) : (
              <IconButton sx={{ padding: 0 }}>
                <Pending sx={{ color: "white" }} />
              </IconButton>
            )
          ) : (
            <>
              <IconButton
                onClick={() => whenDownButtonClick()}
                sx={{ padding: 0 }}
              >
                <KeyboardArrowDownIcon sx={{ color: "white" }} />
              </IconButton>
              <IconButton
                onClick={() => whenUpButtonClick()}
                sx={{ padding: 0 }}
              >
                <KeyboardArrowUpIcon sx={{ color: "white" }} />
              </IconButton>
            </>
          )}
        </Box>
      </S.InputContainer>
    </>
  );
}

export default InputMessage;
