import theme from "@/Styles/theme";
import { lighten } from "polished";
import styled from "styled-components";
import SearchIcon from "@mui/icons-material/Search";

// SearchIcon 커스텀 스타일링
export const StyledSearchIcon = styled(SearchIcon)`
  color: ${theme.colors.primary};
  cursor: pointer;
  transition: color 0.2s ease-in-out;

  &:hover {
    color: ${lighten(0.2, theme.colors.primary)}; /* lighten을 사용해 색상 밝게 */
  }
`;

// 스타일링된 Input 컨테이너
export const SearchContainer = styled.div`
  display: flex;
  align-items: center;
  border: 2px solid ${theme.colors.primary}; /* 테두리 색상 */
  border-radius: 25px; /* 둥근 테두리 */
  padding: 8px 12px;
  width: 100%;
  max-width: 500px; /* 최대 너비 */
  box-sizing: border-box;

  &:focus-within {
    border-color: ${theme.colors.primary}; /* 포커스 상태에서도 같은 테두리 */
  }

  &:hover {
    border-color: ${lighten(0.1, theme.colors.primary)};
  }
`;

export const StyledInput = styled.input`
  border: none;
  outline: none;
  flex: 1; /* Input이 남은 공간 채우기 */
  font-size: 14px;
  color: #333;

  &::placeholder {
    color: #a0a0a0; /* placeholder 색상 */
  }
`;


export const SearchInputList = styled.div<{ $expanded: boolean }>`
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  flex-wrap: wrap;
  max-height: ${({ $expanded }: { $expanded: boolean }) => ($expanded ? "none" : "32px")}; /* 줄 제한 */
  overflow: hidden;

  @media (max-width: 768px) {
    gap: 5px;
  }
`;

export const ToggleButton = styled.button`
  background-color: #f0f0f0;
  border: none;
  border-radius: 20px;
  padding: 5px 10px;
  font-size: 0.8rem;
  font-weight: bold;
  color: #333;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
  margin-top: -10px;

  &:hover {
    background-color: #e0e0e0;
  }

  @media (max-width: 768px) {
    margin-top: 0;
  }
`;
