import { error401ModalAtom } from '@/Atoms/Dialogs/Error/401Atom';
import innerTabDataAtom from '@/Atoms/Plan/InnerTabDataAtom';
import { connectedRoutinesAtom, createRoutineInRoutineModalAtom } from '@/Atoms/Plan/RoutineAtom';
import snackbarAtom from '@/Atoms/Snackbar';
import { createTaskV2 } from '@/Queries/PlanQueries';
import theme from '@/Styles/theme';
import { handleReactQueryApiResponse } from '@/Utils/APIUtil';
import GetIdFromQuerystring from '@/Utils/GetIdFromQuerystring';
import { atom } from 'jotai';
import { setInnerTabDataAtom } from '../InnerTabViewModel';
import { TFunction } from 'i18next';

export const openCreateRoutineInRoutineModalAtom = atom(null, (_, set, label: string) => {
  const modal = {
    label,
    color: theme.colors.primary,
    content: "",
    isLoading: false,
  }
  set(createRoutineInRoutineModalAtom, modal);
})

export const addRoutineInCreateRoutineInRoutineModalAtom = atom(null, async (get, set, t: TFunction) => {
  const connectedRoutines = get(connectedRoutinesAtom);
  const modal = get(createRoutineInRoutineModalAtom);
  const planData = get(innerTabDataAtom);
  const innerTabId = GetIdFromQuerystring("inner_tab_id");
  const plan = planData.find((data) => data.innerTabId === innerTabId);
  if (!modal || !plan) return;
  if (modal.label === "") {
    set(snackbarAtom, { open: true, message: t("plan.contents.routine.snackbar.inputRoutineName"), severity: "error" });
    return;
  }
  if (modal.label.length > 50) {
    set(snackbarAtom, { open: true, message: t("plan.contents.routine.snackbar.editNameLength"), severity: "error" });
    return;
  }
  set(createRoutineInRoutineModalAtom, { ...modal, isLoading: true });
  const requestData = {
    label: modal.label,
    color: modal.color,
    content: modal.content,
    type: "routine",
  }

  const response = await handleReactQueryApiResponse(createTaskV2, () => set(error401ModalAtom, true), innerTabId, requestData);
  if (!response.ok) {
    set(snackbarAtom, { open: true, message: t("plan.contents.routine.snackbar.addRoutineFail"), severity: "error" });
    set(createRoutineInRoutineModalAtom, { ...modal, isLoading: false });
    return;
  }

  const responseJson = await response.json();

  const nodes = responseJson.nodes;

  const responseRoutine = nodes[0];

  const newRoutine = {
    id: responseRoutine.data.backendId as number,
    label: modal.label,
    isCreate: false,
    color: modal.color,
  };

  const newRoutines = [...connectedRoutines, newRoutine];

  const newNodes = [...plan.nodes, responseRoutine];
  set(setInnerTabDataAtom, { innerTabId, nodes: newNodes, edges: plan.edges });

  set(connectedRoutinesAtom, newRoutines);
  set(createRoutineInRoutineModalAtom, null);
  set(snackbarAtom, { open: true, message: t("plan.contents.routine.snackbar.addRoutineSuccess"), severity: "success" });
});
