import { Tab } from '@mui/material';
import { styled as muiStyled } from "@mui/material/styles";
import { lighten } from 'polished';

export const CustomTab = muiStyled(Tab)(({ theme }) => ({
  color: 'gray', // default color
  borderBottom: `2px solid transparent`, // default border
  padding: '6px 10px',
  '&.Mui-selected': {
    color: '#06C25E', // active color
    fontWeight: 'bold', // additional styling for selected tab
    borderBottom: `2px solid #06C25E`, // active border
  },
  '&:hover': {
    color: lighten(0.1, "#06C25E"), // hover color
    backgroundColor: theme.palette.action.hover,
  },
}));
