import React from 'react';
import { Button, Menu, MenuItem } from '@mui/material';

interface DropdownButtonProps {
  label: string;
  items: { name: string; value: string }[];
  onSelect: (item: string) => void;
  startIcon?: React.ReactNode;
  endIcon?: React.ReactNode;
}

const DropdownButton: React.FC<DropdownButtonProps> = ({
  label,
  items,
  onSelect,
  startIcon,
  endIcon,
}) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (item: string) => {
    onSelect(item);
    handleClose();
  };

  return (
    <>
      <Button
        sx={{
          color: "black",
          fontWeight: "bold",
          padding: 0,
          textTransform: "none",
          fontSize: "1rem",
        }}
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        startIcon={startIcon}
        endIcon={endIcon}
      >
        {label}
      </Button>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        {items.map((item, index) => (
          <MenuItem key={index} onClick={() => handleMenuItemClick(item.value)}>
            {item.name}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default DropdownButton;
