export interface NodeData {
  id: string;
  type: string;
  activate: number;
}

export interface EdgeData {
  id: string;
  source: string;
  target: string;
}

// 재귀적으로 가장 깊은 경로를 탐색하는 함수
const findDeepestPath = (
  currentNodeId: string,
  nodes: NodeData[],
  edges: EdgeData[],
  currentPath: { type: 'node' | 'edge'; id: string }[] = []
): { type: 'node' | 'edge'; id: string }[] => {
  // 현재 경로에 현재 노드를 추가
  currentPath.push({ type: 'node', id: currentNodeId });

  // 현재 노드의 상태가 비활성화인 경우 현재 경로 반환
  const currentNode = nodes.find(node => node.id === currentNodeId);
  if (!currentNode || currentNode.activate === 2) {
    return currentPath;
  }

  // 현재 노드에서 연결된 엣지들을 찾는다
  const connectedEdges = edges.filter(edge => edge.source === currentNodeId);

  // 연결된 노드가 없는 경우 (리프 노드에 도달한 경우) 현재 경로 반환
  if (connectedEdges.length === 0) {
    return currentPath;
  }

  let longestPath: { type: 'node' | 'edge'; id: string }[] = [];

  // 연결된 각 노드에 대해 재귀적으로 탐색
  for (const edge of connectedEdges) {
    const nextNodeId = edge.target;
    const newPath = findDeepestPath(nextNodeId, nodes, edges, [...currentPath, { type: 'edge', id: edge.id }]);

    // 가장 긴 경로를 찾음
    if (newPath.length > longestPath.length) {
      longestPath = newPath;
    }
  }

  return longestPath;
}

// 가장 길게 연결된 경로를 찾는 함수
const getLongestPath = (nodes: NodeData[], edges: EdgeData[]): { type: 'node' | 'edge'; id: string }[] => {
  let longestPath: { type: 'node' | 'edge'; id: string }[] = [];

  // 각 노드를 시작점으로 하여 가장 긴 경로를 탐색
  for (const node of nodes) {
    const path = findDeepestPath(node.id, nodes, edges);
    if (path.length > longestPath.length) {
      longestPath = path;
    }
  }

  return longestPath;
}

export default getLongestPath;
