import React from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from "@mui/material";
import { useAtom, useAtomValue, useSetAtom } from "jotai";
import deleteRoutineDialogAtom from '@/Atoms/Dialogs/Delete/RoutineAtom';
import theme from '@/Styles/theme';
import { deleteInnerTabAtom } from '@/ViewModels/Plan/InnerTabViewModel';
import { useNavigate } from 'react-router-dom';
import GetIdFromQuerystring from '@/Utils/GetIdFromQuerystring';
import { planHeaderChangeViewTypeAtom } from '@/ViewModels/Plan/HeaderViewModel';
import CustomColorButton from '@/Components/Common/Buttons/CustomColor';
import { currentPlanAtom } from '@/Atoms/Plan';

const DeleteRoutineDialog = () => {
  const [deleteRoutineDialog, setDeleteRoutineDialog] = useAtom(deleteRoutineDialogAtom);
  const deleteRoutine = useSetAtom(deleteInnerTabAtom);
  const planId = GetIdFromQuerystring("id");
  const plan = useAtomValue(currentPlanAtom);
  const defaultTabId = plan?.innerTabs[0].id || 0;
  const navigate = useNavigate();
  const changeViewType = useSetAtom(planHeaderChangeViewTypeAtom);

  const handleConfirm = () => {
    setDeleteRoutineDialog({ open: false, complete: false });
    navigate(`/plan?id=${planId}&inner_tab_id=${defaultTabId}`);
    changeViewType("mindmap");
  }

  return (
    <Dialog open={deleteRoutineDialog.open} onClose={() => setDeleteRoutineDialog({ open: false, complete: false })} fullWidth maxWidth="xs">
      <DialogTitle sx={{ fontSize: "1rem", fontWeight: "bold" }}>루틴 삭제</DialogTitle>
      <DialogContent >
        {
          deleteRoutineDialog.complete ?
            <Typography sx={{ fontSize: "0.9rem" }}>루틴이 삭제되었습니다.</Typography>
            :
            <>
              <Typography sx={{ fontSize: "0.9rem" }}>정말로 삭제하시겠습니까?</Typography>
              <Typography sx={{ fontSize: "0.9rem" }}>삭제한 루틴은 복구할 수 없습니다.</Typography>
            </>
        }
      </DialogContent>
      <DialogActions>
        {
          deleteRoutineDialog.complete ?
            <CustomColorButton onClick={handleConfirm} text="확인" fontSize="0.8rem" /> :
            <>
              <CustomColorButton onClick={() => setDeleteRoutineDialog({ open: false, complete: false })} text="취소" fontSize="0.8rem" color="black" />
              <CustomColorButton onClick={deleteRoutine} text="삭제" fontSize="0.8rem" />
            </>
        }
      </DialogActions>
    </Dialog>
  );
};

export default DeleteRoutineDialog;
