import { editCrewImageModalAtom } from '@/Atoms/Crew';
import CustomBackgroundColorButton from '@/Components/Common/Buttons/CustomBackgroundColor';
import CustomColorButton from '@/Components/Common/Buttons/CustomColor';
import { editCrewImageModalCloseAtom, editCrewImageModalSaveImageAtom, editCrewImageModalUpdateFileAtom, editCrewImageModalUpdatePreviewAtom } from '@/ViewModels/Crew/EditImageModalViewModel';
import { Upload } from '@mui/icons-material';
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { useAtomValue, useSetAtom } from 'jotai';
import React from 'react';
import { useTranslation } from 'react-i18next';

const EditCrewImageModal = () => {
  const { t } = useTranslation();
  const modal = useAtomValue(editCrewImageModalAtom);
  const close = useSetAtom(editCrewImageModalCloseAtom);
  const updateFile = useSetAtom(editCrewImageModalUpdateFileAtom);
  const updatePreview = useSetAtom(editCrewImageModalUpdatePreviewAtom);
  const handleSaveImage = useSetAtom(editCrewImageModalSaveImageAtom);

  const fileInputRef = React.useRef<HTMLInputElement>(null);

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      const preview = URL.createObjectURL(file);
      updateFile(file);
      updatePreview(preview);
    }
  };

  return (
    <Dialog open={modal.open} onClose={close} fullWidth maxWidth="xs">
      <DialogTitle sx={{ fontSize: '0.9rem', fontWeight: 'bold' }}>{t("crew.editImage.title")}</DialogTitle>
      <DialogContent sx={{ fontSize: '0.8rem' }}>
        <div
          style={{
            width: '90%',
            height: '150px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            border: '2px dashed #c0c0c0',
            borderRadius: '5px',
            margin: '0 auto',
            cursor: 'pointer',
            position: 'relative',
            backgroundColor: modal.preview ? '#f9f9f9' : 'transparent',
          }}
          onClick={() => fileInputRef.current?.click()}
          onMouseOver={(e) => (e.currentTarget.style.borderColor = '#808080')}
          onMouseOut={(e) => (e.currentTarget.style.borderColor = '#c0c0c0')}
        >
          {modal.preview ? (
            <img
              src={modal.preview}
              alt="preview"
              style={{ width: '150px', height: '150px', objectFit: 'cover', borderRadius: '5px' }}
            />
          ) : (
            <>
              <Upload sx={{ fontSize: '2rem', color: 'gray' }} />
              <div style={{ fontSize: '0.8rem', color: 'gray' }}>{t("crew.editImage.description")}</div>
            </>
          )}
          <input
            ref={fileInputRef}
            type="file"
            accept="image/*"
            style={{ display: 'none' }}
            onChange={handleFileChange}
          />
        </div>
      </DialogContent>
      {modal.preview && (
        <DialogActions sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
          <CustomColorButton onClick={() => fileInputRef.current?.click()} text={t("crew.editImage.selectOther")} fontSize="0.8rem" />
          <div style={{ display: "flex", flexDirection: "row", gap: "10px" }}>
            <CustomColorButton onClick={close} text={t("crew.editImage.cancel")} fontSize="0.8rem" color="gray" />
            <CustomBackgroundColorButton onClick={() => handleSaveImage(t)} text={t("crew.editImage.save")} fontSize="0.8rem" />
          </div>
        </DialogActions>
      )}
    </Dialog>
  )
}

export default EditCrewImageModal;
