import { RecommendPlanItem } from '@/Atoms/RecommendPlan/Atom';
import { Node } from '@xyflow/react';
import { atom } from 'jotai';

// 진로진학 탐색
interface CareerSearch {
  image: string; // 학교 이미지 URL
  url: string; // 학교 홈페이지
  school: string; // 학교명
  major: string; // 학과명
  region: string; // 지역
  type: string; // 전형
  competition: string; // 경쟁률
  recruit: string; // 모집인원
  grade50: string; // 50% 이내 성적
  grade70: string; // 70% 이내 성적
}

export const dashboardMenteeCareerSearchAtom = atom<{
  careerSearch: CareerSearch[];
  isLoading: boolean;
}>({
  careerSearch: [],
  isLoading: true,
});

// 추천 직업 탐색
interface JobSearch {
  image: string; // 직업 이미지 URL
  job: string; // 직업명
  major: string[]; // 관련 학과
  qualifications: string[]; // 우대 자격증
  externalEducation: string[]; // 외부 교육 과정
  recommendedEducation: string[]; // 추천 교육 과정
  hopefulness: string; // 직업 전망
}

export const dashboardMenteeJobSearchAtom = atom<{
  jobSearch: JobSearch[];
  isLoading: boolean;
}>({
  jobSearch: [],
  isLoading: true,
});

// 추천 컨텐츠 탐색
export interface ContentSearch {
  image: string; // 컨텐츠 이미지 URL
  link: string; // 컨텐츠 링크
  keywords: string[]; // 컨텐츠 키워드
  name: string; // 컨텐츠명
  author: string; // 저자
}

export const dashboardMenteeContentSearchAtom = atom<{
  contentSearch: ContentSearch[];
  isLoading: boolean;
}>({
  contentSearch: [],
  isLoading: true,
});

// 현재 나의 수준은?
interface MyLevelInfo {
  year: number;
  money: number;
}

interface MyLevel {
  usingOurService: MyLevelInfo[];
  notUsing: MyLevelInfo[];
}

export const dashboardMenteeMyLevelAtom = atom<{
  myLevel: MyLevel;
  isLoading: boolean;
}>({
  myLevel: {
    usingOurService: [],
    notUsing: [],
  },
  isLoading: true,
});

// 일정 관리
export const dashboardMenteeDailyPlanAtom = atom<{
  dailyPlan: Node[];
  isLoading: boolean;
}>({
  dailyPlan: [],
  isLoading: true,
});

export const dashboardMenteePlanTemplatesAtom = atom<RecommendPlanItem[]>([]);

export const dashboardMenteePlanTemplatesExpandedAtom = atom<{
  isFirst: boolean;
  expanded: boolean;
}>({
  isFirst: true,
  expanded: true,
});
