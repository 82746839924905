import { getRoutineListInRoutineAtom } from '@/ViewModels/Plan/Routine/DataViewModel';
import { Button } from '@mui/material';
import { useAtomValue, useSetAtom } from 'jotai';
import React from 'react';
import { styled } from '@mui/material/styles';
import * as S from './styled';
import { pushRoutineSidebarDataAtom } from '@/ViewModels/Plan/Routine/SidebarViewModel';
import { RoutineData, routineDateModeAtom } from '@/Atoms/Plan/RoutineAtom';
import { useTranslation } from 'react-i18next';
import { Node } from '@xyflow/react';
import dayjs from 'dayjs';
import { TaskStatus } from '@/Types/Plan';

const CustomButton = styled(Button)(() => ({
  minWidth: '30px',
  borderColor: '#aaa', // 기본 border 색상
  color: 'black', // 텍스트 색상
  '&:hover': {
    borderColor: 'black', // hover 시 border 색상
  },
}));

interface RoutineListProps {
  width?: number;
}

const RoutineList = ({ width = 1000 }: RoutineListProps) => {
  const { t } = useTranslation();
  const routines = useAtomValue(getRoutineListInRoutineAtom);
  const [currentIndex, setCurrentIndex] = React.useState(0); // 현재 페이지 인덱스
  const [visibleCount, setVisibleCount] = React.useState(4); // 한 화면에 표시할 그룹의 수
  const openSidebar = useSetAtom(pushRoutineSidebarDataAtom);
  const dateMode = useAtomValue(routineDateModeAtom);

  const handleGroupClick = (routine: RoutineData) => {
    const keyNode = routine.key;
    const tasks = routine.tasks as Node[];
    const today = new Date();

    // 각 task별 taskStatus 중 오늘 날짜와 가장 가까운 날짜의 taskStatus를 찾아서 sidebar에 전달
    const taskStatuses = tasks.map((task) => {
      const taskStatus = task.data.taskStatus as TaskStatus[];
      const prevTaskStatus = taskStatus.filter((status) => dayjs(status.date).isSameOrBefore(today));
      const sortedTaskStatus = prevTaskStatus.sort((a, b) => dayjs(b.date).diff(dayjs(a.date)));
      if (sortedTaskStatus.length === 0) return null;
      return sortedTaskStatus[0]; // 가장 가까운 날짜의 taskStatus
    }).filter((status) => status !== null) as TaskStatus[];

    openSidebar({
      type: "routine",
      key: routine.key,
      tasks: routine.tasks,
      taskStatuses
    })
  }

  // 화면 크기에 따라 한 번에 표시될 그룹 갯수 계산
  const updateVisibleCount = (w: number) => {
    let newVisibleCount = 4;
    if (w < 300) {
      newVisibleCount = 1;
    } else if (width < 450) {
      newVisibleCount = 1;
    } else if (width < 600) {
      newVisibleCount = 2;
    } else if (width < 750) {
      newVisibleCount = 3;
    } else {
      newVisibleCount = 4;
    }

    // 현재 페이지 인덱스를 유효한 범위로 조정
    const maxPageIndex = Math.max(0, Math.ceil(routines.length / newVisibleCount) - 1);
    setCurrentIndex((prevIndex) => Math.min(prevIndex, maxPageIndex));

    setVisibleCount(newVisibleCount);
  };

  React.useEffect(() => {
    updateVisibleCount(width);
    window.addEventListener("resize", () => updateVisibleCount(width));
    return () => window.removeEventListener("resize", () => updateVisibleCount(width));

    // eslint-disable-next-line
  }, [width]); // routines가 변경될 때에도 업데이트

  // 이전 버튼 클릭 핸들러
  const handlePrev = () => {
    setCurrentIndex((prev) => Math.max(prev - 1, 0));
  };

  // 다음 버튼 클릭 핸들러
  const handleNext = () => {
    setCurrentIndex((prev) => Math.min(prev + 1, Math.ceil(routines.length / visibleCount) - 1));
  };

  // 현재 페이지에서 표시할 루틴
  const visibleRoutines = routines.slice(
    currentIndex * visibleCount,
    currentIndex * visibleCount + visibleCount
  );

  return (
    routines.length !== 0 &&
    <S.Container>
      {/* 이전 버튼 */}
      <CustomButton
        onClick={handlePrev}
        variant="outlined"
        size="small"
        disabled={currentIndex === 0}
      >
        &lt;
      </CustomButton>

      {/* 그룹 표시 */}
      <S.GroupContainer>
        {visibleRoutines.map((routine, index) => (
          <S.GroupItem key={index} $backgroundColor={routine.key.data.color as string} onClick={() => handleGroupClick(routine)}>
            <div style={{ flex: 1, textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap" }}>{routine.key.data.label as string}</div>
            <div style={{ display: "flex", flexDirection: "column", gap: "2px", fontSize: "9px" }}>
              <div>{t("plan.contents.routine.routineProgress.all")} {routine.progress}%</div>
              <div>{t(`plan.contents.routine.routineProgress.${dateMode.toLowerCase()}`)} {dateMode === "Month" ? routine.monthProgress : dateMode === "Week" ? routine.weekProgress : routine.dayProgress}%</div>
            </div>
          </S.GroupItem>
        ))}
      </S.GroupContainer>

      {/* 다음 버튼 */}
      <CustomButton
        onClick={handleNext}
        variant="outlined"
        size="small"
        disabled={currentIndex >= Math.ceil(routines.length / visibleCount) - 1}
      >
        &gt;
      </CustomButton>
    </S.Container>
  )
}

export default RoutineList;
