import { atom } from 'jotai';
import { ApplicationPeriodInfo } from '../Dialogs/Create/Course';

export interface Lecture {
  id: number;
  name: string; // 강좌명
  teacher: string; // 담당교사
  author_id: number; // 작성자
  grades: number[]; // 학년
  headcount: number; // 정원
  period: number; // 차시
  operatingTimes: { [key: string]: number[] }; // 운영시간
  operatingPlan: string; // 운영계획
  classRoom: string; // 강의실
  textbook: string; // 교재
  isVisibleMember: boolean; // 수강생 표시 여부
  isAbleToCancel: boolean; // 수강 취소 가능 여부
  applicationPeriod: ApplicationPeriodInfo; // 신청기간
  coursePeriod: { // 수강기간
    startDate: string;  // yyyy-MM-dd
    endDate: string;  // yyyy-MM-dd
  };
  hashtags: {
    backgroundColor: string;
    textColor: string;
    value: string;
  }[];
  members: {
    id: number;
    name: string;
    email: string;
    profileImage: string;
  }[];
  management: {
    id: number;
    name: string;
    email: string;
    profileImage: string;
  }
}

export interface Lectures {
  innerTabId: number;
  lectures: Lecture[];
};

export const crewLecturesAtom = atom<Lectures[]>([]);

export const editCrewImageModalAtom = atom<{
  open: boolean;
  preview: string | null;
  file: File | null;
}>({
  open: false,
  preview: null,
  file: null,
});

export interface EditCrewModal {
  name: string;
  color: string;
  isPublic: boolean;
  content: string;
  category: string;
  headCount: number;
  hashtags: string[];
}

export const editCrewModalCheckNameStatusAtom = atom("init" as "init" | "loading" | "success" | "fail" | "invalid");
export const editCrewModalAtom = atom<EditCrewModal | null>(null);
