import { atom } from "jotai";
import { ChattingProps } from "../Components/Helper/Balloon";
import { DokgabiFlowProps } from "@/Models/DokgabiFlow";

export const currentChatbotAtom = atom<DokgabiFlowProps | null>(null);

export enum ChatType {
  ASK = 0,
  SEARCH = 1,
}

export const chattingTypeAtom = atom<ChatType>(ChatType.ASK);
export const isChattingAskAtom = atom<HTMLElement | null>(null);
export const isOpenRecommendQuestionAtom = atom(false);
export const isAIMakingResponseAtom = atom(false);

export const chatInputValueAtom = atom("");
export const searchInputValueAtom = atom("");
export const currentSearchIndexAtom = atom(0);

export const recommendCurrentPageAtom = atom(0);

export const chattingListAtom = atom<ChattingProps[]>([]);

export const chatHistoryPageNumAtom = atom(1);
export const isPageGettingHistoryAtom = atom(false);

export const scrollStateAtom = atom(false);

export const previousCrewAtom = atom<{
  crew_id: number;
  inner_tab_id: number;
} | null>(null);
