import React from 'react';
import StoryPostingContainer from './postingContainer';
import StoryDialog from './dialog';
import { StoryProps, RequestStoryProps } from '../../../../../Atoms/StoryAtom';
import { getUserPosition } from '@/ViewModels/UserViewModel';
import OnlyTemplatePostingContainer from './onlyTemplatePostingContainer';
import { useAtomValue } from 'jotai';

interface StoryPostingProps {
  setTabValue: React.Dispatch<React.SetStateAction<number>>;
  storyProps: RequestStoryProps;
  setStoryProps: React.Dispatch<React.SetStateAction<RequestStoryProps>>
  editingStory: StoryProps | null;
  tabValue: number;
}

const StoryPosting = ({ setTabValue, storyProps, setStoryProps, editingStory, tabValue }: StoryPostingProps) => {
  const [isStoryDialogOpen, setIsStoryDialogOpen] = React.useState<"template" | "story" | null>(null);
  const userPosition = useAtomValue(getUserPosition);

  const handleTemplateClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    setTabValue(1);
    setIsStoryDialogOpen("template");
  }

  const handleStoryClick = () => {
    setTabValue(0);
    setIsStoryDialogOpen("story");
  }

  const handleOnlyTemplateClick = () => {
    setTabValue(1);
    setIsStoryDialogOpen("template");
  }

  return (
    <>
      {/* 스토리 작성 컴포넌트 */}
      {
        userPosition === "멘토" ? <OnlyTemplatePostingContainer onStoryClick={handleOnlyTemplateClick} /> :
          <StoryPostingContainer onStoryClick={handleStoryClick} onTemplateClick={handleTemplateClick} />
      }

      {/* dialog */}
      <StoryDialog
        isEdit={false}
        isStoryDialogOpen={isStoryDialogOpen}
        setIsStoryDialogOpen={setIsStoryDialogOpen}
        story={storyProps}
        setStory={setStoryProps}
        editingStory={editingStory}
        tabValue={tabValue}
        setTabValue={setTabValue}
      />
    </>
  );
}

export default StoryPosting;
