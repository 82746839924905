import { userAtom } from '@/Atoms/UserAtom';
import { atom } from 'jotai';

export const isUserGroupAtom = atom((get) => {
  const user = get(userAtom);
  return user && user.group ? true : false;
});

export const getUserGroupNameAtom = atom((get) => {
  const user = get(userAtom);
  if (!user || !user.group) return "-";
  return user.group.groupName;
})

export const getUserGroupCodeAtom = atom((get) => {
  const user = get(userAtom);
  if (!user || !user.group) return null;
  return user.group.groupCode;
});
