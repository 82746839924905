import { LocationProps } from '@/Atoms/RootAtom';
import { Dayjs } from 'dayjs';
import { atom } from 'jotai';

export const initCreateUltimateModalData = () => {
  return ({
    open: "",
    isCrew: false,
    name: "",
    content: "",
    startDate: null,
    endDate: null,
    termType: null,
    termData: null,
    location: { address: "", latitude: null, longitude: null },
    hashtags: [],
  })
}

interface CreateUltimateModalProps {
  open: string;
  isCrew: boolean;
  name: string;
  content: string;
  startDate: Dayjs | null;
  endDate: Dayjs | null;
  termType: number | null;
  termData: string[] | null;
  location: LocationProps;
  hashtags: {
    backgroundColor: string;
    textColor: string;
    value: string;
  }[];
}

export const createUltimateModalAtom = atom<CreateUltimateModalProps | null>(null);

interface CreateUltimateModalEditRoutineProps {
  termType: number | null;
  termData: string[] | null;
}

export const createUltimateModalEditRoutineAtom = atom<CreateUltimateModalEditRoutineProps>({
  termType: null,
  termData: null,
});
