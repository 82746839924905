import React from 'react';
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';
import ListAltOutlinedIcon from '@mui/icons-material/ListAltOutlined';
import * as S from './styled';
import useIsMobile from '../../../../../Hooks/useIsMobile';
import { languageAtom } from '@/Atoms/RootAtom';
import { useAtomValue } from 'jotai';

interface StoryPostingContainerProps {
  onStoryClick: () => void;
  onTemplateClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
}

const StoryPostingContainer: React.FC<StoryPostingContainerProps> = ({ onStoryClick, onTemplateClick }) => {
  const { isMobile } = useIsMobile();
  const language = useAtomValue(languageAtom);
  return (
    <S.StoryPostingContainer $isMobile={isMobile} onClick={onStoryClick}>
      <S.TitleContainer>
        <S.TitleButton>
          <ArticleOutlinedIcon sx={{ width: "20px", height: "20px" }} />
          피드
        </S.TitleButton>
        <S.TitleButton onClick={onTemplateClick}>
          <ListAltOutlinedIcon sx={{ width: "20px", height: "20px" }} />
          템플릿
        </S.TitleButton>
      </S.TitleContainer>
      <S.DescriptionContainer>
        {language === "KR" ? "스토리를 공유해보세요" : "Share your story"}
      </S.DescriptionContainer>
    </S.StoryPostingContainer>
  );
};

export default StoryPostingContainer;
