import React from "react";
import * as S from "./styled";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { IconButton, Menu, MenuItem, Typography } from "@mui/material";
import { Node } from "@xyflow/react";
import CalendarTodayOutlinedIcon from '@mui/icons-material/CalendarTodayOutlined';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import { LocationProps } from "@/Atoms/RootAtom";
import ScheduleOutlinedIcon from '@mui/icons-material/ScheduleOutlined';
import { useAtomValue, useSetAtom } from "jotai";
import { editTaskDialogLoadDataAtom } from "@/ViewModels/Dialogs/Edit/TaskViewModel";
import useIsMobile from "@/Hooks/useIsMobile";
import snackbarAtom from "@/Atoms/Snackbar";
import { deleteTaskAtom } from "@/ViewModels/Plan/Mindmap/ViewModel";
import { togetherDialogOpenAtom } from "@/ViewModels/Dialogs/TogetherDialogViewModel";
import CustomColorButton from "@/Components/Common/Buttons/CustomColor";
import TagIcon from '@mui/icons-material/Tag';
import dayjs from "dayjs";
import theme from "@/Styles/theme";
import { updateTaskStatusNodeAtom } from "@/ViewModels/Plan/Mindmap/JobNodeViewModel";
import TextMarkdown from "@/Components/Common/Markdown";
import { useTranslation } from "react-i18next";
import { checkPlanUserIdAtom } from "@/ViewModels/Plan/PlanViewModel";
import { devConsoleLog } from "@/Utils/ConsoleLogInDevelopment";

const formatDateToKorean2 = (date: Date): string => {
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, '0'); // 월은 0부터 시작하므로 +1
  const day = date.getDate().toString().padStart(2, '0');
  const hours = date.getHours().toString().padStart(2, '0');
  const minutes = date.getMinutes().toString().padStart(2, '0');

  return `${year}년 ${month}월 ${day}일 ${hours}시 ${minutes}분`;
}

interface TodoTaskProps {
  item: Node;
}

const TodoTask = ({ item }: TodoTaskProps) => {
  const { isMobile } = useIsMobile();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const setSnackbar = useSetAtom(snackbarAtom);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);
  const editTask = useSetAtom(editTaskDialogLoadDataAtom);
  const deleteTask = useSetAtom(deleteTaskAtom);
  const setTogetherDialog = useSetAtom(togetherDialogOpenAtom);
  const updateStatus = useSetAtom(updateTaskStatusNodeAtom);
  const today = dayjs().format('YYYY-MM-DD');
  const isExistTodayStatus = (item.data.taskStatus as { taskStatusId: number, taskId: number, date: string, status: number }[]).length !== 0 && (item.data.taskStatus as { taskStatusId: number, taskId: number, date: string, status: number }[]).find((status: any) => status.date === today);
  const todayStatus = isExistTodayStatus ? (item.data.taskStatus as { taskStatusId: number, taskId: number, date: string, status: number }[]).find((status: any) => status.date === today) : null;
  const { t } = useTranslation();
  const isMine = useAtomValue(checkPlanUserIdAtom);

  const groupItems = [
    { name: t("plan.contents.todo.edit"), value: "edit" },
    { name: t("plan.contents.todo.delete"), value: "delete" },
  ];

  const handleMenuItemClick = (value: string) => {
    if (value === "edit") {
      editTask(item);
    } else if (value === "delete") {
      deleteTask(item.id);
      setSnackbar({ open: true, message: t("dialogs.common.snackbar.deleteSuccess"), severity: "success" });
    }
    // else if (value === "complete") {
    //   todayStatus && updateStatus(item, todayStatus.taskStatusId as number, 1);
    //   setSnackbar({ open: true, message: "성공적으로 완료되었습니다.", severity: "success" });
    // } else if (value === "waiting") {
    //   todayStatus && updateStatus(item, todayStatus.taskStatusId as number, 0);
    //   setSnackbar({ open: true, message: "성공적으로 대기 상태로 변경되었습니다.", severity: "success" });
    // }
    handleClose();
  };

  // Helper function to break title into spans
  const formatTitle = (title: string) => {
    return title.split(/(?<=[?!.])\s+/g).map((part, index) => (
      <span key={index} style={{ display: 'block' }}>
        {part}
      </span>
    ));
  };

  return (
    <>
      <S.StoryItem $isMobile={isMobile}>
        <S.StoryTitleContainer>
          <div style={{ display: "flex", width: "80%", flexDirection: "row", gap: "10px", alignItems: "center" }}>
            {
              todayStatus &&
              <div style={{ width: "20px", height: "20px", borderRadius: "50%", backgroundColor: todayStatus.status === 1 ? theme.colors.primary : theme.colors.customYellow }} />
            }
            {
              item.data && item.data.label as string !== "" &&
              <S.StoryTitle>
                {formatTitle(item.data.label as string)}
              </S.StoryTitle>
            }
          </div>
          {
            isMine &&
            <>
              <IconButton
                sx={{
                  color: "black",
                  fontWeight: "bold",
                  padding: 0,
                  textTransform: "none",
                  fontSize: "0rem",
                }}
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
              >
                <MoreVertIcon sx={{ fontSize: "1.5rem" }} />
              </IconButton>
              <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                  'aria-labelledby': 'basic-button',
                }}
              >
                {groupItems.map((item, index) =>
                  item && <MenuItem key={index} onClick={() => handleMenuItemClick(item.value)}>
                    {item.name}
                  </MenuItem>
                )}
              </Menu>
            </>
          }
        </S.StoryTitleContainer>
        {
          item.data.startDate as string &&
          <div style={{ display: "flex", flexDirection: "row", gap: "10px", alignItems: "center" }}>
            <CalendarTodayOutlinedIcon sx={{ fontSize: "1rem" }} />
            <Typography style={{ fontSize: "0.8rem" }}>
              {formatDateToKorean2(new Date(item.data.startDate as string))}부터
            </Typography>
          </div>
        }
        {
          item.data.endDate as string &&
          <div style={{ display: "flex", flexDirection: "row", gap: "10px", alignItems: "center" }}>
            <CalendarTodayOutlinedIcon sx={{ fontSize: "1rem" }} />
            <Typography style={{ fontSize: "0.8rem" }}>
              {formatDateToKorean2(new Date(item.data.endDate as string))}까지
            </Typography>
          </div>
        }
        {
          (item.data.location as LocationProps).address &&
          <div style={{ display: "flex", flexDirection: "row", gap: "10px", alignItems: "center" }}>
            <LocationOnOutlinedIcon sx={{ fontSize: "1rem" }} />
            <Typography style={{ fontSize: "0.8rem" }}>
              {(item.data.location as LocationProps).address as string}
            </Typography>
          </div>
        }
        {
          (item.data.termType as number) !== null &&
          <div style={{ display: "flex", flexDirection: "row", gap: "10px", alignItems: "center" }}>
            <ScheduleOutlinedIcon sx={{ fontSize: "1rem" }} />
            <Typography style={{ fontSize: "0.8rem" }}>
              {item.data.termType as number === 1 ? "매주" : item.data.termType as number === 2 ? "매월" : "매일"}
            </Typography>
            {
              item.data.termType as number === 1 &&
              (item.data.termData as string[]).map((day, index) => (
                <Typography key={index} style={{ fontSize: "0.8rem" }}>
                  {day}
                </Typography>
              ))
            }
            {
              item.data.termType as number === 2 &&
              (item.data.termData as string[]).map((day, index) => (
                <Typography key={index} style={{ fontSize: "0.8rem" }}>
                  {day}일
                </Typography>
              ))
            }
          </div>
        }
        {
          (item.data.hashtags as { backgroundColor: string, textColor: string, value: string }[]) && (item.data.hashtags as { backgroundColor: string, textColor: string, value: string }[]).length > 0 &&
          <div style={{ display: "flex", flexDirection: "row", gap: "10px", alignItems: "center" }}>
            <TagIcon sx={{ fontSize: "1rem" }} />
            {
              (item.data.hashtags as { backgroundColor: string, textColor: string, value: string }[]).map((tag, index) => (
                <Typography key={index} style={{ fontSize: "0.8rem", backgroundColor: tag.backgroundColor, color: tag.textColor, padding: "2px 5px", borderRadius: "5px" }}>
                  {tag.value}
                </Typography>
              ))
            }
          </div>
        }
        <div style={{ display: "flex", flexDirection: "column", justifyContent: "space-between", width: "100%" }}>
          {
            item.data.content === "" ?
              <Typography sx={{ fontSize: "0.8rem" }}>설명</Typography> :
              <TextMarkdown text={item.data.content as string} />
          }
        </div>
        <S.StoryBottomContainer>
          {
            isMine &&
            <>
              <CustomColorButton text={t("plan.contents.todo.joinUs")} onClick={() => setTogetherDialog(item)} fontSize="0.8rem" style={{ padding: 0 }} variant="text" />
              {
                isExistTodayStatus && todayStatus && (
                  todayStatus.status === 1 ?
                    <CustomColorButton text={t("plan.contents.todo.wait")} onClick={() => updateStatus(item.id, todayStatus.taskStatusId as number, 0)} fontSize="0.8rem" style={{ padding: 0 }} variant="text" /> :
                    <CustomColorButton text={t("plan.contents.todo.done")} onClick={() => updateStatus(item.id, todayStatus.taskStatusId as number, 1)} fontSize="0.8rem" style={{ padding: 0 }} variant="text" />
                )
              }
            </>
          }
          {
            !isMine &&
            <CustomColorButton text="복사하기" onClick={() => devConsoleLog("복사하기")} fontSize="0.8rem" style={{ padding: 0 }} variant="text" />
          }
        </S.StoryBottomContainer>
      </S.StoryItem>
    </>
  )
}

export default TodoTask;
