import { dokgabiChatModalAtom, dokgabiChatModalSidebarAtom } from '@/Atoms/DokgabiChat/Atom';
import useIsMobile from '@/Hooks/useIsMobile';
import theme from '@/Styles/theme';
import { Box, Drawer, Stack } from '@mui/material';
import { useAtom, useAtomValue } from 'jotai';
import React from 'react';
import CloseIcon from '@mui/icons-material/Close';
import MenuIcon from '@mui/icons-material/Menu';
import { fetchMoreChattingListAtom } from '@/ViewModels/HelperViewModel';
import { useTranslation } from 'react-i18next';
import { RecommendQuestion } from '../Helper/RecommendQuestion';
import { RecommendQuestionButton } from '../Helper/RecommendQuestionButton';
import { chatRoomListAtom, currentChatRoomAtom } from '@/Atoms/ChatAtom';
import RenderMessage from './Contents/Messages/RenderMessage';
import InputMessage from './Contents/Messages/InputMessage';
import DokgabiChatHeader from './Header/Chat';
import { devConsoleLog } from '@/Utils/ConsoleLogInDevelopment';
import DokgabiChatMessages from './Contents/Messages';
import DokgabiDesktopSidebar from './Sidebar/Desktop';
import DokgabiChatCreateRoom from './Contents/CreateRoom';
import { dokgabiChatFetchMoreChattingListAtom } from '@/ViewModels/Chat/MessageViewModel';
import DokgabiMobileSidebar from './Sidebar/Mobile';

const DokgabiChat = () => {
  const { t } = useTranslation();
  const [modal, setModal] = useAtom(dokgabiChatModalAtom);
  const { isMobile } = useIsMobile();
  const [openSidebar, setOpenSidebar] = useAtom(dokgabiChatModalSidebarAtom);
  const isPageGettingHistory = useAtomValue(dokgabiChatFetchMoreChattingListAtom);
  const chatEndRef = React.useRef<HTMLDivElement | null>(null);
  const currentChatRoom = useAtomValue(currentChatRoomAtom);

  const scrollToBottom = () => {
    if (chatEndRef.current) {
      chatEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  // currentChatRoom 변경 시 맨 아래로 스크롤
  React.useEffect(() => {
    scrollToBottom();
  }, [currentChatRoom?.messages]);

  const getContentContainerStyle = (): React.CSSProperties => {
    return {
      display: "flex",
      flexDirection: "column",
      width: !isMobile && openSidebar ? "calc(100% - 200px)" : "100%",
      height: "100%"
    }
  }

  return (
    currentChatRoom &&
    <Drawer anchor="right" open={modal.isOpen} onClose={() => setModal({ ...modal, isOpen: false })}>
      <div style={{ width: isMobile ? "100vw" : "50vw", height: "100%", display: "flex", position: "relative", flexDirection: "row" }}>
        {
          !(isMobile && openSidebar) &&
          <div style={getContentContainerStyle()}>
            {modal.mode === "chat" ?
              <>
                {/* header */}
                <DokgabiChatHeader />

                {
                  isPageGettingHistory &&
                  <Box
                    sx={{
                      position: "absolute",
                      zIndex: 10,
                      top: 60,
                      width: "100%",
                      left: 0,
                      background: theme.colors.primary,
                      transition: "box-shadow 0.3s ease",
                      textAlign: "center",
                      color: "white",
                      borderRadius: "0 0 15px 15px",
                    }}
                    aria-live="polite"
                  >
                    {t("makeTextHelper.getChatList")}
                  </Box>
                }

                {/* content - messages */}
                <DokgabiChatMessages />


                {/* footer - input message */}
                <Stack
                  sx={{
                    width: "100%",
                    background: "white",
                    position: "sticky",
                    bottom: "0",
                    paddingBottom: `20px`,
                    justifyContent: "center",
                    alignItems: "center",
                    backdropFilter: `blur(10px)`,
                    WebkitBackdropFilter: `blur(10px)`,
                  }}
                >
                  <Stack
                    sx={{
                      width: "90%",
                      height: "100%",
                      marginBottom: `10px`,
                      alignItems: "center",
                      justifyContent: "center",
                      gap: `5px`,
                      "@media (max-width: 1024px)": {
                        width: "90%",
                      },
                      "@media (max-width: 768px)": {
                        width: "calc(100% - 40px)",
                      },
                    }}
                    direction="column"
                  >
                    <InputMessage />
                    <div style={{ color: "#8f8f8f", fontSize: "13px" }}>
                      {t("makeTextHelper.warningAI")}
                    </div>
                    <RecommendQuestion />
                    {
                      !isMobile &&
                      <RecommendQuestionButton />
                    }
                  </Stack>
                </Stack>
              </> :
              <DokgabiChatCreateRoom />
            }
          </div>
        }

        {/* sidebar - desktop */}
        <DokgabiDesktopSidebar />

        {/* sidebar - mobile */}
        <DokgabiMobileSidebar />

      </div>
    </Drawer>
  );
};

export default DokgabiChat;
