import { useQuery } from 'react-query';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { getUserGroupsAtom, getUserId, getUserPosition } from '@/ViewModels/UserViewModel';
import { useTeacherDashboardAtom } from '@/Atoms/HookControllerAtom';
import teacherDashboardAtom from '@/Atoms/Dashboard/TeacherAtom';
import { getTeacherDashboardData } from '@/Queries/DashboardQueries';
import { handleReactQueryApiResponse } from '@/Utils/APIUtil';
import { error401ModalAtom } from '@/Atoms/Dialogs/Error/401Atom';
import { devConsoleLog } from '@/Utils/ConsoleLogInDevelopment';

const useTeacherDashboard = () => {
  const userId = useAtomValue(getUserId);
  const [controller, setController] = useAtom(useTeacherDashboardAtom);
  const setDashboard = useSetAtom(teacherDashboardAtom);
  const userPosition = useAtomValue(getUserPosition);
  const setError401Modal = useSetAtom(error401ModalAtom);
  const handleError401Modal = () => setError401Modal(true);
  const userGroups = useAtomValue(getUserGroupsAtom);

  return useQuery(['teacherDashboard', userId], () => handleReactQueryApiResponse(getTeacherDashboardData, handleError401Modal, userGroups), {
    enabled: !!userId && controller && userPosition === "교사",
    onSuccess: async (data) => {
      if (!data.ok) return;
      setController(false);
      const clonedResponse = data.clone();
      try {
        const jsonedData = await clonedResponse.json();
        devConsoleLog("useTeacherDashboard onSuccess", jsonedData);
        const { crewData, groupData } = jsonedData;
        const careerPlanning = [...crewData.careerPlanning, ...groupData.careerPlanning];
        const sortedCareerPlanning = careerPlanning.sort((a: any, b: any) => {
          return b.numberOfTodos - a.numberOfTodos;
        });
        // userId가 중복되면 한개만 남기고 제거
        const uniqueCareerPlanning = sortedCareerPlanning.filter((item: any, index: number, self: any) => self.findIndex((t: any) => t.userId === item.userId) === index);
        devConsoleLog("uniqueCareerPlanning", uniqueCareerPlanning);
        const careerProgress = [...crewData.careerProgress, ...groupData.careerProgress];
        devConsoleLog("careerProgress", careerProgress);
        const crews = [...crewData.crews];
        devConsoleLog("crews", crews);
        setDashboard({
          signatures: [],
          careerProgress: careerProgress || [],
          crews: crews || [],
          careerPlanning: uniqueCareerPlanning || [],
        })
      } catch (error) {
        devConsoleLog("Error parsing JSON:", error);
      }
    },
  });
};

export default useTeacherDashboard;
