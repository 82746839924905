import { Lecture } from "@/Atoms/Crew";
import { ApplicationPeriodInfo } from "@/Atoms/Dialogs/Create/Course";

// create lectures
export interface CreateLectureInfo {
  name: string;
  teacherId: number;
  teacher: string;
  grades: number[];
  headcount: number;
  period: number;
  operatingTimes: { [key: string]: number[] };
  operatingPlan: string;
  classRoom: string;
  textbook: string;
  isVisibleMember: boolean;
  isAbleToCancel: boolean;
  applicationPeriod: ApplicationPeriodInfo;
  coursePeriod: {
    startDate: string;
    endDate: string;
  };
  hashtags: {
    backgroundColor: string;
    textColor: string;
    value: string;
  }[];
}

export const createLectures = async (access: string, innerTabId: number, lectures: CreateLectureInfo[], userId: number) => {
  const response = await fetch(`${process.env.REACT_APP_DEV_BACK_LECTURE_ADDRESS}?user_id=${userId}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'accept': 'application/json',
      'Authorization': `Bearer ${access}`,
    },
    body: JSON.stringify({
      innerTabId,
      lectures,
    }),
  });
  return response;
};

// update lecture
export const updateLecture = async (access: string, lectureId: number, lecture: CreateLectureInfo, userId: number) => {
  const response = await fetch(`${process.env.REACT_APP_DEV_BACK_LECTURE_ADDRESS}/${lectureId}?user_id=${userId}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      'accept': 'application/json',
      'Authorization': `Bearer ${access}`,
    },
    body: JSON.stringify(lecture),
  });
  return response;
}

// get lectures
export const getLectures = async (access: string, innerTabId: number) => {
  const response = await fetch(`${process.env.REACT_APP_DEV_BACK_LECTURE_ADDRESS}?inner_tab_id=${innerTabId}`, {
    method: 'GET',
    headers: {
      'accept': 'application/json',
      'Authorization': `Bearer ${access}`,
    },
  });
  return response;
}

// delete lecture
export const deleteLecture = async (access: string, lectureId: number) => {
  const response = await fetch(`${process.env.REACT_APP_DEV_BACK_LECTURE_ADDRESS}/${lectureId}`, {
    method: 'DELETE',
    headers: {
      'accept': 'application/json',
      'Authorization': `Bearer ${access}`,
    },
  });
  return response;
};

// apply lecture
export const applyLecture = async (access: string, lectureId: number, userId: number) => {
  const response = await fetch(`${process.env.REACT_APP_DEV_BACK_LECTURE_ADDRESS}/${lectureId}/apply?user_id=${userId}`, {
    method: 'POST',
    headers: {
      'accept': 'application/json',
      'Authorization': `Bearer ${access}`,
    },
  });
  return response;
};

// cancel apply lecture
export const cancelApplyLecture = async (access: string, lectureId: number, userId: number) => {
  const response = await fetch(`${process.env.REACT_APP_DEV_BACK_LECTURE_ADDRESS}/${lectureId}/cancel?user_id=${userId}`, {
    method: 'DELETE',
    headers: {
      'accept': 'application/json',
      'Authorization': `Bearer ${access}`,
    },
  });
  return response;
};
