import { TaskStatus } from "@/Types/Plan";
import dayjs from "dayjs";

const getClosestTaskStatus = (taskStatuses: TaskStatus[], date: Date): TaskStatus | null => {
  const myTaskStatus = taskStatuses.filter((status) => status.routineId === status.taskId); // taskId와 routineId가 같은 경우만 필터링
  const sortedTaskStatuses = myTaskStatus.sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime());
  const formattedDate = dayjs(date).format("YYYY-MM-DD");
  const closestPastDates = sortedTaskStatuses.filter((status) => status.date <= formattedDate).sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime());
  if (closestPastDates.length === 0) return null;
  return closestPastDates[0];
}

export default getClosestTaskStatus;
