import { atom } from 'jotai';

export interface Mail {
  completed: boolean; // 메일 송신 완료 여부
  senderNickname: string;
  sender: string;
  recipients: string[]; // 받는 사람
  copyRecipients: string[]; // 참조
  hiddenRecipients: string[]; // 숨은 참조
  subject: string;
  content: string;
}

const mailAtom = atom<Mail>({
  completed: false,
  senderNickname: "",
  sender: "",
  recipients: [],
  copyRecipients: [],
  hiddenRecipients: [],
  subject: "",
  content: "",
});

export const recipientTextAtom = atom<string>("");
export const copyRecipientTextAtom = atom<string>("");
export const hiddenRecipientTextAtom = atom<string>("");
export default mailAtom;
