import React from 'react';
import * as S from './styled';
import { Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material';
import CustomBackgroundColorButton from '@/Components/Common/Buttons/CustomBackgroundColor';
import { useAtom, useSetAtom } from 'jotai';
import { planSettigsModalAddTagAtom, planSettingsModalChangeContentAtom, planSettingsModalChangeNameAtom, planSettingsModalCloseAtom, planSettingsModalImageAtom, planSettingsModalIsPublicAtom, planSettingsModalRemoveTagAtom, planSettingsModalSaveAtom } from '@/ViewModels/Plan/PlanSettingsModalViewModel';
import useIsMobile from '@/Hooks/useIsMobile';
import { useTranslation } from 'react-i18next';
import CustomColorButton from '@/Components/Common/Buttons/CustomColor';
import { PhotoCamera } from '@mui/icons-material';
import theme from '@/Styles/theme';
import { lighten } from 'polished';
import TitleValueInEachRow from '../../Contents/Routine/Sidebar/Common/TitleValueInEachRow';
import deletePlanDialogAtom from '@/Atoms/Dialogs/Delete/PlanAtom';

const PlanSettingsModal = () => {
  const { isMobile } = useIsMobile();
  const { t } = useTranslation();
  const [modal, closeModal] = useAtom(planSettingsModalCloseAtom);
  const [name, changeName] = useAtom(planSettingsModalChangeNameAtom);
  const [inputValue, setInputValue] = React.useState<string>("");
  const [isPublic, changeIsPublic] = useAtom(planSettingsModalIsPublicAtom);
  const [description, changeDescription] = useAtom(planSettingsModalChangeContentAtom);
  const [tags, addTag] = useAtom(planSettigsModalAddTagAtom);
  const removeTag = useSetAtom(planSettingsModalRemoveTagAtom);
  const save = useSetAtom(planSettingsModalSaveAtom);
  const [imagePreview, setImagePreview] = React.useState<string | null>(null);
  const changeImage = useSetAtom(planSettingsModalImageAtom);
  const setDeletePlanDialog = useSetAtom(deletePlanDialogAtom);

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === "Enter") {
      addTag(inputValue);
      setInputValue("");
    } else if (e.key === "Backspace" && inputValue === "") {
      removeTag(tags.length - 1);
    }
  }

  const handleImageUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0];
      changeImage(file);
      setImagePreview(URL.createObjectURL(file));
    }
  }

  return (
    modal &&
    <Dialog open={modal !== null} onClose={closeModal} fullWidth maxWidth="xs" fullScreen={isMobile}>
      <DialogTitle sx={{ fontSize: "0.9rem", fontWeight: "bold" }}>{t("plan.modals.settings.title")}</DialogTitle>
      <DialogContent sx={{ fontSize: "0.8rem", display: "flex", flexDirection: "column", gap: "20px" }}>
        <TitleValueInEachRow
          title={t("plan.modals.settings.name")}
          value={
            <S.CustomInput placeholder={t("plan.modals.settings.name")} value={name} onChange={(e) => changeName(e.target.value)} />
          }
        />
        <div style={{ display: "flex", flexDirection: "row", width: "100%", justifyContent: "space-between", alignItems: "center" }}>
          <div style={{ display: "flex", flexDirection: "column", gap: "5px", width: "calc(100% - 100px)" }}>
            <div style={{ fontWeight: "bold" }}>
              {t("plan.modals.settings.setShare")}
            </div>
            <div style={{ fontSize: "0.7rem", color: "#666666" }}>
              {t("plan.modals.settings.shareDescription")}
            </div>
          </div>
          <div style={{ display: "flex", flexDirection: "row", gap: "5px", alignItems: "center" }}>
            {
              isPublic ?
                <span style={{ color: "#666666" }}>{t("plan.modals.settings.public")}</span> :
                <span style={{ color: "#666666" }}>{t("plan.modals.settings.private")}</span>
            }
            <Checkbox checked={isPublic} onChange={changeIsPublic} />
          </div>
        </div>

        {/* <S.ImageContainer>
          <S.ImageInputContainer>
            <input
              accept="image/*"
              id="image-upload"
              type="file"
              style={{ display: 'none' }} // input을 숨김
              onChange={handleImageUpload} // 파일 선택 시 updateState 함수 호출
            />
            <label htmlFor="image-upload">
              <Button
                variant="contained"
                component="span" // span을 사용하여 클릭 이벤트만 처리
                startIcon={<PhotoCamera />} // 원하는 아이콘 추가
                sx={{
                  fontSize: "0.8rem",
                  fontWeight: "bold",
                  backgroundColor: theme.colors.primary, // 원하는 스타일 추가
                  color: '#fff',
                  '&:hover': {
                    backgroundColor: lighten(0.05, theme.colors.primary), // hover시 원하는 스타일 추가
                  },
                }}
              >
                플랜 이미지 업로드
              </Button>
            </label>
          </S.ImageInputContainer>
          {modal.imageUrl && (
            <div style={{ display: "flex", padding: 2, justifyContent: "center" }}>
              {
                imagePreview && <img src={imagePreview} alt="preview" style={{ maxWidth: '150px', maxHeight: '150px' }} />
              }
              {
                !imagePreview && <img src={modal.imageUrl} alt="preview" style={{ maxWidth: '150px', maxHeight: '150px' }} />
              }
            </div>
          )}
        </S.ImageContainer> */}
        <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
          <div style={{ fontWeight: "bold" }}>
            {t("plan.modals.settings.content")}
          </div>
          <TextField multiline rows={5} fullWidth disabled={!isPublic} value={description} onChange={(e) => changeDescription(e.target.value)} />
          <div style={{ fontSize: "0.7rem", color: "#666666", marginTop: "5px" }}>
            {t("plan.modals.settings.contentDescription")}
          </div>
        </div>
        <div style={{ display: "flex", flexDirection: "column", width: "100%", gap: "5px" }}>
          <div style={{ fontSize: "0.8rem", fontWeight: "bold" }}>{t("dialogs.task.create.hashtag")}</div>
          <S.TagInputContainer $isPublic={isPublic}>
            {tags.map((tag, idx) => {
              return <S.TagItem key={idx} style={{ backgroundColor: tag.backgroundColor, color: tag.textColor }}>
                <span>{tag.value}</span>
              </S.TagItem>
            })}
            <S.TagInput
              placeholder={tags.length === 0 ? t("dialogs.task.create.hashtagPlaceholder") : ""}
              value={inputValue}
              onChange={(e) => setInputValue(e.target.value)}
              onKeyDown={handleKeyDown}
              disabled={!isPublic}
            />
          </S.TagInputContainer>
          <div style={{ fontSize: "0.7rem", color: "#666666" }}>
            {t("plan.modals.settings.inputTag")}
          </div>
        </div>
      </DialogContent>
      <DialogActions sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
        <CustomColorButton onClick={() => setDeletePlanDialog({ open: true, complete: false })} text={t("plan.modals.settings.buttons.delete")} fontSize="0.8rem" color="black" />
        <div style={{ display: "flex", flexDirection: "row", gap: "5px" }}>
          <CustomColorButton onClick={closeModal} text={t("plan.modals.settings.buttons.cancel")} fontSize="0.8rem" />
          <CustomBackgroundColorButton onClick={() => save(t)} text={t("plan.modals.settings.buttons.save")} fontSize="0.8rem" />
        </div>
      </DialogActions>
    </Dialog>
  )
}

export default PlanSettingsModal;
