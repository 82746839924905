import { atom, useAtomValue } from "jotai";
import { useState } from "react";
import { getUserId } from "./UserViewModel";
import { createStory, deleteStory, getStoryList, updateStory } from "@/Queries/StoryQueries";
import storiesAtom, { RequestStoryProps, StoryProps, storySortAtom } from "@/Atoms/StoryAtom";
import { devConsoleError } from "@/Utils/ConsoleLogInDevelopment";
import { handleReactQueryApiResponse, handleReactQueryApiResponseWithJson } from "@/Utils/APIUtil";
import { error401ModalAtom } from "@/Atoms/Dialogs/Error/401Atom";

const getInitializeStoryProps = (userId: number) => {
  return {
    user_id: userId || 0,
    title: "",
    content: "",
    date: null,
    hashtags: [],
  }
};

// 스토리 정렬 방식에 따라 정렬된 스토리 목록을 반환
export const getSortedStories = (stories: StoryProps[], selectedSort: string) => {
  let sorted = [...stories]; // 원본 배열을 수정하지 않기 위해 복사
  // 선택된 정렬 방식으로 정렬
  switch (selectedSort) {
    case "latest":
      sorted.sort((a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime());
      break;
    case "oldest":
      sorted.sort((a, b) => new Date(a.created_at).getTime() - new Date(b.created_at).getTime());
      break;
    case "era":
      sorted.sort((a, b) => new Date(a.date ? a.date.value : new Date()).getTime() - new Date(b.date ? b.date.value : new Date()).getTime());
      break;
    default:
      break;
  }
  return sorted;
};

// 스토리 상태 관리
export const getStoriesAtom = atom(null, async (get, set) => {
  const userId = get(getUserId);
  const sorted = get(storySortAtom);
  const handleError401Modal = () => set(error401ModalAtom, true);
  try {
    const res = await handleReactQueryApiResponseWithJson(getStoryList, handleError401Modal, userId);
    if (!res) {
      return [];
    }
    const response = res as StoryProps[];
    set(storiesAtom, []);
    set(storiesAtom, [...getSortedStories(response, sorted)]);
    return [...getSortedStories(response, sorted)];
  } catch (error) {
    devConsoleError("error", error);
    return [];
  }
});

// 스토리 작성
export const createStoryAtom = atom(null, async (get, set, newStory: RequestStoryProps) => {
  const userId = get(getUserId);
  const handleError401Modal = () => set(error401ModalAtom, true);
  try {
    const response = await handleReactQueryApiResponse(createStory, handleError401Modal, userId, newStory);
    if (response.ok) {
      set(storiesAtom, []);
    }
  } catch (error) {
    devConsoleError("error", error);
  }
  set(getStoriesAtom);
});

// 스토리 삭제
export const deleteStoryAtom = atom(null, async (get, set, id: number) => {
  try {
    const handleError401Modal = () => set(error401ModalAtom, true);
    const response = await handleReactQueryApiResponse(deleteStory, handleError401Modal, id);
    if (response.status === 204) {
      set(storiesAtom, []);
      set(getStoriesAtom);
    }
  } catch (error) {
    devConsoleError("error", error);
  }
});

// 스토리 삭제 대기 상태
export const undoStoryAtom = atom(null, (get, set, id: number) => {

});

export const updateStoryAtom = atom(null, async (get, set, updatedStory: StoryProps) => {
  const userId = get(getUserId);
  try {
    const handleError401Modal = () => set(error401ModalAtom, true);
    const response = await handleReactQueryApiResponse(updateStory, handleError401Modal, userId, updatedStory);
    if (response.ok) {
      set(storiesAtom, []);
      set(getStoriesAtom);
      // 새로고침
      window.location.reload();
    }
  } catch (error) {
    devConsoleError("error", error);
  }
  set(getStoriesAtom);
});

const useStory = () => {
  const userId = useAtomValue(getUserId);
  const [tabValue, setTabValue] = useState(0);
  const [selectedSort, setSelectedSort] = useState<string>("latest");
  const [editingStory, setEditingStory] = useState<StoryProps | null>(null);
  const [storyProps, setStoryProps] = useState<RequestStoryProps>(getInitializeStoryProps(userId ?? 0)); // 스토리 작성 폼 상태

  const updateStoryProps = (key: keyof StoryProps, value: any) => {
    setStoryProps({ ...storyProps, [key]: value });
  };

  const startEditingStory = (story: StoryProps) => {
    setEditingStory(story);
  };

  return {
    storyProps,
    setStoryProps,
    updateStoryProps,
    editingStory,
    setEditingStory,
    startEditingStory,
    tabValue,
    setTabValue,
    getInitializeStoryProps,
    selectedSort,
    setSelectedSort,
  };
}

export default useStory;
