import { recommendPlanModalDataAtom, recommendPlanModalStateAtom } from '@/Atoms/RecommendPlan/Atom';
import useIsMobile from '@/Hooks/useIsMobile';
import { Box, Button, Chip, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';
import { useAtomValue, useSetAtom } from 'jotai';
import React from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { Background, BackgroundVariant, Controls, Node, ReactFlow, ReactFlowInstance } from '@xyflow/react';
import { TemplateNode } from '@/Components/Plan/Contents/Mindmap/Nodes/Template';
import TaskNode from '@/Components/Plan/Contents/Mindmap/Nodes/Task';
import JobNode from '@/Components/Plan/Contents/Mindmap/Nodes/Job';
import Group from '@/Components/Plan/Contents/Mindmap/Group';
import CustomEdge from '@/Components/Plan/Contents/Mindmap/Edges';
import * as S from "./styled";
import theme from '@/Styles/theme';
import CustomBackgroundColorButton from '@/Components/Common/Buttons/CustomBackgroundColor';
import { recommendPlanModalCloseAtom, recommendPlanModalImportAtom } from '@/ViewModels/RecommendPlan/PlanInfoModalViewModel';
import CustomColorButton from '@/Components/Common/Buttons/CustomColor';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const nodeTypes = {
  template: TemplateNode,
  default: TemplateNode,
  dream: TaskNode,
  task: TaskNode,
  goal: TaskNode,
  job: JobNode,
  ultimate: TaskNode,
  curriculum: TaskNode,
  major: TaskNode,
  group: Group,
};

const edgeTypes = {
  custom: CustomEdge,
}

const PlanInfoModal = () => {
  const { t } = useTranslation();
  const { isMobile } = useIsMobile();
  const modal = useAtomValue(recommendPlanModalStateAtom);
  const close = useSetAtom(recommendPlanModalCloseAtom);
  const modalData = useAtomValue(recommendPlanModalDataAtom);
  const importPlan = useSetAtom(recommendPlanModalImportAtom);
  const [confirm, setConfirm] = React.useState(false);
  const navigate = useNavigate();

  const onInit = React.useCallback((instance: ReactFlowInstance) => {
    if (modalData) {
      // 특정 노드 찾기 (여기선 첫 번째 노드 예시)
      const targetNode = modalData.nodes.find((node: Node) => node.type === "job"); // 원하는 노드 ID를 조건으로 찾기
      if (targetNode) {
        instance.setCenter(targetNode.position.x + 200, targetNode.position.y + 100, {
          zoom: 0.4, // 확대 배율
        });
      } else {
        instance.fitView();
      }
    }
  }, [modalData]);

  return (
    modal &&
    <Dialog open={!!modal} onClose={close} fullWidth maxWidth="xl" fullScreen={isMobile}>
      <S.CustomModalTitle>
        {modal.planName}
        <CloseIcon onClick={close} sx={{ cursor: "pointer" }} />
      </S.CustomModalTitle>
      <S.ContentContainer>
        <S.ContentMindmap>
          {
            modalData &&
            <ReactFlow
              nodes={modalData.nodes}
              edges={modalData.edges}
              nodeTypes={nodeTypes}
              edgeTypes={edgeTypes}
              fitView
              onInit={onInit}
              style={{ width: "100%", height: "100%" }}
            >
              <Controls />
              <Background
                color="#d8d8d8"
                variant={BackgroundVariant.Lines}
                gap={200}
                style={{ background: "#ececec" }}
              />
            </ReactFlow>
          }
        </S.ContentMindmap>
        <S.ContentInfo>
          <Box sx={{ display: "flex", flexDirection: "column", gap: "10px" }}>
            <Typography>
              {modal.content}
            </Typography>
            <div style={{ display: "flex", flexDirection: "row", gap: "10px" }}>
              {
                modal.hashtag.map((tag, index) => (
                  <Chip key={index} label={tag.value} sx={{ backgroundColor: tag.backgroundColor, color: tag.textColor }} />
                ))
              }
            </div>
          </Box>
        </S.ContentInfo>
      </S.ContentContainer>
      <DialogActions>
        {
          isMobile &&
          <Button sx={{ width: "100%", backgroundColor: theme.colors.primary, fontSize: "0.8rem" }} onClick={() => setConfirm(true)} variant="contained" color="primary">
            {t("recommendPlan.modals.planInfo.apply")}
          </Button>
        }
        {
          !isMobile &&
          <CustomBackgroundColorButton onClick={() => setConfirm(true)} text={t("recommendPlan.modals.planInfo.applyMyPlan")} fontSize="0.8rem" />
        }
      </DialogActions>
      <Dialog open={confirm} onClose={() => setConfirm(false)}>
        <DialogTitle sx={{ fontSize: "0.9rem", fontWeight: "bold" }}>{t("recommendPlan.modals.planInfo.title")}</DialogTitle>
        <DialogContent sx={{ display: "flex", flexDirection: "column", gap: "10px" }}>
          <Typography sx={{ fontSize: "0.8rem" }}>{t("recommendPlan.modals.planInfo.content")}</Typography>
        </DialogContent>
        <DialogActions>
          <CustomColorButton onClick={() => setConfirm(false)} text={t("recommendPlan.modals.planInfo.cancel")} fontSize="0.8rem" color="gray" />
          <CustomBackgroundColorButton onClick={() => importPlan(navigate, t)} text={t("recommendPlan.modals.planInfo.confirm")} fontSize="0.8rem" />
        </DialogActions>
      </Dialog>
    </Dialog>
  )
}

export default PlanInfoModal;
