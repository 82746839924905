import styled from "styled-components";

export const Container = styled.div<{ $isPrev: boolean }>`
  display: flex;
  flex-direction: row;
  justify-content: ${({ $isPrev }) => $isPrev ? "flex-start" : "space-between"};
  width: 100%;
  height: 20px;
  gap: 10px;
`;
