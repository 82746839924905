import { getVerifyRefreshToken } from "@/Queries/AuthQueries";
import { errorModalAtom } from "../Atoms/RootAtom";
import { ResponseStatus } from "../Enums/NetworkEnum";
import { deleteAuthCookies, getCookie } from "./CookieUtils";
import { devConsoleError } from "./ConsoleLogInDevelopment";

export const handleApiResponse = async (
  refresh: string | null,
  set: any,
  fetchFunction: () => Promise<any> | any,
  errorFunction: () => any,
  successFunction: (results?: any) => any
) => {
  const results = await fetchFunction();

  if (results === ResponseStatus.UNAUTHORIZED) {
    // deleteAuthCookies();

    set(errorModalAtom, {
      state: true,
      event: () =>
        (window.location.href = process.env.REACT_APP_PARENT_LOGIN_ADDRESS),
      eventText: "로그인",
      redirectUrl: "",
      text: "인증에 실패하셨습니다.\n서비스를 사용하시고 싶으시면 다시 로그인 해주세요.",
      title: "인증 실패",
    });
    return null;
  } else if (results === ResponseStatus.FORBIDDEN) {
    if (refresh) {
      const results = await getVerifyRefreshToken(refresh);

      return results;
    } else {
      deleteAuthCookies();

      set(errorModalAtom, {
        state: true,
        event: () =>
          (window.location.href = process.env.REACT_APP_PARENT_LOGIN_ADDRESS),
        eventText: "로그인",
        redirectUrl: "",
        text: "인증에 실패하셨습니다.\n서비스를 사용하시고 싶으시면 다시 로그인 해주세요.",
        title: "인증 실패",
      });

      return null;
    }
  } else if (results === ResponseStatus.ERROR) {
    const result = errorFunction();
    return result;
  } else {
    const result = successFunction(results);
    return result;
  }
};

export const handleReactQueryApiResponse = async (
  fetchFunction: (access: string, ...args: any[]) => Promise<any>,
  setError401: () => void,
  ...args: any[]
) => {
  const refreshCookie = getCookie(process.env.REACT_APP_USER_REFRESH_COOKIE_ID);
  const accessCookie = getCookie(process.env.REACT_APP_USER_ACCESS_COOKIE_ID)
    ? getCookie(process.env.REACT_APP_USER_ACCESS_COOKIE_ID)
    : await getVerifyRefreshToken(refreshCookie ?? "");

  let result;
  try {
    result = await fetchFunction(accessCookie, ...args);
  } catch (error) {
    devConsoleError("Initial fetch function error:", error);
    return null;
  }

  if (result instanceof Response && result.status === 403) {
    // Token 재발급 및 재시도
    const newAccessToken = await getVerifyRefreshToken(refreshCookie ?? "");
    try {
      const newResult = await fetchFunction(newAccessToken, ...args);
      return newResult;
    } catch (error) {
      devConsoleError("Retry fetch function error:", error);
      return null;
    }
  } else if (result instanceof Response && result.status === 401) {
    // 토큰 만료 혹은 잘못된 토큰
    deleteAuthCookies();
    setError401();
    return null;
  }

  return result;
};

export const handleReactQueryApiResponseWithJson = async (
  fetchFunction: (access: string, ...args: any[]) => Promise<any>,
  setError401: () => void,
  ...args: any[]
) => {
  const refreshCookie = getCookie(process.env.REACT_APP_USER_REFRESH_COOKIE_ID);
  const accessCookie = getCookie(process.env.REACT_APP_USER_ACCESS_COOKIE_ID) ? getCookie(process.env.REACT_APP_USER_ACCESS_COOKIE_ID) : await getVerifyRefreshToken(refreshCookie ?? "");

  const result = await fetchFunction(accessCookie, ...args);
  if (result.status === ResponseStatus.UNAUTHORIZED || result.status === ResponseStatus.ERROR) {
    deleteAuthCookies();
    setError401();
    return null;
  } else if (result.status === ResponseStatus.FORBIDDEN) {
    const newAccessToken = await getVerifyRefreshToken(refreshCookie ?? "");
    const newResult = await fetchFunction(newAccessToken, ...args);

    if (
      newResult.status === ResponseStatus.UNAUTHORIZED ||
      newResult.status === ResponseStatus.ERROR ||
      newResult.status === ResponseStatus.FORBIDDEN
    ) {
      deleteAuthCookies();
      setError401();
      return null;
    } else {
      return newResult.json();
    }
  } else {
    return result.json();
  }
};

