import React from "react";
import * as S from "./styled";
import { useAtom, useAtomValue, useSetAtom } from "jotai";
import { userAtom } from "../../Atoms/UserAtom";
import MentorDashboard from "../../Components/Dashboard/Mentor";
import MenteeDashboard from "../../Components/Dashboard/Mentee";
import TeacherDashboard from "../../Components/Dashboard/Teacher";
import usePlanHip from "@/Hooks/usePlanHip";
import { waitingModalAtom } from "@/Atoms/RootAtom";
import DailyPlanDialog from "@/Components/Dashboard/Dialogs/DailyPlan";
import { getUserPosition } from "@/ViewModels/UserViewModel";
import useMenteeDashboard from "@/Hooks/useMenteeDashboard";
import useTeacherDashboard from "@/Hooks/useTeacherDashboard";
import PlanInfoModal from "@/Components/RecommendPlan/Modals/PlanInfo";
import { dashboardMenteePlanTemplatesExpandedAtom } from "@/Atoms/Dashboard/MenteeAtom";
import { useTranslation } from "react-i18next";

const Dialogs = () => {
  return <>
    <DailyPlanDialog />
    <PlanInfoModal />
  </>;
}

const Dashboard = () => {
  const user = useAtomValue(userAtom);
  const userPosition = useAtomValue(getUserPosition);
  const { t } = useTranslation();
  const { isLoading } = usePlanHip();
  const setWaitingModal = useSetAtom(waitingModalAtom);
  const { isLoading: isMenteeDashboardLoading } = useMenteeDashboard();
  const { isLoading: isTeacherDashboardLoading } = useTeacherDashboard();
  const containerRef = React.useRef<HTMLDivElement>(null);
  const [expandedPlanTemplate, setExpandedPlanTemplate] = useAtom(dashboardMenteePlanTemplatesExpandedAtom);

  React.useEffect(() => {
    // 컨테이너 현재 스크롤 감지
    const handleScroll = () => {
      if (containerRef.current) {
        const { scrollTop } = containerRef.current;
        if (scrollTop >= 100 && expandedPlanTemplate.isFirst) {
          setExpandedPlanTemplate({ expanded: false, isFirst: false });
        }
      }
    };
    containerRef.current?.addEventListener("scroll", handleScroll);

    return () => {
      containerRef.current?.removeEventListener("scroll", handleScroll);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [expandedPlanTemplate]);

  React.useEffect(() => {
    setWaitingModal({
      state: isLoading && (
        userPosition === "교사" ?
          isTeacherDashboardLoading :
          userPosition === "멘티" ?
            isMenteeDashboardLoading :
            true
      ), text: t("dashboard.main.loading")
    });
  }, [isLoading, isTeacherDashboardLoading, isMenteeDashboardLoading, userPosition, setWaitingModal, t]);

  return (
    <S.Container ref={containerRef}>
      {user?.membership === "mentor" && <MentorDashboard />}
      {user?.membership === "mentee" && <MenteeDashboard />}
      {user?.membership === "teacher" && <TeacherDashboard />}
      {user?.membership === "free" && <MentorDashboard />}
      <Dialogs />
    </S.Container>
  );
};

export default Dashboard;
