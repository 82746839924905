import styled from "styled-components";
import theme from "../../../Styles/theme";
import { lighten } from "polished";

export const Container = styled.div`
  width: 249px;
  height: calc(100vh - 40px);
  display: flex;
  background-color: white;
  justify-content: space-between;
  align-items: center;
  padding: 20px 0px;
  flex-direction: column;
  overflow-x: hidden;
  overflow-y: hidden;
  border-right: 1px solid ${({ theme }) => theme.colors.darkBackground};
`;

export const AccordionContainer = styled.div<{ $open: boolean }>`
  display: flex;
  width: ${({ $open }) => ($open ? "250px" : "56px")};
  min-width: ${({ $open }) => ($open ? "250px" : "56px")};
  height: ${({ $open }) => ($open ? "100vh" : "calc(100vh - 40px)")};
  padding: ${({ $open }) => ($open ? "0px" : "20px")} 0px;
  background-color: ${({ theme }) => theme.colors.primary};
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  transition: width 0.3s ease;
`;

export const FoldContainer = styled.div`
  display: flex;
  width: 56px;
  height: 100vh - 40px;
  padding: 20px 0px;
  background-color: ${({ theme }) => theme.colors.primary};
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  transition: width 0.3s ease;
`;

export const AccordionCloseSection = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 30px;
`;

export const MobileHeaderContainer = styled.header`
  width: calc(100% - 40px);
  min-height: 56px;
  display: flex;
  align-items: center;
  padding: 0 20px;
  justify-content: space-between;
  color: #333;
  border-bottom: 1px solid ${({ theme }) => theme.colors.darkBackground};
  position: relative;
`;

export const ProfileContainer = styled.div<{ $membership: string }>`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  height: calc(100% - ${({ $membership }) => ($membership === "mentee" ? "150px" : "200px")});
`;

export const ProfileImage = styled.img<{ $isMobile: boolean }>`
  width: ${({ $isMobile }) => ($isMobile ? "50px" : "150px")};
  height: ${({ $isMobile }) => ($isMobile ? "50px" : "150px")};
  border-radius: 50%;
  object-fit: cover;
  // border: 2px solid ${({ theme }) => theme.colors.primary};
`;

export const ProfileDescription = styled.div`
  color: black;
  font-size: 1rem;
  font-weight: bold;
  text-align: center;
`;

export const TabContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  // height: calc(100% - 450px);
  overflow-y: auto;
`;

export const Tab = styled.div<{ $active: boolean }>`
  width: 100%;
  min-height: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  color: ${({ $active }) => ($active ? theme.colors.primary : "black")};
  padding: 0;
  font-size: 0.9rem;
  font-weight: bold;
  cursor: pointer;
  &:hover {
    background-color: rgba(0, 0, 0, 0.1);
  }
`;

export const MobileTab = styled.div<{ $active: boolean }>`
  width: calc(100% - 40px);
  height: 50px;
  min-height: 50px;
  display: flex;
  align-items: center;
  color: ${({ $active }) => ($active ? theme.colors.primary : "black")};
  padding: 0px 20px;
  font-size: 0.9rem;
  font-weight: 500;
  cursor: pointer;
  justify-content: space-between;
  border-bottom: 1px solid ${({ theme }) => theme.colors.darkBackground};
`;

export const ButtonContainer = styled.div<{ $membership: string }>`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: ${({ $membership }) => ($membership === "mentee" ? "150px" : "200px")};
  border-top: 1px solid ${({ theme }) => theme.colors.darkBackground};
`;

export const MobileDrawer = styled.div`
  display: flex;
  width: 300px;
  height: 100%;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
`;

export const MobileTopContainer = styled.div<{ $membership: string }>`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: ${({ $membership }) => ($membership === "mentee" ? "calc(100% - 155px)" : "calc(100% - 205px)")};
`;

export const MobileProfileContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: calc(100% - 40px);
  height: 50px;
  align-items: center;
  padding: 10px 20px;
  justify-content: space-between;
  border-bottom: 1px solid ${({ theme }) => theme.colors.darkBackground};
  position: relative;
`;

export const MobileProfileInfo = styled.div`
  display: flex;
  width: 190px;
  height: 100%;
  flex-direction: column;
  justify-content: center;
`;

export const StyledCreatePlanButton = styled.button<{ $disabled: boolean }>`
  display: flex;
  width: 90%;
  min-height: 40px;
  font-size: 0.9rem;
  background-color: ${({ $disabled }) =>
    $disabled ? "#ccc" : theme.colors.primary}; // disabled 상태일 때 회색
  color: white;
  align-items: center;
  justify-content: center;
  cursor: ${({ $disabled }) => ($disabled ? "not-allowed" : "pointer")};
  border: none;
  transition: background-color 0.2s ease;

  &:hover {
    background-color: ${({ $disabled }) =>
    $disabled ? "#ccc" : lighten(-0.1, theme.colors.primary)}; // 비활성화 상태에서는 hover 색상 유지
  }
`;
