import { notificationAtom, notificationDataAtom } from '@/Atoms/Notification/atom';
import { atom } from 'jotai';

// 알림을 모두 읽었는지 확인하는 로직
export const isAllReadNotificationAtom = atom((get) => get(notificationDataAtom).every((notification) => !notification.unread));

// 모두 읽음 처리
export const allReadNotificationAtom = atom(null, (get, set) => {
  set(notificationDataAtom, get(notificationDataAtom).map((notification) => ({ ...notification, unread: false })));
  set(notificationAtom, (prev) => ({ ...prev, isUnread: !get(isAllReadNotificationAtom) }));

  // TODO: 서버에 읽음 처리 요청
});

// 단일 알림 읽음 처리
export const readNotificationAtom = atom(null, (get, set, id: number) => {
  set(notificationDataAtom, get(notificationDataAtom).map((notification) => (notification.id === id ? { ...notification, unread: false } : notification)));
  set(notificationAtom, (prev) => ({ ...prev, isUnread: !get(isAllReadNotificationAtom) }));

  // TODO: 서버에 읽음 처리 요청
});
