import { atom } from "jotai";
import { Edge, Node } from "@xyflow/react";

export interface MindmapProps {
  id: number;
  name: string;
}

export const defaultInnerTabNodesAtom = atom<Node[]>([]);
export const firstGetNodeStateAtom = atom<boolean>(true);
export const typeAtom = atom<string | null>(null);
export const selectedNodeAtom = atom<Node | null>(null);
export const selectedEdgeAtom = atom<Edge | null>(null);
export const careerPathAtom = atom<{ id: number, path: string[] } | null>(null);
export const currentZoomAtom = atom<number>(0.4);
