import React from 'react';
import * as S from './styled';
import { useAtomValue } from 'jotai';
import crewAtom from '@/Atoms/CrewAtom';
import { useTranslation } from 'react-i18next';
import { Avatar, AvatarGroup, List, ListItem, Popover, Tooltip } from '@mui/material';
import { getTextColorBasedOnBackground } from '@/Utils/GetRandomColor';

const InfomationOfEachRow = ({ title, content }: { title: string | null, content: React.ReactNode }) => {
  return (
    <S.EachRowConatiner>
      {title && <S.Title>{title}</S.Title>}
      {content}
    </S.EachRowConatiner >
  );
}

const CrewInformation = () => {
  const crew = useAtomValue(crewAtom);
  const { t } = useTranslation();
  const [memberAnchorEl, setMemberAnchorEl] = React.useState<null | HTMLElement>(null);
  const [color, setColor] = React.useState<string>("#000");

  React.useEffect(() => {
    if (crew) {
      setColor(getTextColorBasedOnBackground(crew?.crewInfo.color as string | "#000"));
    }
  }, [crew]);

  const informationData: { title: string | null, content: React.ReactNode }[] = [
    { title: t("crew.crewInfo.content"), content: crew?.crewInfo.content },
    { title: t("crew.crewInfo.leader"), content: crew?.members.find((member) => member.authority === 10)?.nickname },
    { title: t("crew.crewInfo.counts"), content: `${crew?.members.length} / ${crew?.crewInfo.headCount}` },
    {
      title: null, content: (
        <>
          <AvatarGroup
            total={crew?.crewInfo.headCount}
            max={10}
            sx={{
              "& .MuiAvatarGroup-avatar": {
                fontSize: "0.9rem", // 폰트 크기 조정
                fontWeight: "bold", // 폰트 두께 조정
                cursor: "pointer", // 커서 변경
              },
            }}
            onClick={(e) => setMemberAnchorEl(e.currentTarget)}
          >
            {crew?.members.map((member, index) => (
              <Tooltip key={index} title={<div style={{ fontSize: "1rem", fontWeight: "bold" }}>{member.nickname}</div>} arrow>
                <Avatar src={`${process.env.REACT_APP_USER_API_ADDRESS}${member.profileImageUrl}`} alt={member.nickname} />
              </Tooltip>
            ))}
          </AvatarGroup>
          <Popover
            open={Boolean(memberAnchorEl)}
            anchorEl={memberAnchorEl}
            onClose={() => setMemberAnchorEl(null)}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            sx={{
              '& .MuiPaper-root': {
                borderRadius: '12px',
                boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.2)',
                padding: '0px',
              },
            }}
          >
            <List
              sx={{
                width: '250px',
                maxHeight: '300px',
                overflowY: 'auto',
                padding: 0,
              }}
            >
              {crew?.members.map((member, index) => (
                <ListItem
                  key={`list-item-${index}`}
                  sx={{
                    display: 'flex',
                    width: "calc(100% - 24px)",
                    alignItems: 'center',
                    gap: '12px',
                    padding: '8px 12px',
                    borderRadius: '8px',
                    '&:hover': {
                      backgroundColor: 'rgba(0, 0, 0, 0.05)',
                      cursor: 'pointer',
                    },
                  }}
                >
                  <Avatar src={`${process.env.REACT_APP_USER_API_ADDRESS}${member.profileImageUrl}`} alt={member.nickname} />
                  <div
                    style={{
                      textOverflow: 'ellipsis',
                      overflow: 'hidden',
                      whiteSpace: 'nowrap',
                    }}
                  >
                    {member.nickname}
                  </div>
                </ListItem>
              ))}
            </List>
          </Popover>
        </>
      )
    },
    (crew?.crewInfo.category !== null) ? { title: t("crew.crewInfo.category"), content: crew?.crewInfo.category } : { title: null, content: null },
    ...(crew?.crewInfo.hashtags && crew.crewInfo.hashtags.length > 0
      ? [{
        title: t("crew.crewInfo.hashtag"),
        content: (
          <S.HashTagContainer>
            {crew.crewInfo.hashtags.map(tag => <S.HashTag key={tag} $color={color} $backgroundColor={crew.crewInfo.color} >#{tag}</S.HashTag>)}
          </S.HashTagContainer>
        )
      }]
      : []
    )
  ];

  return (
    <S.Container>
      {informationData.map((info, index) => (
        info && <InfomationOfEachRow key={index} title={info.title} content={info.content} />
      ))}
    </S.Container>
  );
}

export default CrewInformation;
