import { Box, Button, styled, Typography } from "@mui/material";
import theme from "../../../Styles/theme";

export const CustomBox = styled(Box)({
  padding: "16px",
  height: "100%",
  boxSizing: "border-box",
  border: "1px solid #c0c0c0",
  borderRadius: "5px",
  display: "flex", // flex 컨테이너로 설정
  flexDirection: "column", // 세로로 정렬되도록 설정
  flexGrow: 1, // 부모의 나머지 공간을 차지하도록 설정
  position: "relative",
  "& .my-skill-ability": {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    display: "none",
  },
  "&:hover .my-skill-ability": {
    display: "block",
  },
  gap: "10px"
});

export const CustomButton = styled(Button)({
  background: theme.colors.primary,

  "&:hover": {
    background: theme.colors.darkPrimary,
  },
});

export const TitleTypo = styled(Typography)({
  // paddingLeft: "20px",
  color: theme.colors.primary,
  fontWeight: "bold",
});

export const DescTypo = styled(Typography)({
  // paddingLeft: "20px",
  fontSize: "14px",
  color: "#c0c0c0",
});

export const WelcomeMessageTypo = styled(Typography)({
  // paddingLeft: "20px",
  fontWeight: "bold",
});

export const UnivTypo = styled(Typography)({
  color: "black",
  fontWeight: "bold",
});

export const DepartmentTypo = styled(Typography)({
  color: "#c0c0c0",
});

interface LevelBoxProps {
  color: string;
}

export const LevelBox = styled(Box)<LevelBoxProps>((props) => ({
  background: `${props.color}30`,
  color: props.color,
  fontWeight: "bold",
  padding: "5px 20px",
  borderRadius: "30px",
}));

export const KeywordBox = styled(Box)<LevelBoxProps>((props) => ({
  background: `${props.color}30`,
  color: props.color,
  fontSize: "10px",
  padding: "5px",
  borderRadius: "30px",
}));
