import theme from "@/Styles/theme";
import { atom } from "jotai";
import { setInnerTabDataAtom, updatePlanDataMindmapAtom } from "../../InnerTabViewModel";
import GetIdFromQuerystring from "@/Utils/GetIdFromQuerystring";
import { deleteNode, deleteNodesAndEdges } from "@/Queries/PlanQueries";
import { getUserId } from "@/ViewModels/UserViewModel";
import { handleReactQueryApiResponse } from "@/Utils/APIUtil";
import { error401ModalAtom } from "@/Atoms/Dialogs/Error/401Atom";
import innerTabDataAtom from "@/Atoms/Plan/InnerTabDataAtom";

export const updatePlanDataAtom = atom(null, async (get, set) => {
  const planData = get(innerTabDataAtom);
  const innerTabId = GetIdFromQuerystring("inner_tab_id");
  const plan = planData.find((plan) => plan.innerTabId === innerTabId);
  if (!plan) {
    return;
  }
  const nodes = plan.nodes;
  const edges = plan.edges.map((edge) => ({
    ...edge,
    style: {
      ...edge.style,
      stroke: theme.colors.primary,
      strokeWidth: 3,
      strokeDasharray: undefined,
    },
    animated: false,
  }));
  set(updatePlanDataMindmapAtom, innerTabId, nodes, edges);
});

// delete task
export const deleteTaskAtom = atom(null, async (get, set, id: string) => {
  const planData = get(innerTabDataAtom);
  const innerTabId = GetIdFromQuerystring("inner_tab_id");
  const plan = planData.find((plan) => plan.innerTabId === innerTabId);
  if (!plan) {
    return;
  }
  const node = plan.nodes.find((node) => node.id === id);
  if (!node) {
    return;
  }
  if (node.type === "group") {
    const childNodes = plan.nodes.filter((node) => node.parentId === id);
    const newNodes = plan.nodes.filter((node) => node.id !== id && !childNodes.includes(node));
    await handleReactQueryApiResponse(
      deleteNode,
      () => set(error401ModalAtom, true),
      [node.data.backendId, ...childNodes.map((node) => node.data.backendId)],
      innerTabId,
      get(getUserId)
    );
    set(setInnerTabDataAtom, { innerTabId, nodes: newNodes, edges: plan.edges });
  } else {
    const connectedEdges = plan.edges
      .filter((edge) => edge.source === id || edge.target === id)
      .map((edge) => edge.data?.backendId as number);
    const newNodes = plan.nodes.filter((node) => node.id !== id);
    if (connectedEdges.length > 0) {
      const newEdges = plan.edges.filter((edge) => !connectedEdges.includes(edge.data?.backendId as number));
      await handleReactQueryApiResponse(
        deleteNodesAndEdges,
        () => set(error401ModalAtom, true),
        [node.data.backendId],
        connectedEdges,
        innerTabId,
        get(getUserId)
      );
      set(setInnerTabDataAtom, { innerTabId, nodes: newNodes, edges: newEdges });
    } else {
      await handleReactQueryApiResponse(
        deleteNode,
        () => set(error401ModalAtom, true),
        [node.data.backendId],
        innerTabId,
        get(getUserId)
      );
      set(setInnerTabDataAtom, { innerTabId, nodes: newNodes, edges: plan.edges });
    }
  }
});
