import { Node } from "@xyflow/react";
import { atom } from "jotai";

interface TogetherDialogAtomProps {
  node: Node | null;
}

export const togetherDialogAtom = atom<TogetherDialogAtomProps>({ node: null });
export const togetherDialogSelectedCrewIdAtom = atom<{
  crewId: number;
  innerTabId: number;
} | null>(null);
