import { atom } from "jotai";

export interface MentorProps {
  id: number;
  character_image: {
    id: number;
    file_path: string;
    character_type: string;
    maker: string;
    is_public: boolean;
    init_date: string;
    update_date: string | null;
    delete_date: string | null;
  };
  name: string;
  chatbot_socket_parameter: string;
  maker: string;
}

export const mentorListAtom = atom<MentorProps[]>([]);
