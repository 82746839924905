import { useQuery } from 'react-query';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { getUserId, userLanguageAtom } from '@/ViewModels/UserViewModel';
import { useRootAtom } from '@/Atoms/HookControllerAtom';
import i18n from '@/Locales/i18n';
import { getAliceByLanguageAtom } from '@/ViewModels/ChatViewModel';
import { emptySignature, getSignature } from '@/Queries/SignatureQueries';
import { signatureAtom } from '@/Atoms/Signature/Atom';
import { handleReactQueryApiResponse } from '@/Utils/APIUtil';
import { error401ModalAtom } from '@/Atoms/Dialogs/Error/401Atom';

const useRoot = () => {
  const userId = useAtomValue(getUserId);
  const [controller, setController] = useAtom(useRootAtom);
  const setElice = useSetAtom(getAliceByLanguageAtom);
  const language = useAtomValue(userLanguageAtom);
  const setSignature = useSetAtom(signatureAtom);
  const setError401Modal = useSetAtom(error401ModalAtom);
  const handleError401Modal = () => setError401Modal(true);

  return useQuery(['root', userId], () => setElice(language), {
    enabled: userId !== 0 && controller,
    onSuccess: async () => {
      setController(false);
      if (language === "KR") {
        i18n.changeLanguage("ko");
      } else {
        i18n.changeLanguage("en");
      }
      await handleReactQueryApiResponse(emptySignature, handleError401Modal).then(async (data) => {
        if (data && data.ok) {
          const response = await handleReactQueryApiResponse(getSignature, handleError401Modal);
          if (!response.ok) return;
          const jsonedData = await response.json();
          setSignature(jsonedData.signatures);
        }
      }).catch(() => { });
    },
  });
};

export default useRoot;
