import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: calc(100% - 40px);
  height: auto;
  // min-height: 260px;
  padding: 20px;
  gap: 10px;

  @media (max-width: 768px) {
    width: calc(100% - 20px);
    padding: 10px;
  }
`;

export const Title = styled.div`
  font-size: 1.2rem;
  font-weight: bold;
`;

export const ButtonGroup = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
`;

export const ElementContainer = styled.div`
  display: flex;
  width: 100%;
  height: 40px;
  flex-direction: row;
  align-items: center;
  gap: 10px;
`;

export const ElementTitle = styled.div`
  font-size: 0.9rem;
  font-weight: bold;
  width: 100px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 768px) {
    font-size: 0.8rem;
    width: 80px;
  }
`;

export const ElementInput = styled.input`
  width: calc(100% - 110px);
  height: 28px;
  padding: 5px;
  border: 1px solid #d9d9d9;
`;
