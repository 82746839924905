import { atom } from 'jotai';
import dayjs from 'dayjs';
import { ApplicationPeriodInfo } from '../../Create/Course';

interface EditLectureDialog {
  open: boolean;
  id: number;
  name: string; // 강좌명
  teacher: string; // 담당교사
  author_id: number; // 작성자
  grades: number[]; // 학년
  headcount: number; // 정원
  period: number; // 차시
  operatingTimes: { [key: string]: number[] }; // 운영시간
  operatingPlan: string; // 운영계획
  classRoom: string; // 강의실
  textbook: string; // 교재
  isVisibleMember: boolean; // 수강생 표시 여부
  isAbleToCancel: boolean; // 수강 취소 가능 여부
  applicationPeriod: ApplicationPeriodInfo; // 신청기간
  coursePeriod: { // 수강기간
    startDate: string;  // yyyy-MM-dd
    endDate: string;  // yyyy-MM-dd
  };
  hashtags: {
    backgroundColor: string;
    textColor: string;
    value: string;
  }[];
  members: {
    id: number;
    name: string;
    email: string;
    profileImage: string;
  }[];
  management: {
    id: number;
    name: string;
    email: string;
    profileImage: string;
  }
}

export const editLectureDialogInitializeState = (open: boolean, id: number): EditLectureDialog => ({
  open: open,
  id: id,
  name: '',
  teacher: '',
  author_id: 0,
  grades: [],
  headcount: 1,
  period: 1,
  operatingTimes: {
    Monday: [],
    Tuesday: [],
    Wednesday: [],
    Thursday: [],
    Friday: [],
    Saturday: [],
    Sunday: [],
  },
  operatingPlan: '',
  classRoom: '',
  textbook: '',
  isVisibleMember: true,
  isAbleToCancel: true,
  applicationPeriod: {
    start: {
      date: dayjs().format('YYYY-MM-DD'),
      hour: 0,
      minute: 0,
    },
    end: {
      date: dayjs().format('YYYY-MM-DD'),
      hour: 0,
      minute: 0,
    },
    detail: {
      option1: false,
      option2: false,
      option3: false,
      option4: false,
      option5: false,
    }
  },
  coursePeriod: {
    startDate: dayjs().format('YYYY-MM-DD'),
    endDate: dayjs().format('YYYY-MM-DD'),
  },
  hashtags: [],
  members: [],
  management: {
    id: 0,
    name: '',
    email: '',
    profileImage: '',
  }
});

const editLectureDialogAtom = atom<EditLectureDialog>(editLectureDialogInitializeState(false, 0));

export default editLectureDialogAtom;
