import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;

  @media (max-width: 768px) {
    flex-direction: column;
    height: calc(100vh - 56px);
  }
`;
