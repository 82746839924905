import { crewLecturesAtom, Lecture } from '@/Atoms/Crew';
import createLectureDialogAtom, { createLectureDialogInitializeState, MultipleLectureFormat } from '@/Atoms/Dialogs/Create/Lecture';
import { error401ModalAtom } from '@/Atoms/Dialogs/Error/401Atom';
import snackbarAtom from '@/Atoms/Snackbar';
import { CreateLectureInfo, createLectures } from '@/Queries/LectureQueries';
import { handleReactQueryApiResponse } from '@/Utils/APIUtil';
import GetIdFromQuerystring from '@/Utils/GetIdFromQuerystring';
import { getUserId } from '@/ViewModels/UserViewModel';
import dayjs from 'dayjs';
import { TFunction } from 'i18next';
import { atom } from 'jotai';

export const createLectureDialogOpenAtom = atom(null, (get, set) => {
  set(createLectureDialogAtom, createLectureDialogInitializeState(true));
})

export const createLectureDialogCloseAtom = atom(null, (get, set) => {
  set(createLectureDialogAtom, createLectureDialogInitializeState(false));
});

export const createLectureDialogChangeModeAtom = atom((get) => get(createLectureDialogAtom).mode, (get, set, mode: "menu" | "single" | "multi" | "confirm" | "edit") => {
  set(createLectureDialogAtom, (prev) => ({
    ...prev,
    mode,
  }));
});

export const createLectureDialogUploadFileAtom = atom((get) => get(createLectureDialogAtom).file, (get, set, file: File | null) => {
  set(createLectureDialogAtom, (prev) => ({
    ...prev,
    file,
  }));
});

export const createLectureDialogConfirmClickPreviousAtom = atom(null, (get, set) => {
  set(createLectureDialogChangeModeAtom, "multi");
  set(createLectureDialogAtom, (prev) => ({
    ...prev,
    allMultipleLecture: [],
    selectedMultipleLecture: [],
  }))
});

export const createLectureDialogAllMultipleLectureAtom = atom((get) => get(createLectureDialogAtom).allMultipleLecture, (get, set, allMultipleLecture: MultipleLectureFormat[]) => {
  set(createLectureDialogAtom, (prev) => ({
    ...prev,
    allMultipleLecture,
  }));
});

export const createLectureDialogSelectedMultipleLectureAtom = atom((get) => get(createLectureDialogAtom).selectedMultipleLecture, (get, set, selectedMultipleLecture: number[]) => {
  set(createLectureDialogAtom, (prev) => ({
    ...prev,
    selectedMultipleLecture,
  }));
});

export const createLectureDialogEditMultipleLectureAtom = atom(null, (get, set, index: number) => {
  set(createLectureDialogAtom, (prev) => ({
    ...prev,
    mode: "edit",
    editIndex: index,
    editLecture: prev.allMultipleLecture[index],
  }));
});

export const createLectureDialogEditCancelAtom = atom(null, (get, set) => {
  set(createLectureDialogAtom, (prev) => ({
    ...prev,
    mode: "multi",
    editIndex: -1,
    editLecture: null,
  }));
});

export const createLectureDialogEditSaveAtom = atom(null, async (get, set, t: TFunction<"translation", undefined>) => {
  set(createLectureDialogAtom, (prev) => {
    const { editIndex, editLecture } = prev;

    if (editLecture) {
      const newAllMultipleLecture = prev.allMultipleLecture.map((lecture) => lecture.index === editIndex ? editLecture : lecture);
      set(snackbarAtom, { open: true, message: t("dialogs.common.snackbar.lecture.editLectureComplete"), severity: 'success' });
      return {
        ...prev,
        mode: "confirm",
        editIndex: -1,
        editLecture: null,
        allMultipleLecture: newAllMultipleLecture,
      };
    }
    return prev;
  });
});

// create multiple lecture
export const createLectureDialogSaveMultipleLectureAtom = atom(null, async (get, set, t: TFunction<"translation", undefined>) => {
  const dialog = get(createLectureDialogAtom);
  const userId = get(getUserId);

  if (dialog.selectedMultipleLecture.length === 0) {
    set(snackbarAtom, { open: true, message: t("dialogs.common.snackbar.lecture.addLecturePlease"), severity: 'error' });
    return;
  }

  const innerTabId = GetIdFromQuerystring('inner_tab_id');

  const selectedLectures = dialog.selectedMultipleLecture
  const newLectures: CreateLectureInfo[] = dialog.allMultipleLecture.filter((lecture) => selectedLectures.includes(lecture.index)).map((lecture) => ({
    name: lecture.name,
    teacherId: 0, // TODO: teacherId should be set
    teacher: lecture.teacher,
    grades: lecture.grades,
    headcount: lecture.headcount,
    period: lecture.period,
    operatingTimes: lecture.operatingTimes,
    operatingPlan: lecture.operatingPlan,
    classRoom: lecture.classRoom,
    textbook: lecture.textbook,
    isVisibleMember: lecture.isVisibleMember,
    isAbleToCancel: lecture.isAbleToCancel,
    applicationPeriod: lecture.applicationPeriod,
    coursePeriod: lecture.coursePeriod,
    hashtags: lecture.hashtags
  }));

  const handleError401Modal = () => set(error401ModalAtom, true);

  await handleReactQueryApiResponse(createLectures, handleError401Modal, innerTabId, newLectures, userId).then(async (response) => {
    if (response === null) {
      set(snackbarAtom, { open: true, message: t("dialogs.common.snackbar.lecture.createLectureError"), severity: 'error' });
      return;
    }
    const jsonedData = await response.json();
    const responseLectures = jsonedData.lectures;
    set(crewLecturesAtom, (prev) => prev.map((tab) => {
      if (tab.innerTabId === innerTabId) {
        return {
          ...tab,
          lectures: tab.lectures ? [...tab.lectures, ...responseLectures] : responseLectures,
        };
      } else {
        return tab;
      }
    }));
  });

  set(snackbarAtom, { open: true, message: t("dialogs.common.snackbar.lecture.createBatchLectureComplete"), severity: 'success' });
  set(createLectureDialogAtom, createLectureDialogInitializeState(false));
});

// save dialog
export const createLectureDialogSaveAtom = atom(null, async (get, set, t: TFunction<"translation", undefined>) => {
  const dialog = get(createLectureDialogAtom);

  // Validation
  if (!dialog.teacher.trim()) {
    set(snackbarAtom, { open: true, message: t("dialogs.common.snackbar.lecture.enterTeacher"), severity: 'error' });
    return;
  }

  if (!dialog.name.trim()) {
    set(snackbarAtom, { open: true, message: t("dialogs.common.snackbar.lecture.enterLectureName"), severity: 'error' });
    return;
  }

  if (!dialog.grades || dialog.grades.length === 0) {
    set(snackbarAtom, { open: true, message: t("dialogs.common.snackbar.lecture.enterGrade"), severity: 'error' });
    return;
  }

  if (!dialog.headcount || dialog.headcount <= 0) {
    set(snackbarAtom, { open: true, message: t("dialogs.common.snackbar.lecture.headcount"), severity: 'error' });
    return;
  }

  if (!dialog.period || dialog.period <= 0) {
    set(snackbarAtom, { open: true, message: t("dialogs.common.snackbar.lecture.period"), severity: 'error' });
    return;
  }

  if (Object.keys(dialog.operatingTimes).length === 0 ||
    !Object.values(dialog.operatingTimes).some(times => times.length > 0)) {
    set(snackbarAtom, { open: true, message: t("dialogs.common.snackbar.lecture.operationTime"), severity: 'error' });
    return;
  }

  // Date validations
  const { applicationPeriod, coursePeriod } = dialog;

  const applicationStart = new Date(`${applicationPeriod.start.date}T${applicationPeriod.start.hour}:${applicationPeriod.start.minute}`);
  const applicationEnd = new Date(`${applicationPeriod.end.date}T${applicationPeriod.end.hour}:${applicationPeriod.end.minute}`);
  const courseStart = new Date(coursePeriod.startDate);
  const courseEnd = new Date(coursePeriod.endDate);

  if (applicationStart >= applicationEnd) {
    set(snackbarAtom, { open: true, message: t("dialogs.common.snackbar.lecture.applicationStart"), severity: 'error' });
    return;
  }

  if (courseStart >= courseEnd) {
    set(snackbarAtom, { open: true, message: t("dialogs.common.snackbar.lecture.courseStart"), severity: 'error' });
    return;
  }

  if (applicationEnd >= courseStart) {
    set(snackbarAtom, { open: true, message: t("dialogs.common.snackbar.lecture.applicationCourse"), severity: 'error' });
    return;
  }

  const innerTabId = GetIdFromQuerystring('inner_tab_id');

  // If all validations pass, create a new lecture object
  const newLecture: CreateLectureInfo = {
    name: dialog.name,
    teacherId: 0,
    teacher: dialog.teacher,
    grades: dialog.grades,
    headcount: dialog.headcount,
    period: dialog.period,
    operatingTimes: dialog.operatingTimes,
    operatingPlan: dialog.operatingPlan,
    classRoom: dialog.classRoom,
    textbook: dialog.textbook,
    isVisibleMember: dialog.isVisibleMember,
    isAbleToCancel: dialog.isAbleToCancel,
    applicationPeriod: dialog.applicationPeriod,
    coursePeriod: dialog.coursePeriod,
    hashtags: dialog.hashtags
  };

  const handleError401Modal = () => set(error401ModalAtom, true);

  await handleReactQueryApiResponse(createLectures, handleError401Modal, innerTabId, [newLecture], get(getUserId)).then(async (response) => {
    if (response === null) {
      set(snackbarAtom, { open: true, message: t("dialogs.common.snackbar.lecture.createLectureError"), severity: 'error' });
      return;
    }
    const jsonedData = await response.json();
    const responseLectures = jsonedData.lectures;
    set(crewLecturesAtom, (prev) => prev.map((tab) => {
      if (tab.innerTabId === innerTabId) {
        return {
          ...tab,
          lectures: tab.lectures ? [...tab.lectures, ...responseLectures] : responseLectures,
        };
      } else {
        return tab;
      }
    }));
  });

  set(snackbarAtom, { open: true, message: t("dialogs.common.snackbar.lecture.createLectureComplete"), severity: 'success' });

  // Close the dialog
  set(createLectureDialogAtom, (prev) => createLectureDialogInitializeState(false));
});

// edit teacher
export const createLectureDialogEditMultipleLectureTeacherAtom = atom(null, (get, set, teacher: string) => {
  const editLecture = get(createLectureDialogAtom).editLecture;
  if (editLecture) {
    const newEditLecture = { ...editLecture, teacher };
    set(createLectureDialogAtom, (prev) => ({
      ...prev,
      editLecture: newEditLecture,
    }));
  }
});

// edit headcount
export const createLectureDialogEditMultipleLectureHeadcountAtom = atom((get) => get(createLectureDialogAtom).editLecture?.headcount, (get, set, headcount: number) => {
  const editLecture = get(createLectureDialogAtom).editLecture;
  if (editLecture) {
    const newEditLecture = { ...editLecture, headcount };
    set(createLectureDialogAtom, (prev) => ({
      ...prev,
      editLecture: newEditLecture,
    }));
  }
});

// edit period
export const createLectureDialogEditMultipleLecturePeriodAtom = atom((get) => get(createLectureDialogAtom).editLecture?.period, (get, set, period: number) => {
  const editLecture = get(createLectureDialogAtom).editLecture;
  if (editLecture) {
    const newEditLecture = { ...editLecture, period };
    set(createLectureDialogAtom, (prev) => ({
      ...prev,
      editLecture: newEditLecture,
    }));
  }
});

// edit grade
export const createLectureDialogEditMultipleLectureGradesAtom = atom(
  (get) => get(createLectureDialogAtom).editLecture?.grades,
  (get, set, grade: number) => {
    const editLecture = get(createLectureDialogAtom).editLecture;
    if (editLecture) {
      let updatedGrades: number[] = [];

      if (grade === 0) {
        // If all grades [0, 1, 2, 3] are already selected, clear the array
        if (editLecture.grades.length === 4 && editLecture.grades.every((g) => [0, 1, 2, 3].includes(g))) {
          updatedGrades = [];
        } else {
          // Otherwise, set to [0, 1, 2, 3]
          updatedGrades = [0, 1, 2, 3];
        }
      } else {
        // If the grade already exists, remove it and remove 0 if present
        if (editLecture.grades.includes(grade)) {
          updatedGrades = editLecture.grades.filter((g) => g !== grade && g !== 0);
        } else {
          // Add the grade
          updatedGrades = [...editLecture.grades, grade];

          // Check if we now have [1, 2, 3] and need to add 0
          if ([1, 2, 3].every((g) => updatedGrades.includes(g))) {
            updatedGrades = Array.from(new Set([...updatedGrades, 0]));
          }
        }
      }

      const newEditLecture = { ...editLecture, grades: updatedGrades };
      set(createLectureDialogAtom, (prev) => ({
        ...prev,
        editLecture: newEditLecture,
      }));
    }
  }
);

// edit operating plan
export const createLectureDialogEditMultipleLectureOperatingPlanAtom = atom((get) => get(createLectureDialogAtom).editLecture?.operatingPlan, (get, set, operatingPlan: string) => {
  const editLecture = get(createLectureDialogAtom).editLecture;
  if (editLecture) {
    const newEditLecture = { ...editLecture, operatingPlan };
    set(createLectureDialogAtom, (prev) => ({
      ...prev,
      editLecture: newEditLecture,
    }));
  }
});

// edit operating times
export const createLectureDialogEditMultipleLectureOperatingTimesAtom = atom((get) => get(createLectureDialogAtom).editLecture?.operatingTimes, (get, set, day: string, time: number) => {
  const editLecture = get(createLectureDialogAtom).editLecture;
  if (editLecture) {
    const newOperatingTimes = {
      ...editLecture.operatingTimes,
      [day]: editLecture.operatingTimes[day].includes(time)
        ? editLecture.operatingTimes[day].filter((t) => t !== time)
        : [...editLecture.operatingTimes[day], time],
    };

    const newEditLecture = { ...editLecture, operatingTimes: newOperatingTimes };
    set(createLectureDialogAtom, (prev) => ({
      ...prev,
      editLecture: newEditLecture,
    }));
  }
});

// edit operating times (specific day)
export const createLectureDialogEditMultipleLectureOperatingTimesDayAtom = atom(null, (get, set, day: string) => {
  const editLecture = get(createLectureDialogAtom).editLecture;
  if (editLecture) {
    const newOperatingTimes = {
      ...editLecture.operatingTimes,
      [day]: Array.from({ length: 14 }, (_, i) => i),
    };

    const newEditLecture = { ...editLecture, operatingTimes: newOperatingTimes };
    set(createLectureDialogAtom, (prev) => ({
      ...prev,
      editLecture: newEditLecture,
    }));
  }
});

// edit operating times (specific time)
export const createLectureDialogEditMultipleLectureOperatingTimesTimeAtom = atom(null, (get, set, time: number) => {
  const editLecture = get(createLectureDialogAtom).editLecture;
  if (editLecture) {
    const newOperatingTimes = Object.fromEntries(
      Object.entries(editLecture.operatingTimes).map(([day, times]) => [
        day,
        times.includes(time) ? times : [...times, time],
      ])
    );

    const newEditLecture = { ...editLecture, operatingTimes: newOperatingTimes };
    set(createLectureDialogAtom, (prev) => ({
      ...prev,
      editLecture: newEditLecture,
    }));
  }
});

// edit class room
export const createLectureDialogEditMultipleLectureClassRoomAtom = atom((get) => get(createLectureDialogAtom).editLecture?.classRoom, (get, set, LectureRoom: string) => {
  const editLecture = get(createLectureDialogAtom).editLecture;
  if (editLecture) {
    const newEditLecture = { ...editLecture, classRoom: LectureRoom };
    set(createLectureDialogAtom, (prev) => ({
      ...prev,
      editLecture: newEditLecture,
    }));
  }
});

// edit name
export const createLectureDialogEditMultipleLectureNameAtom = atom((get) => get(createLectureDialogAtom).editLecture?.name, (get, set, name: string) => {
  const editLecture = get(createLectureDialogAtom).editLecture;
  if (editLecture) {
    const newEditLecture = { ...editLecture, name };
    set(createLectureDialogAtom, (prev) => ({
      ...prev,
      editLecture: newEditLecture,
    }));
  }
});

// edit textbook
export const createLectureDialogEditMultipleLectureTextbookAtom = atom((get) => get(createLectureDialogAtom).editLecture?.textbook, (get, set, textbook: string) => {
  const editLecture = get(createLectureDialogAtom).editLecture;
  if (editLecture) {
    const newEditLecture = { ...editLecture, textbook };
    set(createLectureDialogAtom, (prev) => ({
      ...prev,
      editLecture: newEditLecture,
    }));
  }
});

// application period
export const createLectureDialogEditMultipleLectureApplicationPeriodAtom = atom((get) => get(createLectureDialogAtom).editLecture?.applicationPeriod);

// edit application period (start, end date)
export const createLectureDialogEditMultipleLectureApplicationPeriodDateAtom = atom(null, (get, set, date: Date, type: "start" | "end") => {
  const editLecture = get(createLectureDialogAtom).editLecture;
  if (editLecture) {
    const newEditLecture = {
      ...editLecture,
      applicationPeriod: {
        ...editLecture.applicationPeriod,
        [type]: {
          ...editLecture.applicationPeriod[type],
          date: dayjs(date).format('YYYY-MM-DD'),
        },
      },
    };
    set(createLectureDialogAtom, (prev) => ({
      ...prev,
      editLecture: newEditLecture,
    }));
  }
});

// edit application period (start, end hour and minute)
export const createLectureDialogEditMultipleLectureApplicationPeriodTimeAtom = atom(null, (get, set, time: number, type: "start" | "end", option: "hour" | "minute") => {
  const newTime = option === "hour" ? time > 23 ? 23 : time : time > 59 ? 59 : time;
  const editLecture = get(createLectureDialogAtom).editLecture;
  if (editLecture) {
    const newEditLecture = {
      ...editLecture,
      applicationPeriod: {
        ...editLecture.applicationPeriod,
        [type]: {
          ...editLecture.applicationPeriod[type],
          [option]: newTime,
        },
      },
    };
    set(createLectureDialogAtom, (prev) => ({
      ...prev,
      editLecture: newEditLecture,
    }));
  }
});

// edit course period
export const createLectureDialogEditMultipleLectureCoursePeriodAtom = atom((get) => get(createLectureDialogAtom).editLecture?.coursePeriod, (get, set, date: Date, type: "startDate" | "endDate") => {
  const editLecture = get(createLectureDialogAtom).editLecture;
  if (editLecture) {
    const newEditLecture = {
      ...editLecture,
      coursePeriod: {
        ...editLecture.coursePeriod,
        [type]: dayjs(date).format('YYYY-MM-DD'),
      },
    };
    set(createLectureDialogAtom, (prev) => ({
      ...prev,
      editLecture: newEditLecture,
    }));
  }
});

// 해시태그 추가
export const createLectureDialogEditMultipleLectureAddHashtagAtom = atom(null, (get, set, hashtag: { backgroundColor: string, textColor: string, value: string }) => {
  const editLecture = get(createLectureDialogAtom).editLecture;
  if (editLecture) {
    const newEditLecture = { ...editLecture, hashtags: [...editLecture.hashtags, hashtag] };
    set(createLectureDialogAtom, (prev) => ({
      ...prev,
      editLecture: newEditLecture,
    }));
  }
});

// 해시태그 삭제(pop)
export const createLectureDialogEditMultipleLectureRemoveHashtagAtom = atom(null, (get, set) => {
  const editLecture = get(createLectureDialogAtom).editLecture;
  if (editLecture) {
    const newEditLecture = { ...editLecture, hashtags: editLecture.hashtags.slice(0, -1) };
    set(createLectureDialogAtom, (prev) => ({
      ...prev,
      editLecture: newEditLecture,
    }));
  }
});

// 수강생 표시 여부
export const createLectureDialogEditMultipleLectureIsVisibleMemberAtom = atom((get) => get(createLectureDialogAtom).editLecture?.isVisibleMember, (get, set, isVisibleMember: boolean) => {
  const editLecture = get(createLectureDialogAtom).editLecture;
  if (editLecture) {
    const newEditLecture = { ...editLecture, isVisibleMember };
    set(createLectureDialogAtom, (prev) => ({
      ...prev,
      editLecture: newEditLecture,
    }));
  }
});

// 수강 취소 가능 여부
export const createLectureDialogEditMultipleLectureIsAbleToCancelAtom = atom((get) => get(createLectureDialogAtom).editLecture?.isAbleToCancel, (get, set, isAbleToCancel: boolean) => {
  const editLecture = get(createLectureDialogAtom).editLecture;
  if (editLecture) {
    const newEditLecture = { ...editLecture, isAbleToCancel };
    set(createLectureDialogAtom, (prev) => ({
      ...prev,
      editLecture: newEditLecture,
    }));
  }
});

// create lecture dialog atoms
// edit teacher
export const createLectureDialogTeacherAtom = atom((get) => get(createLectureDialogAtom).teacher, (get, set, teacher: string) => {
  set(createLectureDialogAtom, (prev) => ({
    ...prev,
    teacher,
  }));
});

// edit headcount
export const createLectureDialogHeadcountAtom = atom((get) => get(createLectureDialogAtom).headcount, (get, set, headcount: number) => {
  set(createLectureDialogAtom, (prev) => ({
    ...prev,
    headcount,
  }));
});

// edit period
export const createLectureDialogPeriodAtom = atom((get) => get(createLectureDialogAtom).period, (get, set, period: number) => {
  set(createLectureDialogAtom, (prev) => ({
    ...prev,
    period,
  }));
});

// edit grade
export const createLectureDialogGradesAtom = atom(
  (get) => get(createLectureDialogAtom).grades,
  (get, set, grade: number) => {
    set(createLectureDialogAtom, (prev) => {
      let updatedGrades: number[] = [];

      if (grade === 0) {
        // If all grades [0, 1, 2, 3] are already selected, clear the array
        if (prev.grades.length === 4 && prev.grades.every((g) => [0, 1, 2, 3].includes(g))) {
          updatedGrades = [];
        } else {
          // Otherwise, set to [0, 1, 2, 3]
          updatedGrades = [0, 1, 2, 3];
        }
      } else {
        // If the grade already exists, remove it and remove 0 if present
        if (prev.grades.includes(grade)) {
          updatedGrades = prev.grades.filter((g) => g !== grade && g !== 0);
        } else {
          // Add the grade
          updatedGrades = [...prev.grades, grade];

          // Check if we now have [1, 2, 3] and need to add 0
          if ([1, 2, 3].every((g) => updatedGrades.includes(g))) {
            updatedGrades = Array.from(new Set([...updatedGrades, 0]));
          }
        }
      }

      return { ...prev, grades: updatedGrades };
    });
  }
);

// edit operating plan
export const createLectureDialogOperatingPlanAtom = atom((get) => get(createLectureDialogAtom).operatingPlan, (get, set, operatingPlan: string) => {
  set(createLectureDialogAtom, (prev) => ({
    ...prev,
    operatingPlan,
  }));
});

// edit operating times
export const createLectureDialogOperatingTimesAtom = atom((get) => get(createLectureDialogAtom).operatingTimes, (get, set, day: string, time: number) => {
  set(createLectureDialogAtom, (prev) => ({
    ...prev,
    operatingTimes: {
      ...prev.operatingTimes,
      [day]: prev.operatingTimes[day].includes(time)
        ? prev.operatingTimes[day].filter((t) => t !== time)
        : [...prev.operatingTimes[day], time],
    },
  }));
});

// edit operating times (specific day)
export const createLectureDialogOperatingTimesDayAtom = atom(null, (get, set, day: string) => {
  set(createLectureDialogAtom, (prev) => ({
    ...prev,
    operatingTimes: {
      ...prev.operatingTimes,
      [day]: Array.from({ length: 14 }, (_, i) => i),
    },
  }));
});

// edit operating times (specific time)
export const createLectureDialogOperatingTimesTimeAtom = atom(null, (get, set, time: number) => {
  set(createLectureDialogAtom, (prev) => ({
    ...prev,
    operatingTimes: Object.fromEntries(
      Object.entries(prev.operatingTimes).map(([day, times]) => [
        day,
        times.includes(time) ? times : [...times, time],
      ])
    ),
  }));
});

// edit class room
export const createLectureDialogClassRoomAtom = atom((get) => get(createLectureDialogAtom).classRoom, (get, set, LectureRoom: string) => {
  set(createLectureDialogAtom, (prev) => ({
    ...prev,
    classRoom: LectureRoom,
  }));
});

// edit name
export const createLectureDialogNameAtom = atom((get) => get(createLectureDialogAtom).name, (get, set, name: string) => {
  set(createLectureDialogAtom, (prev) => ({
    ...prev,
    name,
  }));
});

// edit textbook
export const createLectureDialogTextbookAtom = atom((get) => get(createLectureDialogAtom).textbook, (get, set, textbook: string) => {
  set(createLectureDialogAtom, (prev) => ({
    ...prev,
    textbook,
  }));
});

// application period
export const createLectureDialogApplicationPeriodAtom = atom((get) => get(createLectureDialogAtom).applicationPeriod);

// edit application period (start, end date)
export const createLectureDialogApplicationPeriodDateAtom = atom(null, (get, set, date: Date, type: "start" | "end") => {
  set(createLectureDialogAtom, (prev) => ({
    ...prev,
    applicationPeriod: {
      ...prev.applicationPeriod,
      [type]: {
        ...prev.applicationPeriod[type],
        date: dayjs(date).format('YYYY-MM-DD'),
      },
    },
  }));
});

// edit application period (start, end hour and minute)
export const createLectureDialogApplicationPeriodTimeAtom = atom(null, (get, set, time: number, type: "start" | "end", option: "hour" | "minute") => {
  const newTime = option === "hour" ? time > 23 ? 23 : time : time > 59 ? 59 : time;
  set(createLectureDialogAtom, (prev) => ({
    ...prev,
    applicationPeriod: {
      ...prev.applicationPeriod,
      [type]: {
        ...prev.applicationPeriod[type],
        [option]: newTime,
      },
    },
  }));
});

// edit course period
export const createLectureDialogCoursePeriodAtom = atom((get) => get(createLectureDialogAtom).coursePeriod, (get, set, date: Date, type: "startDate" | "endDate") => {
  set(createLectureDialogAtom, (prev) => ({
    ...prev,
    coursePeriod: {
      ...prev.coursePeriod,
      [type]: dayjs(date).format('YYYY-MM-DD'),
    },
  }));
});

// 해시태그 추가
export const createLectureDialogAddHashtagAtom = atom(null, (get, set, hashtag: { backgroundColor: string, textColor: string, value: string }) => {
  set(createLectureDialogAtom, (prev) => ({
    ...prev,
    hashtags: [...prev.hashtags, hashtag],
  }));
});

// 해시태그 삭제(pop)
export const createLectureDialogRemoveHashtagAtom = atom(null, (get, set) => {
  set(createLectureDialogAtom, (prev) => ({
    ...prev,
    hashtags: prev.hashtags.slice(0, -1),
  }));
});

// 수강생 표시 여부
export const createLectureDialogIsVisibleMemberAtom = atom((get) => get(createLectureDialogAtom).isVisibleMember, (get, set, isVisibleMember: boolean) => {
  set(createLectureDialogAtom, (prev) => ({
    ...prev,
    isVisibleMember,
  }));
});

// 수강 취소 가능 여부
export const createLectureDialogIsAbleToCancelAtom = atom((get) => get(createLectureDialogAtom).isAbleToCancel, (get, set, isAbleToCancel: boolean) => {
  set(createLectureDialogAtom, (prev) => ({
    ...prev,
    isAbleToCancel,
  }));
});
