import React from "react";
import * as S from "./styled";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { IconButton, Menu, MenuItem, Typography } from "@mui/material";
import { Node } from "@xyflow/react";
import CalendarTodayOutlinedIcon from "@mui/icons-material/CalendarTodayOutlined";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import { LocationProps } from "@/Atoms/RootAtom";
import ScheduleOutlinedIcon from "@mui/icons-material/ScheduleOutlined";
import { useAtomValue, useSetAtom } from "jotai";
import { editTaskDialogLoadDataAtom } from "@/ViewModels/Dialogs/Edit/TaskViewModel";
import useIsMobile from "@/Hooks/useIsMobile";
import snackbarAtom from "@/Atoms/Snackbar";
import { togetherDialogAtom } from "@/Atoms/Dialogs/Together";
import { deleteTaskAtom } from "@/ViewModels/Plan/Mindmap/ViewModel";
import CustomColorButton from "@/Components/Common/Buttons/CustomColor";
import TextMarkdown from "@/Components/Common/Markdown";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import CommentOutlinedIcon from '@mui/icons-material/CommentOutlined';
import { feedDetailDialogAtom } from "@/Atoms/Dialogs/FeedDetail/atom";
import { getUserId } from "@/ViewModels/UserViewModel";
import TagIcon from '@mui/icons-material/Tag';
import { devConsoleLog } from "@/Utils/ConsoleLogInDevelopment";

interface CrewTaskProps {
  item: Node;
}

const CrewTask = ({ item }: CrewTaskProps) => {
  const userId = useAtomValue(getUserId);
  const { isMobile } = useIsMobile();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const setSnackbar = useSetAtom(snackbarAtom);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) =>
    setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);
  const editTask = useSetAtom(editTaskDialogLoadDataAtom);
  const deleteTask = useSetAtom(deleteTaskAtom);
  const setTogetherDialog = useSetAtom(togetherDialogAtom);
  const { t } = useTranslation();
  const contentRef = React.useRef<HTMLDivElement>(null);
  const [isExpanded, setIsExpanded] = React.useState(false);
  const MAX_HEIGHT = 300;
  const [isOverflowing, setIsOverflowing] = React.useState(false); // 높이 초과 여부
  const setFeedDetailDialog = useSetAtom(feedDetailDialogAtom);

  const groupItems = [
    { name: t("crew.crewTask.menuItems.edit"), value: "edit" },
    { name: t("crew.crewTask.menuItems.delete"), value: "delete" },
  ];
  const handleMenuItemClick = (value: string) => {
    if (value === "edit") {
      editTask(item);
    } else if (value === "delete") {
      deleteTask(item.id);
      setSnackbar({
        open: true,
        message: t("dialogs.common.snackbar.deleteSuccess"),
        severity: "success",
      });
    }
    handleClose();
  };

  // Helper function to break title into spans
  const formatTitle = (title: string) => {
    return title.split(/(?<=[?!.])\s+/g).map((part, index) => (
      // <span key={index} style={{ cursor: "pointer" }} onClick={() => setFeedDetailDialog(item)}>
      <span key={index}>
        {part}
      </span >
    ));
  };

  const formatDateToLocalizedString = (date: Date, timeType: string): string => {
    const dateFormat = t('crew.crewTask.date.dateFormat');
    const timeFormat = t('crew.crewTask.date.timeFormat');
    const formattedDate = dayjs(date).format(dateFormat);
    const formattedTime = dayjs(date).format(timeFormat);

    return t('crew.crewTask.date.from', { date: formattedDate, time: formattedTime, timeType: timeType });
  };

  // 높이 초과 여부를 확인
  React.useEffect(() => {
    if (contentRef.current) {
      setIsOverflowing(contentRef.current.scrollHeight > MAX_HEIGHT);
    }
  }, [contentRef]);

  return (
    <S.StoryItem $isMobile={isMobile}>
      <S.StoryTitleContainer>
        {item.data && (item.data.label as string) !== "" && (
          <S.StoryTitle>
            {formatTitle(item.data.label as string)}
          </S.StoryTitle>
        )}
        {
          item.data.userId === userId &&
          <>
            <IconButton
              sx={{
                color: "black",
                fontWeight: "bold",
                padding: 0,
                textTransform: "none",
                fontSize: "0rem",
              }}
              aria-controls={open ? "basic-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              onClick={handleClick}
            >
              <MoreVertIcon sx={{ fontSize: "1.5rem" }} />
            </IconButton>
            <Menu
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
            >
              {groupItems.map((item, index) => (
                <MenuItem
                  key={index}
                  onClick={() => handleMenuItemClick(item.value)}
                >
                  {item.name}
                </MenuItem>
              ))}
            </Menu>
          </>
        }
      </S.StoryTitleContainer>
      <div
        ref={contentRef}
        style={{
          maxHeight: isExpanded ? "none" : `${MAX_HEIGHT}px`,
          overflow: isExpanded ? "visible" : "hidden",
          textOverflow: "ellipsis",
          display: "-webkit-box",
          WebkitLineClamp: isExpanded ? "none" : 3, // 줄 수 제한
          WebkitBoxOrient: "vertical"
        }}
      >
        <div style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
          {(item.data.startDate as string) && (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                gap: "10px",
                alignItems: "center",
              }}
            >
              <CalendarTodayOutlinedIcon sx={{ fontSize: "1rem" }} />
              <Typography style={{ fontSize: "0.8rem" }}>
                {formatDateToLocalizedString(new Date(item.data.startDate as string), t("crew.crewTask.date.timeType.from"))}
              </Typography>
            </div>
          )}
          {(item.data.endDate as string) && (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                gap: "10px",
                alignItems: "center",
              }}
            >
              <CalendarTodayOutlinedIcon sx={{ fontSize: "1rem" }} />
              <Typography style={{ fontSize: "0.8rem" }}>
                {formatDateToLocalizedString(new Date(item.data.endDate as string), t("crew.crewTask.date.timeType.until"))}
              </Typography>
            </div>
          )}
          {(item.data.location as LocationProps).address && (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                gap: "10px",
                alignItems: "center",
              }}
            >
              <LocationOnOutlinedIcon sx={{ fontSize: "1rem" }} />
              <Typography style={{ fontSize: "0.8rem" }}>
                {(item.data.location as LocationProps).address as string}
              </Typography>
            </div>
          )}
          {(item.data.termType as number) !== null && (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                gap: "10px",
                alignItems: "center",
              }}
            >
              <ScheduleOutlinedIcon sx={{ fontSize: "1rem" }} />
              <Typography style={{ fontSize: "0.8rem" }}>
                {(item.data.termType as number) === 1
                  ? "매주"
                  : (item.data.termType as number) === 2
                    ? "매월"
                    : "매일"}
              </Typography>
              {(item.data.termType as number) === 1 &&
                (item.data.termData as string[]).map((day, index) => (
                  <Typography key={index} style={{ fontSize: "0.8rem" }}>
                    {day}요일
                  </Typography>
                ))}
              {(item.data.termType as number) === 2 &&
                (item.data.termData as string[]).map((day, index) => (
                  <Typography key={index} style={{ fontSize: "0.8rem" }}>
                    {day}일
                  </Typography>
                ))}
            </div>
          )}
          {
            (item.data.hashtags as { backgroundColor: string, textColor: string, value: string }[]) && (item.data.hashtags as { backgroundColor: string, textColor: string, value: string }[]).length > 0 &&
            <div style={{ display: "flex", flexDirection: "row", gap: "10px", alignItems: "center" }}>
              <TagIcon sx={{ fontSize: "1rem" }} />
              {
                (item.data.hashtags as { backgroundColor: string, textColor: string, value: string }[]).map((tag, index) => (
                  <Typography key={index} style={{ fontSize: "0.8rem", backgroundColor: tag.backgroundColor, color: tag.textColor, padding: "2px 5px", borderRadius: "5px" }}>
                    {tag.value}
                  </Typography>
                ))
              }
            </div>
          }
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            {item.data.content === "" ?
              <Typography sx={{ fontSize: "0.8rem" }}>설명</Typography>
              :
              <TextMarkdown text={item.data.content as string} />
            }
          </div>
        </div>
      </div>
      <S.StoryBottomContainer>
        <div style={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", gap: "20px" }}>
          {/* <CommentOutlinedIcon
            sx={{ fontSize: "1.2rem", color: "gray", cursor: "pointer" }}
            onClick={() => setFeedDetailDialog(item)}
          /> */}
          {isOverflowing && (
            <CustomColorButton
              text={isExpanded ? "접기" : "펼치기"}
              onClick={() => setIsExpanded((prev) => !prev)}
              fontSize="0.8rem"
            />
          )}
        </div>
        <CustomColorButton
          text={t("crew.crewTask.joinUs")}
          onClick={() => setTogetherDialog({ node: item })}
          fontSize="0.8rem"
        />
      </S.StoryBottomContainer>
    </S.StoryItem>
  );
};

export default CrewTask;
