import { error401ModalAtom } from "@/Atoms/Dialogs/Error/401Atom";
import { getLatestVersion } from "@/Queries/Version";
import { handleReactQueryApiResponse } from "@/Utils/APIUtil";
import { useSetAtom } from "jotai";
import { useEffect, useState } from "react";
import { useQuery } from "react-query";

const getCurrentBuildVersion = (): string | null => {
  const metaTag = document.querySelector("meta[name='build-version']");
  return metaTag ? metaTag.getAttribute("content") : null;
};

const useVersionCheck = () => {
  const [isOutdated, setIsOutdated] = useState(false); // 업데이트 필요 여부
  const currentVersion = getCurrentBuildVersion(); // 현재 빌드 버전
  const setError401Modal = useSetAtom(error401ModalAtom);

  // React Query를 사용해 최신 버전 가져오기
  const { data, isLoading, refetch } = useQuery(
    "latestVersion",
    async () => handleReactQueryApiResponse(getLatestVersion, () => setError401Modal(true)),
    {
      refetchInterval: 60000, // 1분마다 최신 버전 확인
      enabled: !!currentVersion, // 현재 버전이 존재할 때만 동작
      onSuccess: async (response) => {
        if (!response.ok) return; // 요청 실패 처리
        const responseJson = await response.json();

        // 버전 비교
        if (responseJson.version !== currentVersion) {
          setIsOutdated(true); // 최신 버전과 다르면 상태 업데이트
        }
      },
    }
  );

  // 수동으로 버전 확인을 강제 실행하고 싶을 때 refetch 사용 가능
  const checkVersionManually = () => {
    refetch();
  };

  return { isOutdated, isLoading, checkVersionManually };
};

export default useVersionCheck;
