import { atom } from 'jotai';

interface PlanSettingsModal {
  isPublic: boolean;
  name: string;
  content: string;
  imageUrl: string | null;
  tags: {
    backgroundColor: string;
    textColor: string;
    value: string;
  }[];
}

export const initPlanSettingsModalData: PlanSettingsModal = {
  name: '',
  isPublic: false,
  content: '',
  imageUrl: null,
  tags: [],
};

export const planSettingsModalAtom = atom<PlanSettingsModal | null>(null);
