import { error401ModalAtom } from '@/Atoms/Dialogs/Error/401Atom';
import planAtom, { currentPlanAtom } from '@/Atoms/Plan';
import { initPlanSettingsModalData, planSettingsModalAtom } from '@/Atoms/Plan/SettingsModalAtom';
import snackbarAtom from '@/Atoms/Snackbar';
import { updatePlanSetting } from '@/Queries/PlanQueries';
import { handleReactQueryApiResponse } from '@/Utils/APIUtil';
import GetIdFromQuerystring from '@/Utils/GetIdFromQuerystring';
import getRandomColorWithText from '@/Utils/GetRandomColor';
import { TFunction } from 'i18next';
import { atom } from 'jotai';

export const planSettingsModalOpenAtom = atom(null, (get, set) => {
  const plan = get(currentPlanAtom);
  if (!plan) return;
  set(planSettingsModalAtom, {
    name: plan.name || '',
    isPublic: plan.isPublic || false,
    content: plan.content || '',
    imageUrl: plan.imageUrl || null,
    tags: plan.hashtags || [],
  });
});

export const planSettingsModalCloseAtom = atom((get) => get(planSettingsModalAtom), (_get, set) => {
  set(planSettingsModalAtom, null);
});

export const planSettingsModalIsPublicAtom = atom((get) => get(planSettingsModalAtom)?.isPublic || false, (get, set) => {
  const modal = get(planSettingsModalAtom);
  if (!modal) return;
  set(planSettingsModalAtom, { ...modal, isPublic: !modal.isPublic });
});

export const planSettigsModalAddTagAtom = atom((get) => get(planSettingsModalAtom)?.tags || [], (get, set, tag: string) => {
  const modal = get(planSettingsModalAtom);
  if (!modal) return;
  if (modal.tags.length >= 5) return;
  if (modal.tags.find((t) => t.value === tag)) return;
  const { backgroundColor, textColor } = getRandomColorWithText();
  set(planSettingsModalAtom, { ...modal, tags: [...modal.tags, { backgroundColor, textColor, value: tag }] });
});

export const planSettingsModalImageAtom = atom((get) => get(planSettingsModalAtom)?.imageUrl || null, (get, set, imageUrl: any) => {
  const modal = get(planSettingsModalAtom);
  if (!modal) return;
  set(planSettingsModalAtom, { ...modal, imageUrl });
});

export const planSettingsModalRemoveTagAtom = atom(null, (get, set, idx: number) => {
  const modal = get(planSettingsModalAtom);
  if (!modal) return;
  const tags = modal.tags.filter((_, i) => i !== idx);
  set(planSettingsModalAtom, { ...modal, tags });
});

export const planSettingsModalChangeNameAtom = atom((get) => get(planSettingsModalAtom)?.name || '', (get, set, name: string) => {
  const modal = get(planSettingsModalAtom);
  if (!modal) return;
  set(planSettingsModalAtom, { ...modal, name });
});

export const planSettingsModalChangeContentAtom = atom((get) => get(planSettingsModalAtom)?.content || '', (get, set, content: string) => {
  const modal = get(planSettingsModalAtom);
  if (!modal) return;
  set(planSettingsModalAtom, { ...modal, content });
});

export const planSettingsModalSaveAtom = atom(null, async (get, set, t: TFunction) => {
  const modal = get(planSettingsModalAtom);
  const planId = GetIdFromQuerystring("id");
  if (!modal || planId === 0) return;
  const setError401Modal = () => set(error401ModalAtom, true);
  if (modal.name.length === 0) {
    set(snackbarAtom, (prev) => ({ ...prev, open: true, message: t("plan.modals.settings.snackbar.inputName") }));
    return;
  }
  // API 호출
  try {
    const formData = new FormData();
    formData.append("content", modal.content);
    formData.append("is_public", modal.isPublic.toString());
    formData.append("hashtags", modal.tags.map((tag) => tag.value).join("|||"));
    if (modal.imageUrl)
      formData.append("imageFile", modal.imageUrl);
    const requestData = {
      name: modal.name,
      content: modal.content,
      is_public: modal.isPublic,
      hashtags: modal.tags.map((tag) => tag.value),
    }
    await handleReactQueryApiResponse(updatePlanSetting, setError401Modal, planId, requestData);
    set(planAtom, (prev) => {
      return prev.map((plan) => {
        if (plan.id === planId) {
          return { ...plan, name: modal.name, isPublic: modal.isPublic, content: modal.content, hashtags: modal.tags };
        }
        return plan;
      });
    });
    set(currentPlanAtom, (prev) => {
      if (prev?.id === planId) {
        return { ...prev, name: modal.name, isPublic: modal.isPublic, content: modal.content, hashtags: modal.tags };
      }
      return prev;
    });
    set(snackbarAtom, (prev) => ({ ...prev, open: true, message: t("plan.modals.settings.snackbar.completeSetting") }));
  } catch (e) {
    set(snackbarAtom, (prev) => ({ ...prev, open: true, message: t("plan.modals.settings.snackbar.failSetting") }));
    return;
  }
  set(planSettingsModalAtom, null);
});
