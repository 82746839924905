import React from "react";
import { Grid, Chip, useMediaQuery, Box } from "@mui/material";
import theme from "@/Styles/theme";
import * as S from "./styled";
import { ContentSearch } from "@/Atoms/Dashboard/MenteeAtom";

interface Props {
  contentSearch: ContentSearch[];
  language: string;
}

export const ResponsiveContentGrid = ({ contentSearch, language }: Props) => {
  const isXl = useMediaQuery("(min-width:1200px)");
  const isLg = useMediaQuery("(min-width:992px) and (max-width:1199px)");
  const isMd = useMediaQuery("(min-width:768px) and (max-width:991px)");
  const isSm = useMediaQuery("(max-width:767px) and (min-width:600px)");
  const isXs = useMediaQuery("(max-width:599px)");

  const [itemsPerRow, setItemsPerRow] = React.useState(0);

  // 반응형 항목 수 설정
  React.useEffect(() => {
    if (isXl) {
      setItemsPerRow(3);
    } else if (isLg) {
      setItemsPerRow(3);
    } else if (isMd) {
      setItemsPerRow(2);
    } else if (isSm) {
      setItemsPerRow(2);
    } else if (isXs) {
      setItemsPerRow(1);
    }
  }, [isXl, isLg, isMd, isSm, isXs]);

  return (
    <Grid
      container
      spacing={2}
      sx={{ flex: 1, overflowY: "auto", overflowX: "hidden", padding: "10px" }}
      justifyContent="flex-start"
      display="flex"
    >
      {contentSearch.map((data, index) => (
        <Grid
          item
          xs={12 / itemsPerRow} // 한 줄당 항목 수를 컨트롤
          key={`stack-${index}`}
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            cursor: "pointer",
          }}
          onClick={() => (window.location.href = data.link)}
        >
          {language === "KR" && (
            <Box
              component="img"
              src={
                data.image !== "N/A"
                  ? data.image
                  : "https://img.lovepik.com/free-template/bg/20200421/bg/c5c7556e6a18f.png_list.jpg"
              }
              alt="book"
              sx={{
                width: 150,
                // height: "150px",
                objectFit: "cover",
                // borderRadius: "8px",
              }}
            />
          )}
          <S.UnivTypo>{data.name}</S.UnivTypo>
          <S.DepartmentTypo>{data.author}</S.DepartmentTypo>
          <div
            style={{
              display: "flex",
              flexDirection: language === "KR" ? "row" : "column",
              gap: "5px",
              alignItems: "center",
              justifyContent: "center",
              flexWrap: "wrap",
              marginTop: "5px",
            }}
          >
            {data.keywords.map((keyword, index) => (
              <Chip
                key={`keywordbox-${index}`}
                label={keyword}
                size="small"
                sx={{
                  backgroundColor: theme.colors.primary,
                  color: "#fff",
                  fontSize: "12px",
                  height: "20px",
                }}
              />
            ))}
          </div>
        </Grid>
      ))}
    </Grid>
  );
};
