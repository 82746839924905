import React from "react";
import * as S from "./styled";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { IconButton, Menu, MenuItem, Typography } from "@mui/material";
import CalendarTodayOutlinedIcon from '@mui/icons-material/CalendarTodayOutlined';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import { LocationProps } from "@/Atoms/RootAtom";
import { useAtomValue, useSetAtom } from "jotai";
import useIsMobile from "@/Hooks/useIsMobile";
import snackbarAtom from "@/Atoms/Snackbar";
import TagIcon from '@mui/icons-material/Tag';
import TextMarkdown from "@/Components/Common/Markdown";
import { useTranslation } from "react-i18next";
import { checkPlanUserIdAtom } from "@/ViewModels/Plan/PlanViewModel";
import { PlanFeed } from "@/Atoms/Plan";
import { deleteFeedInPlanAtom, openFeedModalAtom } from "@/ViewModels/Plan/FeedViewModel";

const formatDateToKorean2 = (date: Date): string => {
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, '0'); // 월은 0부터 시작하므로 +1
  const day = date.getDate().toString().padStart(2, '0');
  const hours = date.getHours().toString().padStart(2, '0');
  const minutes = date.getMinutes().toString().padStart(2, '0');

  return `${year}년 ${month}월 ${day}일`;
}

interface FeedItemProps {
  item: PlanFeed;
}

const FeedItem = ({ item }: FeedItemProps) => {
  const { isMobile } = useIsMobile();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const setSnackbar = useSetAtom(snackbarAtom);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);
  const deleteFeed = useSetAtom(deleteFeedInPlanAtom);
  const { t } = useTranslation();
  const isMine = useAtomValue(checkPlanUserIdAtom);
  const editFeed = useSetAtom(openFeedModalAtom);

  const groupItems = [
    { name: t("plan.contents.todo.edit"), value: "edit" },
    { name: t("plan.contents.todo.delete"), value: "delete" },
  ];

  const handleMenuItemClick = (value: string) => {
    if (value === "edit") {
      editFeed("edit", item);
    } else if (value === "delete") {
      deleteFeed(item.id);
      setSnackbar({ open: true, message: t("dialogs.common.snackbar.deleteSuccess"), severity: "success" });
    }
    handleClose();
  };

  // Helper function to break title into spans
  const formatTitle = (title: string) => {
    return title.split(/(?<=[?!.])\s+/g).map((part, index) => (
      <span key={index} style={{ display: 'block' }}>
        {part}
      </span>
    ));
  };

  return (
    <>
      <S.StoryItem $isMobile={isMobile}>
        <S.StoryTitleContainer>
          <div style={{ display: "flex", width: "80%", flexDirection: "row", gap: "10px", alignItems: "center" }}>
            {
              item.name && item.name !== "" &&
              <S.StoryTitle>
                {formatTitle(item.name)}
              </S.StoryTitle>
            }
          </div>
          {
            isMine &&
            <>
              <IconButton
                sx={{
                  color: "black",
                  fontWeight: "bold",
                  padding: 0,
                  textTransform: "none",
                  fontSize: "0rem",
                }}
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
              >
                <MoreVertIcon sx={{ fontSize: "1.5rem" }} />
              </IconButton>
              <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                  'aria-labelledby': 'basic-button',
                }}
              >
                {groupItems.map((item, index) =>
                  item && <MenuItem key={index} onClick={() => handleMenuItemClick(item.value)}>
                    {item.name}
                  </MenuItem>
                )}
              </Menu>
            </>
          }
        </S.StoryTitleContainer>
        {
          (item.startDate as string || item.endDate as string) &&
          <div style={{ display: "flex", flexDirection: "row", gap: "10px", alignItems: "center" }}>
            <CalendarTodayOutlinedIcon sx={{ fontSize: "1rem" }} />
            {
              item.startDate as string &&
              <Typography style={{ fontSize: "0.8rem", marginRight: "-5px" }}>
                {formatDateToKorean2(new Date(item.startDate as string))}부터
              </Typography>
            }
            {
              item.endDate as string &&
              <Typography style={{ fontSize: "0.8rem" }}>
                {formatDateToKorean2(new Date(item.endDate as string))}까지
              </Typography>
            }
          </div>
        }
        {
          item.location &&
          <div style={{ display: "flex", flexDirection: "row", gap: "10px", alignItems: "center" }}>
            <LocationOnOutlinedIcon sx={{ fontSize: "1rem" }} />
            <Typography style={{ fontSize: "0.8rem" }}>
              {item.location}
            </Typography>
          </div>
        }
        {
          item.hashtags && item.hashtags.length > 0 &&
          <div style={{ display: "flex", flexDirection: "row", gap: "10px", alignItems: "center" }}>
            <TagIcon sx={{ fontSize: "1rem" }} />
            {
              item.hashtags.map((tag, index) => (
                <Typography key={index} style={{ fontSize: "0.8rem", backgroundColor: tag.backgroundColor, color: tag.textColor, padding: "2px 5px", borderRadius: "5px" }}>
                  {tag.value}
                </Typography>
              ))
            }
          </div>
        }
        <div style={{ display: "flex", flexDirection: "column", justifyContent: "space-between", width: "100%" }}>
          <TextMarkdown text={item.content as string} />
        </div>
      </S.StoryItem>
    </>
  )
}

export default FeedItem;
