import editDefaultRoutineOptionAtom from '@/Atoms/Dialogs/Edit/DefaultRoutineOptionAtom';
import GetIdFromQuerystring from '@/Utils/GetIdFromQuerystring';
import { setInnerTabDataAtom } from '@/ViewModels/Plan/InnerTabViewModel';
import { onEdgesChangeAtom, onNodesChangeAtom } from '@/ViewModels/Plan/Mindmap/FlowViewModel';
import { atom } from 'jotai';

// 모달 열기
export const editDefaultRoutineOptionOpenAtom = atom((get) => get(editDefaultRoutineOptionAtom), (get, set, id: string, change: number) => {
  const nodes = get(onNodesChangeAtom);
  const task = nodes.find((node) => node.id === id);
  const innerTabId = GetIdFromQuerystring("inner_tab_id");
  if (!task || !task.data) return;

  if (task.data.termType !== change) {
    if (change === 0) {
      const newUpdatedTaskData = {
        ...task.data,
        termType: change,
        termData: [],
      }
      const newUpdatedTask = {
        ...task,
        data: newUpdatedTaskData,
      }
      const newNodes = nodes.map((node) => (node.id === id) ? newUpdatedTask : node);
      set(setInnerTabDataAtom, { innerTabId, nodes: newNodes, edges: get(onEdgesChangeAtom) });
      return;
    }
    set(editDefaultRoutineOptionAtom, {
      open: id,
      prevTermType: task.data.termType as number,
      prevTermData: task.data.termData as string[],
      termType: change,
      termData: [],
    });
  } else {
    const newUpdatedTaskData = {
      ...task.data,
      termType: null,
      termData: null,
    }
    const newUpdatedTask = {
      ...task,
      data: newUpdatedTaskData,
    }
    const newNodes = nodes.map((node) => (node.id === id) ? newUpdatedTask : node);
    set(setInnerTabDataAtom, { innerTabId, nodes: newNodes, edges: get(onEdgesChangeAtom) });
  }
});

// 루틴 옵션 변경
export const editDefaultRoutineChangeOptionAtom = atom(null, (get, set) => {
  const { open: taskId, termType, termData } = get(editDefaultRoutineOptionAtom);
  if (!taskId) return;
  const nodes = get(onNodesChangeAtom);
  const task = nodes.find((node) => node.id === taskId);
  const innerTabId = GetIdFromQuerystring("inner_tab_id");
  if (!task) return;

  const newUpdatedTaskData = {
    ...task.data,
    termType: termType,
    termData: termData,
  }
  const newUpdatedTask = {
    ...task,
    data: newUpdatedTaskData,
  }
  const newNodes = nodes.map((node) => (node.id === taskId) ? newUpdatedTask : node);
  set(setInnerTabDataAtom, { innerTabId, nodes: newNodes, edges: get(onEdgesChangeAtom) });
  set(editDefaultRoutineOptionAtom, { open: null, termType: null, termData: null, prevTermData: null, prevTermType: null });
});
