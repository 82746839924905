import { recommendPlanSearchInputListAtom, recommendPlanSearchInputValueAtom } from '@/Atoms/RecommendPlan/Atom';
import { atom } from 'jotai';

export const recommendPlanSearchInputValueOnChangeAtom = atom((get) => get(recommendPlanSearchInputValueAtom), (_get, set, e: React.ChangeEvent<HTMLInputElement>) => {
  set(recommendPlanSearchInputValueAtom, e.target.value);
});

export const recommendPlanSearchInputAddListAtom = atom(null, (get, set) => {
  const value = get(recommendPlanSearchInputValueAtom);
  if (!value) return;
  if (get(recommendPlanSearchInputListAtom).includes(value)) return;
  set(recommendPlanSearchInputListAtom, (prev) => [...prev, value]);
  set(recommendPlanSearchInputValueAtom, '');
});

export const recommendPlanSearchInputRemoveListAtom = atom(null, (get, set, index: number) => {
  set(recommendPlanSearchInputListAtom, (prev) => prev.filter((_, i) => i !== index));
});
