import { DatePicker } from '@mui/x-date-pickers';
import { Dayjs } from 'dayjs';
import React from 'react';

interface CustomDatePickerProps {
  label: string;
  value: Dayjs | null;
  onChange: (date: Dayjs | null) => void;
  shouldDisableDate?: (day: Dayjs) => boolean;
  format?: string;
}

const CustomDatePicker = ({ label, value, onChange, shouldDisableDate, format }: CustomDatePickerProps) => {
  return (
    <DatePicker
      label={label}
      value={value}
      onChange={(date) => onChange(date)}
      shouldDisableDate={shouldDisableDate}
      format={format ? format : "YYYY.MM.DD"}
      slotProps={{
        textField: {
          size: 'small',
          InputProps: {
            sx: { borderRadius: '10px', backgroundColor: 'white', fontSize: "14px" }, // Apply the styles here
          },
          InputLabelProps: {
            sx: {
              fontSize: '14px', // Placeholder(Label)의 fontSize 조정
              color: '#9e9e9e', // Optional: 색상 설정
            },
          },
        },
      }}
    />
  )
}

export default CustomDatePicker;
