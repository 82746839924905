import React from "react";
import * as S from "./styled";
import { useSetAtom } from "jotai";
import { Typography } from "@mui/material";
import theme from "../../Styles/theme";
import CrewsBox from "@/Components/PlanHip/Contents/CrewsBox";
import NeighborsBox from "@/Components/PlanHip/Contents/NeighborsBox";
import usePlanHip from "@/Hooks/usePlanHip";
import { waitingModalAtom } from "@/Atoms/RootAtom";
import CreateCrewDialog from "@/Components/PlanHip/Dialogs/CreateCrew";
import PlanHipTopSection from "@/Components/PlanHip/Contents/TopSection";
import FindCrewDialog from "@/Components/PlanHip/Dialogs/FindCrew";
import { useTranslation } from "react-i18next";

const recommendedSubjects = [
  "스터디",
  "진주",
  "서울",
  "F&B",
  "덕질",
  "운동",
  "여향",
  "반려동물",
];

const Dialogs = () => {
  return (
    <>
      <CreateCrewDialog />
      <FindCrewDialog />
    </>
  )
}

const PlanHip = () => {
  const { isLoading } = usePlanHip();
  const setWaitingModal = useSetAtom(waitingModalAtom);
  const { t } = useTranslation();

  React.useEffect(() => {
    setWaitingModal({ state: isLoading, text: t("dialogs.common.waiting.loading") });
  }, [isLoading, t, setWaitingModal]);

  return (
    <S.Container>
      {/* 상단 안내 메세지 */}
      <PlanHipTopSection />

      {/* 내가 참여중인 CREW */}
      <CrewsBox title={t("planHip.myCrew")} myCrews={true} />

      {/* 추천 CREW */}
      <CrewsBox title={t("planHip.notMyCrew")} myCrews={false} />

      {/* 주제 추천 */}
      {/* <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          gap: "10px",
        }}
      >
        <Typography
          sx={{
            fontSize: "0.8rem",
            fontWeight: "bold",
            color: theme.colors.primary,
          }}
        >
          {t("planHip.topic")}
        </Typography>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-start",
            gap: "10px",
            flexWrap: "wrap",
          }}
        >
          {recommendedSubjects.map((subject, index) => (
            <div
              key={index}
              style={{
                fontSize: "0.7rem",
                fontWeight: "bold",
                color: "white",
                backgroundColor: "gray",
                padding: "5px 10px",
                borderRadius: "10px",
                cursor: "pointer",
              }}
            >
              #{subject}
            </div>
          ))}
        </div>
      </div> */}

      {/* 이웃 추천 */}
      {/* <NeighborsBox /> */}
      <Dialogs />
    </S.Container>
  );
};

export default PlanHip;
