import inactivateDialogAtom from '@/Atoms/Dialogs/Inactivate';
import { Edge, Node } from '@xyflow/react';
import { atom } from 'jotai';
import { updatePlanDataMindmapAtom } from '../Plan/InnerTabViewModel';
import GetIdFromQuerystring from '@/Utils/GetIdFromQuerystring';
import { waitingModalAtom } from '@/Atoms/RootAtom';
import { onEdgesChangeAtom, onNodesChangeAtom } from '../Plan/Mindmap/FlowViewModel';

const getChildrenNodes = (nodes: Node[], targetNode: Node, edges: Edge[]) => {
  // bfs로 하위 노드를 전부 가져옴
  const childNodes: Node[] = [];
  const queue: Node[] = [targetNode];
  while (queue.length > 0) {
    const node = queue.shift();
    if (!node) continue;
    childNodes.push(node);
    const nextEdges = edges.filter(edge => edge.source === node.id);
    nextEdges.forEach(edge => {
      const nextNode = nodes.find(node => node.id === edge.target);
      if (nextNode) queue.push(nextNode);
    });
  }
  return childNodes;
};


export const inactivateDialogConfirmAtom = atom(null, async (get, set) => {
  const innerTabId = GetIdFromQuerystring("inner_tab_id");
  const dialog = get(inactivateDialogAtom);
  if (!dialog) return;
  const targetNode = dialog.node;
  // 비활성화 로직
  // nodeId를 이용해 하위 노드를 전부 조회
  const nodes = get(onNodesChangeAtom);
  if (!targetNode) return;
  set(waitingModalAtom, (prev) => ({ ...prev, state: true }));
  const childNodes = getChildrenNodes(nodes, targetNode, get(onEdgesChangeAtom));
  const newNodes = nodes.map(node => (childNodes.find(childNode => childNode.id === node.id)) ? { ...node, data: { ...node.data, activate: (node.id === targetNode.id) ? 1 : 2 } } : node);
  set(updatePlanDataMindmapAtom, innerTabId, newNodes, get(onEdgesChangeAtom));
  set(inactivateDialogAtom, null);
});

export const inactivateDialogClearAtom = atom(null, async (get, set) => {
  const innerTabId = GetIdFromQuerystring("inner_tab_id");
  const dialog = get(inactivateDialogAtom);
  if (!dialog) return;
  const targetNode = dialog.node;
  // 비활성화 로직
  // nodeId를 이용해 하위 노드를 전부 조회
  const nodes = get(onNodesChangeAtom);
  if (!targetNode) return;
  set(waitingModalAtom, (prev) => ({ ...prev, state: true }));
  const childNodes = getChildrenNodes(nodes, targetNode, get(onEdgesChangeAtom));
  const newNodes = nodes.map(node => (childNodes.find(childNode => childNode.id === node.id)) ? { ...node, data: { ...node.data, activate: 0, } } : node);
  set(updatePlanDataMindmapAtom, innerTabId, newNodes, get(onEdgesChangeAtom));
  set(inactivateDialogAtom, null);
});
