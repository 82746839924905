import { settingsEditProfileDialogAtom } from '@/Atoms/Settings';
import CustomBackgroundColorButton from '@/Components/Common/Buttons/CustomBackgroundColor';
import CustomColorButton from '@/Components/Common/Buttons/CustomColor';
import { Dialog, DialogActions, DialogContent, DialogTitle, MenuItem, Select } from '@mui/material';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import React from 'react';
import { useTranslation } from 'react-i18next';
import * as S from './styled';
import { getUserNickname, userLanguageAtom } from '@/ViewModels/UserViewModel';
import { settingsEditProfileDialogChangeValueAtom, settingsEditProfileDialogSaveAtom } from '@/ViewModels/Settings/ProfileViewModel';

const SettingsEditProfileDialog = () => {
  const userNickname = useAtomValue(getUserNickname);
  const [dialog, setDialog] = useAtom(settingsEditProfileDialogAtom);
  const { t } = useTranslation();
  const save = useSetAtom(settingsEditProfileDialogSaveAtom);
  const setValue = useSetAtom(settingsEditProfileDialogChangeValueAtom);
  const language = useAtomValue(userLanguageAtom);

  return (
    dialog &&
    <Dialog open={dialog !== null} onClose={() => setDialog(null)} maxWidth="xs" fullWidth >
      <DialogTitle sx={{ fontSize: "0.9rem", fontWeight: "bold" }}>{t(`settings.contents.profile.editDialog.${dialog.key}.title`)}</DialogTitle>
      <DialogContent>
        <div style={{ fontSize: "0.8rem" }}>
          {t(`settings.contents.profile.editDialog.${dialog.key}.description`)}
        </div>
        {
          dialog.key === "nickname" &&
          <input type="text" style={S.ContentInputStyle("100%")} placeholder={userNickname} value={dialog.value} onChange={(e) => setValue(e.target.value)} />
        }
        {
          dialog.key === "language" &&
          <Select style={{ width: "100%", height: "50px" }} value={dialog.value === "" ? language : dialog.value} onChange={(e) => setValue(e.target.value as string)}>
            <MenuItem value="US">{t("settings.contents.profile.US")}</MenuItem>
            <MenuItem value="KR">{t("settings.contents.profile.KR")}</MenuItem>
          </Select>
        }
      </DialogContent>
      <DialogActions>
        <CustomColorButton text={t("settings.contents.profile.editDialog.cancel")} fontSize="0.8rem" onClick={() => setDialog(null)} />
        <CustomBackgroundColorButton text={t("settings.contents.profile.editDialog.save")} fontSize="0.8rem" onClick={() => save(t)} />
      </DialogActions>
    </Dialog >
  )
}

export default SettingsEditProfileDialog;
