import styled from 'styled-components';

// <div style={{ width: '150px', textAlign: 'center', fontWeight: 'bold', fontSize: '16px' }}>
export const DateDisplay = styled.div`
  width: 150px;
  text-align: center;
  font-weight: bold;
  font-size: 14px;

  @media (max-width: 768px) {
    width: 140px;
    font-size: 12px;
  }

  @media (max-width: 480px) {
    width: 120px;
    font-size: 10px;
  }

  @media (max-width: 320px) {
    width: 100px;
    font-size: 9px;
  }
`;
