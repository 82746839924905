import { Task } from "gantt-task-react";
import dayjs from 'dayjs';
import minMax from 'dayjs/plugin/minMax';
import { Edge, Node } from "@xyflow/react";
import theme from "@/Styles/theme";
import { darken, lighten } from "polished";
import { TaskStatus } from "@/Types/Plan";
import { devConsoleLog } from "./ConsoleLogInDevelopment";

dayjs.extend(minMax);

/**
 * Nodes 배열을 Gantt의 Task 배열로 변환하는 함수
 *
 * @param nodes Node[]
 * @returns Task[] 변환된 Gantt Task 배열
 */
const convertNodesToGanttTasks = (nodes: Node[], edges: Edge[], expandGroupList: string[]): Task[] => {
  const tasks: Task[] = [];
  const groupNodes = nodes.filter((node) => node.type === 'routine');
  const taskNodes = nodes.filter((node) => node.type === 'task');
  const nonGroupTaskNodeIds = new Set<string>();

  groupNodes.forEach((node) => {
    // groupNode의 id를 가지고 있는 taskNode들을 찾아서 taskNode의 startDate와 endDate를 비교하여 groupNode의 startDate와 endDate를 설정
    // const childTasks = taskNodes.filter((task) => task.parentId === node.id);
    const childTasks = edges.filter((edge) => edge.source === node.id).map((edge) => taskNodes.find((task) => task.id === edge.target)).filter((node): node is Node => node !== undefined);
    // startDate와 endDate를 Dayjs 객체로 변환
    const startDate = dayjs.min(childTasks.map((task) => task.data.startDate ? dayjs(task.data.startDate as string) : dayjs())) || null;
    const endDate = dayjs.max(childTasks.map((task) => task.data.endDate ? dayjs(task.data.endDate as string) : dayjs())) || null;

    // childTasks의 taskStatus들을 모두 하나의 배열로 합침
    const childTaskStatuses = childTasks.map((task) => (task.data.taskStatus as TaskStatus[]).filter((status) => status.routineId === status.taskId)).flat();

    // childTaskStatuses 중에서 완료된 taskStatus들을 찾음
    const completedTaskStatuses = childTaskStatuses.filter((status) => status.status === 1);

    // 완료된 taskStatus들의 개수를 이용하여 progress를 계산
    const progress = childTaskStatuses.length === 0 ? 0 : completedTaskStatuses.length / childTaskStatuses.length * 100;

    // 그룹 작업 추가
    const groupTask: Task = {
      id: node.id,
      name: node.data.label as string || '',
      start: startDate ? startDate.toDate() : new Date(),
      end: endDate ? endDate.toDate() : new Date(),
      progress: progress,
      type: 'project',
      hideChildren: expandGroupList.includes(node.id),
      // styles: {
      //   progressColor: node.data.color as string || theme.colors.darkPrimary,
      //   progressSelectedColor: lighten(0.1, node.data.color as string || theme.colors.darkPrimary),
      //   backgroundColor: node.data.color as string || theme.colors.primary,
      //   backgroundSelectedColor: lighten(0.1, node.data.color as string || theme.colors.primary)
      // }
      styles: {
        progressColor: node.data.color
          ? darken(0.2, node.data.color as string)
          : theme.colors.darkPrimary,
        progressSelectedColor: node.data.color
          ? darken(0.3, node.data.color as string)
          : theme.colors.darkPrimary,
        backgroundColor: node.data.color
          ? node.data.color as string
          : theme.colors.primary,
        backgroundSelectedColor: node.data.color
          ? lighten(0.1, node.data.color as string)
          : theme.colors.primary,
      },
    };

    tasks.push(groupTask);

    // 자식 작업 추가
    childTasks.forEach((task) => {
      const unsetDate = task.data.startDate === null || task.data.endDate === null;
      const taskStatuses = task.data.taskStatus as TaskStatus[] || [];
      const myTaskStatuses = taskStatuses.filter((status) => status.routineId === status.taskId);
      const completedTaskStatuses = myTaskStatuses.filter((status) => status.status === 1);
      const progress = myTaskStatuses.length === 0 ? 0 : completedTaskStatuses.length / myTaskStatuses.length * 100;
      const childTask: Task = {
        id: task.id + "|" + node.id,
        name: unsetDate ? "" : task.data.label as string || '',
        start: task.data.startDate ? dayjs(task.data.startDate as string).toDate() : new Date(),
        end: task.data.endDate ? dayjs(task.data.endDate as string).toDate() : new Date(),
        progress: progress,
        type: 'task',
        project: node.id,
        dependencies: [],
        isDisabled: false,
        styles: { progressColor: node.data.color as string || theme.colors.darkPrimary, progressSelectedColor: lighten(0.1, node.data.color as string || theme.colors.darkPrimary), backgroundColor: unsetDate ? 'none' : 'gray' }
      };
      tasks.push(childTask);
      nonGroupTaskNodeIds.add(task.id); // groupNode가 있는 taskNode들을 추가했으므로 추가한 taskNode의 id를 저장
    });
  });

  // groupNode가 없는 taskNode들을 추가
  const nonGroupTaskNodes = taskNodes.filter((task) => !nonGroupTaskNodeIds.has(task.id));
  nonGroupTaskNodes.forEach((task) => {
    if (!task.parentId) {
      const unsetDate = task.data.startDate === null || task.data.endDate === null;
      const taskStatuses = task.data.taskStatus as TaskStatus[] || [];
      const myTaskStatuses = taskStatuses.filter((status) => status.routineId === status.taskId);
      const completedTaskStatuses = myTaskStatuses.filter((status) => status.status === 1);
      const progress = myTaskStatuses.length === 0 ? 0 : completedTaskStatuses.length / myTaskStatuses.length * 100;
      const childTask: Task = {
        id: task.id,
        name: unsetDate ? "" : task.data.label as string || '',
        start: task.data.startDate ? dayjs(task.data.startDate as string).toDate() : new Date(),
        end: task.data.endDate ? dayjs(task.data.endDate as string).toDate() : new Date(),
        progress: progress,
        type: 'task',
        dependencies: [],
        isDisabled: false,
        styles: { progressColor: task.data.color as string || theme.colors.darkPrimary, progressSelectedColor: lighten(0.1, task.data.color as string || theme.colors.darkPrimary), backgroundColor: unsetDate ? 'none' : 'gray' }
      };
      tasks.push(childTask);
    }
  });
  devConsoleLog("tasks", tasks);
  return tasks;
};

export default convertNodesToGanttTasks;
