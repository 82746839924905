import React from "react";
import { Box, Divider } from "@mui/material";
import * as S from "../styled";
import { PieChart } from "../../Charts/PieChart";
import { useTranslation } from "react-i18next";

export function MenteeStudyState() {
  const { t } = useTranslation();
  const sampleData = [
    {
      key: t("dashboard.mentor.careerPlanningStatus.done"),
      value: 100,
    },
    {
      key: t("dashboard.mentor.careerPlanningStatus.deactivate"),
      value: 20,
    },
    {
      key: t("dashboard.mentor.careerPlanningStatus.doing"),
      value: 15,
    },
    {
      key: t("dashboard.mentor.careerPlanningStatus.waiting"),
      value: 40,
    },
  ];
  return (
    <S.CustomBox
      sx={{ height: "100%", display: "flex", flexDirection: "column" }}
    >
      <S.TitleTypo>{t("dashboard.mentor.careerPlanningStatus.title")}</S.TitleTypo>
      <Divider sx={{ margin: "10px 0" }} />
      <S.DescTypo sx={{ marginBottom: "10px" }}>
        {t("dashboard.mentor.careerPlanningStatus.description")}
      </S.DescTypo>

      <Box sx={{ flexGrow: 1 }}>
        <PieChart data={sampleData} />
      </Box>
    </S.CustomBox>
  );
}
