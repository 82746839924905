import { Dialog, DialogActions, DialogContent } from '@mui/material';
import React from 'react';
import * as S from './styled';
import { createSignatureAtom } from '@/Atoms/Signature/Atom';
import { useAtomValue, useSetAtom } from 'jotai';
import { getUserEmailAtom, getUserImage } from '@/ViewModels/UserViewModel';
import { useTranslation } from 'react-i18next';
import CustomBackgroundColorButton from '@/Components/Common/Buttons/CustomBackgroundColor';
import { SketchPicker } from 'react-color';
import { editSignatureColorCreateSignatureAtom } from '@/ViewModels/Signature/CreateSignatureViewModel';
import { changeModeSignatureDialogAtom } from '@/ViewModels/Signature/ViewModel';

interface Colors {
  backgroundColor: string;
  fontColor: string;
  borderColor: string;
}

const EditCardStyle = () => {
  const { t } = useTranslation();
  const signature = useAtomValue(createSignatureAtom);
  const userImage = useAtomValue(getUserImage);
  const userEmail = useAtomValue(getUserEmailAtom);
  const sortedInfo = signature?.orderList || [];
  const [colorPickerDialog, setColorPickerDialog] = React.useState<string | null>(null);
  const editColor = useSetAtom(editSignatureColorCreateSignatureAtom);
  const [colors, setColors] = React.useState<Colors | null>(null);
  const changeMode = useSetAtom(changeModeSignatureDialogAtom);

  const handleChangeColor = () => {
    if (colors === null) return;
    editColor(colors.backgroundColor, "backgroundColor");
    editColor(colors.fontColor, "fontColor");
    editColor(colors.borderColor, "borderColor");
    changeMode("create");
  }

  React.useEffect(() => {
    if (colors === null && signature) {
      setColors({ backgroundColor: signature.backgroundColor, fontColor: signature.fontColor, borderColor: signature.borderColor });
    }
  }, [colors, signature]);

  return (
    signature && colors &&
    <>
      <DialogContent sx={{ display: "flex", flexDirection: "column", alignItems: "center", height: "500px", position: "relative", gap: "15px", overflow: "hidden", justifyContent: "center" }}>
        <S.SignatureContainer $borderColor={colors.borderColor} $backgroundColor={colors.backgroundColor} $fontColor={colors.fontColor} >
          <S.SignatureProfileContainer>
            <S.SignatureImage src={signature.isUserProfile ? userImage : typeof signature.image === 'string' ? signature.image : userImage} alt="signature" />
            <S.SignatureProfileInfo>
              <S.GridItem style={{ padding: 0 }}>
                <S.ItemTitle style={{ fontSize: "1.5rem" }}>{signature.name}</S.ItemTitle>
                <S.ItemTitle>
                  {`${signature.representative ? t("signature.card.representative") : ""} ${signature.careerAdvancement ? t("signature.card.careerAdvancement") : ""} ${t("signature.card.signature")}`}
                </S.ItemTitle>
              </S.GridItem>
              <S.ItemTitle style={{ display: "flex", fontSize: "1.5rem", justifyContent: "flex-end" }}>{signature.nickname}</S.ItemTitle>
              <S.ItemValue style={{ display: "flex", justifyContent: "flex-end" }}>{userEmail}</S.ItemValue>
            </S.SignatureProfileInfo>
          </S.SignatureProfileContainer>
          <S.SignatureGrid $height="50%">
            {sortedInfo.slice(0, 4).map((item, index) => (
              <S.GridItem key={index}>
                <S.ItemTitle>{item.label}</S.ItemTitle>
                <S.ItemValue>{Array.isArray(item.value) ? item.value.join(", ") : item.value}</S.ItemValue>
              </S.GridItem>
            ))}
          </S.SignatureGrid>
        </S.SignatureContainer>
        <div style={{ display: "flex", flexDirection: "row", gap: "20px", justifyContent: "center", alignItems: "center" }}>
          <CustomBackgroundColorButton fontSize="0.8rem" text={t("signature.style.changeBackgroundColor")} onClick={() => setColorPickerDialog("backgroundColor")} />
          <CustomBackgroundColorButton fontSize="0.8rem" text={t("signature.style.changeFontColor")} onClick={() => setColorPickerDialog("fontColor")} />
          <CustomBackgroundColorButton fontSize="0.8rem" text={t("signature.style.changeBorderColor")} onClick={() => setColorPickerDialog("borderColor")} />
        </div>
      </DialogContent>
      <DialogActions>
        <CustomBackgroundColorButton fontSize="0.8rem" text={t("signature.create.save")} onClick={handleChangeColor} />
      </DialogActions>
      <Dialog
        open={colorPickerDialog !== null}
        onClose={() => setColorPickerDialog(null)}
        maxWidth="xs"
      >
        {
          colorPickerDialog !== null &&
          <SketchPicker
            color={colorPickerDialog === "backgroundColor" ? colors.backgroundColor : colorPickerDialog === "fontColor" ? colors.fontColor : colors.borderColor}
            onChange={(color) => setColors({ ...colors, [colorPickerDialog]: color.hex })}
          />
        }
      </Dialog>
    </>
  )
}

export default EditCardStyle;
