import { chatRoomListAtom, currentChatRoomAtom } from "@/Atoms/ChatAtom";
import { useAtomValue, useSetAtom } from "jotai";
import React from "react";
import RenderMessage from "./RenderMessage";
import { dokgabiChatModalAtom } from "@/Atoms/DokgabiChat/Atom";
import { dokgabiChatFetchMoreChattingListAtom } from "@/ViewModels/Chat/MessageViewModel";

const DokgabiChatMessages = () => {
  const chatEndRef = React.useRef<HTMLDivElement | null>(null);
  const chatContainerRef = React.useRef<HTMLDivElement | null>(null);
  const currentChatRoom = useAtomValue(currentChatRoomAtom);
  const [currentChatRoomId, setCurrentChatRoomId] = React.useState<number | undefined>(undefined);
  const chatRoomList = useAtomValue(chatRoomListAtom);
  const fetchMoreChattingList = useSetAtom(dokgabiChatFetchMoreChattingListAtom);
  const [firstScroll, setFirstScroll] = React.useState(false);
  const modal = useAtomValue(dokgabiChatModalAtom);

  const scrollToBottom = () => {
    if (chatEndRef.current) {
      chatEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  // 컴포넌트가 처음 렌더링될 때 리스트 맨 아래로 스크롤
  React.useEffect(() => {
    // 첫 렌더링 시 메시지가 있을 때만 실행
    if (modal.isOpen && !firstScroll && currentChatRoom && currentChatRoom?.messages?.length > 0) {
      setTimeout(() => {
        setFirstScroll(true);
        scrollToBottom();
      }, 500);
    }
  }, [modal.isOpen, currentChatRoom, currentChatRoom?.messages, firstScroll]);

  React.useEffect(() => {
    if (currentChatRoom) {
      setCurrentChatRoomId(
        chatRoomList.findIndex((chatRoom) => chatRoom.id === currentChatRoom.id)
      );
    }
  }, [currentChatRoom, chatRoomList]);

  const handleScroll = () => {
    if (chatContainerRef.current) {
      const container = chatContainerRef.current;

      if (container.scrollTop === 0) {
        const previousScrollHeight = container.scrollHeight;

        fetchMoreChattingList().then(() => {
          requestAnimationFrame(() => {
            const newScrollHeight = container.scrollHeight;
            const scrollDifference = newScrollHeight - previousScrollHeight;
            container.scrollTop = scrollDifference;
          });
        });
      }
    }
  };

  return (
    <div
      style={{
        flex: 1,
        overflowY: "auto",
        padding: "20px",
        display: "flex",
        flexDirection: "column",
        gap: "20px",
      }}
      ref={chatContainerRef}
      onScroll={handleScroll}
    >
      {currentChatRoom?.messages.map((message, index) => (
        <React.Fragment key={index}>
          <RenderMessage message={message} currentChatRoomId={currentChatRoomId} chatRoomList={chatRoomList} />
        </React.Fragment>
      ))}
      <div ref={chatEndRef}></div>
    </div>
  )
}

export default DokgabiChatMessages;
