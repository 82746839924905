// 피드 신규 생성
export const createPlanFeed = async (access: string, planId: number) => {
  const res = await fetch(`${process.env.REACT_APP_PLAN_FEED_ADDRESS}?plan_id=${planId}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${access}`,
    }
  });
  return res;
}

export interface UpdateFeedContentProps {
  label: string;
  content: string;
  start_date: string | null;
  end_date: string | null;
  location_address: string;
  hashtags: {
    text_color: string;
    background_color: string;
    value: string;
  }[];
}

// 피드 업데이트
export const updatePlanFeed = async (access: string, feedId: number, content: UpdateFeedContentProps) => {
  const res = await fetch(`${process.env.REACT_APP_PLAN_FEED_ADDRESS}/${feedId}`, {
    method: "PUT",
    headers: {
      "accept": "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${access}`,
    },
    body: JSON.stringify(content)
  });
  return res;
}

// 피드 삭제
export const deletePlanFeed = async (access: string, feedId: number) => {
  const res = await fetch(`${process.env.REACT_APP_PLAN_FEED_ADDRESS}/${feedId}`,
    {
      method: "DELETE",
      headers: {
        "accept": "application/json",
        Authorization: `Bearer ${access}`,
      }
    }
  );
  return res;
}

// upload task image API
export const uploadTaskImage = async (access: string, file: File, innerTabId: number, taskId: number) => {
  const formData = new FormData();
  formData.append("imageFile", file);
  const response = await fetch(
    `${process.env.REACT_APP_PLAN_IMAGE_ADDRESS}/${innerTabId}/${taskId}/task_image`,
    {
      method: "POST",
      headers: {
        "Authorization": `Bearer ${access}`
      },
      body: formData,
    }
  );
  return response;
}

// 피드 이미지 업로드 API
export const uploadPlanFeedImage = async (access: string, file: File, innerTabId: number, feedId: number) => {
  const formData = new FormData();
  formData.append("imageFile", file);
  const response = await fetch(
    `${process.env.REACT_APP_PLAN_IMAGE_ADDRESS}/${innerTabId}/${feedId}/feed_image`,
    {
      method: "POST",
      headers: {
        "Authorization": `Bearer ${access}`
      },
      body: formData,
    }
  );
  return response;
}

// 피드 이미지 삭제 API
export const deletePlanFeedImages = async (access: string, innerTabId: number, feedId: number, imageUrls: string[]) => {
  const response = await fetch(
    `${process.env.REACT_APP_PLAN_IMAGE_ADDRESS}/${innerTabId}/${feedId}/feed_image`,
    {
      method: "DELETE",
      headers: {
        "Authorization": `Bearer ${access}`,
        "accept": "application/json",
        "Content-type": "application/json",
      },
      body: JSON.stringify({
        imageURLs: imageUrls,
      }),
    }
  );
  return response;
}
