import React from 'react';
import * as S from './styled';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { recommendPlanSearchInputAddListAtom, recommendPlanSearchInputRemoveListAtom, recommendPlanSearchInputValueOnChangeAtom } from '@/ViewModels/RecommendPlan/SearchInputViewModel';
import { recommendPlanSearchInputListAtom } from '@/Atoms/RecommendPlan/Atom';
import { Chip } from '@mui/material';
import { useTranslation } from 'react-i18next';

const SearchInput = () => {
  const { t } = useTranslation();
  const [value, onChange] = useAtom(recommendPlanSearchInputValueOnChangeAtom);
  const addList = useSetAtom(recommendPlanSearchInputAddListAtom);
  const searchList = useAtomValue(recommendPlanSearchInputListAtom)
  const [expanded, setExpanded] = React.useState(false);
  const handleToggle = () => setExpanded((prev) => !prev);
  const removeList = useSetAtom(recommendPlanSearchInputRemoveListAtom);
  const onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => e.key === 'Enter' && addList();

  return (
    <>
      <S.SearchContainer>
        <S.StyledInput
          type="text"
          placeholder={t("recommendPlan.searchInput.placeholder")}
          value={value}
          onChange={onChange}
          onKeyDown={onKeyDown}
        />
        <S.StyledSearchIcon onClick={addList} />
      </S.SearchContainer>
      {
        searchList.length > 0 &&
        <S.SearchInputList $expanded={expanded}>
          <div style={{ fontSize: "0.8rem", color: "#333" }}>{t("recommendPlan.searchInput.applyFilter")}</div>
          {searchList.map((item, index) => (
            <Chip
              key={index}
              label={item}
              onDelete={() => removeList(index)} // 삭제 이벤트 핸들러
              style={{
                backgroundColor: "#f0f0f0",
                fontSize: "0.8rem",
                fontWeight: "bold",
              }}
            />
          ))}
        </S.SearchInputList>
      }
      {searchList.length > 0 && ( // 필터 개수가 많을 때만 토글 버튼 보이기
        <S.ToggleButton onClick={handleToggle}>
          {expanded ? t("recommendPlan.searchInput.toggleOn") : t("recommendPlan.searchInput.toggleOff")}
        </S.ToggleButton>
      )}
    </>
  )
}

export default SearchInput;
