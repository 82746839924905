import styled from "styled-components";

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: row;
  min-height: 50px;
`;

export const ContentTitle = styled.div`
  display: flex;
  width: 15%;
  justify-content: center;
  align-items: center;
  background-color: #e0e0e0;
  border-top: 1px solid #c0c0c0;
  border-left: 1px solid #c0c0c0;
  border-right: 1px solid #c0c0c0;
  font-size: 0.8rem;
  font-weight: bold;
`;

export const ContentContent = styled.div`
  display: flex;
  width: 85%;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  border-top: 1px solid #c0c0c0;
  border-right: 1px solid #c0c0c0;
  padding: 10px;
  gap: 10px;
`;

export const ContentContentRow = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
`;

export const ContentInputStyle = (width: number) => ({ width: `${width}px`, padding: "5px", border: "1px solid #c0c0c0", borderRadius: "5px" });
