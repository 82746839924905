import { ChatRoomProps, MessageProps } from "@/Atoms/ChatAtom";
import { Avatar, Box, Stack, Typography } from "@mui/material";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import remarkBreaks from "remark-breaks";
import rehypeRaw from "rehype-raw";
import CustomBackgroundColorButton from "@/Components/Common/Buttons/CustomBackgroundColor";
import { useSetAtom } from "jotai";
import { createMindmapAtom } from "@/ViewModels/ChatViewModel";
import React from "react";
import { useTranslation } from "react-i18next";

interface RenderMessageProps {
  message: MessageProps;
  currentChatRoomId: number | undefined;
  chatRoomList: ChatRoomProps[];
};

const RenderMessage = ({ message, currentChatRoomId, chatRoomList }: RenderMessageProps) => {
  const { t } = useTranslation();
  const createMindmap = useSetAtom(createMindmapAtom);

  switch (message.type) {
    case "user":
      return (
        <Stack
          direction={"row"}
          sx={{ gap: "15px", width: "100%", justifyContent: "end" }}
        >
          <Stack sx={{ gap: "3px", textAlign: "end", maxWidth: "60%" }}>
            <Box
              sx={{
                background: process.env.REACT_APP_MAIN_COLOR,
                borderRadius: "5px",
                padding: "15px",
                color: "white",
              }}
            >
              {message.text}
            </Box>
            {/* <Typography fontSize={"13px"} color={"#8f8f8f"}>
              {date}
            </Typography> */}
          </Stack>
        </Stack>
      );
    case "chatbot":
      if (currentChatRoomId === undefined) return null;
      return (
        <Stack direction={"row"} sx={{ gap: "15px", width: "100%" }}>
          {
            chatRoomList[currentChatRoomId]?.chatbots[0].image &&
            <Avatar
              src={`${process.env.REACT_APP_DOKGABI_MEDIA_ADDRESS}${chatRoomList[currentChatRoomId]?.chatbots[0].image}`}
            />
          }
          <Stack sx={{ gap: "3px", maxWidth: "70%" }}>
            <Typography>
              {chatRoomList[currentChatRoomId]?.chatbots[0].name}
            </Typography>
            <Box
              sx={{
                background: "#ececec",
                borderRadius: "5px",
                padding: "15px",
              }}
            >
              <ReactMarkdown
                remarkPlugins={[remarkGfm, remarkBreaks]}
                rehypePlugins={[rehypeRaw]}
                components={{
                  p: ({ node, children }) => (
                    <p style={{ margin: "10px" }}>{children}</p>
                  ),
                  h1: ({ node, children }) => (
                    <h1 style={{ margin: "10px" }}>{children}</h1>
                  ),
                  h2: ({ node, children }) => (
                    <h2 style={{ margin: "10px" }}>{children}</h2>
                  ),
                  h3: ({ node, children }) => (
                    <h3 style={{ margin: "10px" }}>{children}</h3>
                  ),
                  h4: ({ node, children }) => (
                    <h4 style={{ margin: "10px" }}>{children}</h4>
                  ),
                  h5: ({ node, children }) => (
                    <h5 style={{ margin: "10px" }}>{children}</h5>
                  ),
                  h6: ({ node, children }) => (
                    <h6 style={{ margin: "10px" }}>{children}</h6>
                  ),
                  ul: ({ node, children }) => (
                    <ul style={{ margin: "10px" }}>{children}</ul>
                  ),
                  ol: ({ node, children }) => (
                    <ol style={{ margin: "10px" }}>{children}</ol>
                  ),
                  li: ({ node, children }) => (
                    <li style={{ margin: "10px" }}>{children}</li>
                  ),
                  table: ({ node, children }) => (
                    <table
                      style={{
                        width: "100%",
                        borderCollapse: "collapse",
                        margin: "20px 0",
                        backgroundColor: "#fff", // 바탕색 하얀색 설정
                      }}
                    >
                      {children}
                    </table>
                  ),
                  tbody: ({ node, children }) => <tbody>{children}</tbody>,
                  tr: ({ node, children }) => (
                    <tr style={{ borderBottom: "1px solid #ddd" }}>
                      {children}
                    </tr>
                  ),
                  th: ({ node, children }) => (
                    <th
                      style={{
                        backgroundColor: "#f2f2f2",
                        padding: "10px",
                        textAlign: "left",
                        borderBottom: "2px solid #ddd",
                      }}
                    >
                      {children}
                    </th>
                  ),
                  td: ({ node, children }) => (
                    <td
                      style={{
                        padding: "10px",
                        borderBottom: "1px solid #ddd",
                        backgroundColor: "#fff", // 바탕색 하얀색 설정
                      }}
                    >
                      {children}
                    </td>
                  ),
                  blockquote: ({ node, children }) => (
                    <blockquote style={{ margin: "10px" }}>
                      {children}
                    </blockquote>
                  ),
                  code: ({ node, className, children }) => {
                    const isInline = !className; // className이 없으면 인라인 코드로 간주
                    return isInline ? (
                      <code
                        style={{
                          backgroundColor: "#000", // 인라인 코드 배경을 검은색으로 설정
                          color: "#fff", // 텍스트는 흰색으로
                          padding: "2px 4px",
                          borderRadius: "4px",
                          fontFamily: "monospace",
                        }}
                      >
                        {children}
                      </code>
                    ) : (
                      <pre
                        style={{
                          backgroundColor: "#1e1e1e", // 코드 블록 배경을 다크 모드 스타일로
                          color: "#dcdcdc", // 코드 블록 텍스트 색상 설정
                          padding: "10px",
                          borderRadius: "8px",
                          overflowX: "auto",
                          fontFamily: "monospace",
                          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)", // 그림자 추가
                        }}
                      >
                        <code>{children}</code>
                      </pre>
                    );
                  },
                  pre: ({ node, children }) => (
                    <pre style={{ margin: "10px" }}>{children}</pre>
                  ),
                  em: ({ node, children }) => (
                    <em style={{ margin: "10px" }}>{children}</em>
                  ),
                  strong: ({ node, children }) => (
                    <strong style={{ margin: "10px" }}>{children}</strong>
                  ),
                  a: ({ node, href, children }) => (
                    <a
                      href={href}
                      style={{
                        color: "#1a0dab",
                        textDecoration: "underline",
                      }}
                    >
                      {children}
                    </a>
                  ),
                  img: ({ node, src, alt }) => (
                    <img
                      src={src}
                      alt={alt}
                      style={{
                        maxWidth: "100%", // 이미지가 부모 요소 너비를 넘지 않도록
                        border: "2px solid #ddd", // 얇은 테두리 추가
                        borderRadius: "8px", // 모서리를 둥글게
                        padding: "5px", // 이미지와 테두리 사이에 약간의 패딩 추가
                        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)", // 가벼운 그림자 추가
                        margin: "20px 0", // 상하 여백 추가
                      }}
                    />
                  ),
                }}
              >
                {message.text.replace(/\n/g, "<br />")}
              </ReactMarkdown>
            </Box>
            {/* <Typography fontSize={"13px"} color={"#8f8f8f"}>
              {date}
            </Typography> */}
          </Stack>
        </Stack>
      );
    case "system":
      // if (!window.location.pathname.includes("my-plan")) return null;
      if (!window.location.pathname.includes("plan")) return null;
      if (currentChatRoomId === undefined) return null;
      const isPlanPath = window.location.pathname.includes("plan");

      const handleCreateMindmap = (jobButton: string) => {
        if (isPlanPath) {
          createMindmap(jobButton, t);
        } else {
          // 여기에 다른 경로로 이동하는 로직 및 마인드맵 생성 로직 추가
        }
      };

      return (
        <Stack direction={"row"} sx={{ gap: "15px", width: "100%" }}>
          {
            chatRoomList[currentChatRoomId]?.chatbots[0].image &&
            <Avatar
              src={`${process.env.REACT_APP_DOKGABI_MEDIA_ADDRESS}${chatRoomList[currentChatRoomId]?.chatbots[0].image}`}
            />
          }
          <Stack sx={{ gap: "3px", maxWidth: "70%" }}>
            <Typography>
              {chatRoomList[currentChatRoomId]?.chatbots[0].name}
            </Typography>
            <Box
              sx={{
                background: "#ececec",
                borderRadius: "5px",
                padding: "15px",
              }}
            >
              <Typography>{message.text}</Typography>
              <div style={{ display: "flex", flexDirection: "row", flexWrap: "wrap", gap: "10px", marginTop: "10px" }}
              >
                {message.jobButtons?.map((jobButton, index) => (
                  <CustomBackgroundColorButton onClick={() => handleCreateMindmap(jobButton)} key={index} text={jobButton} />
                ))}
              </div>
            </Box>
          </Stack>
        </Stack>
      );
    default:
      return null;
  }
};

export default RenderMessage;
