import React from 'react';
import * as S from './styled';
import CareerPlannerTimelapse from '../../Components/CareerPlanner/Contents/Timelapse';
import { getUserPosition } from '@/ViewModels/UserViewModel';
import { useAtomValue } from 'jotai';

const Timelapse = () => {
  const userPosition = useAtomValue(getUserPosition);

  React.useEffect(() => {
    if (userPosition === "멘티") {
      window.location.href = "/";
    }
  }, [userPosition]);

  return (
    <S.Container>
      <CareerPlannerTimelapse />
    </S.Container>
  );
}

export default Timelapse;
