import React from "react";
import * as S from "./styled";
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';
import { useAtomValue, useSetAtom } from "jotai";
import { FormControl, MenuItem, Select } from "@mui/material";
import { useTranslation } from "react-i18next";
import { checkPlanUserIdAtom } from "@/ViewModels/Plan/PlanViewModel";
import { getFeedsInPlanAtom, openFeedModalAtom } from "@/ViewModels/Plan/FeedViewModel";
import FeedItem from "./Item";

const Feed = () => {
  const nodes = useAtomValue(getFeedsInPlanAtom);
  // const setCreateTaskDialog = useSetAtom(createTaskDialogAtom);
  const openCreateFeedModal = useSetAtom(openFeedModalAtom);
  const [sort, setSort] = React.useState("최신순"); // 최신순, 시대순, 오래된순
  const { t } = useTranslation();
  const isMine = useAtomValue(checkPlanUserIdAtom);

  // 노드 정렬 로직
  const sortedNodes = React.useMemo(() => {
    if (!nodes) return [];
    return nodes
      .sort((a, b) => {
        if (sort === "최신순") {
          const dateA = a.createdAt ? new Date(a.createdAt as string).getTime() : new Date().getTime();
          const dateB = b.createdAt ? new Date(b.createdAt as string).getTime() : new Date().getTime();
          return dateB - dateA;
        }
        if (sort === "오래된순") {
          const dateA = a.createdAt ? new Date(a.createdAt as string).getTime() : new Date().getTime();
          const dateB = b.createdAt ? new Date(b.createdAt as string).getTime() : new Date().getTime();
          return dateA - dateB;
        }
        if (sort === "시대순") {
          // startDate가 null인 경우 하위로 정렬
          const dateA = a.startDate ? new Date(a.startDate as string).getTime() : Infinity;
          const dateB = b.startDate ? new Date(b.startDate as string).getTime() : Infinity;
          return dateA - dateB;
        }
        return 0;
      });
  }, [nodes, sort]);

  return (
    <S.Container>
      <S.ContentContainer>
        {
          isMine &&
          <S.StoryPostingContainer onClick={() => openCreateFeedModal("create")}>
            <S.TitleContainer>
              <S.TitleButton>
                <ArticleOutlinedIcon sx={{ width: "20px", height: "20px" }} />
                {t("plan.contents.feed.feed")}
              </S.TitleButton>
            </S.TitleContainer>
            <S.DescriptionContainer>
              {t("plan.contents.feed.description")}
            </S.DescriptionContainer>
          </S.StoryPostingContainer>
        }
        <div style={{ width: "100%", height: "50px", display: "flex", justifyContent: "flex-end", alignItems: "center", gap: "20px" }}>
          <div style={{ fontSize: "1rem", fontWeight: "bold" }}>{t("plan.contents.feed.sort")}</div>
          <FormControl variant="standard" sx={{ minWidth: 120 }}>
            <Select
              value={sort}
              onChange={(e) => setSort(e.target.value)}
              label={t("plan.contents.feed.sort")}
              displayEmpty
              disableUnderline // underline 제거
              sx={{
                '& .MuiSelect-standard': {
                  borderBottom: 'none', // borderBottom 제거
                }
              }}
            >
              <MenuItem value="최신순">{t("plan.contents.feed.latest")}</MenuItem>
              <MenuItem value="시대순">{t("plan.contents.feed.chronological")}</MenuItem>
              <MenuItem value="오래된순">{t("plan.contents.feed.oldest")}</MenuItem>
            </Select>
          </FormControl>
        </div>
        <div style={{
          display: "flex",
          flexDirection: "column",
          gap: "10px",
          width: "100%",
          alignItems: "center",
        }}>
          {sortedNodes.map(story =>
            <FeedItem key={story.id} item={story} />
          )}
        </div>
        <div style={{ minHeight: "1px" }} />
      </S.ContentContainer>
    </S.Container>
  )
}

export default Feed;
