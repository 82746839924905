import { LocationProps } from '@/Atoms/RootAtom';
import dayjs, { Dayjs } from 'dayjs';
import { atom } from 'jotai';

export const initCreateTaskInRoutineModalData = () => {
  return ({
    open: 0,
    isCrew: false,
    name: "",
    content: "",
    startDate: null,
    endDate: null,
    termType: null,
    termData: null,
    location: { address: "", latitude: null, longitude: null },
    hashtags: [],
  })
}

interface CreateTaskInRoutineModalProps {
  open: number;
  isCrew: boolean;
  name: string;
  content: string;
  startDate: Dayjs | null;
  endDate: Dayjs | null;
  termType: number | null;
  termData: string[] | null;
  location: LocationProps;
  hashtags: {
    backgroundColor: string;
    textColor: string;
    value: string;
  }[];
}

export const createTaskInRoutineModalAtom = atom<CreateTaskInRoutineModalProps | null>(null);

interface CreateTaskInRoutineModalEditRoutineProps {
  termType: number | null;
  termData: string[] | null;
}

export const createTaskInRoutineModalEditRoutineAtom = atom<CreateTaskInRoutineModalEditRoutineProps>({
  termType: null,
  termData: null,
});
