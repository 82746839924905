import { createTaskDialogAtom } from "@/Atoms/Dialogs/Create/Task";
import { createTaskInGroupDialogAtom } from "@/Atoms/Dialogs/Create/TaskInGroup";
import { editTaskDialogAtom } from "@/Atoms/Dialogs/Edit/Task";
import { error401ModalAtom } from "@/Atoms/Dialogs/Error/401Atom";
import textEditorDialogAtom, { textEditorForCreateAndEditDialogAtom } from "@/Atoms/Dialogs/TextEditor";
import innerTabDataAtom from "@/Atoms/Plan/InnerTabDataAtom";
import { deleteTextEditorImages } from "@/Queries/PlanQueries";
import { handleReactQueryApiResponse } from "@/Utils/APIUtil";
import GetIdFromQuerystring from "@/Utils/GetIdFromQuerystring";
import { atom } from "jotai";
import { setInnerTabDataAtom } from "../Plan/InnerTabViewModel";
import { createTaskInRoutineModalAtom } from "@/Atoms/Dialogs/Create/TaskInRoutine";
import { createUltimateModalAtom } from "@/Atoms/Dialogs/Create/Ultimate";
import { editUltimateModalAtom } from "@/Atoms/Dialogs/Edit/Ultimate";

const getImageSrcs = (content: string) => {
  return Array.from(content.matchAll(/<img[^>]+src="([^">]+)"/g), match => match[1]);
}

export const textEditorOpenAtom = atom(null, (get, set, nodeId: string, content: string) => {
  const imgSrcs = getImageSrcs(content);
  set(textEditorDialogAtom, { open: true, nodeId, content, imgSrcs: imgSrcs });
})

export const textEditorCloseAtom = atom(null, (get, set) => {
  set(textEditorDialogAtom, { open: false, nodeId: "", content: "", imgSrcs: [] });
});

export const textEditorOnChangeAtom = atom(null, (get, set, content: string) => {
  const newImgSrcs = getImageSrcs(content);
  const prevImgSrcs = get(textEditorDialogAtom).imgSrcs;

  // 중복 없이 두 배열 합치기
  const newImgSrcsSet = prevImgSrcs && prevImgSrcs.length > 0 ? new Set([...prevImgSrcs, ...newImgSrcs]) : new Set(newImgSrcs);
  const imgSrcs = Array.from(newImgSrcsSet);
  set(textEditorDialogAtom, (prev) => ({ ...prev, content, imgSrcs }));
});

export const textEditorSaveAtom = atom(null, async (get, set, content: string) => {
  const nodeId = get(textEditorDialogAtom).nodeId;
  if (nodeId === "") return;
  const planData = get(innerTabDataAtom);
  const innerTabId = GetIdFromQuerystring("inner_tab_id");
  const plan = planData.find((data) => data.innerTabId === innerTabId);
  if (!plan) return;
  const nodes = plan.nodes;
  const newImgSrcs = getImageSrcs(content);

  // 이미지 삭제
  const prevImgSrcs = get(textEditorDialogAtom).imgSrcs;
  const deleteImgSrcs = prevImgSrcs.filter((prevImgSrc) => !newImgSrcs.includes(prevImgSrc));
  const handleError401Modal = () => set(error401ModalAtom, true);

  if (deleteImgSrcs.length > 0) {
    await handleReactQueryApiResponse(deleteTextEditorImages, handleError401Modal, innerTabId, deleteImgSrcs);
  }

  const newNodes = nodes.map((node) => (node.id === nodeId) ? { ...node, data: { ...node.data, content } } : node);
  set(setInnerTabDataAtom, { innerTabId, nodes: newNodes, edges: plan.edges });
  set(textEditorCloseAtom);
});

export const textEditorSaveForCreateAndEditCloseAtom = atom(null, (get, set) => {
  set(textEditorForCreateAndEditDialogAtom, { open: false, content: "", imgSrcs: [] });
});

export const textEditorSaveForCreateAndEditOnChangeAtom = atom(null, (get, set, content: string) => {
  const newImgSrcs = getImageSrcs(content);
  const prevImgSrcs = get(textEditorForCreateAndEditDialogAtom).imgSrcs;

  // 중복 없이 두 배열 합치기
  const newImgSrcsSet = prevImgSrcs && prevImgSrcs.length > 0 ? new Set([...prevImgSrcs, ...newImgSrcs]) : new Set(newImgSrcs);
  const imgSrcs = Array.from(newImgSrcsSet);

  set(textEditorForCreateAndEditDialogAtom, (prev) => ({ ...prev, content, imgSrcs }));
});

export const textEditorSaveForCreateAndEditAtom = atom(null, async (get, set, content: string) => {
  const innerTabId = GetIdFromQuerystring("inner_tab_id");
  const prevImgSrcs = get(textEditorForCreateAndEditDialogAtom).imgSrcs;
  const newImgSrcs = getImageSrcs(content);
  const deleteImgSrcs = prevImgSrcs.filter((prevImgSrc) => !newImgSrcs.includes(prevImgSrc));
  const handleError401Modal = () => set(error401ModalAtom, true);
  if (deleteImgSrcs.length > 0) {
    await handleReactQueryApiResponse(deleteTextEditorImages, handleError401Modal, innerTabId, deleteImgSrcs);
  }

  set(createTaskInGroupDialogAtom, (prev) => ({ ...prev, content }));
  set(createTaskDialogAtom, (prev) => ({ ...prev, content }));
  set(editTaskDialogAtom, (prev) => ({ ...prev, content }));
  const createTaskInRoutine = get(createTaskInRoutineModalAtom);
  if (createTaskInRoutine) {
    set(createTaskInRoutineModalAtom, { ...createTaskInRoutine, content });
  }

  const createUltimateModal = get(createUltimateModalAtom);
  if (createUltimateModal) {
    set(createUltimateModalAtom, { ...createUltimateModal, content });
  }

  const editUltimateModal = get(editUltimateModalAtom);
  if (editUltimateModal) {
    set(editUltimateModalAtom, { ...editUltimateModal, content });
  }
});
