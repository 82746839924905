import CustomBackgroundColorButton from '@/Components/Common/Buttons/CustomBackgroundColor';
import CustomColorButton from '@/Components/Common/Buttons/CustomColor';
import theme from '@/Styles/theme';
import { createUltimateModalAddHashtagAtom, createUltimateModalAddTaskAtom, createUltimateModalAddTaskInCrewAtom, createUltimateModalClearStateAtom, createUltimateModalEditLocationAtom, createUltimateModalEditRoutineApplyAtom, createUltimateModalEditRoutineClearStateAtom, createUltimateModalEditRoutineDataAtom, createUltimateModalEditRoutineStartAtom, createUltimateModalRemoveHashtagAtom, createUltimateModalSetRoutineOptionAtom, createUltimateModalUpdateStateAtom } from '@/ViewModels/Dialogs/Create/UltimateViewModel';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, ToggleButton, ToggleButtonGroup } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import * as S from './styled';
import React from 'react';
import getRandomColorWithText from '@/Utils/GetRandomColor';
import { textEditorForCreateAndEditDialogAtom } from '@/Atoms/Dialogs/TextEditor';
import TextMarkdown from '@/Components/Common/Markdown';
import { languageAtom } from '@/Atoms/RootAtom';
import { useTranslation } from 'react-i18next';
import useIsMobile from '@/Hooks/useIsMobile';
import parse, { domToReact } from "html-react-parser";
import { useLocation } from 'react-router-dom';

const weekDays = ["월", "화", "수", "목", "금", "토", "일"];

const CreateUltimateModal = () => {
  const { isMobile } = useIsMobile();
  const { t } = useTranslation();
  const [createUltimateModal, updateState] = useAtom(createUltimateModalUpdateStateAtom);
  const language = useAtomValue(languageAtom);
  const clearState = useSetAtom(createUltimateModalClearStateAtom);
  const setRoutineOption = useSetAtom(createUltimateModalSetRoutineOptionAtom);
  const addTask = useSetAtom(createUltimateModalAddTaskAtom);
  const [editRoutineData, setEditRoutineData] = useAtom(createUltimateModalEditRoutineDataAtom);
  const editRoutineStart = useSetAtom(createUltimateModalEditRoutineStartAtom);
  const editRoutineClearState = useSetAtom(createUltimateModalEditRoutineClearStateAtom);
  const applyRoutine = useSetAtom(createUltimateModalEditRoutineApplyAtom);
  const editLocation = useSetAtom(createUltimateModalEditLocationAtom);
  const [inputValue, setInputValue] = React.useState("");
  const addHashtag = useSetAtom(createUltimateModalAddHashtagAtom);
  const removeHashtag = useSetAtom(createUltimateModalRemoveHashtagAtom);
  const setTextEditorDialog = useSetAtom(textEditorForCreateAndEditDialogAtom);
  const location = useLocation();
  const currentPath = location.pathname;

  // 엔터키 입력 시 태그 추가
  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter' && inputValue.trim()) {
      event.preventDefault();
      const { backgroundColor, textColor } = getRandomColorWithText();
      if (createUltimateModal && !createUltimateModal.hashtags.find((tag) => tag.value === inputValue.trim())) {
        addHashtag({
          backgroundColor,
          textColor,
          value: inputValue.trim(),
        });
      }
      setInputValue(""); // 입력창 초기화
    } else if (event.key === 'Backspace' && inputValue === "") {
      if (createUltimateModal && createUltimateModal.hashtags.length > 0) {
        event.preventDefault();
        removeHashtag();
      }
    }
  };

  return (
    createUltimateModal &&
    <Dialog
      open={createUltimateModal !== null}
      onClose={clearState}
      maxWidth="sm"
      fullWidth
      fullScreen={isMobile}
    >
      {
        editRoutineData.termType === null ?
          <>
            <DialogTitle sx={{ fontSize: "0.9rem", fontWeight: "bold" }}>{t("dialogs.ultimate.create.title")}</DialogTitle>
            <DialogContent sx={{ display: "flex", flexDirection: "column", gap: "20px" }}>
              <TextField
                label={t("dialogs.ultimate.create.name")}
                variant="outlined"
                fullWidth
                value={createUltimateModal.name}
                onChange={(e) => updateState("name", e.target.value)}
                sx={{ marginTop: "10px" }}
              />
              <S.ContentContainer onClick={() => setTextEditorDialog((prev) => ({ ...prev, open: true, content: createUltimateModal.content }))}>
                {
                  createUltimateModal.content === "" ?
                    <div style={{ fontSize: "1rem" }}>{t("dialogs.ultimate.create.description")}</div> :
                    <TextMarkdown text={createUltimateModal.content} />
                }
              </S.ContentContainer>
              <div style={{ display: "flex", width: "100%", fontSize: "0.8rem", fontWeight: "bold" }}>{t("dialogs.task.create.setDate")}</div>
              <div style={{ display: "flex", width: "100%", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
                <DatePicker
                  label={t("dialogs.task.create.startDate")}
                  sx={{ width: "47%" }}
                  value={dayjs(createUltimateModal.startDate)}
                  onChange={(date) => updateState('startDate', date)}
                  shouldDisableDate={createUltimateModal.endDate ? (day) => day.isAfter(createUltimateModal.endDate) : undefined}
                  format="YYYY.MM.DD"
                />
                <div style={{ fontSize: "1.2rem", fontWeight: "600" }}>~</div>
                <DatePicker
                  label={t("dialogs.task.create.endDate")}
                  sx={{ width: "47%" }}
                  value={dayjs(createUltimateModal.endDate)}
                  onChange={(date) => updateState('endDate', date)}
                  shouldDisableDate={createUltimateModal.startDate ? (day) => day.isBefore(createUltimateModal.startDate) : undefined}
                  format="YYYY.MM.DD"
                />
              </div>
              {
                currentPath === "/plan" &&
                <div style={{ display: "flex", flexDirection: "row", width: "100%", justifyContent: "space-between", alignItems: "center" }}>
                  <div style={{ fontSize: "0.8rem", fontWeight: "bold" }}>{t("dialogs.task.create.routine.interval")}</div>
                  <ToggleButtonGroup
                    exclusive
                    value={createUltimateModal.termType}
                    onChange={(event, newViewType) => updateState("termType", newViewType)}
                    color="primary"
                  >
                    <ToggleButton value={0} selected={createUltimateModal.termType === 0} onClick={() => setRoutineOption(0)}>{t("dialogs.task.create.routine.daily")}</ToggleButton>
                    <ToggleButton value={1} selected={createUltimateModal.termType === 1} onClick={() => setRoutineOption(1)}>{t("dialogs.task.create.routine.weekly")}</ToggleButton>
                    <ToggleButton value={2} selected={createUltimateModal.termType === 2} onClick={() => setRoutineOption(2)}>{t("dialogs.task.create.routine.monthly")}</ToggleButton>
                  </ToggleButtonGroup>
                </div>
              }

              {
                (createUltimateModal.termType === 1 || createUltimateModal.termType === 2) &&
                <>
                  <div style={{ display: "flex", flexDirection: "row", width: "100%", justifyContent: "space-between", alignItems: "center" }}>
                    <div style={{ fontSize: "1rem", fontWeight: "bold" }}>{t("dialogs.task.create.routine.repeat")}</div>
                    <CustomBackgroundColorButton onClick={editRoutineStart} text={t("dialogs.task.create.buttons.edit")} fontSize='0.8rem' />
                  </div>
                  <div style={{ display: "flex", flexDirection: "row", width: "100%", justifyContent: "space-between", alignItems: "center" }}>
                    <div style={{ fontSize: "1rem", fontWeight: "bold" }}>{createUltimateModal.termType === 1 ? t("dialogs.task.create.routine.weekly") : t("dialogs.task.create.routine.monthly")}</div>
                    <div style={{
                      display: "grid",
                      gridTemplateColumns: `repeat(${createUltimateModal.termData && createUltimateModal.termData.length < 7 ? createUltimateModal.termData.length : 7}, 1fr)`,
                      gap: "5px",
                    }}>
                      {createUltimateModal.termData?.map((interval: string) => (
                        <div key={interval} style={{ fontSize: "1rem", fontWeight: "bold" }}>{createUltimateModal.termType === 1 ? t(`dialogs.task.create.routine.${interval}`) : `${interval}일`}
                        </div>
                      ))}
                    </div>
                  </div>
                </>
              }
              <TextField
                label={t("dialogs.task.create.location")}
                variant="outlined"
                fullWidth
                value={createUltimateModal.location.address}
                onChange={(e) => editLocation(e.target.value)}
              />
              <div style={{ display: "flex", flexDirection: "row", width: "100%", justifyContent: "space-between", alignItems: "center" }}>
                <div style={{ fontSize: "0.8rem", fontWeight: "bold" }}>{t("dialogs.task.create.hashtag")}</div>
                <S.TagInputContainer>
                  {createUltimateModal.hashtags.map((tag, idx) => {
                    return <S.TagItem key={idx} style={{ backgroundColor: tag.backgroundColor, color: tag.textColor }}>
                      <span>{tag.value}</span>
                    </S.TagItem>
                  })}
                  <S.TagInput
                    placeholder={createUltimateModal.hashtags.length === 0 ? t("dialogs.task.create.hashtagPlaceholder") : ""}
                    value={inputValue}
                    onChange={(e) => setInputValue(e.target.value)}
                    onKeyDown={handleKeyDown}
                  />
                </S.TagInputContainer>
              </div>
            </DialogContent>
            <DialogActions>
              <CustomColorButton onClick={clearState} text={t("dialogs.task.create.buttons.cancel")} fontSize="0.8rem" />
              <CustomBackgroundColorButton onClick={addTask} text={t("dialogs.task.create.buttons.confirm")} fontSize="0.8rem" />
            </DialogActions>
          </>
          :
          <>
            <DialogTitle>{t("dialogs.task.create.routine.setRepeat")}</DialogTitle>
            <DialogContent>
              {createUltimateModal.termType === 1 ? (
                <div style={{ display: 'flex', flexDirection: 'row', width: "100%", justifyContent: "space-between" }}>
                  {weekDays.map((day) => (
                    <div key={day} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', backgroundColor: editRoutineData.termData?.includes(day) ? theme.colors.primary : "white", borderRadius: "50%", color: editRoutineData.termData?.includes(day) ? "white" : "black", fontSize: "0.9rem", fontWeight: "bold", width: "30px", height: "30px", cursor: "pointer", justifyContent: "center" }} onClick={() => setEditRoutineData(day)}>
                      {t(`dialogs.task.create.routine.${day}`)}
                    </div>
                  ))}
                </div>
              ) : (
                <div style={{ display: "grid", gridTemplateColumns: "repeat(7, 1fr)", gap: "5px" }}>
                  {
                    Array.from({ length: 31 }, (_, i) => i + 1).map((day) => (
                      <div key={day} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', backgroundColor: editRoutineData.termData?.includes(day.toString()) ? theme.colors.primary : "white", borderRadius: "50%", color: editRoutineData.termData?.includes(day.toString()) ? "white" : "black", fontSize: "0.9rem", fontWeight: "bold", width: "30px", height: "30px", cursor: "pointer", justifyContent: "center" }} onClick={() => setEditRoutineData(day.toString())}>
                        {day}
                      </div>
                    ))
                  }
                </div>
              )}
            </DialogContent>
            <DialogActions>
              <CustomColorButton onClick={editRoutineClearState} text={t("dialogs.task.create.buttons.cancel")} fontSize="0.8rem" />
              <CustomBackgroundColorButton onClick={applyRoutine} text={t("dialogs.task.create.buttons.confirm")} fontSize="0.8rem" />
            </DialogActions>
          </>
      }
    </Dialog>
  )
}

export default CreateUltimateModal;
