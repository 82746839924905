import { atom } from "jotai";
import { PlanFeed } from "../..";

interface EditFeedModalProps extends PlanFeed {
  imgSrcs: string[];
}

const editFeedModalAtom = atom<EditFeedModalProps | null>(null);

export default editFeedModalAtom;
