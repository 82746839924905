import colorPickerDialogAtom from "@/Atoms/Dialogs/ColorPicker";
import { Dialog } from "@mui/material";
import { useAtom } from "jotai";
import React from "react";
import { ChromePicker } from "react-color";

const ColorPickerDialog = () => {
  const [colorPickerDialog, setColorPickerDialog] = useAtom(colorPickerDialogAtom);

  return (
    <Dialog
      open={colorPickerDialog.open}
      onClose={() => setColorPickerDialog((prev) => ({ ...prev, open: false }))}
      maxWidth="xs"
    >
      <ChromePicker color={colorPickerDialog.color} onChange={(color) => setColorPickerDialog({ open: colorPickerDialog.open, color: color.hex })} />
    </Dialog>
  )
}

export default ColorPickerDialog;
