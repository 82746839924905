import { addTasksInRoutineAtom, addTasksInRoutineLoadingAtom, connectedRoutinesAtom, createTaskInRoutineStateAtom, initializeCreateTaskInRoutineState, RoutineSidebarProps } from '@/Atoms/Plan/RoutineAtom';
import React from 'react';
import * as S from './styled';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { Node } from '@xyflow/react';
import { CheckCircle } from '@mui/icons-material';
import { Button } from '@mui/material';
import theme from '@/Styles/theme';
import { addTasksToRoutineAtom } from '@/ViewModels/Plan/Routine/AddTaskToRoutineViewModel';
import { pushRoutineSidebarDataAtom } from '@/ViewModels/Plan/Routine/SidebarViewModel';
import { useTranslation } from 'react-i18next';

interface AddTaskToRoutineProps {
  data: RoutineSidebarProps;
}

const AddTaskToRoutine = ({ data }: AddTaskToRoutineProps) => {
  const { t } = useTranslation();
  const [addTasks, setAddTasks] = useAtom(addTasksInRoutineAtom);
  const isLoading = useAtomValue(addTasksInRoutineLoadingAtom);
  const addTasksToRoutine = useSetAtom(addTasksToRoutineAtom);
  const openSidebar = useSetAtom(pushRoutineSidebarDataAtom);
  const setCreateTaskState = useSetAtom(createTaskInRoutineStateAtom);
  const setConnectedRoutines = useSetAtom(connectedRoutinesAtom);

  const handleAddAndRemoveTasks = (task: Node) => {
    if (addTasks.find((addTask) => addTask.id === task.id)) {
      setAddTasks(addTasks.filter((addTask) => addTask.id !== task.id));
      return;
    }
    setAddTasks([...addTasks, task]);
  }

  const handleCreateNewTask = () => {
    if (!data.key) return;
    setCreateTaskState(initializeCreateTaskInRoutineState());
    setConnectedRoutines([]);
    openSidebar({
      type: "addNewTaskToRoutine",
      key: data.key,
      tasks: []
    })
  }

  return (
    <div style={{ width: "100%", height: "100%", overflowY: "auto", display: "flex", flexDirection: "column", gap: "20px", position: "relative" }}>
      <S.CreateTaskButton onClick={handleCreateNewTask}>{t("plan.contents.routine.sidebar.content.addNewTask")}</S.CreateTaskButton>
      {
        data.tasks.map((task, idx) => (
          <S.TaskButton
            key={task.id}
            $isSelected={addTasks.find((addTask) => addTask.id === task.id) ? true : false}
            onClick={() => handleAddAndRemoveTasks(task)}
          >
            {task.data.label as string}
            {
              addTasks.find((addTask) => addTask.id === task.id) &&
              <CheckCircle sx={{ width: "20px", height: "20px", color: "#000" }} />
            }
          </S.TaskButton>
        ))
      }
      <div style={{ width: "100%", minHeight: "40px" }} />

      {/* 버튼 - 하단 고정  */}
      <Button
        variant='contained'
        style={{ backgroundColor: isLoading ? "#ccc" : theme.colors.primary, color: 'white', fontWeight: 'bold', cursor: 'pointer', width: "calc(100% - 40px)", position: "fixed", bottom: "20px" }}
        disabled={addTasks.length === 0 || isLoading}
        onClick={() => addTasksToRoutine(t)}
      >
        {
          isLoading ? t("plan.contents.routine.sidebar.content.loadingToAddTask") : addTasks.length === 0 ? t("plan.contents.routine.sidebar.content.add") : t("plan.contents.routine.sidebar.content.numOfTasks", { num: addTasks.length })
        }
      </Button>
    </div>
  );
}

export default AddTaskToRoutine;
