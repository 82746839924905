const convertDayToKorean = (day: string) => {
  switch (day) {
    case "Monday":
      return "월";
    case "Tuesday":
      return "화";
    case "Wednesday":
      return "수";
    case "Thursday":
      return "목";
    case "Friday":
      return "금";
    case "Saturday":
      return "토";
    case "Sunday":
      return "일";
    default:
      return "";
  }
};

export default convertDayToKorean;
