import React from 'react';
import * as S from './styled';
import CrewTask from '../Task';
import { useAtomValue, useSetAtom } from 'jotai';
import { FormControl, MenuItem, Select, SelectChangeEvent, Tabs } from '@mui/material';
import theme from '@/Styles/theme';
import CrewLecture from '../Lecture';
import snackbarAtom from '@/Atoms/Snackbar';
import { crewLecturesAtom } from '@/Atoms/Crew';
import GetIdFromQuerystring from '@/Utils/GetIdFromQuerystring';
import { ApplicationPeriodInfo } from '@/Atoms/Dialogs/Create/Course';
import { useTranslation } from 'react-i18next';
import { onNodesChangeAtom } from '@/ViewModels/Plan/Mindmap/FlowViewModel';

// Utility function for status
const getStatus = (applicationPeriod: ApplicationPeriodInfo, coursePeriod: { startDate: string; endDate: string }): string => {
  const now = new Date();
  const startApplicationDate = new Date(applicationPeriod.start.date);
  const endApplicationDate = new Date(applicationPeriod.end.date);
  const startCourseDate = new Date(coursePeriod.startDate);
  const endCourseDate = new Date(coursePeriod.endDate);
  let message = "상태 없음";

  if (now < startApplicationDate) {
    message = "준비중";
  } else if (now >= startApplicationDate && now <= endApplicationDate) {
    message = "신청 중";
  } else if (now > endApplicationDate && now < startCourseDate) {
    message = "운영 시작 전";
  } else if (now >= startCourseDate && now <= endCourseDate) {
    message = "운영 중";
  } else if (now > endCourseDate) {
    message = "종료";
  }

  return message;
};

const CrewTasks = () => {
  const nodes = useAtomValue(onNodesChangeAtom);
  const [sort, setSort] = React.useState("최신순"); // 최신순, 시대순, 오래된순
  const [filterStatus, setFilterStatus] = React.useState("전체"); // 상태 필터링
  const [tab, setTab] = React.useState('feed');
  const crewLectures = useAtomValue(crewLecturesAtom);
  const innerTabId = GetIdFromQuerystring("inner_tab_id");
  const setSnackbar = useSetAtom(snackbarAtom);
  const { t } = useTranslation();
  // Memoize lectures to prevent unnecessary renders
  const lectures = React.useMemo(
    () => crewLectures.find((lecture) => lecture.innerTabId === innerTabId)?.lectures || [],
    [crewLectures, innerTabId]
  );

  // Filter lectures by selected status
  const filteredLectures = React.useMemo(() => {
    const applyingLectures = lectures.filter((lecture) => getStatus(lecture.applicationPeriod, lecture.coursePeriod) === "신청 중");
    const operatingLectures = lectures.filter((lecture) => getStatus(lecture.applicationPeriod, lecture.coursePeriod) === "운영 중");
    const notStartedLectures = lectures.filter((lecture) => getStatus(lecture.applicationPeriod, lecture.coursePeriod) === "운영 시작 전");
    const endedLectures = lectures.filter((lecture) => getStatus(lecture.applicationPeriod, lecture.coursePeriod) === "종료");
    const preparingLectures = lectures.filter((lecture) => getStatus(lecture.applicationPeriod, lecture.coursePeriod) === "준비중");
    if (filterStatus === "전체") return [...applyingLectures, ...operatingLectures, ...notStartedLectures, ...preparingLectures, ...endedLectures];
    return lectures.filter((lecture) => {
      const statusMessage = getStatus(lecture.applicationPeriod, lecture.coursePeriod);
      return statusMessage === filterStatus;
    });
  }, [lectures, filterStatus]);

  const handleFilterChange = (event: SelectChangeEvent<string>) => {
    setFilterStatus(event.target.value as string);
  };

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    if (newValue === "lecture" && lectures.length === 0) {
      setSnackbar({ open: true, message: t("dialogs.common.snackbar.lecture.noLecture"), severity: "error" });
      return;
    }
    setTab(newValue);
  };

  // 노드 정렬 로직
  const sortedNodes = React.useMemo(() => {
    return nodes
      .filter((node) => node.id.includes("task"))
      .sort((a, b) => {
        if (sort === "최신순") {
          const dateA = a.data.createdAt ? new Date(a.data.createdAt as string).getTime() : new Date().getTime();
          const dateB = b.data.createdAt ? new Date(b.data.createdAt as string).getTime() : new Date().getTime();
          return dateB - dateA;
        }
        if (sort === "오래된순") {
          const dateA = a.data.createdAt ? new Date(a.data.createdAt as string).getTime() : new Date().getTime();
          const dateB = b.data.createdAt ? new Date(b.data.createdAt as string).getTime() : new Date().getTime();
          return dateA - dateB;
        }
        if (sort === "시대순") {
          // startDate가 null인 경우 하위로 정렬
          const dateA = a.data.startDate ? new Date(a.data.startDate as string).getTime() : Infinity;
          const dateB = b.data.startDate ? new Date(b.data.startDate as string).getTime() : Infinity;
          return dateA - dateB;
        }
        return 0;
      });
  }, [nodes, sort]);

  return (
    <>
      <div style={{ width: "100%", height: "50px", display: "flex", justifyContent: "space-between", alignItems: "center", gap: "20px" }}>
        <Tabs onChange={handleChange} aria-label="lab API tabs example" indicatorColor={theme.colors.primary} value={tab}>
          <S.CustomTab label={t("crew.crewTasks.feed")} value="feed" />
          <S.CustomTab label={`${t("crew.crewTasks.lecture")} ${lectures.length}`} value="lecture" />
        </Tabs>
        <div style={{ display: "flex", flexDirection: "row", gap: "20px", alignItems: "center" }}>
          {
            tab === "lecture" ?
              <>
                <div style={{ fontSize: "1rem", fontWeight: "bold" }}>{t("crew.crewTasks.status")}</div>
                <FormControl variant="standard" sx={{ minWidth: 120 }}>
                  <Select
                    value={filterStatus}
                    onChange={handleFilterChange}
                    displayEmpty
                    disableUnderline
                    sx={{
                      '& .MuiSelect-standard': {
                        borderBottom: 'none',
                      }
                    }}
                  >
                    <MenuItem value="전체">{t("crew.crewTasks.all")}</MenuItem>
                    <MenuItem value="준비중">{t("crew.crewTasks.ready")}</MenuItem>
                    <MenuItem value="신청 중">{t("crew.crewTasks.applying")}</MenuItem>
                    <MenuItem value="운영 시작 전">{t("crew.crewTasks.beforeStart")}</MenuItem>
                    <MenuItem value="운영 중">{t("crew.crewTasks.inProgress")}</MenuItem>
                    <MenuItem value="종료">{t("crew.crewTasks.end")}</MenuItem>
                  </Select>
                </FormControl>
              </> :
              <>
                <div style={{ fontSize: "1rem", fontWeight: "bold" }}>{t("crew.crewTasks.sort")}</div>
                <FormControl variant="standard" sx={{ minWidth: 120 }}>
                  <Select
                    value={sort}
                    onChange={(e) => setSort(e.target.value)}
                    label={t("crew.crewTasks.sort")}
                    displayEmpty
                    disableUnderline // underline 제거
                    sx={{
                      '& .MuiSelect-standard': {
                        borderBottom: 'none', // borderBottom 제거
                      }
                    }}
                  >
                    <MenuItem value="최신순">{t("crew.crewTasks.latest")}</MenuItem>
                    <MenuItem value="시대순">{t("crew.crewTasks.chronological")}</MenuItem>
                    <MenuItem value="오래된순">{t("crew.crewTasks.oldest")}</MenuItem>
                  </Select>
                </FormControl>
              </>
          }
        </div>
      </div >
      <S.Container>
        {tab === "feed" ? sortedNodes.map((node, idx) => <CrewTask key={`${node.id}-${node.data.backendId}-${idx}`} item={node} />) : filteredLectures.map(lecture => <CrewLecture key={lecture.id} lecture={lecture} />)
        }
      </S.Container>
    </>
  )
}

export default CrewTasks;
