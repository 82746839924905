import { error401ModalAtom } from "@/Atoms/Dialogs/Error/401Atom";
import planAtom, { PlanFeed } from "@/Atoms/Plan";
import createFeedModalAtom from "@/Atoms/Plan/Feed/CreateModalAtom";
import editFeedModalAtom from "@/Atoms/Plan/Feed/EditModalAtom";
import snackbarAtom from "@/Atoms/Snackbar";
import { createPlanFeed, deletePlanFeed, updatePlanFeed, UpdateFeedContentProps, deletePlanFeedImages, uploadPlanFeedImage } from "@/Queries/PlanFeedQueries";
import { deleteTextEditorImages } from "@/Queries/PlanQueries";
import { handleReactQueryApiResponse } from "@/Utils/APIUtil";
import GetIdFromQuerystring from "@/Utils/GetIdFromQuerystring";
import { atom } from "jotai";

export const getFeedsInPlanAtom = atom((get) => {
  const plans = get(planAtom);
  const planId = GetIdFromQuerystring("id");
  const plan = plans.find((plan) => plan.id === planId);
  console.log("getFeedsInPlanAtom", plan, plans);
  if (!plan) return [];
  return plan.feeds;
});

const getImageSrcs = (content: string) => {
  return Array.from(content.matchAll(/<img[^>]+src="([^">]+)"/g), match => match[1]);
}

const deleteBlobImageSrcs = (content: string, imgSrcs: string[]) => {
  const newContent = imgSrcs.reduce((prevContent, imgSrc) => {
    return prevContent.replace(`<img src="${imgSrc}">`, "");
  }, content);
  return newContent;
}

const replaceBlobImageToUrl = (content: string, blobImages: string[], urls: string[]) => {
  const newContent = blobImages.reduce((prevContent, blobImage, index) => {
    return prevContent.replace(blobImage, urls[index]);
  }, content);
  return newContent;
}

export const openFeedModalAtom = atom(null, async (get, set, type: "create" | "edit", feed?: PlanFeed) => {
  if (type === "create") {
    // 빈 피드 생성 요청 API 호출
    const response = await handleReactQueryApiResponse(createPlanFeed, () => set(error401ModalAtom, true), GetIdFromQuerystring("id"));
    if (!response.ok) {
      set(snackbarAtom, { open: true, message: "피드 생성에 실패했습니다.", severity: "error" });
      return;
    }
    const data = await response.json();
    const feedId = data.feedId;
    if (!feedId) {
      set(snackbarAtom, { open: true, message: "피드 생성에 실패했습니다.", severity: "error" });
      return;
    }
    console.log("openFeedModalAtom", feedId);
    set(createFeedModalAtom, {
      id: feedId,
      name: "",
      content: "",
      startDate: null,
      endDate: null,
      location: "",
      hashtags: [],
      createdAt: new Date().toISOString(),
      imgSrcs: [],
    });
  } else if (type === "edit" && feed) {
    const editFeed = {
      ...feed,
      imgSrcs: getImageSrcs(feed.content),
    }
    set(editFeedModalAtom, editFeed);
  }
})

export const closeFeedModalAtom = atom(null, async (get, set, type: "create" | "edit") => {
  if (type === "create") {
    const createFeedModal = get(createFeedModalAtom);
    if (!createFeedModal) return;
    await handleReactQueryApiResponse(deletePlanFeed, () => set(error401ModalAtom, true), createFeedModal.id);
    const imgSrcs = getImageSrcs(createFeedModal.content);

    if (imgSrcs.length > 0) {
      // 이미지 삭제
      const innerTabId = GetIdFromQuerystring("inner_tab_id");
      await handleReactQueryApiResponse(deletePlanFeedImages, () => set(error401ModalAtom, true), innerTabId, createFeedModal.id, imgSrcs);
    }
    set(createFeedModalAtom, null);
  }
  else {
    const editFeedModal = get(editFeedModalAtom);
    if (!editFeedModal) return;
    const prevImgSrcs = editFeedModal.imgSrcs;
    const newImgSrcs = getImageSrcs(editFeedModal.content);
    const deleteImgSrcs = prevImgSrcs.filter((prevImgSrc) => !newImgSrcs.includes(prevImgSrc));
    if (deleteImgSrcs.length > 0) {
      // 이미지 삭제
      const innerTabId = GetIdFromQuerystring("inner_tab_id");
      await handleReactQueryApiResponse(deletePlanFeedImages, () => set(error401ModalAtom, true), innerTabId, editFeedModal.id, deleteImgSrcs);
    }
    set(editFeedModalAtom, null);
  }
})

// key에 따라 상태 변경
export const updateStateFeedModalAtom = atom(null, (get, set, key: string, type: "create" | "edit", value: any) => {
  if (type === "create") {
    const createFeedModal = get(createFeedModalAtom);
    if (!createFeedModal) return;
    set(createFeedModalAtom, {
      ...createFeedModal,
      [key]: value,
    });
  } else {
    const editFeedModal = get(editFeedModalAtom);
    if (!editFeedModal) return;
    set(editFeedModalAtom, {
      ...editFeedModal,
      [key]: value,
    });
  }
});

// 장소 변경
export const updateLocationFeedModalAtom = atom(null, (get, set, type: "create" | "edit", address: string) => {
  if (type === "create") {
    const createFeedModal = get(createFeedModalAtom);
    if (!createFeedModal) return;
    set(createFeedModalAtom, {
      ...createFeedModal,
      location: address,
    });
  } else {
    const editFeedModal = get(editFeedModalAtom);
    if (!editFeedModal) return;
    set(editFeedModalAtom, {
      ...editFeedModal,
      location: address,
    });
  }
})

// 해시태그 추가
export const addHashtagInFeedModalAtom = atom(null, (get, set, type: "create" | "edit", hashtag: { backgroundColor: string, textColor: string, value: string }) => {
  if (type === "create") {
    const createFeedModal = get(createFeedModalAtom);
    if (!createFeedModal) return;
    if (!createFeedModal.hashtags) return;
    set(createFeedModalAtom, {
      ...createFeedModal,
      hashtags: [...createFeedModal.hashtags, hashtag],
    });
  } else {
    const editFeedModal = get(editFeedModalAtom);
    if (!editFeedModal) return;
    if (!editFeedModal.hashtags) return;
    set(editFeedModalAtom, {
      ...editFeedModal,
      hashtags: [...editFeedModal.hashtags, hashtag],
    });
  }
});

// 해시태그 삭제(pop)
export const removeHashtagInFeedModalAtom = atom(null, (get, set, type: "create" | "edit") => {
  if (type === "create") {
    const createFeedModal = get(createFeedModalAtom);
    if (!createFeedModal) return;
    if (!createFeedModal.hashtags) return;
    set(createFeedModalAtom, {
      ...createFeedModal,
      hashtags: createFeedModal.hashtags.slice(0, -1),
    });
  } else {
    const editFeedModal = get(editFeedModalAtom);
    if (!editFeedModal) return;
    if (!editFeedModal.hashtags) return;
    set(editFeedModalAtom, {
      ...editFeedModal,
      hashtags: editFeedModal.hashtags.slice(0, -1),
    });
  }
});

// 피드 생성 및 수정 동시 처리
export const createAndEditFeedInFeedModalAtom = atom(null, async (get, set, type: "create" | "edit") => {
  const modal = type === "create" ? get(createFeedModalAtom) : get(editFeedModalAtom);
  if (!modal) return;
  const plans = get(planAtom);
  const planId = GetIdFromQuerystring("id");
  const plan = plans.find((plan) => plan.id === planId);
  if (!plan) return;
  const feeds = plan.feeds;
  if (!modal.name || modal.name === "") {
    set(snackbarAtom, { open: true, message: "피드 이름을 입력해주세요.", severity: "error" });
    return;
  }
  if (!modal.content || modal.content === "") {
    set(snackbarAtom, { open: true, message: "피드 내용을 입력해주세요.", severity: "error" });
    return;
  }
  const imgSrcs = getImageSrcs(modal.content);
  let newContent: string | null = null;
  if (imgSrcs.length > 0) {
    const base64ImgSrcs = imgSrcs.filter((imgSrc) => imgSrc.startsWith("data:image"));
    if (base64ImgSrcs.length > 0) {
      // base64 이미지 업로드
      const innerTabId = GetIdFromQuerystring("inner_tab_id");
      const successBlobImages = [];
      const successUrls = [];
      const errorBlobImages = [];

      for (const imgData of base64ImgSrcs) {
        const byteString = atob(imgData.split(",")[1]);
        const mimeString = imgData.split(",")[0].split(":")[1].split(";")[0];

        const ab = new ArrayBuffer(byteString.length);
        const ia = new Uint8Array(ab);
        for (let i = 0; i < byteString.length; i++) {
          ia[i] = byteString.charCodeAt(i);
        }

        const blob = new Blob([ab], { type: mimeString });

        const imgUrlResponse = await handleReactQueryApiResponse(uploadPlanFeedImage, () => set(error401ModalAtom, true), blob, innerTabId, modal?.id || 0);
        if (!imgUrlResponse.ok) {
          errorBlobImages.push(imgData);
          continue;
        }
        const responseJson = await imgUrlResponse.json();
        const imgUrl = process.env.REACT_APP_DEV_BACK_IMAGE_ADDRESS + responseJson.image_url;
        // const imgUrl = "http://10.10.112.148:8002/plan_images" + responseJson.image_url;
        successBlobImages.push(imgData);
        successUrls.push(imgUrl);
      }
      if (errorBlobImages.length > 0) {
        newContent = deleteBlobImageSrcs(modal.content, errorBlobImages);
      }
      if (successBlobImages.length > 0) {
        newContent = replaceBlobImageToUrl(modal.content, successBlobImages, successUrls);
      }
      console.log("newContent", newContent);
    }
  }
  if (type === "edit") {
    // 수정 시 변경된 이미지 삭제
    const prevImgSrcs = modal.imgSrcs;
    const newImgSrcs = getImageSrcs(modal.content);
    const deleteImgSrcs = prevImgSrcs.filter((prevImgSrc) => !newImgSrcs.includes(prevImgSrc));
    if (deleteImgSrcs.length > 0) {
      // 이미지 삭제
      const innerTabId = GetIdFromQuerystring("inner_tab_id");
      await handleReactQueryApiResponse(deletePlanFeedImages, () => set(error401ModalAtom, true), innerTabId, modal.id, deleteImgSrcs);
    }
  }
  const newFeed = {
    ...modal,
    content: newContent || modal.content,
  };
  const updateContent: UpdateFeedContentProps = {
    label: newFeed.name,
    content: newFeed.content,
    start_date: newFeed.startDate,
    end_date: newFeed.endDate,
    location_address: newFeed.location,
    hashtags: newFeed.hashtags.map((hashtag) => ({ text_color: hashtag.textColor, background_color: hashtag.backgroundColor, value: hashtag.value })),
  }
  const response = await handleReactQueryApiResponse(updatePlanFeed, () => set(error401ModalAtom, true), newFeed.id, updateContent);
  if (!response.ok) {
    set(snackbarAtom, { open: true, message: `피드 ${type === "create" ? "생성" : "수정"}에 실패했습니다.`, severity: "error" });
    return;
  }
  const updatedFeeds = type === "create" ? [...feeds, newFeed] : feeds.map((feed) => (feed.id === modal.id ? modal : feed));
  const updatedPlan = {
    ...plan,
    feeds: updatedFeeds,
  };
  const updatedPlans = plans.map((plan) => (plan.id === planId ? updatedPlan : plan));
  set(planAtom, updatedPlans);
  set(createFeedModalAtom, null);
  set(editFeedModalAtom, null);
  set(snackbarAtom, { open: true, message: `피드가 ${type === "create" ? "생성" : "수정"}되었습니다.`, severity: "success" });
});

// 피드 생성
export const createFeedInCreateFeedModalAtom = atom(null, (get, set) => {
  const createFeedModal = get(createFeedModalAtom);
  if (!createFeedModal) return;
  const plans = get(planAtom);
  const planId = GetIdFromQuerystring("id");
  const plan = plans.find((plan) => plan.id === planId);
  if (!plan) return;
  const feeds = plan.feeds;
  const newFeed = {
    ...createFeedModal,
  };
  const updatedPlan = {
    ...plan,
    feeds: [...feeds, newFeed],
  };
  const updatedPlans = plans.map((plan) => (plan.id === planId ? updatedPlan : plan));
  set(planAtom, updatedPlans);
  set(createFeedModalAtom, null);
  set(snackbarAtom, { open: true, message: "피드가 생성되었습니다.", severity: "success" });
});

// 피드 수정
export const editFeedInEditFeedModalAtom = atom(null, (get, set) => {
  const editFeedModal = get(editFeedModalAtom);
  if (!editFeedModal) return;
  const plans = get(planAtom);
  const planId = GetIdFromQuerystring("id");
  const plan = plans.find((plan) => plan.id === planId);
  if (!plan) return;
  const feeds = plan.feeds;
  const updatedFeeds = feeds.map((feed) => (feed.id === editFeedModal.id ? editFeedModal : feed));
  const updatedPlan = {
    ...plan,
    feeds: updatedFeeds,
  };
  const updatedPlans = plans.map((plan) => (plan.id === planId ? updatedPlan : plan));
  set(planAtom, updatedPlans);
  set(editFeedModalAtom, null);
  set(snackbarAtom, { open: true, message: "피드가 수정되었습니다.", severity: "success" });
});

// 피드 삭제
export const deleteFeedInPlanAtom = atom(null, async (get, set, feedId: number) => {
  const plans = get(planAtom);
  const planId = GetIdFromQuerystring("id");
  const plan = plans.find((plan) => plan.id === planId);
  if (!plan) return;
  const feeds = plan.feeds;
  const updatedFeeds = feeds.filter((feed) => feed.id !== feedId);
  const updatedPlan = {
    ...plan,
    feeds: updatedFeeds,
  };
  const feed = feeds.find((feed) => feed.id === feedId);
  if (!feed) return;
  const imgSrcs = getImageSrcs(feed.content);
  const response = await handleReactQueryApiResponse(deletePlanFeed, () => set(error401ModalAtom, true), feedId);
  if (!response.ok) {
    set(snackbarAtom, { open: true, message: "피드 삭제에 실패했습니다.", severity: "error" });
    return;
  }
  const updatedPlans = plans.map((plan) => (plan.id === planId ? updatedPlan : plan));
  set(planAtom, updatedPlans);
  set(snackbarAtom, { open: true, message: "피드가 삭제되었습니다.", severity: "success" });

  // 이미지 삭제
  const innerTabId = GetIdFromQuerystring("inner_tab_id");
  await handleReactQueryApiResponse(deletePlanFeedImages, () => set(error401ModalAtom, true), innerTabId, feed.id, imgSrcs);
});

// // 일정 등록 및 제거
// export const addAndRemoveScheduleInFeedModalAtom = atom(null, (get, set, type: "create" | "edit") => {
//   const date = new Date();
//   if (type === "create") {
//     const createFeedModal = get(createFeedModalAtom);
//     if (!createFeedModal) return;
//     if (!createFeedModal.startDate) {
//       set(createFeedModalAtom, {
//         ...createFeedModal,
//         startDate: date.toISOString(),
//         endDate: new Date(date.getFullYear(), date.getMonth(), date.getDate() + 1).toISOString(),
//       });
//     } else {
//       set(createFeedModalAtom, {
//         ...createFeedModal,
//         startDate: null,
//         endDate: null,
//       });
//     }
//   } else {
//     const editFeedModal = get(editFeedModalAtom);
//     if (!editFeedModal) return;
//     if (!editFeedModal.startDate) {
//       set(editFeedModalAtom, {
//         ...editFeedModal,
//         startDate: date.toISOString(),
//         endDate: new Date(date.getFullYear(), date.getMonth(), date.getDate() + 1).toISOString(),
//       });
//     } else {
//       set(editFeedModalAtom, {
//         ...editFeedModal,
//         startDate: null,
//         endDate: null,
//       });
//     }
//   }
// });

// // 장소 등록 및 제거
// export const addAndRemoveLocationInFeedModalAtom = atom(null, (get, set, type: "create" | "edit") => {
//   if (type === "create") {
//     const createFeedModal = get(createFeedModalAtom);
//     if (!createFeedModal) return;
//     if (!createFeedModal.location) {
//       set(createFeedModalAtom, {
//         ...createFeedModal,
//         location: { address: "", latitude: 0, longitude: 0 },
//       });
//     } else {
//       set(createFeedModalAtom, {
//         ...createFeedModal,
//         location: null,
//       });
//     }
//   } else {
//     const editFeedModal = get(editFeedModalAtom);
//     if (!editFeedModal) return;
//     if (!editFeedModal.location) {
//       set(editFeedModalAtom, {
//         ...editFeedModal,
//         location: { address: "", latitude: 0, longitude: 0 },
//       });
//     } else {
//       set(editFeedModalAtom, {
//         ...editFeedModal,
//         location: null,
//       });
//     }
//   }
// });

// // 해시태그 등록 및 제거
// export const addAndRemoveHashtagInFeedModalAtom = atom(null, (get, set, type: "create" | "edit") => {
//   if (type === "create") {
//     const createFeedModal = get(createFeedModalAtom);
//     if (!createFeedModal) return;
//     if (!createFeedModal.hashtags) {
//       set(createFeedModalAtom, {
//         ...createFeedModal,
//         hashtags: [],
//       });
//     } else {
//       set(createFeedModalAtom, {
//         ...createFeedModal,
//         hashtags: null,
//       });
//     }
//   } else {
//     const editFeedModal = get(editFeedModalAtom);
//     if (!editFeedModal) return;
//     if (!editFeedModal.hashtags) {
//       set(editFeedModalAtom, {
//         ...editFeedModal,
//         hashtags: [],
//       });
//     } else {
//       set(editFeedModalAtom, {
//         ...editFeedModal,
//         hashtags: null,
//       });
//     }
//   }
// });
