import { Signature } from '@/Atoms/Signature/Atom';
import { getUserId, getUserImage } from '@/ViewModels/UserViewModel';
import { useAtomValue, useSetAtom } from 'jotai';
import React from 'react';
import LoopIcon from '@mui/icons-material/Loop';
import * as S from './styled';
import { useTranslation } from 'react-i18next';
import MenuIcon from '@mui/icons-material/Menu';
import { IconButton, Menu, MenuItem, Tooltip } from '@mui/material';
import { changeRepresentativeSignatureAtom, deleteSignatureAtom, editSignatureModeAtom } from '@/ViewModels/Signature/ViewModel';
import { getTextColorBasedOnBackground } from '@/Utils/GetRandomColor';
import useIsMobile from '@/Hooks/useIsMobile';
import VerifiedIcon from '@mui/icons-material/Verified';
import theme from '@/Styles/theme';
import SchoolIcon from '@mui/icons-material/School';
import { devConsoleLog } from '@/Utils/ConsoleLogInDevelopment';

interface SignatureCardProps {
  signature: Signature;
  isHalf?: boolean;
}

const SignatureCard = ({ signature, isHalf = false }: SignatureCardProps) => {
  const { t } = useTranslation();
  const { isMobile } = useIsMobile();
  const userId = useAtomValue(getUserId);
  const userImage = useAtomValue(getUserImage);
  const [back, setBack] = React.useState(false);
  const sortedInfo = signature.info.filter((i) => i.visible).sort((a, b) => a.orderBy - b.orderBy);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);
  const deleteSignature = useSetAtom(deleteSignatureAtom);
  const changeRepresentative = useSetAtom(changeRepresentativeSignatureAtom);
  const color = getTextColorBasedOnBackground(signature.backgroundColor);
  const editSignature = useSetAtom(editSignatureModeAtom);
  const halfSize = isMobile || isHalf;

  const groupItems = [
    !signature.representative && { name: t("signature.list.changeRepresentative"), value: "changeRepresentative" },
    { name: t("signature.list.edit"), value: "edit" },
    !signature.careerAdvancement && { name: t("signature.list.delete"), value: "delete" },
  ];

  const handleMenuItemClick = (value: string) => {
    if (value === "edit") {
      editSignature(signature.id);
    } else if (value === "delete") {
      deleteSignature(signature.id, t);
    } else if (value === "changeRepresentative") {
      changeRepresentative(signature.id, t);
    }
    handleClose();
  };

  return (
    <S.SignatureContainer $backgroundColor={signature.backgroundColor} $borderColor={signature.borderColor} $fontColor={signature.fontColor} $isHalf={halfSize} $careerAdvancement={signature.careerAdvancement}>
      {
        back ?
          <div style={{ display: "flex", flexDirection: "column", gap: "10px", width: "100%", height: "100%", padding: "10px", overflowY: "auto" }}>
            <S.SignatureGrid $height={halfSize ? "calc(100% - 20px)" : "calc(100% - 40px)"} style={{ rowGap: halfSize ? "10px" : "20px" }}>
              {sortedInfo.map((item, index) => (
                // <S.GridItem key={index} style={{ gap: halfSize ? "3px" : "6px", width: halfSize ? "130px" : "260px", flexDirection: "column", alignItems: "flex-start" }} $isHalf={halfSize}>
                //   <S.ItemBackTitle $isHalf={halfSize} style={{ width: "100%" }}>{item.label}</S.ItemBackTitle>
                //   <S.ItemBackValue $isHalf={halfSize} style={{ width: "100%" }}>{Array.isArray(item.value) ? item.value.join(", ") : item.value}</S.ItemBackValue>
                // </S.GridItem>
                <S.GridItem key={index} style={{ gap: halfSize ? "5px" : "10px", width: halfSize ? "130px" : "260px", flexDirection: "column", justifyContent: "center", alignItems: "flex-start" }} $isHalf={halfSize}>
                  <S.ItemTitle $isHalf={halfSize} style={{ width: "100%" }}>{item.label}</S.ItemTitle>
                  <S.ItemValue $isHalf={halfSize} style={{ width: "100%" }}>{Array.isArray(item.value) ? item.value.join(", ") : item.value}</S.ItemValue>
                </S.GridItem>
              ))}
            </S.SignatureGrid>
          </div> :
          <>
            <S.SignatureProfileContainer $isHalf={halfSize}>
              <S.SignatureImage src={signature.isUserProfile ? `${process.env.REACT_APP_USER_API_ADDRESS}${signature.image}` : `${process.env.REACT_APP_CAREER_PLANNER_USER_MEDIA_ADDRESS}${signature.image}` || userImage} alt="signature" $isHalf={halfSize} />
              <S.SignatureProfileInfo $isHalf={halfSize}>
                <S.GridItem style={{ padding: 0 }} $isHalf={halfSize}>
                  <Tooltip title={signature.name} placement="top" arrow>
                    <S.ItemTitle $isHalf={halfSize} style={{ fontSize: halfSize ? "0.7rem" : "1.4rem", width: "50%" }}>{signature.name}</S.ItemTitle>
                  </Tooltip>
                  <div style={{ display: "flex", flexDirection: "row", gap: halfSize ? "5px" : "10px", alignItems: "center" }}>
                    {
                      signature.representative &&
                      <VerifiedIcon sx={{ fontSize: halfSize ? "0.75rem" : "1.5rem", color: theme.colors.primary }} />
                    }
                    {/* 휘장 추가 */}
                    {signature.careerAdvancement && (
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "5px",
                          padding: halfSize ? "2px 5px" : "5px 10px",
                          backgroundColor: theme.colors.primary,
                          borderRadius: "10px",
                          color: "white",
                          fontWeight: "bold",
                          fontSize: halfSize ? "0.5rem" : "1rem",
                        }}
                      >
                        <SchoolIcon sx={{ fontSize: halfSize ? "0.75rem" : "1.2rem", color: "white" }} />
                        <span style={{ fontSize: halfSize ? "0.4rem" : "0.8rem" }}>{t("signature.card.careerAdvancementSignature")}</span>
                      </div>
                    )}
                    {/* {
                      signature.careerAdvancement &&
                      <SchoolIcon sx={{ fontSize: halfSize ? "0.75rem" : "1.5rem", color: theme.colors.primary }} />
                    } */}
                    {
                      signature.tag &&
                      <S.ItemTitle $isHalf={halfSize} style={{ padding: "5px", backgroundColor: signature.fontColor, borderRadius: "5px", color: getTextColorBasedOnBackground(signature.fontColor) }}>
                        {signature.tag}
                      </S.ItemTitle>
                    }
                  </div>
                </S.GridItem>
                <S.ItemTitle $isHalf={halfSize} style={{ display: "flex", fontSize: halfSize ? "0.75rem" : "1.5rem", justifyContent: "flex-end" }}>{signature.nickname}</S.ItemTitle>
                <S.ItemValue $isHalf={halfSize} style={{ display: "flex", justifyContent: "flex-end" }}>{signature.email}</S.ItemValue>
              </S.SignatureProfileInfo>
            </S.SignatureProfileContainer>
            <S.SignatureGrid $height={halfSize ? "calc(50% - 10px)" : "calc(50% - 20px)"}>
              {sortedInfo.slice(0, 4).map((item, index) => (
                <S.GridItem key={index} style={{ gap: halfSize ? "5px" : "10px", width: halfSize ? "130px" : "260px", flexDirection: "column", justifyContent: "center", alignItems: "flex-start" }} $isHalf={halfSize}>
                  <S.ItemTitle $isHalf={halfSize} style={{ width: "100%" }}>{item.label}</S.ItemTitle>
                  <S.ItemValue $isHalf={halfSize} style={{ width: "100%" }}>{Array.isArray(item.value) ? item.value.join(", ") : item.value}</S.ItemValue>
                </S.GridItem>
              ))}
            </S.SignatureGrid>
          </>
      }
      {
        sortedInfo.length > 4 &&
        <LoopIcon sx={{ ...S.LoopIconStyle(halfSize), color: color }} onClick={() => setBack(!back)} />
      }
      {
        userId === signature.user_id &&
        <>
          <IconButton
            sx={{
              ...S.MenuIconStyle(halfSize),
              color: "black",
              fontWeight: "bold",
              padding: 0,
              textTransform: "none",
            }}
            aria-controls={open ? 'basic-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            onClick={handleClick}
          >
            <MenuIcon sx={{ fontSize: isMobile ? "1rem" : "1.5rem", color: color }} />
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              'aria-labelledby': 'basic-button',
            }}
          >
            {groupItems.map((item, index) =>
              item && <MenuItem key={index} onClick={() => handleMenuItemClick(item.value)} sx={{ fontSize: "0.8rem" }}>
                {item.name}
              </MenuItem>
            )}
          </Menu>
        </>
      }
    </S.SignatureContainer >
  )
}

export default SignatureCard;
