import { error401ModalAtom } from '@/Atoms/Dialogs/Error/401Atom';
import innerTabDataAtom from '@/Atoms/Plan/InnerTabDataAtom';
import { addTasksInRoutineAtom, connectedRoutinesAtom, createTaskInRoutineEditRoutineAtom, createTaskInRoutineStateAtom, routineSidebarAtom } from '@/Atoms/Plan/RoutineAtom';
import snackbarAtom from '@/Atoms/Snackbar';
import { createTaskV2 } from '@/Queries/PlanQueries';
import { handleReactQueryApiResponse } from '@/Utils/APIUtil';
import GetIdFromQuerystring from '@/Utils/GetIdFromQuerystring';
import { atom } from 'jotai';
import { setInnerTabDataAtom } from '../InnerTabViewModel';
import { clearRoutineSidebarDataAtom, popAndChangeTasksInRoutineAtom, popRoutineSidebarDataAtom } from './SidebarViewModel';
import { TFunction } from 'i18next';

// 루틴 변경 시작
export const createTaskInRoutineEditRoutineStartAtom = atom(null, (get, set) => {
  const termData = get(createTaskInRoutineStateAtom).termData;
  set(createTaskInRoutineEditRoutineAtom, {
    termType: get(createTaskInRoutineStateAtom).termType,
    termData: termData ? [...termData] : [],
  });
});

// 루틴 일자 초기화
export const createTaskInRoutineEditRoutineClearStateAtom = atom(null, (get, set) => {
  set(createTaskInRoutineEditRoutineAtom, {
    termType: null,
    termData: null,
  });
});

// 루틴 변경 적용
export const createTaskInRoutineEditRoutineApplyAtom = atom(null, (get, set) => {
  const { termType, termData } = get(createTaskInRoutineEditRoutineAtom);

  if (!termType || !termData) return;

  // termType === 1 일 경우 [월, 화, 수, 목, 금, 토, 일] 순으로 정렬
  if (termType === 1) {
    termData.sort((a, b) => {
      const week = ["월", "화", "수", "목", "금", "토", "일"];
      return week.indexOf(a) - week.indexOf(b);
    });
  }
  // termType === 2 일 경우 숫자로 정렬
  else {
    termData.sort((a, b) => parseInt(a) - parseInt(b));
  }

  set(createTaskInRoutineStateAtom, (prev) => ({
    ...prev,
    termData: termData,
  }));
  set(createTaskInRoutineEditRoutineClearStateAtom);
});

// 루틴 일자 변경
export const createTaskInRoutineEditRoutineDataAtom = atom((get) => get(createTaskInRoutineEditRoutineAtom), (get, set, day: string) => {
  const editRoutineData = get(createTaskInRoutineEditRoutineAtom);
  if (!editRoutineData.termData) return;
  if (editRoutineData.termData.includes(day)) {
    set(createTaskInRoutineEditRoutineAtom, { termType: editRoutineData.termType, termData: editRoutineData.termData.filter((interval) => interval !== day) });
  } else {
    set(createTaskInRoutineEditRoutineAtom, { termType: editRoutineData.termType, termData: [...editRoutineData.termData, day] });
  }
});

export const createTaskInRoutineCreateTaskAtom = atom(null, async (get, set, t: TFunction) => {
  const createTaskData = get(createTaskInRoutineStateAtom);
  const connectedRoutines = get(connectedRoutinesAtom);
  const planData = get(innerTabDataAtom);
  const innerTabId = GetIdFromQuerystring("inner_tab_id");
  const plan = planData.find((data) => data.innerTabId === innerTabId);
  const sidebar = get(routineSidebarAtom);
  if (!plan || sidebar.length === 0) return;
  const data = sidebar[sidebar.length - 1];
  const sidebarType = data.type;

  if (createTaskData.label === "") {
    set(snackbarAtom, { open: true, message: t("plan.contents.routine.snackbar.inputTaskName"), severity: "error" });
    return;
  }
  if (createTaskData.label.length > 50) {
    set(snackbarAtom, { open: true, message: t("plan.contents.routine.snackbar.taskNameLength"), severity: "error" });
    return;
  }
  set(createTaskInRoutineStateAtom, (prev) => ({ ...prev, isLoading: true }));

  const requestData = {
    label: createTaskData.label,
    content: createTaskData.content,
    hashtags: createTaskData.hashtags,
    startDate: createTaskData.startDate.startOf('day').format("YYYY-MM-DDTHH:mm:ss"), // 로컬 시간으로 포맷팅
    endDate: createTaskData.endDate ? createTaskData.endDate.endOf('day').format("YYYY-MM-DDTHH:mm:ss") : null, // 로컬 시간으로 포맷팅
    termType: createTaskData.termType,
    termData: createTaskData.termData,
    color: createTaskData.color,
    times: createTaskData.times,
    routines: connectedRoutines.map((routine) => ({ routine_id: routine.id })),
    type: "task",
  }

  const response = await handleReactQueryApiResponse(createTaskV2, () => set(error401ModalAtom, true), innerTabId, requestData);

  if (!response.ok) {
    set(snackbarAtom, { open: true, message: t("plan.contents.routine.snackbar.addTaskFail"), severity: "error" });
    set(createTaskInRoutineStateAtom, { ...createTaskData, isLoading: false });
    return;
  }

  const responseJson = await response.json();

  const { nodes, edges } = responseJson;

  const planNodes = plan.nodes;
  const planEdges = plan.edges;

  const newNodes = [...planNodes, ...nodes];
  const newEdges = [...planEdges, ...edges];

  set(setInnerTabDataAtom, { innerTabId, nodes: newNodes, edges: newEdges });
  set(snackbarAtom, { open: true, message: t("plan.contents.routine.snackbar.addTaskSuccess"), severity: "success" });
  if (sidebarType === "createTask") {
    set(clearRoutineSidebarDataAtom);
  } else if (sidebarType === "addNewTaskToRoutine") {
    set(popRoutineSidebarDataAtom);
  }
});

export const createNewTaskToRoutineAtom = atom(null, async (get, set, t: TFunction) => {
  const createTaskData = get(createTaskInRoutineStateAtom);
  const planData = get(innerTabDataAtom);
  const innerTabId = GetIdFromQuerystring("inner_tab_id");
  const plan = planData.find((data) => data.innerTabId === innerTabId);
  const sidebar = get(routineSidebarAtom);
  if (!plan || sidebar.length === 0) return;
  const data = sidebar[sidebar.length - 1];
  const sidebarType = data.type;

  if (createTaskData.label === "") {
    set(snackbarAtom, { open: true, message: t("plan.contents.routine.snackbar.inputTaskName"), severity: "error" });
    return;
  }
  set(createTaskInRoutineStateAtom, (prev) => ({ ...prev, isLoading: true }));

  const requestData = {
    label: createTaskData.label,
    content: createTaskData.content,
    hashtags: createTaskData.hashtags,
    startDate: createTaskData.startDate.startOf('day').format("YYYY-MM-DDTHH:mm:ss"), // 로컬 시간으로 포맷팅
    endDate: createTaskData.endDate ? createTaskData.endDate.endOf('day').format("YYYY-MM-DDTHH:mm:ss") : null, // 로컬 시간으로 포맷팅
    termType: createTaskData.termType,
    termData: createTaskData.termData,
    color: createTaskData.color,
    times: createTaskData.times,
    routines: [],
    type: "task",
  }

  const response = await handleReactQueryApiResponse(createTaskV2, () => set(error401ModalAtom, true), innerTabId, requestData);

  if (!response.ok) {
    set(snackbarAtom, { open: true, message: t("plan.contents.routine.snackbar.addTaskFail"), severity: "error" });
    set(createTaskInRoutineStateAtom, { ...createTaskData, isLoading: false });
    return;
  }

  const responseJson = await response.json();

  const { nodes, edges } = responseJson;

  const planNodes = plan.nodes;
  const planEdges = plan.edges;

  const newNodes = [...planNodes, ...nodes];
  const newEdges = [...planEdges, ...edges];

  set(setInnerTabDataAtom, { innerTabId, nodes: newNodes, edges: newEdges });
  set(snackbarAtom, { open: true, message: t("plan.contents.routine.snackbar.addTaskSuccess"), severity: "success" });
  if (sidebarType === "createTask") {
    set(clearRoutineSidebarDataAtom);
  } else if (sidebarType === "addNewTaskToRoutine") {
    const node = nodes[0];
    const prevTasks = get(addTasksInRoutineAtom);
    const newPrevTasks = [...prevTasks, node];
    const prevData = sidebar[sidebar.length - 2];
    const newPrevDataTasks = [...prevData.tasks, node];
    set(addTasksInRoutineAtom, newPrevTasks);
    set(popAndChangeTasksInRoutineAtom, newPrevDataTasks);
  }
});
