import { Plan } from "@/Atoms/Plan";
import { InnerTabData } from "@/Atoms/Plan/InnerTabDataAtom";
import { Edge, Node } from "@xyflow/react";

export const createPlan = async (access: string, userId: number, type: string) => {
  const response = await fetch(
    `${process.env.REACT_APP_PLAN_ADDRESS}/empty?user_id=${userId}`,
    // `http://10.10.112.148:8002/yback_plan/plans/empty?user_id=${userId}`,
    {
      method: "POST",
      headers: {
        "accept": "application/json",
        'Authorization': `Bearer ${access}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        type: type,
      }),
    }
  );
  return response;
}

export const parseGetPlansResponse = (userId: number, data: any): Plan[] => {
  return data.map((plan: any) => {
    return {
      id: plan.id,
      userId: userId,
      name: plan.name,
      isPublic: plan.isPublic,
      imageUrl: plan.imageUrl,
      content: plan.content,
      hashtags: plan.hashtags,
      type: plan.type || "mindmap",
      innerTabs: plan.innerTabs.map((innerTab: any) => {
        return {
          id: innerTab.inner_tab_id,
          name: innerTab.name,
          viewType: (innerTab.viewType as "mindmap" | "todo" | "routine" | "gantt") || "mindmap",
        };
      }),
      feeds: plan.feeds ? plan.feeds.map((feed: {
        content: string;
        createdAt: string;
        endDate: string | null;
        startDate: string | null;
        label: string;
        location_address: string;
        hashtags: {
          textColor: string;
          backgroundColor: string;
          value: string;
        }[];
        user_id: number;
        feed_id: number;
      }) => ({
        id: feed.feed_id,
        createdAt: feed.createdAt,
        name: feed.label,
        content: feed.content,
        startDate: feed.startDate,
        endDate: feed.endDate,
        location: feed.location_address,
        hashtags: feed.hashtags || [],
      })) : [],
    };
  });
}

export const parseGetPlanResponse = (data: any): Plan => {
  return {
    id: data.id,
    userId: data.userId,
    name: data.name,
    isPublic: data.isPublic,
    imageUrl: data.imageUrl,
    content: data.content,
    hashtags: data.hashtags,
    type: data.type || "mindmap",
    innerTabs: data.innerTabs.map((innerTab: any) => {
      return {
        id: innerTab.inner_tab_id,
        name: innerTab.name,
        viewType: (innerTab.viewType as "mindmap" | "todo" | "routine" | "gantt") || "mindmap",
      };
    }),
    feeds: data.feeds ? data.feeds.map((feed: {
      content: string;
      createdAt: string;
      endDate: string | null;
      startDate: string | null;
      label: string;
      location_address: string;
      hashtags: {
        textColor: string;
        backgroundColor: string;
        value: string;
      }[];
      user_id: number;
      feed_id: number;
    }) => ({
      id: feed.feed_id,
      createdAt: feed.createdAt,
      name: feed.label,
      content: feed.content,
      startDate: feed.startDate,
      endDate: feed.endDate,
      location: feed.location_address,
      hashtags: feed.hashtags || [],
    })) : [],
  };
}

export const getPlans = async (access: string, userId: number) => {
  const request = {
    method: "GET",
    headers: {
      "accept": "application/json",
      "Authorization": `Bearer ${access}`,
    },
  };
  const response = await fetch(
    `${process.env.REACT_APP_PLAN_USER_API_ADDRESS}/${userId}`,
    request
  );
  return response;
};

export const parseGetPlanDataResponse = (data: any | null, innerTabId: number): InnerTabData => {
  if (!data) {
    return {
      innerTabId,
      nodes: [],
      edges: [],
    }
  }
  if (!data.nodes) {
    return {
      innerTabId,
      nodes: [],
      edges: [],
    }
  }
  const groupNodes = data.nodes.filter((node: Node) => node.type === "group");
  const nonGroupNodes = data.nodes.filter((node: Node) => node.type !== "group");
  return {
    innerTabId,
    nodes: [...groupNodes, ...nonGroupNodes].map((node: Node) => ({
      ...node,
      data: {
        ...node.data,
        hashtags: node.data.hashtags || [],
      }
    })),
    edges: data.edges,
  };
}

export const getPlanData = async (access: string, innerTabId: number) => {
  const request = {
    method: "GET",
    headers: {
      "Authorization": `Bearer ${access}`,
      "accept": "application/json",
    },
  }
  const response = await fetch(
    `${process.env.REACT_APP_INNER_TAB_ADDRESS}/${innerTabId}`,
    // `http://10.10.112.148:8002/yback_plan/inner-tabs/${innerTabId}`,
    request
  );
  return response;
}

const parseGroupNodes = (nodes: Node[]): Node[] => {
  return nodes.map((node) => {
    if (node.type === "group") {
      return {
        ...node,
        style: {
          ...node.style,
          width: node.measured?.width || 600,
          height: node.measured?.height || 400,
        },
      };
    }
    return node;
  });
}

export const updatePlanData = async (access: string, userId: number, innerTabId: number, nodes: Node[], edges: Edge[]) => {
  const groupNodes = parseGroupNodes(nodes.filter((node) => node.type === "group"));
  const nonGroupNodes = nodes.filter((node) => node.type !== "group");
  const request = {
    method: "PUT",
    headers: {
      "accept": "application/json",
      "Content-Type": "application/json",
      "Authorization": `Bearer ${access}`,
    },
    body: JSON.stringify({
      nodes: [...groupNodes, ...nonGroupNodes],
      edges: edges
    }),
  }
  const response = await fetch(
    `${process.env.REACT_APP_INNER_TAB_ADDRESS}/${innerTabId}?user_id=${userId}`,
    // `http://10.10.112.148:8002/yback_plan/inner-tabs/${innerTabId}?user_id=${userId}`,
    request
  );
  return response;
}

export const updatePlanName = async (access: string, planId: number, name: string) => {
  const response = await fetch(
    `${process.env.REACT_APP_PLAN_ADDRESS}/${planId}`,
    // `http://10.10.112.148:8002/yback_plan/plans/${planId}`,
    {
      method: "PUT",
      headers: {
        "accept": "application/json",
        "Content-Type": "application/json",
        "Authorization": `Bearer ${access}`,
      },
      body: JSON.stringify({
        name: name
      }),
    }
  );
  return response;
}

export const deletePlan = async (access: string, planId: number) => {
  const response = await fetch(
    `${process.env.REACT_APP_PLAN_ADDRESS}/${planId}`,
    // `http://10.10.112.148:8002/yback_plan/plans/${planId}`,
    {
      method: "DELETE",
      headers: {
        "Authorization": `Bearer ${access}`,
        "accept": "application/json",
      },
    }
  );
  return response;
}

export const deleteInnerTab = async (access: string, userId: number, innerTabId: number) => {
  const response = await fetch(
    `${process.env.REACT_APP_INNER_TAB_ADDRESS}/${innerTabId}?user_id=${userId}`,
    // `http://10.10.112.148:8002/yback_plan/inner-tabs/${innerTabId}?user_id=${userId}`,
    {
      method: "DELETE",
      headers: {
        "Authorization": `Bearer ${access}`,
        "accept": "application/json",
      },
    }
  );
  return response;
}

export const deleteNode = async (access: string, nodeBackendIds: number[], innerTabId: number, userId: number) => {
  const response = await fetch(
    `${process.env.REACT_APP_TASK_ADDRESS}?user_id=${userId}`,
    // `http://10.10.112.148:8002/yback_plan/tasks?user_id=${userId}`,
    {
      method: "DELETE",
      headers: {
        "Authorization": `Bearer ${access}`,
        "accept": "application/json",
        "Content-type": "application/json",
      },
      body: JSON.stringify({
        innerTabId: innerTabId,
        backendIds: nodeBackendIds,
      }),
    }
  );
  return response;
}

export const deleteEdge = async (access: string, edgeBackendIds: number[], innerTabId: number) => {
  const response = await fetch(
    `${process.env.REACT_APP_RELATION_ADDRESS}`,
    {
      method: "DELETE",
      headers: {
        "Authorization": `Bearer ${access}`,
        "accept": "application/json",
        "Content-type": "application/json",
      },
      body: JSON.stringify({
        innerTabId: innerTabId,
        backendIds: edgeBackendIds,
      }),
    }
  );
  return response;
}

export const deleteNodesAndEdges = async (access: string, nodeBackendIds: number[], edgeBackendIds: number[], innerTabId: number, userId: number) => {
  const response = await fetch(
    `${process.env.REACT_APP_TASK_AND_RELATION_ADDRESS}?user_id=${userId}`,
    // `http://10.10.112.148:8002/yback_plan/tasks-and-edges?user_id=${userId}`,
    {
      method: "DELETE",
      headers: {
        "Authorization": `Bearer ${access}`,
        "accept": "application/json",
        "Content-type": "application/json",
      },
      body: JSON.stringify({
        innerTabId: innerTabId,
        nodeBackendIds: nodeBackendIds,
        edgeBackendIds: edgeBackendIds,
      }),
    }
  );
  return response;
}

// 함께하기
export const shareWithCrew = async (access: string, nodeId: number, userId: number, crewInnerTabId: number) => {
  const response = await fetch(
    `${process.env.REACT_APP_TASK_ADDRESS}/${nodeId}/share?user_id=${userId}`,
    {
      method: "POST",
      headers: {
        "Authorization": `Bearer ${access}`,
        "accept": "application/json",
        "Content-type": "application/json",
      },
      body: JSON.stringify({
        crewInnerTabId: crewInnerTabId,
      }),
    }
  );
  return response;
}

// 텍스트 에디터 이미지 업로드 API
export const uploadTextEditorImage = async (access: string, file: File, innerTabId: number) => {
  const formData = new FormData();
  formData.append("imageFile", file);
  const response = await fetch(
    `${process.env.REACT_APP_PLAN_IMAGE_ADDRESS}/${innerTabId}/task_image`,
    {
      method: "POST",
      headers: {
        "Authorization": `Bearer ${access}`,
        "accept": "application/json",
      },
      body: formData,
    }
  );
  return response;
}

// 텍스트 에디터 이미지 삭제 API
export const deleteTextEditorImages = async (access: string, innerTabId: number, imageUrls: string[]) => {
  const response = await fetch(
    `${process.env.REACT_APP_PLAN_IMAGE_ADDRESS}/${innerTabId}/task_image`,
    {
      method: "DELETE",
      headers: {
        "Authorization": `Bearer ${access}`,
        "accept": "application/json",
        "Content-type": "application/json",
      },
      body: JSON.stringify({
        imageURLs: imageUrls,
      }),
    }
  );
  return response;
}

// task status 변경 API
export const updateTaskStatus = async (access: string, taskStatusId: number, status: number) => {
  const response = await fetch(
    `${process.env.REACT_APP_TASK_STATUS_ADDRESS}/${taskStatusId}`,
    {
      method: "PUT",
      headers: {
        "Authorization": `Bearer ${access}`,
        "accept": "application/json",
        "Content-type": "application/json",
      },
      body: JSON.stringify({
        status: status,
      }),
    }
  );
  return response;
}

// task 생성 API
export const createTask = async (access: string, innerTabId: number, task: Node) => {
  const response = await fetch(
    `${process.env.REACT_APP_TASK_ADDRESS}?inner_tab_id=${innerTabId}`,
    // `http://10.10.112.148:8002/yback_plan/tasks?inner_tab_id=${innerTabId}`,
    {
      method: "POST",
      headers: {
        "Authorization": `Bearer ${access}`,
        "accept": "application/json",
        "Content-type": "application/json",
      },
      body: JSON.stringify(task),
    }
  );
  return response;
}

// task 생성 API
export const createTaskV2 = async (access: string, innerTabId: number, data: any) => {
  const response = await fetch(
    `${process.env.REACT_APP_TASK_ADDRESS}/create/v2?inner_tab_id=${innerTabId}`,
    {
      method: "POST",
      headers: {
        "Authorization": `Bearer ${access}`,
        "accept": "application/json",
        "Content-type": "application/json",
      },
      body: JSON.stringify(data),
    }
  );
  return response;
}

// 하나의 노드를 기준으로 하위 노드들과 엣지를 연결하는 API
export const relateTasks = async (access: string, upperTaskId: number, lowerTaskIds: number[]) => {
  const response = await fetch(
    `${process.env.REACT_APP_TASK_ADDRESS}/routine-tasks`,
    {
      method: "POST",
      headers: {
        "Authorization": `Bearer ${access}`,
        "accept": "application/json",
        "Content-type": "application/json",
      },
      body: JSON.stringify({
        routine_id: upperTaskId,
        children_ids: lowerTaskIds,
      }),
    }
  );
  return response;
}

// task 수정 API
export const updateTaskV2 = async (access: string, taskId: number, data: any) => {
  const response = await fetch(
    `${process.env.REACT_APP_TASK_ADDRESS}/update/v2?task_id=${taskId}`,
    {
      method: "PUT",
      headers: {
        "Authorization": `Bearer ${access}`,
        "accept": "application/json",
        "Content-type": "application/json",
      },
      body: JSON.stringify(data),
    }
  );
  return response;
}

// task 수정 API
export const updateTask = async (access: string, task: Node) => {
  const response = await fetch(
    `${process.env.REACT_APP_TASK_ADDRESS}`,
    // `http://10.10.112.148:8002/yback_plan/tasks`,
    {
      method: "PUT",
      headers: {
        "Authorization": `Bearer ${access}`,
        "accept": "application/json",
        "Content-type": "application/json",
      },
      body: JSON.stringify(task),
    }
  );
  return response;
}

// edge 생성 API
export const createEdge = async (access: string, innerTabId: number, edge: Edge, status: { upper_task_id: number, lower_task_ids: number[] }[]) => {
  const body = {
    edge: edge,
    task_status_data: status,
  }
  const response = await fetch(
    `${process.env.REACT_APP_RELATION_ADDRESS}?inner_tab_id=${innerTabId}`,
    {
      method: "POST",
      headers: {
        "Authorization": `Bearer ${access}`,
        "accept": "application/json",
        "Content-type": "application/json",
      },
      body: JSON.stringify(body),
    }
  );
  return response;
}

export const updatePartialTasks = async (access: string, innerTabId: number, tasks: Node[]) => {
  const response = await fetch(
    `${process.env.REACT_APP_INNER_TAB_ADDRESS}/${innerTabId}/partial-update`,
    // `http://10.10.112.148:8002/yback_plan/inner-tabs/${innerTabId}/partial-update`,
    {
      method: "PUT",
      headers: {
        "Authorization": `Bearer ${access}`,
        "accept": "application/json",
        "Content-type": "application/json",
      },
      body: JSON.stringify({
        nodes: tasks
      }),
    }
  );
  return response;
}

interface updatePlanSettingRequest {
  name: string;
  is_public: boolean;
  content: string;
  hashtags: { backgroundColor: string, textColor: string, value: string }[];
}

export const updatePlanSetting = async (access: string, planId: number, request: updatePlanSettingRequest) => {
  const response = await fetch(
    `${process.env.REACT_APP_PLAN_ADDRESS}/${planId}`,
    // `http://10.10.112.148:8002/yback_plan/plans/${planId}`,
    {
      method: "PUT",
      headers: {
        "accept": "application/json",
        "Content-Type": "application/json",
        "Authorization": `Bearer ${access}`,
      },
      body: JSON.stringify(request),
    }
  );
  return response;
}

export const getRecommendPlans = async (access: string, country: "KR" | "US") => {
  const response = await fetch(
    `${process.env.REACT_APP_PLAN_ADDRESS}/recommend?country=${country}`,
    {
      method: "GET",
      headers: {
        "accept": "application/json",
        "Authorization": `Bearer ${access}`,
      },
    }
  );
  return response;
}

export const importPlan = async (access: string, planId: number, innerTabId: number) => {
  const response = await fetch(
    `${process.env.REACT_APP_PLAN_ADDRESS}/share?plan_id=${planId}&inner_tab_id=${innerTabId}`,
    {
      method: "POST",
      headers: {
        "accept": "application/json",
        "Authorization": `Bearer ${access}`,
      },
    }
  );
  return response;
}

// 연관 직업 추천 API
export const getRelatedJobs = async (access: string, job: string) => {
  const response = await fetch(
    `${process.env.REACT_APP_JOB_ADDRESS}/get-relate-job`,
    {
      method: "POST",
      headers: {
        "accept": "application/json",
        "Authorization": `Bearer ${access}`,
        "Content-type": "application/json",
      },
      body: JSON.stringify({
        job: job,
      }),
    }
  );
  return response;
}

// 직업명을 통해 직업 플랜 새로 생성하는 API
export const createPlanByJob = async (access: string, job: string) => {
  const response = await fetch(
    `${process.env.REACT_APP_JOB_ADDRESS}/new-with-job?job=${job}`,
    {
      method: "POST",
      headers: {
        "accept": "application/json",
        "Authorization": `Bearer ${access}`,
      },
    }
  );
  return response;
}

// 기존 innerTab에 직업 마인드맵을 생성하는 API
export const createJobMindmap = async (access: string, innerTabId: number, job: string) => {
  const response = await fetch(
    `${process.env.REACT_APP_JOB_ADDRESS}/replace-job?inner_tab_id=${innerTabId}&job=${job}`,
    {
      method: "POST",
      headers: {
        "accept": "application/json",
        "Authorization": `Bearer ${access}`,
      },
    }
  );
  return response;
}
