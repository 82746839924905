import { atom } from "jotai";

const deleteRoutineDialogAtom = atom<{
  open: boolean;
  complete: boolean;
}>({
  open: false,
  complete: false,
});

export default deleteRoutineDialogAtom;
