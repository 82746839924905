import createLectureDialogAtom from '@/Atoms/Dialogs/Create/Lecture';
import CustomBackgroundColorButton from '@/Components/Common/Buttons/CustomBackgroundColor';
import { createLectureDialogChangeModeAtom, createLectureDialogCloseAtom, createLectureDialogConfirmClickPreviousAtom, createLectureDialogEditCancelAtom, createLectureDialogEditSaveAtom, createLectureDialogSaveAtom, createLectureDialogSaveMultipleLectureAtom } from '@/ViewModels/Dialogs/Create/LectureViewModel';
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import React from 'react';
import CloseIcon from '@mui/icons-material/Close';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import * as S from './styled';
import * as C from './containers';
import CustomColorButton from '@/Components/Common/Buttons/CustomColor';
import theme from '@/Styles/theme';
import { useTranslation } from 'react-i18next';

const CreateLectureDialog = () => {
  const dialog = useAtomValue(createLectureDialogAtom);
  const closeDialog = useSetAtom(createLectureDialogCloseAtom);
  const saveDialog = useSetAtom(createLectureDialogSaveAtom);
  const editCancel = useSetAtom(createLectureDialogEditCancelAtom);
  const saveEditLecture = useSetAtom(createLectureDialogEditSaveAtom);
  const clickPrevious = useSetAtom(createLectureDialogConfirmClickPreviousAtom);
  const createMultipleLectures = useSetAtom(createLectureDialogSaveMultipleLectureAtom);
  const [mode, setMode] = useAtom(createLectureDialogChangeModeAtom);
  const { t } = useTranslation();

  const selectModes = [
    { title: t("dialogs.lecture.menu.singleTitle"), description: t("dialogs.lecture.menu.singleDescription"), onClick: () => setMode("single") },
    { title: t("dialogs.lecture.menu.multiTitle"), description: t("dialogs.lecture.menu.multiDescription"), onClick: () => setMode("multi") }
  ]

  return (
    <Dialog
      open={dialog.open}
      onClose={closeDialog}
      maxWidth={mode === "confirm" ? "md" : "sm"}
      fullWidth
    >
      <DialogTitle sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between", fontSize: "0.9rem", fontWeight: "bold" }}>
        <div style={{ display: "flex", flexDirection: "row", gap: "10px", alignItems: "center" }}>
          {mode !== "menu" && <ArrowBackIcon onClick={() => setMode("menu")} sx={{ cursor: "pointer" }} />}
          {mode === "menu" ? t("dialogs.lecture.menu.title") : mode === "single" ? t("dialogs.lecture.single.title") : t("dialogs.lecture.multi.title")}
        </div>
        <CloseIcon onClick={closeDialog} sx={{ color: "black", cursor: "pointer" }} />
      </DialogTitle>
      <DialogContent sx={{ display: "flex", flexDirection: "column", gap: "15px" }}>
        {
          mode === "menu" ?
            <>
              <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-around" }}>
                {selectModes.map((item, idx) => (
                  <S.SelectButton key={idx} onClick={item.onClick}>
                    <div style={{ fontWeight: "bold" }}>{item.title}</div>
                    <div>{item.description}</div>
                  </S.SelectButton>
                ))}
              </div>
            </> :
            mode === "single" ?
              <C.CreateSingleLecture /> :
              mode === "multi" ?
                <C.CreateMultiLecture /> :
                mode === "confirm" ?
                  <C.ConfirmMultiLecture /> :
                  <C.EditMultiLecture />
        }
      </DialogContent>
      {
        mode === "single" &&
        <DialogActions>
          <CustomColorButton text={t("dialogs.lecture.buttons.cancel")} onClick={closeDialog} fontSize="0.8rem" />
          <CustomBackgroundColorButton text={t("dialogs.lecture.buttons.create")} onClick={() => saveDialog(t)} fontSize="0.8rem" />
        </DialogActions>
      }
      {
        mode === "multi" && dialog.file !== null &&
        <DialogActions>
          <CustomBackgroundColorButton text={t("dialogs.lecture.buttons.next")} onClick={() => setMode("confirm")} fontSize="0.8rem" />
        </DialogActions>
      }
      {
        mode === "confirm" &&
        <DialogActions>
          <CustomColorButton text={t("dialogs.lecture.buttons.previous")} onClick={clickPrevious} fontSize="0.8rem" color="gray" />
          <CustomBackgroundColorButton text={t("dialogs.lecture.buttons.multiCreate", { count: dialog.selectedMultipleLecture.length })} onClick={() => createMultipleLectures(t)} fontSize="0.8rem" backgroundColor={dialog.selectedMultipleLecture.length === 0 ? "gray" : theme.colors.priamry} />
        </DialogActions>
      }
      {
        mode === "edit" &&
        <DialogActions>
          <CustomColorButton text={t("dialogs.lecture.buttons.cancel")} onClick={editCancel} fontSize="0.8rem" color="gray" />
          <CustomBackgroundColorButton text={t("dialogs.lecture.buttons.edit")} onClick={() => saveEditLecture(t)} fontSize="0.8rem" />
        </DialogActions>
      }
    </Dialog>
  )
}

export default CreateLectureDialog;
