import mailSidebarAtom from "@/Atoms/Mail/SidebarAtom";
import { useAtomValue } from "jotai";
import React from "react";
import * as S from './styled';
import MailController from "./Controller";
import MailTextEditor from "./TextEditor";
import 'react-quill/dist/quill.snow.css';
import mailAtom from "@/Atoms/Mail/Atom";
import MailComplete from "./Complete";

const MailContents = () => {
  const mailSidebar = useAtomValue(mailSidebarAtom);
  const mail = useAtomValue(mailAtom);

  return (
    <S.Container $mailSidebar={mailSidebar}>
      {
        mail.completed ?
          <MailComplete /> :
          <>
            <MailController />
            <MailTextEditor />
          </>
      }
    </S.Container>
  )
}

export default MailContents;
