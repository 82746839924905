import { textEditorForCreateAndEditDialogAtom } from "@/Atoms/Dialogs/TextEditor";
import { textEditorSaveForCreateAndEditAtom, textEditorSaveForCreateAndEditCloseAtom, textEditorSaveForCreateAndEditOnChangeAtom } from "@/ViewModels/Dialogs/TextEditorViewModel";
import { Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { useAtom, useSetAtom } from "jotai";
import React from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import CustomBackgroundColorButton from "../../Buttons/CustomBackgroundColor";
import CustomColorButton from "../../Buttons/CustomColor";
import { uploadTextEditorImage } from "@/Queries/PlanQueries";
import GetIdFromQuerystring from "@/Utils/GetIdFromQuerystring";
import { devConsoleError } from "@/Utils/ConsoleLogInDevelopment";
import { handleReactQueryApiResponse } from "@/Utils/APIUtil";
import { error401ModalAtom } from "@/Atoms/Dialogs/Error/401Atom";
import useIsMobile from "@/Hooks/useIsMobile";

const TextEditorForCreateAndEditDialog = () => {
  const { isMobile } = useIsMobile();
  const quillRef = React.useRef<ReactQuill | null>(null);
  const innerTabId = GetIdFromQuerystring("inner_tab_id");
  const [dialog, setDialog] = useAtom(textEditorForCreateAndEditDialogAtom);
  const close = useSetAtom(textEditorSaveForCreateAndEditCloseAtom);
  const save = useSetAtom(textEditorSaveForCreateAndEditAtom);
  const onChange = useSetAtom(textEditorSaveForCreateAndEditOnChangeAtom);
  const setError401Modal = useSetAtom(error401ModalAtom);

  const imageHandler = async () => {
    const input = document.createElement('input');
    input.setAttribute('type', 'file');
    input.setAttribute('accept', 'image/*');
    input.click();

    input.addEventListener('change', async () => {
      // input.files가 null이 아닌지 확인
      if (!input.files || input.files.length === 0) return;

      const file = input.files[0];
      try {
        const imgUrlResponse = await handleReactQueryApiResponse(uploadTextEditorImage, () => setError401Modal(true), file, innerTabId);
        if (!imgUrlResponse.ok) {
          devConsoleError(imgUrlResponse);
          return;
        }
        const imgUrlResponseJson = await imgUrlResponse.json();
        if (imgUrlResponseJson && quillRef.current) { // quillRef.current가 null이 아닌지 확인
          const imgUrl = process.env.REACT_APP_DEV_BACK_IMAGE_ADDRESS + imgUrlResponseJson.image_url;
          const editor = quillRef.current.getEditor();
          const range = editor.getSelection();
          if (range) {
            editor.insertEmbed(range.index, 'image', imgUrl);
            editor.setSelection(range.index + 1 as any);
          }
        }
      } catch (error) {
        devConsoleError(error);
      }
    });
  };

  // 모바일과 데스크톱 버전의 툴바 옵션 분리
  const modules = React.useMemo(() => {
    const desktopToolbar = [
      [{ header: "1" }, { header: "2" }, { font: [] }],
      [{ list: "ordered" }, { list: "bullet" }],
      ["bold", "italic", "underline", "strike"],
      [{ color: [] }, { background: [] }],
      [{ align: [] }],
      ["link", "image"],
      ["clean"],
    ];

    const mobileToolbar = [
      ["bold", "italic", "underline"],
      [{ list: "ordered" }, { list: "bullet" }],
      ["image"],
    ];

    return {
      toolbar: {
        container: isMobile ? mobileToolbar : desktopToolbar,
        handlers: { image: imageHandler },
      },
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [innerTabId, isMobile]);

  // Quill의 formats 설정
  const formats = [
    'header', 'font', 'list', 'bullet',
    'bold', 'italic', 'underline', 'strike',
    'color', 'background', 'align',
    'link', 'image'
  ];

  const handleSave = () => {
    save(dialog.content);
    close();
  }

  return (
    dialog.open &&
    <Dialog
      open={dialog.open}
      onClose={close}
      fullWidth
      maxWidth="md"
      fullScreen={isMobile}
    >
      <DialogTitle>텍스트 에디터</DialogTitle>
      <DialogContent sx={{ height: "500px" }}>
        <ReactQuill ref={quillRef} theme="snow" style={{ height: "450px" }} modules={modules} formats={formats} value={dialog.content} onChange={(content) => {
          // 다이얼로그가 열려있는 경우에만 업데이트
          if (dialog.open) {
            onChange(content);
          }
        }} />
      </DialogContent>
      <DialogActions>
        <CustomColorButton text="닫기" onClick={close} fontSize="0.8rem" />
        <CustomBackgroundColorButton text="저장" onClick={handleSave} fontSize="0.8rem" />
      </DialogActions>
    </Dialog>
  )
}

export default TextEditorForCreateAndEditDialog;
