import React from 'react';
import * as S from './styled';
import { Button, IconButton, MenuItem, Popover } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { drawerSidebarAtom } from '@/Atoms/SidebarAtom';
import { useLocation, useNavigate } from 'react-router-dom';
import { sendMailAtom } from '@/ViewModels/Mail/ViewModel';
import { createCrewDialogOpenAtom } from '@/ViewModels/Dialogs/Create/CrewViewModel';
import crewAtom from '@/Atoms/CrewAtom';
import { crewJoinAtom, crewLeaveAtom } from '@/ViewModels/Crew/ViewModel';
import { getUserId } from '@/ViewModels/UserViewModel';
import useIsMobile from '@/Hooks/useIsMobile';
import { useTranslation } from 'react-i18next';
import PlanMobileHeaderContent from '@/Components/Plan/Contents/Mobile/HeaderContent';
import { MoreVert } from '@mui/icons-material';
import { editCrewImageModalOpenAtom } from '@/ViewModels/Crew/EditImageModalViewModel';
import { editCrewModalOpenAtom } from '@/ViewModels/Crew/EditCrewModalViewModel';
import GetIdFromQuerystring from '@/Utils/GetIdFromQuerystring';
import snackbarAtom from '@/Atoms/Snackbar';

const MobileSidebar = () => {
  const { isMobile } = useIsMobile();
  const [drawerOpen, setDrawerOpen] = useAtom(drawerSidebarAtom);
  const location = useLocation();
  const navigate = useNavigate();
  const sendMail = useSetAtom(sendMailAtom);
  const createCrewDialogOpen = useSetAtom(createCrewDialogOpenAtom);
  const crew = useAtomValue(crewAtom);
  const joinCrew = useSetAtom(crewJoinAtom);
  const leaveCrew = useSetAtom(crewLeaveAtom);
  const userId = useAtomValue(getUserId);
  const { t } = useTranslation();
  const [crewMoreAnchorEl, setCrewMoreAnchorEl] = React.useState<null | HTMLElement>(null);
  const openEditImageModal = useSetAtom(editCrewImageModalOpenAtom);
  const openEditModal = useSetAtom(editCrewModalOpenAtom);
  const members = crew?.members || [];
  const isHost = members.find((member) => member.userId === userId)?.authority === 10;
  const crewId = GetIdFromQuerystring("crew_id");
  const innerTabId = GetIdFromQuerystring("inner_tab_id");
  const setSnackbar = useSetAtom(snackbarAtom);

  const handleOpenEditImageModal = () => {
    openEditImageModal();
    setCrewMoreAnchorEl(null);
  }

  const handleOpenEditModal = () => {
    openEditModal();
    setCrewMoreAnchorEl(null);
  }

  const handleCopyLink = () => {
    if (!crewId || !innerTabId) return;
    navigator.clipboard.writeText(`${process.env.REACT_APP_WEB_ADDRESS}/crew?crew_id=${crewId}&inner_tab_id=${innerTabId}`);
    setSnackbar({ open: true, message: t("crew.header.copyLinkSuccess"), severity: "success" });
    setCrewMoreAnchorEl(null);
  }

  const handleLeaveCrew = () => {
    leaveCrew(navigate, t);
    setCrewMoreAnchorEl(null);
  }

  React.useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 768) setDrawerOpen(false);
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
    // eslint-disable-next-line
  }, []);

  const renderMobileButton = () => {
    if (!location) return null;

    switch (location.pathname) {
      case "/mail":
        return <Button onClick={sendMail} sx={{ color: "black", fontWeight: "bold", padding: 0 }}>{t("sidebar.mobile.send")}</Button>;

      case "/plan-hip":
        return <Button onClick={createCrewDialogOpen} sx={{ color: "black", fontWeight: "bold", padding: 0 }}>{t("sidebar.mobile.createCrew")}</Button>;

      case "/crew":
        if (crew && crew.members.map((member) => member.userId).includes(userId)) {
          // return <Button onClick={handleLeaveCrew} sx={{ color: "black", fontWeight: "bold", padding: 0 }}>{t("sidebar.mobile.leaveCrew")}</Button>;
          return <IconButton onClick={(e) => setCrewMoreAnchorEl(e.currentTarget)}><MoreVert sx={{ color: "black", fontWeight: "bold", padding: 0 }} /></IconButton>;
        }
        if (crew && crew.crewInfo.headCount !== crew.crewInfo.current) {
          return <Button onClick={() => joinCrew(t)} sx={{ color: "black", fontWeight: "bold", padding: 0 }}>{t("sidebar.mobile.joinCrew")}</Button>;
        }
        return null;

      case "/plan":
        return <PlanMobileHeaderContent />;

      default:
        return null;
    }
  };

  return (
    isMobile &&
    <S.MobileHeaderContainer>
      <IconButton
        edge="start"
        color="inherit"
        aria-label="menu"
        onClick={() => setDrawerOpen(!drawerOpen)}
      >
        <MenuIcon />
      </IconButton>
      <Popover
        open={Boolean(crewMoreAnchorEl)}
        anchorEl={crewMoreAnchorEl}
        onClose={() => setCrewMoreAnchorEl(null)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}>
        <MenuItem onClick={handleCopyLink}>링크 복사</MenuItem>
        {isHost && <MenuItem onClick={handleOpenEditImageModal}>크루 이미지 수정</MenuItem>}
        {isHost && <MenuItem onClick={handleOpenEditModal}>크루 정보 수정</MenuItem>}
        <MenuItem onClick={handleLeaveCrew}>{t("crew.header.leaveCrew")}</MenuItem>
      </Popover>
      {renderMobileButton()}
    </S.MobileHeaderContainer>
  )
}

export default MobileSidebar;
