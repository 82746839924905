import { error401ModalAtom } from '@/Atoms/Dialogs/Error/401Atom';
import innerTabDataAtom from '@/Atoms/Plan/InnerTabDataAtom';
import { deleteTaskModalOpenAtom, routineSidebarAtom } from '@/Atoms/Plan/RoutineAtom';
import { deleteNodesAndEdges } from '@/Queries/PlanQueries';
import { handleReactQueryApiResponse } from '@/Utils/APIUtil';
import GetIdFromQuerystring from '@/Utils/GetIdFromQuerystring';
import { getUserId } from '@/ViewModels/UserViewModel';
import { atom } from 'jotai';
import { setInnerTabDataAtom } from '../InnerTabViewModel';
import snackbarAtom from '@/Atoms/Snackbar';
import { clearRoutineSidebarDataAtom, popRoutineSidebarDataAtom } from './SidebarViewModel';
import { TFunction } from 'i18next';
import { Node } from '@xyflow/react';
import { changeTaskStatusById } from '@/Queries/RoutineQueries';
import { TaskStatus } from '@/Types/Plan';

export const deleteTaskInRoutineAtom = atom(null, async (get, set, t: TFunction) => {
  const planData = get(innerTabDataAtom);
  const innerTabId = GetIdFromQuerystring("inner_tab_id");
  const currentPlanData = planData.find((data) => data.innerTabId === innerTabId);
  const sidebar = get(routineSidebarAtom);
  if (sidebar.length === 0) return;
  if (!currentPlanData) return;
  const data = sidebar[sidebar.length - 1];

  const key = data.key;
  if (!key) return;
  set(deleteTaskModalOpenAtom, (prev) => ({ ...prev, loading: true }));

  const edges = currentPlanData.edges;
  const connectedEdges = edges.filter((edge) => edge.source === key.id || edge.target === key.id).map((edge) => edge.data?.backendId as number);
  const notConnectedEdges = edges.filter((edge) => edge.source !== key.id && edge.target !== key.id);
  const nodes = currentPlanData.nodes;
  const filteredNodes = nodes.filter((node) => node.id !== key.id);

  try {
    const response = await handleReactQueryApiResponse(deleteNodesAndEdges, () => set(error401ModalAtom, true), [key.data.backendId as number], connectedEdges, innerTabId, get(getUserId));
    if (!response.ok) {
      throw new Error("Delete task failed");
    }
    set(setInnerTabDataAtom, { innerTabId, nodes: filteredNodes, edges: notConnectedEdges });
    set(snackbarAtom, { open: true, message: t("plan.contents.routine.snackbar.deleteTaskSuccess"), severity: "success" });
    if (data.type === "taskInRoutine") {
      set(popRoutineSidebarDataAtom);
    } else {
      set(clearRoutineSidebarDataAtom);
    }
  } catch (error) {
    set(snackbarAtom, { open: true, message: t("plan.contents.routine.snackbar.deleteTaskFail"), severity: "error" });
  }
  set(deleteTaskModalOpenAtom, { id: null, loading: false });
})

// 할 일 내부에서 완료 요청
export const requestChangeTaskStatusInRoutineByIdAtom = atom(null, async (get, set, task: Node, taskStatusId: number, t: TFunction) => {
  const planData = get(innerTabDataAtom);
  const innerTabId = GetIdFromQuerystring("inner_tab_id");
  const currentPlanData = planData.find((data) => data.innerTabId === innerTabId);
  const sidebar = get(routineSidebarAtom);
  if (sidebar.length === 0) return;
  if (!currentPlanData) return;

  const sidebarData = sidebar[sidebar.length - 1];
  const sidebarTasks = sidebarData.tasks as Node[];

  try {

    const response = await handleReactQueryApiResponse(changeTaskStatusById, () => set(error401ModalAtom, true), taskStatusId);

    if (!response.ok) {
      set(snackbarAtom, { open: true, message: t("plan.contents.routine.snackbar.taskStatusChangeFail"), severity: "error" });
    }

    const responseJson = await response.json();
    const updatedTaskStatus = responseJson as TaskStatus;

    // 완료한 세부 할 일 상태 업데이트
    const prevTaskStatus = task.data?.taskStatus as TaskStatus[];

    const newTaskStatus = prevTaskStatus.map((prevStatus) => {
      if (prevStatus.taskStatusId === updatedTaskStatus.taskStatusId) {
        return updatedTaskStatus;
      }
      return prevStatus;
    });

    // 상태 업데이트
    const newTask = {
      ...task,
      data: {
        ...task.data,
        taskStatus: newTaskStatus,
      },
    }

    let newSidebarData;
    if (task.id === sidebarData.key?.id) {
      // 상세 뷰의 경우
      newSidebarData = { ...sidebarData, key: newTask, taskStatus: updatedTaskStatus };
    } else {
      const newSidebarTasks = sidebarTasks.map((sidebarTask) => sidebarTask.id === task.id ? newTask : sidebarTask);
      const newTaskstatuses = sidebarData.taskStatuses?.map((status) => status.taskStatusId === updatedTaskStatus.taskStatusId ? updatedTaskStatus : status);
      newSidebarData = { ...sidebarData, tasks: newSidebarTasks, taskStatuses: newTaskstatuses };
    }
    const newSidebar = [...sidebar.slice(0, sidebar.length - 1), newSidebarData];

    const newNodes = currentPlanData.nodes.map((node) => node.id === task.id ? newTask : node);

    set(setInnerTabDataAtom, { innerTabId, nodes: newNodes, edges: currentPlanData.edges });
    set(routineSidebarAtom, newSidebar);

  } catch (error) {
    set(snackbarAtom, { open: true, message: t("plan.contents.routine.snackbar.taskStatusChangeFail"), severity: "error" });
  }
});
