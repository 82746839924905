import React from "react";

const TitleValueInARow = ({ title, value }: { title: string; value: any }) => {
  return (
    <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", width: "100%", height: "auto", fontSize: "12px", alignItems: "center" }}>
      <div style={{ fontWeight: "bold" }}>{title}</div>
      {value}
    </div>
  )
}

export default TitleValueInARow;
