import styled from "styled-components";

export const Container = styled.div<{ $isCalendar: boolean, $dateMode: "Month" | "Week" | "Day" }>`
  position: relative;
  width: ${({ $isCalendar }) => ($isCalendar ? "calc(100% - 40px)" : "calc(100% - 20px)")};
  height: ${({ $isCalendar }) => ($isCalendar ? "calc(100% - 40px)" : "calc(100% - 20px)")};
  display: flex;
  padding: ${({ $isCalendar }) => ($isCalendar ? "20px" : "20px 0px 0px 20px")};
  flex-direction: column;
  overflow: ${({ $isCalendar, $dateMode }) => ($isCalendar && ($dateMode === "Week" || $dateMode === "Day") ? "hidden" : "auto")};
  background-color: white;
  gap: 20px;
  align-items: center;
`;

export const InnerContainer = styled.div`
  display: flex;
  align-items: flex-start;
  width: 100%;
  height: 100%;
  gap: 20px;
  overflow-x: auto;
  overflow-y: hidden;
`;

export const KanbanList = styled.div<{ $backgroundColor: string }>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  min-width: 335px;
  gap: 10px;
  padding: 20px 20px;
  min-height: 100px;
  // max-height: 80%;
  background-color: ${({ $backgroundColor }) => $backgroundColor};
  // overflow-y: scroll;
  ms-overflow-style: none;
  scrollbar-width: none;
  border-radius: 20px;
`;

export const AddKanbanList = styled.div<{ $backgroundColor: string }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  min-width: 331px;
  gap: 10px;
  padding: 20px 20px;
  min-height: 40px;
  background-color: ${({ $backgroundColor }) => $backgroundColor + "50"};
  border: 2px solid ${({ $backgroundColor }) => $backgroundColor + "50"};
  ms-overflow-style: none;
  scrollbar-width: none;
  border-radius: 20px;
  cursor: pointer;

  &:hover {
    border: 2px solid ${({ $backgroundColor }) => $backgroundColor};
  }
`;

export const KanbanInnerList = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 10px;
  overflow-y: scroll;
  ms-overflow-style: none;
  scrollbar-width: none;
`;

export const AddTaskButton = styled.button`
  display: flex;
  width: 100%;
  min-height: 50px;
  cursor: pointer;
  border: 1px dashed white;
  border-radius: 10px;
  align-items: center;
  justify-content: center;
  z-index: 1;
  background: none;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;

  &:hover {
    border: 3px dashed white;
    font-weight: bold;
  }
`;

export const TagInputContainer = styled.div`
  display: flex;
  width: calc(100% - 2rem);
  flex-wrap: wrap;
  gap: 0.5em;
  padding: 0.4rem 1rem;
  border: 1px solid #c0c0c0;
  border-radius: 4px;
`;

export const TagItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 3px 6px;
  font-size: 0.8em;
  border-radius: 5px;
  color: white;
  font-weight: bold;
`;

export const TagInput = styled.input`
  flex-grow: 1;
  min-width: 30px;
  max-width: 150px;
  padding: 0.5em 0;
  border: none;
  outline: none;
  font-family: "Noto Sans KR", sans-serif;
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: calc(100% - 22px); // padding 10px * 2 + border 1px * 2
  height: 200px;
  border: 1px solid #d9d9d9;
  border-radius: 5px;
  padding: 10px;
  cursor: pointer;
  overflow-y: auto;

  &:hover {
    border-color: black;
  }
`;

export const KanbanItem = styled.div<{ $expanded: boolean }>`
  width: 313px;
  height: auto;
  background-color: white;
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  border-radius: 10px;
  gap: 10px;
  color: black;
  border: ${({ $expanded }) => ($expanded ? "1px solid blue" : "1px solid white")};

  &:hover {
    border: 1px solid blue;
  }
`;

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  position: relative;
`
export const HeaderTitle = styled.div`
  width: 60%;
  textOverflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  display: block;
  text-align: center;
  min-width: 0;
`;

// div style={{ display: "flex", alignItems: "center", gap: "5px" }}
export const CalenderEvent = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
`;
