import React from 'react';
import * as S from './styled';
import { useAtomValue, useSetAtom } from 'jotai';
import { getUserId } from '@/ViewModels/UserViewModel';
import crewAtom from '@/Atoms/CrewAtom';
import { crewCloseAtom, crewJoinAtom, crewLeaveAtom } from '@/ViewModels/Crew/ViewModel';
import { useNavigate } from 'react-router-dom';
import useIsMobile from '@/Hooks/useIsMobile';
import IeumLogo from '@assets/Images/ieum_logo.png';
import CustomBackgroundColorButton from '@/Components/Common/Buttons/CustomBackgroundColor';
import { useTranslation } from 'react-i18next';
import { ContentCopy, Login, Logout, Settings } from '@mui/icons-material';
import { Dialog, DialogActions, DialogContent, DialogTitle, IconButton, MenuItem, Popover, Tooltip } from '@mui/material';
import CustomColorButton from '@/Components/Common/Buttons/CustomColor';
import { editCrewImageModalOpenAtom } from '@/ViewModels/Crew/EditImageModalViewModel';
import { editCrewModalOpenAtom } from '@/ViewModels/Crew/EditCrewModalViewModel';
import GetIdFromQuerystring from '@/Utils/GetIdFromQuerystring';
import snackbarAtom from '@/Atoms/Snackbar';

const CrewTopSection = () => {
  const { isMobile } = useIsMobile();
  const crew = useAtomValue(crewAtom);
  const userId = useAtomValue(getUserId);
  const joinCrew = useSetAtom(crewJoinAtom);
  const leaveCrew = useSetAtom(crewLeaveAtom);
  const closeCrew = useSetAtom(crewCloseAtom);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [leaveModal, setLeaveModal] = React.useState(false);
  const [closeModal, setCloseModal] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const openEditImageModal = useSetAtom(editCrewImageModalOpenAtom);
  const openEditModal = useSetAtom(editCrewModalOpenAtom);
  const setSnackbar = useSetAtom(snackbarAtom);
  const members = crew?.members || [];
  const isHost = members.find((member) => member.userId === userId)?.authority === 10;
  const isMember = members.find((member) => member.userId === userId);
  const crewId = GetIdFromQuerystring("crew_id");
  const innerTabId = GetIdFromQuerystring("inner_tab_id");

  const handleOpenEditImageModal = () => {
    openEditImageModal();
    setAnchorEl(null);
  }

  const handleOpenEditModal = () => {
    openEditModal();
    setAnchorEl(null);
  }

  const handleCopyLink = () => {
    navigator.clipboard.writeText(`${process.env.REACT_APP_WEB_ADDRESS}/crew?crew_id=${crewId}&inner_tab_id=${innerTabId}`);
    setSnackbar({ open: true, message: t("crew.header.copyLinkSuccess"), severity: "success" });
  }

  return (crew &&
    <S.Container>
      <S.Title>
        <S.CrewThumbnail src={crew.crewInfo.imageUrl ? `${process.env.REACT_APP_CAREER_PLANNER_PLAN_MEDIA_ADDRESS}${crew.crewInfo.imageUrl.split("/media")[1]}` : IeumLogo} alt="logo" />
        {crew.crewInfo.name}
      </S.Title>
      {!isMobile &&
        <div style={{ display: "flex", flexDirection: "row", gap: "20px", alignItems: "center" }}>
          {
            isMember &&
            <Tooltip title={t("crew.header.share")} arrow>
              <IconButton sx={{ color: "black" }} onClick={handleCopyLink}>
                <ContentCopy />
              </IconButton>
            </Tooltip>
          }
          {
            isHost &&
            <Tooltip title={t("crew.header.setting")} arrow>
              <IconButton sx={{ color: "black" }} onClick={(e) => setAnchorEl(e.currentTarget)}>
                <Settings />
              </IconButton>
            </Tooltip>
          }
          {
            crew.members.map((member) => member.userId).includes(userId) ?
              <Tooltip title={t(isHost ? "crew.header.closeCrew" : "crew.header.leaveCrew")} arrow>
                <IconButton sx={{ color: "black" }} onClick={() => isHost ? setCloseModal(true) : setLeaveModal(true)}>
                  <Logout />
                </IconButton>
              </Tooltip> :
              crew.crewInfo.headCount !== crew.crewInfo.current ?
                <Tooltip title={t("crew.header.joinCrew")} arrow>
                  <IconButton sx={{ color: "black" }} onClick={() => joinCrew(t)}>
                    <Login />
                  </IconButton>
                </Tooltip> :
                null
          }
        </div>
      }
      <Dialog open={leaveModal} onClose={() => setLeaveModal(false)}>
        <DialogTitle sx={{ fontSize: "0.9rem", fontWeight: "bold" }}>{t("crew.header.leaveCrew")}</DialogTitle>
        <DialogContent sx={{ fontSize: "0.8rem" }}>
          <div>
            {t("crew.header.leaveModal.content")}
          </div>
        </DialogContent>
        <DialogActions>
          <CustomColorButton onClick={() => setLeaveModal(false)} color="gray" text={t("crew.header.leaveModal.cancel")} fontSize="0.8rem" />
          <CustomBackgroundColorButton onClick={() => leaveCrew(navigate, t)} text={t("crew.header.leaveModal.leave")} fontSize="0.8rem" />
        </DialogActions>
      </Dialog>
      <Dialog open={closeModal} onClose={() => setCloseModal(false)}>
        <DialogTitle sx={{ fontSize: "0.9rem", fontWeight: "bold" }}>{t("crew.header.closeCrew")}</DialogTitle>
        <DialogContent sx={{ fontSize: "0.8rem", display: "flex", flexDirection: "column", gap: "10px" }}>
          <div>
            {t("crew.header.closeModal.content")}
          </div>
          <div>
            {t("crew.header.closeModal.content2")}
          </div>
        </DialogContent>
        <DialogActions>
          <CustomColorButton onClick={() => setCloseModal(false)} color="gray" text={t("crew.header.closeModal.cancel")} fontSize="0.8rem" />
          <CustomBackgroundColorButton onClick={() => closeCrew(navigate, t)} text={t("crew.header.closeModal.close")} fontSize="0.8rem" />
        </DialogActions>
      </Dialog>
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <MenuItem onClick={handleOpenEditImageModal}>{t("crew.header.editImage")}</MenuItem>
        <MenuItem onClick={handleOpenEditModal}>{t("crew.header.editInfo")}</MenuItem>
      </Popover>
    </S.Container>
  );
}

export default CrewTopSection;
