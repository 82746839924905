import { Edge, Node } from "@xyflow/react";

interface CreateRoutineRequest {
  plan_id: number;
  name: string;
  view_type: string;
  content: string;
  tasks: Node[];
  edges: Edge[];
}

export const createRoutine = async (access: string, request: CreateRoutineRequest) => {
  const response = await fetch(
    `${process.env.REACT_APP_INNER_TAB_ADDRESS}/routine`,
    {
      method: "POST",
      headers: {
        "Authorization": `Bearer ${access}`,
        "Content-Type": "application/json",
        "accept": "application/json",
      },
      body: JSON.stringify(request),
    },
  );
  return response;
}

export interface CreateUltimateContent {
  label: string;
  content: string;
  location: string;
  hashtags: {
    backgroundColor: string;
    textColor: string;
    value: string;
  }[];
  startDate: string | null;
  endDate: string | null;
  termType: number | null;
  termData: number[] | string[] | null;
}

// 루틴에서 필살기 생성
export const createUltimate = async (access: string, innerTabId: number, content: CreateUltimateContent) => {
  const response = await fetch(
    `${process.env.REACT_APP_TASK_ADDRESS}/ultimate?inner_tab_id=${innerTabId}`,
    // `http://10.10.112.148:8002/yback_plan/tasks/ultimate?inner_tab_id=${innerTabId}`,
    {
      method: "POST",
      headers: {
        "Authorization": `Bearer ${access}`,
        "accept": "application/json",
        "Content-type": "application/json",
      },
      body: JSON.stringify(content),
    }
  );
  return response;
}

export interface CreateTaskInRoutineContent {
  label: string;
  content: string;
  location: string;
  hashtags: {
    backgroundColor: string;
    textColor: string;
    value: string;
  }[];
  startDate: string | null;
  endDate: string | null;
  termType: number | null;
  termData: number[] | string[] | null;
}

// 루틴에서 할 일 생성
export const createTaskInRoutine = async (access: string, upperTaskId: number, content: CreateTaskInRoutineContent) => {
  const response = await fetch(
    `${process.env.REACT_APP_TASK_ADDRESS}/ultimate/new-task?upper_task_id=${upperTaskId}`,
    // `http://10.10.112.148:8002/yback_plan/tasks/ultimate/new-task?upper_task_id=${upperTaskId}`,
    {
      method: "POST",
      headers: {
        "Authorization": `Bearer ${access}`,
        "accept": "application/json",
        "Content-type": "application/json",
      },
      body: JSON.stringify(content),
    }
  )
  return response;
}

// 루틴에서 할 일 완료
export const completeTasksInRoutine = async (access: string, upperTaskId: number, lowerTaskIds: number[]) => {
  const response = await fetch(
    `${process.env.REACT_APP_TASK_STATUS_ADDRESS}`,
    // `http://10.10.112.148:8002/yback_plan/task-status`,
    {
      method: "PUT",
      headers: {
        "Authorization": `Bearer ${access}`,
        "accept": "application/json",
        "Content-type": "application/json",
      },
      body: JSON.stringify({
        upper_task_id: upperTaskId,
        lower_task_ids: lowerTaskIds,
      }),
    }
  )
  return response;
}

// taskStatusId에 해당하는 상태 변경
export const changeTaskStatusById = async (access: string, taskStatusId: number) => {
  const response = await fetch(
    `${process.env.REACT_APP_TASK_STATUS_ADDRESS}/cw/${taskStatusId}`,
    {
      method: "PUT",
      headers: {
        "Authorization": `Bearer ${access}`,
        "accept": "application/json",
      },
    }
  )
  return response;
}

// 루틴 내부의 할 일 순서 조회
export const getTaskOrderInRoutine = async (access: string, routineId: number) => {
  const response = await fetch(
    `${process.env.REACT_APP_TASK_ADDRESS}/order?routine_id=${routineId}`,
    {
      method: "GET",
      headers: {
        "Authorization": `Bearer ${access}`,
        "accept": "application/json",
      },
    }
  )
  return response;
}

// 루틴 내부의 할 일 순서 변경
export const updateTaskOrderInRoutine = async (access: string, routineId: number, order: { task_id: number, order: number }[]) => {
  const requestBody = {
    tasks: order,
  }
  const response = await fetch(
    `${process.env.REACT_APP_TASK_ADDRESS}/order?routine_id=${routineId}`,
    {
      method: "PUT",
      headers: {
        "Authorization": `Bearer ${access}`,
        "accept": "application/json",
        "Content-type": "application/json",
      },
      body: JSON.stringify(requestBody),
    }
  )
  return response;
}
