import React, { useState } from "react";
import { useAtom, useAtomValue } from "jotai";
import {
  currentChatbotAtom,
  isOpenRecommendQuestionAtom,
  recommendCurrentPageAtom,
} from "../../../Atoms/HelperAtom";
import {
  CardActions,
  CardContent,
  IconButton,
  Typography,
  Dialog,
  DialogContent,
  DialogTitle,
  useMediaQuery,
  useTheme,
  Grid,
} from "@mui/material";
import {
  ArrowBackIosNew,
  ArrowForward,
  ArrowForwardIos,
  Close,
  QuestionMark,
} from "@mui/icons-material";
import * as S from "./styled";
import { useGetMyChatbotFrontElement } from "../../../Queries/HelperQueries";
import { hanldeRecommendQuestionAtom } from "../../../ViewModels/HelperViewModel";
import { useTranslation } from "react-i18next";
import useIsMobile from "@/Hooks/useIsMobile";
import { currentChatRoomAtom } from "@/Atoms/ChatAtom";

export function RecommendQuestion() {
  const { t } = useTranslation();
  const { isMobile } = useIsMobile();
  // const [currentChatbot] = useAtom(currentChatbotAtom);
  const currentChatRoom = useAtomValue(currentChatRoomAtom);
  const [isOpenRecommendQuestion, setIsOpenRecommendQuestion] = useAtom(
    isOpenRecommendQuestionAtom
  );
  const [, hanldeRecommendQuestion] = useAtom(hanldeRecommendQuestionAtom);

  const { data: recommendQuestionList } = useGetMyChatbotFrontElement(
    // currentChatbot?.id ?? -1
    currentChatRoom?.chatbots[0]?.id ?? -1
  );

  // 페이지 관련 상태
  const [page, setPage] = useAtom(recommendCurrentPageAtom);

  const itemsPerPage = 3;

  // 전체 페이지 수 계산
  const totalPages = Math.ceil(
    (recommendQuestionList?.length ?? 0) / itemsPerPage
  );

  // 페이지 전환 핸들러
  const handleNextPage = () => {
    if (page < totalPages - 1) {
      setPage(page + 1);
    }
  };

  const handlePrevPage = () => {
    if (page > 0) {
      setPage(page - 1);
    }
  };

  // 현재 페이지에 해당하는 아이템들 가져오기
  const currentItems = recommendQuestionList?.slice(
    page * itemsPerPage,
    page * itemsPerPage + itemsPerPage
  );

  // 테마와 화면 크기 감지
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md")); // 1024px 이하

  // 모달을 닫기 위한 핸들러
  const handleCloseModal = () => {
    // 필요한 경우 모달을 닫는 로직을 추가
    setIsOpenRecommendQuestion(false);
  };

  // 1024px 이하일 때 모달로 띄우기
  if (isSmallScreen && isOpenRecommendQuestion) {
    return null;
    // return (
    //   <Dialog
    //     open={isOpenRecommendQuestion}
    //     onClose={handleCloseModal}
    //     fullWidth
    //     maxWidth="md"
    //   >
    //     <DialogTitle
    //       sx={{
    //         display: "flex",
    //         flexDirection: "row",
    //         justifyContent: "space-between",
    //         alignItems: "center",
    //       }}
    //     >
    //       <Typography>{t("makeTextHelper.recommendedQuestion.recommend")}</Typography>
    //       <IconButton onClick={() => setIsOpenRecommendQuestion(false)}>
    //         <Close />
    //       </IconButton>
    //     </DialogTitle>
    //     <Grid
    //       container
    //       sx={{ maxHeight: "100%", overflowY: "auto", padding: "12px 24px" }}
    //     >
    //       {recommendQuestionList?.map((ques, index) => (
    //         <Grid xs={12} sm={6}>
    //           <S.CustomMobileCard
    //             key={`recommend-question-mobile-${page}-${index}`}
    //             onClick={() => hanldeRecommendQuestion(ques.value)}
    //           >
    //             <CardContent>{ques.value}</CardContent>
    //             <CardActions sx={{ justifyContent: "end" }}>
    //               <IconButton aria-label="add to favorites">
    //                 <ArrowForward />
    //               </IconButton>
    //             </CardActions>
    //           </S.CustomMobileCard>
    //         </Grid>
    //       ))}
    //     </Grid>
    //   </Dialog>
    // );
  } else if (isOpenRecommendQuestion) {
    return (
      <div style={{ display: "flex", alignItems: "center", width: "100%" }}>
        <IconButton
          aria-label="back"
          size="large"
          style={{
            width: `40px`,
            height: `40px`,
            borderRadius: "20px",
            color: page === 0 ? "#8f8f8f" : "#336699", // 첫 페이지에서는 비활성화 색상
          }}
          onClick={handlePrevPage}
          disabled={page === 0} // 첫 페이지에서 비활성화
        >
          <ArrowBackIosNew style={{ fontSize: `30px` }} />
        </IconButton>

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "start",
            width: `100%`,
          }}
        >
          {/* 카드 첫번째 아이템 */}
          <S.FirstCard key={`recommend-question-0-0`} style={{ cursor: "default" }}>
            <CardContent>{t("makeTextHelper.recommendQuestion.first")}</CardContent>
            <CardActions sx={{ justifyContent: "end" }}>
              <IconButton aria-label="add to favorites" sx={{ color: "white" }}>
                <QuestionMark />
              </IconButton>
            </CardActions>
          </S.FirstCard>

          {/* 추천 질문들 3개씩 보여줌 */}
          {currentItems?.map((ques: any, index: number) => (
            <S.CustomCard
              key={`recommend-question-${page}-${index}`}
              onClick={() => hanldeRecommendQuestion(ques.value)}
            >
              <CardContent>{ques.value}</CardContent>
              <CardActions sx={{ justifyContent: "end" }}>
                <IconButton aria-label="add to favorites">
                  <ArrowForward />
                </IconButton>
              </CardActions>
            </S.CustomCard>
          ))}
        </div>

        <IconButton
          aria-label="forward"
          size="large"
          style={{
            width: "40px",
            height: "40px",
            borderRadius: "20px",
            color: page === totalPages - 1 ? "#8f8f8f" : "#336699", // 마지막 페이지에서는 비활성화 색상
          }}
          onClick={handleNextPage}
          disabled={page === totalPages - 1} // 마지막 페이지에서 비활성화
        >
          <ArrowForwardIos style={{ fontSize: `30px` }} />
        </IconButton>
      </div>
    );
  }

  return null;
}
