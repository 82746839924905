import { useAtom, useSetAtom } from "jotai";
import React, { useState } from "react";
import {
  chattingTypeAtom,
  ChatType,
  previousCrewAtom,
  searchInputValueAtom,
} from "../../../Atoms/HelperAtom";
import {
  Avatar,
  Box,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import Lottie from "react-lottie";
import LottieData from "./animation/Animation - 1729166410374.json";
import { CopyAll } from "@mui/icons-material";
import { noticeModalAtom } from "@/Atoms/RootAtom";
import { useNavigate } from "react-router-dom";
import { createTaskDialogAtom } from "@/Atoms/Dialogs/Create/Task";
import { v4 as uuidv4 } from "uuid";
import { marked } from "marked";
import { useTranslation } from "react-i18next";

const convertMarkdownToHtml = async (
  markdownContent: string
): Promise<string> => {
  return marked.parse(markdownContent || "");
};

export interface ChattingProps {
  name: string;
  image: string;
  text: string;
  date: string;
  chattingUser: string;
}

export function Balloon({
  name,
  image,
  text,
  date,
  chattingUser,
}: ChattingProps) {
  const { t } = useTranslation();
  const [chatType] = useAtom(chattingTypeAtom);
  const [searchValue] = useAtom(searchInputValueAtom);
  const [noticeModal, setNoticeModal] = useAtom(noticeModalAtom);
  const [previousCrew] = useAtom(previousCrewAtom);

  const setCreateTaskDialog = useSetAtom(createTaskDialogAtom);

  const [tooltipOpen, setTooltipOpen] = useState(false);

  const navigate = useNavigate();

  const handleCopy = async () => {
    const htmlContent = await convertMarkdownToHtml(text);
    navigator.clipboard.writeText(text).then(() => {
      setNoticeModal({
        event: () => {
          navigate(
            `/crew?crew_id=${previousCrew?.crew_id}&inner_tab_id=${previousCrew?.inner_tab_id}`
          );

          setNoticeModal((current) => ({ ...current, state: false }));

          setCreateTaskDialog((prev) => ({
            ...prev,
            open: `task-${uuidv4()}`,
            isCrew: true,
            content: htmlContent,
          }));
        },
        eventText: t("makeTextHelper.balloon.goToCrew.eventText"),
        redirectUrl: "",
        state: true,
        text: t("makeTextHelper.balloon.goToCrew.text"),
        title: t("makeTextHelper.balloon.goToCrew.title"),
      });
    });
  };

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: LottieData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  // 검색어 하이라이트 처리 함수
  const highlightText = (children: any) => {
    if (!searchValue || chatType === ChatType.ASK) return children;

    // React.Children.map을 사용하여 children을 순회
    return React.Children.map(children, (child) => {
      // 문자열인지 확인 (텍스트 노드만 처리)
      if (typeof child === "string") {
        const regex = new RegExp(`(${searchValue})`, "gi");
        return child.split(regex).map((part, index) =>
          regex.test(part) ? (
            <span
              key={`${index}-highlight-search-field`}
              className="highlight-search-field"
              style={{ backgroundColor: "yellow", color: "black" }}
            >
              {part}
            </span>
          ) : (
            part
          )
        );
      }
      // 문자열이 아닌 경우 (JSX 요소) 그대로 반환
      return child;
    });
  };

  if (chattingUser === "chatbot") {
    return (
      <Stack
        // direction={"row"}
        sx={{
          gap: "10px",
          width: "100%",
          flexDirection: "row" /* 모바일 */,
          "& .only-in-mobile-view": {
            display: "none",
          },
          "& .only-in-web-view": {
            display: "block",
          },

          "@media (max-width: 768px)": {
            flexDirection: "column",
            "& .only-in-web-view": {
              display: "none",
            },
            "& .only-in-mobile-view": {
              display: "block",
            },
          },
        }}
      >
        <Avatar className="only-in-web-view" src={image} />

        <Stack direction={"row"} gap={"10px"} sx={{ alignItems: "center" }}>
          <Avatar className="only-in-mobile-view" src={image} />
          <Typography className="only-in-mobile-view">{name}</Typography>
        </Stack>
        <Stack
          sx={{
            gap: "5px",
            maxWidth: "70%",
            "@media (max-width: 1024px)": {
              maxWidth: "85%",
            },

            /* 모바일 */
            "@media (max-width: 768px)": {
              maxWidth: "100%",
            },
          }}
        >
          <Typography className="only-in-web-view">{name}</Typography>
          <Box
            sx={{
              background: "#ececec",
              borderRadius: "5px",
              padding: "15px",

              "@media (max-width: 1024px)": {
                padding: "7px",
              },

              /* 모바일 */
              "@media (max-width: 768px)": {
                padding: "3px",
              },
            }}
          >
            {text !== "" ? (
              <ReactMarkdown
                remarkPlugins={[remarkGfm]}
                components={{
                  p: ({ node, children }) => (
                    <p
                      style={{
                        margin: "10px",
                        wordBreak: "break-word",
                        overflowWrap: "break-word",
                      }}
                    >
                      {highlightText(children)}
                    </p>
                  ),
                  h1: ({ node, children }) => (
                    <h1
                      style={{
                        margin: "10px",
                        wordBreak: "break-word",
                        overflowWrap: "break-word",
                      }}
                    >
                      {highlightText(children)}
                    </h1>
                  ),
                  h2: ({ node, children }) => (
                    <h2
                      style={{
                        margin: "10px",
                        wordBreak: "break-word",
                        overflowWrap: "break-word",
                      }}
                    >
                      {highlightText(children)}
                    </h2>
                  ),
                  h3: ({ node, children }) => (
                    <h3
                      style={{
                        margin: "10px",
                        wordBreak: "break-word",
                        overflowWrap: "break-word",
                      }}
                    >
                      {highlightText(children)}
                    </h3>
                  ),
                  h4: ({ node, children }) => (
                    <h4
                      style={{
                        margin: "10px",
                        wordBreak: "break-word",
                        overflowWrap: "break-word",
                      }}
                    >
                      {highlightText(children)}
                    </h4>
                  ),
                  h5: ({ node, children }) => (
                    <h5
                      style={{
                        margin: "10px",
                        wordBreak: "break-word",
                        overflowWrap: "break-word",
                      }}
                    >
                      {highlightText(children)}
                    </h5>
                  ),
                  h6: ({ node, children }) => (
                    <h6
                      style={{
                        margin: "10px",
                        wordBreak: "break-word",
                        overflowWrap: "break-word",
                      }}
                    >
                      {highlightText(children)}
                    </h6>
                  ),
                  ul: ({ node, children }) => (
                    <ul
                      style={{
                        margin: "10px",
                        wordBreak: "break-word",
                        overflowWrap: "break-word",
                      }}
                    >
                      {highlightText(children)}
                    </ul>
                  ),
                  ol: ({ node, children }) => (
                    <ol
                      style={{
                        margin: "10px",
                        wordBreak: "break-word",
                        overflowWrap: "break-word",
                      }}
                    >
                      {highlightText(children)}
                    </ol>
                  ),
                  li: ({ node, children }) => (
                    <li
                      style={{
                        margin: "10px",
                        wordBreak: "break-word",
                        overflowWrap: "break-word",
                      }}
                    >
                      {highlightText(children)}
                    </li>
                  ),
                  table: ({ node, children }) => (
                    <table
                      style={{
                        width: "100%",
                        borderCollapse: "collapse",
                        margin: "20px 0",
                        backgroundColor: "#fff", // 바탕색 하얀색 설정
                        wordBreak: "break-word",
                        overflowWrap: "break-word",
                      }}
                    >
                      {highlightText(children)}
                    </table>
                  ),
                  tbody: ({ node, children }) => (
                    <tbody>{highlightText(children)}</tbody>
                  ),
                  tr: ({ node, children }) => (
                    <tr style={{ borderBottom: "1px solid #ddd" }}>
                      {highlightText(children)}
                    </tr>
                  ),
                  th: ({ node, children }) => (
                    <th
                      style={{
                        backgroundColor: "#f2f2f2",
                        textAlign: "left",
                        borderBottom: "2px solid #ddd",
                        wordBreak: "break-word",
                        overflowWrap: "break-word",
                      }}
                    >
                      {highlightText(children)}
                    </th>
                  ),
                  td: ({ node, children }) => (
                    <td
                      style={{
                        padding: "10px",
                        borderBottom: "1px solid #ddd",
                        backgroundColor: "#fff", // 바탕색 하얀색 설정
                        wordBreak: "break-word",
                        overflowWrap: "break-word",
                      }}
                    >
                      {highlightText(children)}
                    </td>
                  ),
                  blockquote: ({ node, children }) => (
                    <blockquote
                      style={{
                        width: "100%",
                        margin: "10px",
                        wordBreak: "break-word",
                        overflowWrap: "break-word",
                      }}
                    >
                      {highlightText(children)}
                    </blockquote>
                  ),
                  code: ({ node, children }) => (
                    <code
                      style={{
                        // overflowX: "auto",
                        margin: "10px",
                        wordBreak: "break-word", // 긴 단어 줄 바꿈
                        overflowWrap: "break-word", // 줄 바꿈 허용
                      }}
                    >
                      {highlightText(children)}
                    </code>
                  ),
                  pre: ({ node, children }) => (
                    <pre
                      style={{
                        margin: "10px",
                        wordBreak: "break-word", // 줄 바꿈
                        overflowX: "auto", // 가로 스크롤 허용
                        whiteSpace: "pre-wrap", // 줄 바꿈 처리
                        overflowWrap: "break-word", // 줄 바꿈 허용
                      }}
                    >
                      {highlightText(children)}
                    </pre>
                  ),
                  em: ({ node, children }) => (
                    <em
                      style={{
                        margin: "10px",
                        wordBreak: "break-word",
                        overflowWrap: "break-word",
                      }}
                    >
                      {highlightText(children)}
                    </em>
                  ),
                  strong: ({ node, children }) => (
                    <strong
                      style={{
                        margin: "10px",
                        wordBreak: "break-word",
                        overflowWrap: "break-word",
                      }}
                    >
                      {highlightText(children)}
                    </strong>
                  ),
                  a: ({ node, href, children }) => (
                    <a
                      href={href}
                      target="_blank" // 새 창에서 열기
                      rel="noopener noreferrer" // 보안 관련 속성 추가
                      style={{
                        color: "#1a0dab",
                        textDecoration: "underline",
                        wordBreak: "break-word",
                        overflowWrap: "break-word",
                      }}
                    >
                      {highlightText(children)}
                    </a>
                  ),
                  img: ({ node, src, alt }) => (
                    <img
                      src={src}
                      alt={alt}
                      style={{
                        maxWidth: "100%", // 이미지가 부모 요소 너비를 넘지 않도록
                        border: "2px solid #ddd", // 얇은 테두리 추가
                        borderRadius: "8px", // 모서리를 둥글게
                        padding: "5px", // 이미지와 테두리 사이에 약간의 패딩 추가
                        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)", // 가벼운 그림자 추가
                        margin: "20px 0", // 상하 여백 추가
                      }}
                    />
                  ),
                }}
              >
                {text}
              </ReactMarkdown>
            ) : (
              <Lottie
                options={defaultOptions}
                height={40}
                width={100}
                isClickToPauseDisabled={true}
              />
            )}
          </Box>
          <Stack
            direction={"row"}
            sx={{ width: "100%", justifyContent: "space-between" }}
          >
            <Typography fontSize={"12px"} color={"#8f8f8f"}>
              {date}
            </Typography>
            {/* 툴팁이 있는 아이콘 버튼 */}
            <Tooltip
              title="복사 성공!" // 툴팁 내용
              open={tooltipOpen} // 상태에 따라 툴팁을 표시
              disableFocusListener
              disableHoverListener
              disableTouchListener
              placement="top"
            >
              <IconButton onClick={handleCopy}>
                <CopyAll />
              </IconButton>
            </Tooltip>
          </Stack>
        </Stack>
      </Stack>
    );
  }

  // user 채팅
  return (
    <Stack
      direction={"row"}
      sx={{ gap: "0.9375rem", width: "100%", justifyContent: "end" }}
    >
      <Stack
        sx={{
          gap: "0.1875rem",
          textAlign: "end",
          maxWidth: "60%",
          display: "inline-block",
        }}
      >
        <Box
          sx={{
            background: "#06C25E",
            borderRadius: "0.3125rem",
            padding: "0.9375rem",
            color: "white",
            display: "inline-block",
          }}
        >
          {text.split("\n").map((line, index) => (
            <span key={index}>
              {highlightText(line)}
              <br />
            </span>
          ))}
        </Box>
        <Typography fontSize={"0.8rem"} color={"#8f8f8f"}>
          {date}
        </Typography>
      </Stack>
    </Stack>
  );
}
