import { selectedNodeAtom } from '@/Atoms/Plan/MindmapAtom';
import getRandomColorWithText from '@/Utils/GetRandomColor';
import { atom } from 'jotai';
import { v4 as uuidv4 } from 'uuid';
import { debouncedUpdateTaskAtom, initializeNewData, onEdgesChangeAtom, onNodesChangeAtom } from '../FlowViewModel';
import { debounceUpdatePlanDataMindmapAtom, getPlanDataMindmapAtom, setInnerTabDataAtom } from '../../InnerTabViewModel';
import GetIdFromQuerystring from '@/Utils/GetIdFromQuerystring';
import { handleReactQueryApiResponse } from '@/Utils/APIUtil';
import { createTask, deleteNodesAndEdges } from '@/Queries/PlanQueries';
import { error401ModalAtom } from '@/Atoms/Dialogs/Error/401Atom';
import { getUserId } from '@/ViewModels/UserViewModel';
import { Edge } from '@xyflow/react';
import innerTabDataAtom from '@/Atoms/Plan/InnerTabDataAtom';
import snackbarAtom from '@/Atoms/Snackbar';

export const initializeGroupNodeAtom = (userId: number, position: { x: number, y: number }) => {
  const id = uuidv4();
  const { backgroundColor, } = getRandomColorWithText();
  return {
    id: `group-${id}`,
    type: "group",
    position: position,
    data: initializeNewData(userId, `group-${id}`, "그룹명을 입력해주세요."),
    style: {
      background: backgroundColor + "33",
      border: `2px solid ${backgroundColor}`,
      padding: "10px",
      borderRadius: "5px",
      width: 600,
      height: 400,
      display: "flex",
      fontSize: "1.2rem",
    },
    measured: {
      width: 600,
      height: 400,
    }
  }
}

// Group 생성 메서드
export const createGroupNodeAtom = atom(null, async (get, set, position: { x: number, y: number }) => {
  const planData = get(innerTabDataAtom);
  const innerTabId = GetIdFromQuerystring("inner_tab_id");
  const plan = planData.find((planData) => planData.innerTabId === innerTabId);
  if (!plan) return;
  const groupNode = initializeGroupNodeAtom(get(getUserId), position);
  const nodes = get(onNodesChangeAtom);
  try {
    const response = await handleReactQueryApiResponse(createTask, () => set(error401ModalAtom, true), innerTabId, groupNode);
    if (!response.ok) {
      set(snackbarAtom, (prev) => ({ ...prev, open: true, message: "생성에 실패했습니다.", severity: "error" }));
      return;
    }

    const responseJson = await response.json();
    const updateNewNode = {
      ...groupNode,
      data: {
        ...groupNode.data,
        backendId: responseJson.backendId,
      },
    }

    const newNodes = [updateNewNode, ...nodes];
    set(setInnerTabDataAtom, { innerTabId, nodes: newNodes, edges: plan.edges });
  } catch (error) {
    set(snackbarAtom, (prev) => ({ ...prev, open: true, message: "생성에 실패했습니다.", severity: "error" }));
  }
});

// Group 해제 메서드
export const detachNodeInGroupAtom = atom(null, (get, set, id: string) => {
  const detachNode = get(onNodesChangeAtom).find((node) => node.id === id);
  if (!detachNode || !detachNode.parentId) return;
  const group = get(onNodesChangeAtom).find((node) => node.id === detachNode.parentId);
  if (!group) return;

  // 그룹 노드의 위치와 크기 정보를 가져옴
  const groupPosition = group.position;
  const groupMeasure = group.measured;
  if (!groupMeasure || !groupMeasure.width || !groupMeasure.height) return;

  // 노드의 새로운 위치를 계산 (그룹 노드의 위치 + 크기 + 30px)
  const newPosition = { x: groupPosition.x + groupMeasure.width + 30, y: groupPosition.y };

  const newNodes = get(onNodesChangeAtom).map((node) => node.id === id ? { ...node, parentId: undefined, position: newPosition } : node);

  debounceUpdatePlanDataMindmapAtom(set, GetIdFromQuerystring("inner_tab_id"), newNodes, get(onEdgesChangeAtom));
})

// Group label 변경 메서드
export const changeGroupLabelAtom = atom(null, (get, set, id: string, label: string) => {
  const nodes = get(onNodesChangeAtom);
  const node = nodes.find((node) => node.id === id);
  if (!node) return;
  const newNode = { ...node, data: { ...node.data, label } };
  const newNodes = nodes.map((node) => node.id === id ? newNode : node);
  const innerTabId = GetIdFromQuerystring("inner_tab_id");

  set(setInnerTabDataAtom, { innerTabId, nodes: newNodes, edges: get(onEdgesChangeAtom) });
  debouncedUpdateTaskAtom(get, set, newNode);
});

// Group 삭제 메서드
export const deleteGroupNodeAtom = atom(null, async (get, set, id: string) => {
  const groupNode = get(onNodesChangeAtom).find((node) => node.id === id);
  const innerTabId = GetIdFromQuerystring("inner_tab_id");
  if (!groupNode) return;
  const childNodes = get(onNodesChangeAtom).filter((node) => node.parentId === groupNode.id);
  const connectedChildEdges = get(onEdgesChangeAtom).filter((edge: Edge) => childNodes.find((node) => node.id === edge.source || node.id === edge.target)).map((edge: Edge) => edge.data?.backendId as number);
  const deleteNodes = [groupNode, ...childNodes].map((node) => node.data.backendId as number);
  await handleReactQueryApiResponse(deleteNodesAndEdges, () => set(error401ModalAtom, true), deleteNodes, connectedChildEdges, innerTabId, get(getUserId));
  set(selectedNodeAtom, null);
  set(getPlanDataMindmapAtom);
});
