import { atom } from 'jotai';
import { v4 as uuidv4 } from 'uuid';

export interface SignatureInfo {
  key: string;
  label: string;
  value: string;
  orderBy: number;
  visible: boolean;
}

export interface Signature {
  id: number;
  user_id: number;
  representative: boolean; // 대표 시그니쳐 여부
  careerAdvancement: boolean; // 진로진학 시그니쳐 여부
  name: string; // 시그니쳐 이름
  nickname: string; // 사용자 닉네임
  tag: string; // 시그니쳐 태그
  email: string; // 사용자 이메일
  isUserProfile: boolean; // 프로필 이미지 여부
  image: string | null;
  info: SignatureInfo[];
  borderColor: string;
  backgroundColor: string;
  fontColor: string;
}

export interface CreateSignature {
  id: number;
  representative: boolean; // 대표 시그니쳐 여부
  careerAdvancement: boolean; // 진로진학 시그니쳐 여부
  name: string; // 시그니쳐 이름
  nickname: string; // 사용자 닉네임
  tag: string; // 시그니쳐 태그
  email: string; // 사용자 이메일
  isUserProfile: boolean; // 프로필 이미지 여부
  isUploadImage: boolean;
  previewImage: string | null;
  image: File | null;
  info: SignatureInfo[];
  orderList: SignatureInfo[];
  borderColor: string;
  backgroundColor: string;
  fontColor: string;
}

const createSignatureInfo = (label: string): SignatureInfo => {
  return {
    key: uuidv4(),
    label,
    value: "",
    orderBy: 0,
    visible: true,
  }
}

export const initialCreateSignature = (nickname: string, email: string, language: string): CreateSignature => {
  const info: SignatureInfo[] = [
    createSignatureInfo(language === "KR" ? "목표성적" : "Target Grade"),
    createSignatureInfo(language === "KR" ? "선호직업" : "Preferred Job"),
    createSignatureInfo(language === "KR" ? "관심사" : "Interest"),
    createSignatureInfo(language === "KR" ? "직업명" : "Job"),
    createSignatureInfo(language === "KR" ? "학과명" : "Major"),
  ].map((info, index) => ({
    ...info,
    orderBy: index,
  }));
  const orderList = [...info];

  return {
    id: 0,
    representative: false,
    careerAdvancement: false,
    name: language === "KR" ? "새 시그니쳐" : "New Signature",
    nickname: nickname,
    tag: "",
    email: email,
    isUserProfile: true,
    previewImage: null,
    isUploadImage: false,
    image: null,
    info: info,
    orderList: orderList,
    borderColor: "#c0c0c0",
    backgroundColor: "#FFFFFF",
    fontColor: "#000000",
  }
};

interface SignatureDialog {
  open: boolean;
  mode: "list" | "create" | "edit" | "style";
}

export const signatureDialogAtom = atom<SignatureDialog>({
  open: false,
  mode: "list",
});

export const createSignatureAtom = atom<CreateSignature | null>(null);

export const createSignatureMobileModeAtom = atom<"edit" | "style">("edit");

export const notSavedSignatureDialogAtom = atom<boolean>(false);

export const signatureAtom = atom<Signature[]>([]);

export const signatureCurrentIndexAtom = atom<number>(-1);
