import React from 'react';
import { CircularProgress } from '@mui/material';
import { useTranslation } from 'react-i18next';

const LoadingContent = () => {
  const { t } = useTranslation();
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
        justifyContent: "center",
        alignItems: "center",
        gap: "50px",
      }}
    >
      <div style={{ fontSize: "0.9rem", fontWeight: "bold" }}>
        {t("dashboard.mentee.loading")}
      </div>
      <CircularProgress />
    </div>
  )
}

export default LoadingContent;
