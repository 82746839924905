import { EditCrewModal, editCrewModalAtom, editCrewModalCheckNameStatusAtom } from '@/Atoms/Crew';
import crewAtom from '@/Atoms/CrewAtom';
import { error401ModalAtom } from '@/Atoms/Dialogs/Error/401Atom';
import snackbarAtom from '@/Atoms/Snackbar';
import { checkCrewName, updateCrew } from '@/Queries/CrewQueries';
import { handleReactQueryApiResponse } from '@/Utils/APIUtil';
import { devConsoleError } from '@/Utils/ConsoleLogInDevelopment';
import GetIdFromQuerystring from '@/Utils/GetIdFromQuerystring';
import { TFunction } from 'i18next';
import { atom } from 'jotai';

export const editCrewModalOpenAtom = atom(null, (get, set) => {
  const crew = get(crewAtom);
  if (!crew) return;
  const editCrew = {
    name: crew.crewInfo.name,
    content: crew.crewInfo.content,
    headCount: crew.crewInfo.headCount,
    hashtags: crew.crewInfo.hashtags || [],
    imageUrl: crew.crewInfo.imageUrl,
    color: crew.crewInfo.color,
    category: crew.crewInfo.category,
    isPublic: crew.crewInfo.isPublic,
  }
  set(editCrewModalAtom, editCrew);
});

export const editCrewModalCloseAtom = atom(null, (get, set) => {
  set(editCrewModalAtom, null);
});

export const editCrewModalUpdateStateAtom = atom(null, (get, set, key: keyof EditCrewModal, value: any) => {
  if (key === "name") {
    set(editCrewModalCheckNameStatusAtom, "invalid");
  }
  set(editCrewModalAtom, (prev) => (prev === null) ? null : {
    ...prev,
    [key]: value,
  });
});

const nameValidationRegex = /^[a-zA-Z0-9가-힣]+(?:\s[a-zA-Z0-9가-힣]+)*$/;

export const editCrewModalCheckDuplicationAtom = atom(null, async (get, set) => {
  const handleError401Modal = () => set(error401ModalAtom, true);

  // Check Duplication - "loading" 상태로 설정
  set(editCrewModalCheckNameStatusAtom, "loading");

  const name = get(editCrewModalAtom)?.name;
  if (!name) {
    set(editCrewModalCheckNameStatusAtom, "fail");
    return;
  }

  // 이름 유효성 검사
  if (!nameValidationRegex.test(name)) {
    set(editCrewModalCheckNameStatusAtom, "invalid");
    return;
  }

  // 새로운 setTimeout을 설정하여 마지막 요청만 처리
  try {
    const response = await handleReactQueryApiResponse(checkCrewName, handleError401Modal, name); // API 호출
    // 결과에 따라 상태 변경
    set(editCrewModalCheckNameStatusAtom, response.status === 204 ? "success" : "fail");
  } catch (error) {
    devConsoleError("중복 체크 실패:", error);
    set(editCrewModalCheckNameStatusAtom, "fail"); // 에러 발생 시 "fail" 상태로 설정
  }
});

export const editCrewModalUpdateCrewAtom = atom(null, async (get, set, t: TFunction) => {
  const editCrew = get(editCrewModalAtom);
  if (!editCrew) return;

  const crew = {
    name: editCrew.name,
    content: editCrew.content,
    headcount: editCrew.headCount,
    hashtags: editCrew.hashtags,
    color: editCrew.color,
    category: editCrew.category,
    is_public: editCrew.isPublic,
  };

  const crewId = GetIdFromQuerystring("crew_id");

  const handleError401Modal = () => set(error401ModalAtom, true);

  try {
    const response = await handleReactQueryApiResponse(updateCrew, handleError401Modal, crewId, crew); // API 호출
    if (response.status !== 200) {
      set(snackbarAtom, { open: true, message: t("viewModel.snackbar.editCrewModalUpdateCrew.editFail"), severity: "error" });
    } else {
      set(snackbarAtom, { open: true, message: t("viewModel.snackbar.editCrewModalUpdateCrew.editSuccess"), severity: "success" });

      const currentCrew = get(crewAtom);
      if (currentCrew) {
        set(crewAtom, {
          ...currentCrew,
          crewInfo: {
            ...currentCrew.crewInfo,
            isPublic: crew.is_public,
            name: crew.name,
            content: crew.content,
            headCount: crew.headcount,
            hashtags: crew.hashtags,
            color: crew.color,
            category: crew.category
          },
        });
      }
      set(editCrewModalCloseAtom);
    }
  } catch (error) {
    devConsoleError("크루 수정 실패:", error);
    set(snackbarAtom, { open: true, message: t("viewModel.snackbar.editCrewModalUpdateCrew.editFail"), severity: "error" });
  }
});
