import { RecommendPlanItem, recommendPlanListAtom, recommendPlanSearchInputListAtom } from '@/Atoms/RecommendPlan/Atom';
import { atom } from 'jotai';

export const getRecommendPlanListAtom = atom((get) => {
  const planList = get(recommendPlanListAtom);
  const filterList = get(recommendPlanSearchInputListAtom);
  if (!filterList.length) return planList;

  return planList.filter((plan) =>
    filterList.every((filter) => plan.planName.includes(filter))
  );
}, (get, set, update: RecommendPlanItem[]) => {
  set(recommendPlanListAtom, update);
});
