import React from 'react';
import * as S from './styled';
import * as C from './components';
import { Gantt, Task, ViewMode } from 'gantt-task-react';
import { useAtomValue, useSetAtom } from 'jotai';
import { ganttChartDoubleClickAtom } from '@/ViewModels/Plan/GanttChartViewModel';
import "gantt-task-react/dist/index.css";
import { useTranslation } from 'react-i18next';
import { devConsoleLog } from '@/Utils/ConsoleLogInDevelopment';
import { ganttExpandListAtom, routineDateModeAtom } from '@/Atoms/Plan/RoutineAtom';
import dayjs from 'dayjs';
import { ganttChartBarClickAtom, ganttChartExpanderClickAtom } from '@/ViewModels/Plan/Routine/GanttChartViewModel';
import { getGanttChartDataAtom } from '@/ViewModels/Plan/Routine/DataViewModel';
import { userLanguageAtom } from '@/ViewModels/UserViewModel';
import { DndContext, DragEndEvent, Modifier, closestCenter } from "@dnd-kit/core";
import { SortableContext, arrayMove, verticalListSortingStrategy } from "@dnd-kit/sortable";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";

const GanttChart = () => {
  const userLanguage = useAtomValue(userLanguageAtom);
  const [height, setHeight] = React.useState<number>(window.innerHeight - 250);
  const expandList = useAtomValue(ganttExpandListAtom);
  const dateMode = useAtomValue(routineDateModeAtom);
  const doubleClick = useSetAtom(ganttChartDoubleClickAtom);
  const { t } = useTranslation();
  const tasks = useAtomValue(getGanttChartDataAtom);
  const [orderTasks, setOrderTasks] = React.useState<Task[]>(tasks);
  const barClick = useSetAtom(ganttChartBarClickAtom);
  const expanderClick = useSetAtom(ganttChartExpanderClickAtom);

  const handleProgressChange = async (task: Task) => {
    // setTasks(tasks.map(t => (t.id === task.id ? task : t)));
    devConsoleLog("On progress change Id:" + task.id);
  };

  const handleClick = (task: Task) => {
    barClick(task.id);
  };

  const handleSelect = (task: Task, isSelected: boolean) => {
    devConsoleLog(task.name + " has " + (isSelected ? "selected" : "unselected"));
  };

  const formatDate = (date: string): string => {
    return dayjs(date).format(dateMode === 'Month' ? 'YYYY년 MM월' : dateMode === 'Week' ? 'YYYY년 MM월 DD일' : 'MM월 DD일');
  };

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;

    if (!over || active.id === over.id) return;
    const oldIndex = orderTasks.findIndex((task) => task.id === active.id);
    const newIndex = orderTasks.findIndex((task) => task.id === over.id);

    const activeIds = (active.id as string).split("|");
    const overIds = (over.id as string).split("|");

    if (activeIds.length === overIds.length) {
      if (activeIds.length === 1) {
        // active 루틴이 over 루틴 다음으로 이동
      }
      const newTasks = arrayMove(orderTasks, oldIndex, newIndex);
      setOrderTasks(newTasks);
    }
  };

  React.useEffect(() => {
    const handleResize = () => {
      setHeight(window.innerHeight - 250);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  React.useEffect(() => {
    setOrderTasks(tasks);
  }, [tasks]);

  return (
    <S.Container>
      {
        orderTasks.length === 0 ?
          <div>{t("plan.contents.gantt.noData")}</div> :
          <DndContext collisionDetection={closestCenter} autoScroll={false} onDragEnd={handleDragEnd}>
            <Gantt
              tasks={orderTasks}
              viewMode={dateMode === "Month" ? ViewMode.Month : dateMode === "Week" ? ViewMode.Week : ViewMode.Day}
              // onDateChange={taskChange}
              onDelete={() => { }}
              // onProgressChange={handleProgressChange}
              onDoubleClick={() => { }}
              onClick={handleClick}
              // onSelect={handleSelect}
              onExpanderClick={expanderClick}
              TaskListHeader={C.TaskListHeader}
              TaskListTable={C.CustomListTable}
              // TooltipContent={C.StandardTooltipContent}
              TooltipContent={() => null}
              listCellWidth={expandList ? "100px" : ""}
              columnWidth={70}
              handleWidth={10}
              locale={userLanguage === "KR" ? "ko" : "en"}
            // ganttHeight={height}
            />
          </DndContext>
      }
    </S.Container>
  )
}

export default GanttChart;
