import React from "react";
import { Chip, Divider, Grid } from "@mui/material";
import { useMediaQuery } from "@mui/material";
import { useAtom, useAtomValue, useSetAtom } from "jotai";
import { dashboardMenteePlanTemplatesAtom, dashboardMenteePlanTemplatesExpandedAtom } from "@/Atoms/Dashboard/MenteeAtom";
import * as S from "./styled";
import { recommendPlanModalOpenAtom } from "@/ViewModels/RecommendPlan/PlanInfoModalViewModel";
import { KeyboardArrowDown, KeyboardArrowLeft, KeyboardArrowRight, KeyboardArrowUp } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useRecommendPlanAtom } from "@/Atoms/HookControllerAtom";

const ResponsiveGrid = () => {
  const isXl = useMediaQuery("(min-width:1200px)");
  const isLg = useMediaQuery("(min-width:992px) and (max-width:1199px)");
  const isMd = useMediaQuery("(min-width:768px) and (max-width:991px)");
  const isSm = useMediaQuery("(max-width:767px) and (min-width:600px)");
  const isXs = useMediaQuery("(max-width:599px)");
  const { t } = useTranslation();
  const [itemsPerRow, setItemsPerRow] = React.useState(0);
  const planTemplates = useAtomValue(dashboardMenteePlanTemplatesAtom);
  const openModal = useSetAtom(recommendPlanModalOpenAtom);
  const [expanded, setExpanded] = useAtom(dashboardMenteePlanTemplatesExpandedAtom);
  const [currentPage, setCurrentPage] = React.useState(1);
  const navigate = useNavigate();
  const setController = useSetAtom(useRecommendPlanAtom);

  const handleClickMore = () => {
    setController(true);
    navigate('/recommend-plan');
  }

  // 페이지 수 계산
  const totalPages = React.useMemo(
    () => Math.ceil(planTemplates.length / itemsPerRow),
    [planTemplates, itemsPerRow]
  );

  // 현재 페이지의 데이터 계산
  const items = React.useMemo(() => {
    const startIndex = (currentPage - 1) * itemsPerRow;
    const endIndex = Math.min(startIndex + itemsPerRow, planTemplates.length);
    return planTemplates.slice(startIndex, endIndex);
  }, [planTemplates, itemsPerRow, currentPage]);

  // 반응형 항목 수 설정
  React.useEffect(() => {
    if (isXl) {
      setItemsPerRow(7);
      if (currentPage > totalPages) setCurrentPage(1);
    } else if (isLg) {
      setItemsPerRow(5);
      if (currentPage > totalPages) setCurrentPage(1);
    } else if (isMd) {
      setItemsPerRow(3);
      if (currentPage > totalPages) setCurrentPage(1);
    } else if (isSm) {
      setItemsPerRow(2);
      if (currentPage > totalPages) setCurrentPage(1);
    } else if (isXs) {
      setItemsPerRow(1);
      if (currentPage > totalPages) setCurrentPage(1);
    }
  }, [isXl, isLg, isMd, isSm, isXs, currentPage, totalPages]);

  return (
    <S.Container>
      <S.Header>
        <div style={{ cursor: "pointer" }} onClick={() => setExpanded((prev) => ({ ...prev, expanded: !prev.expanded }))}>
          {t("dashboard.mentee.planTemplate.title")}
        </div>
        <div style={{ display: "flex", flexDirection: "row", gap: "10px", alignItems: "center" }}>
          <S.MoreButton onClick={handleClickMore}>{t("dashboard.mentee.planTemplate.more")}</S.MoreButton>
          {expanded.expanded ? (
            <KeyboardArrowUp
              sx={{ width: "1.2em", height: "1.2em", color: "black", cursor: "pointer" }}
              onClick={() => setExpanded((prev) => ({ ...prev, expanded: false }))}
            />
          ) : (
            <KeyboardArrowDown
              sx={{ width: "1.2em", height: "1.2em", color: "black", cursor: "pointer" }}
              onClick={() => setExpanded((prev) => ({ ...prev, expanded: true }))}
            />
          )}
        </div>
      </S.Header>
      {expanded.expanded && (
        <>
          <Divider />
          <div style={{ fontSize: "14px", color: "#c0c0c0", marginBottom: "-10px" }}>{t("dashboard.mentee.planTemplate.description")}</div>
          <Grid
            container
            spacing={0}
            sx={{ width: "100%", minHeight: "200px" }}
            justifyContent="center"
            display="flex"
            position="relative"
            gap={2}
          >
            {items.map((item, index) => {
              const isImageUrl = item.imageUrl !== null;
              const imageUrl = item.imageUrl ? `${item.imageUrl.split("/media")[1]}` : "https://media.istockphoto.com/id/1054315098/ko/%EB%B2%A1%ED%84%B0/%EA%B0%84%EB%8B%A8%ED%95%9C-%EB%B8%94%EB%9E%99-%EC%86%90%EC%9C%BC%EB%A1%9C-%EA%B7%B8%EB%A6%B0%EB%90%9C-%EB%A7%88%EC%9D%B8%EB%93%9C-%EB%A7%B5.jpg?s=612x612&w=0&k=20&c=d0QonRWfSSL2jL2nWzHpTxxyUWDoY_X4_btGuSO2BzY=";
              const isProfileImage = imageUrl.includes("/profile_images");
              const finalImageUrl = !isProfileImage ? imageUrl :
                `${!isProfileImage ? process.env.REACT_APP_CAREER_PLANNER_PLAN_MEDIA_ADDRESS : process.env.REACT_APP_USER_API_ADDRESS}${imageUrl}`

              return (
                <Grid
                  item
                  xs={12 / (itemsPerRow + (itemsPerRow === 1 ? 0 : 1))}
                  key={item.planId}
                  sx={S.GridItem}
                  onClick={() => openModal(item)}
                >
                  <S.Image
                    src={item.imageUrl ? `${process.env.REACT_APP_CAREER_PLANNER_PLAN_MEDIA_ADDRESS}${item.imageUrl.split("/media")[1]}` : "https://media.istockphoto.com/id/1054315098/ko/%EB%B2%A1%ED%84%B0/%EA%B0%84%EB%8B%A8%ED%95%9C-%EB%B8%94%EB%9E%99-%EC%86%90%EC%9C%BC%EB%A1%9C-%EA%B7%B8%EB%A6%B0%EB%90%9C-%EB%A7%88%EC%9D%B8%EB%93%9C-%EB%A7%B5.jpg?s=612x612&w=0&k=20&c=d0QonRWfSSL2jL2nWzHpTxxyUWDoY_X4_btGuSO2BzY="}
                    // src={finalImageUrl}
                    alt="image"
                  />
                  <div style={{ textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap", width: "100%", textAlign: "center", fontSize: "14px", fontWeight: "bold" }}>
                    {item.planName}
                  </div>
                  {
                    item.hashtag.length > 0 &&
                    <div style={{ display: "flex", flexDirection: "row", gap: "5px", flexWrap: "wrap", width: "100%", justifyContent: "center" }}>
                      {item.hashtag.slice(0, 1).map((tag, index) => (
                        <Chip key={index} label={tag.value} size="small" sx={{ backgroundColor: tag.backgroundColor, color: tag.textColor }} />
                      ))}
                    </div>
                  }
                </Grid>
              )
            })}

            {/* 페이지 네비게이션 버튼 */}
            {itemsPerRow !== 7 && (
              <>
                <KeyboardArrowLeft
                  sx={{
                    width: "1.5em",
                    height: "1.5em",
                    color: currentPage === 1 ? "gray" : "black",
                    cursor: currentPage === 1 ? "default" : "pointer",
                    position: "absolute",
                    top: "50%",
                    left: "-25px",
                    transform: "translateY(-50%)",
                  }}
                  onClick={() => {
                    if (currentPage > 1) setCurrentPage((prev) => prev - 1);
                  }}
                />
                <KeyboardArrowRight
                  sx={{
                    width: "1.5em",
                    height: "1.5em",
                    color: currentPage === totalPages ? "gray" : "black",
                    cursor: currentPage === totalPages ? "default" : "pointer",
                    position: "absolute",
                    top: "50%",
                    right: "-25px",
                    transform: "translateY(-50%)",
                  }}
                  onClick={() => {
                    if (currentPage < totalPages) setCurrentPage((prev) => prev + 1);
                  }}
                />
              </>
            )}
          </Grid>
        </>
      )}
    </S.Container>
  );
};

export default ResponsiveGrid;
