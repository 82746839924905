import React from "react";
import * as S from './styled';
import { Button } from "@mui/material";
import theme from "@/Styles/theme";
import Lottie from 'react-lottie';
import LottieData from './Animation/MailAnimation.json';
import { useSetAtom } from "jotai";
import { rewriteMailAtom } from "@/ViewModels/Mail/ViewModel";
import { useNavigate } from "react-router-dom";
import useIsMobile from "@/Hooks/useIsMobile";
import CustomColorButton from "@/Components/Common/Buttons/CustomColor";

const MailComplete = () => {
  const { isMobile } = useIsMobile();
  const rewrite = useSetAtom(rewriteMailAtom);
  const navigate = useNavigate();

  return (
    <S.Container>
      <div style={{ display: "flex", justifyContent: "center", alignItems: "center", width: "200px", height: "200px", position: "relative" }}>
        <Lottie
          options={{
            loop: true,
            autoplay: true,
            animationData: LottieData,
            rendererSettings: {
              preserveAspectRatio: "xMidYMid slice",
            },
          }}
          height={400}
          width={400}
          style={{ position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)" }}
        />
      </div>
      <div style={{ fontSize: `${isMobile ? 1 : 1.5}rem`, fontWeight: "bold" }}>메일이 성공적으로 전송되었습니다.</div>
      <div style={{ display: "flex", flexDirection: "row", gap: "20px" }}>
        <CustomColorButton text="메일 쓰기" onClick={rewrite} style={{ border: `1px solid ${theme.colors.primary}` }} />
        <CustomColorButton text="메인으로" onClick={() => navigate('/')} style={{ border: `1px solid ${theme.colors.primary}` }} />
      </div>
    </S.Container>
  )
}

export default MailComplete;
