import React from "react";
import { Box, Modal, Typography, Button } from "@mui/material";
import { useAtom } from "jotai";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline"; // MUI icon
import { useNavigate } from "react-router-dom";
import { errorModalAtom } from "../../../Atoms/RootAtom";
import * as S from "./styled";
import CustomBackgroundColorButton from "@/Components/Common/Buttons/CustomBackgroundColor";

const ErrorModal: React.FC = () => {
  const [errorState, setErrorState] = useAtom(errorModalAtom);
  const navigate = useNavigate();

  const handleClose = () => {
    setErrorState((prev) => ({ ...prev, state: false }));
  };

  const handleRedirect = () => {
    navigate(errorState.redirectUrl);
    handleClose();
  };

  return (
    <Modal
      open={errorState.state}
      aria-labelledby="modal-error-title"
      aria-describedby="modal-error-description"
    >
      <S.CustomBox>
        <ErrorOutlineIcon sx={{ fontSize: 50, color: "red" }} />{" "}
        {/* Danger icon */}
        <Typography
          id="modal-error-title"
          variant="h6"
          component="h2"
          sx={{ mt: 2 }}
        >
          {errorState.title}
        </Typography>
        {errorState.text.split("\n").map((sent, index) => (
          <Typography
            key={`modal-description-error-${index}`}
            sx={{ mt: 2, fontSize: 14 }}
          >
            {sent}
          </Typography>
        ))}
        <Box sx={{ display: "flex", justifyContent: "center", mt: 3 }}>
          <CustomBackgroundColorButton onClick={handleClose} text="닫기" fontSize="0.8rem" backgroundColor="#8f8f8f" />
          {errorState.redirectUrl !== "" && (
            <CustomBackgroundColorButton onClick={handleRedirect} text="이동" fontSize="0.8rem" />
          )}
          {errorState.event !== null && (
            <CustomBackgroundColorButton onClick={errorState.event} text={errorState.eventText} fontSize="0.8rem" />
          )}
        </Box>
      </S.CustomBox>
    </Modal>
  );
};

export default ErrorModal;
