import { Edge, Node } from '@xyflow/react';
import { atom } from 'jotai';

export interface CreateRoutineDialogOpenAtomProps {
  dialogTitle: string;
  currentSelectedTask: Node | null;
  selectedRoutines: {
    key: Node;
    tasks: Node[];
    connectedEdges: Edge[];
  }[];
  selectedUnconnectedTasks: Node[];
  isLoading: boolean;
}

const createRoutineDialogAtom = atom<CreateRoutineDialogOpenAtomProps | null>(null);

export default createRoutineDialogAtom;
