import React from 'react';
import { connectedRoutinesAtom, createTaskInRoutineStateAtom, ganttExpandListAtom, initializeCreateTaskInRoutineState, RoutineDateMode, routineDateModeAtom, routineHeaderModeAtom, routineViewModeAtom } from '@/Atoms/Plan/RoutineAtom';
import { useAtom, useSetAtom } from 'jotai';
import DateNavigator from '../DateNavigator';
import { FormControl, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { Menu } from '@mui/icons-material';
import FullCalendar from '@fullcalendar/react';
import * as S from './styled';
import { pushRoutineSidebarDataAtom } from '@/ViewModels/Plan/Routine/SidebarViewModel';
import { useTranslation } from 'react-i18next';
import RoutineList from '../RoutineList';
import { ganttChartSetLongestLabelWidthAtom } from '@/ViewModels/Plan/Routine/GanttChartViewModel';

interface RoutineHeaderProps {
  calendarRef: React.RefObject<FullCalendar>;
}

const RoutineHeader = ({ calendarRef }: RoutineHeaderProps) => {
  const { t } = useTranslation();
  const [viewMode, setViewMode] = useAtom(routineViewModeAtom);
  const [dateMode, setDateMode] = useAtom(routineDateModeAtom);
  const [expandList, setExpandList] = useAtom(ganttExpandListAtom);
  const handleDateModeChange = (e: SelectChangeEvent<RoutineDateMode>) => setDateMode(e.target.value as RoutineDateMode);
  const openSidebar = useSetAtom(pushRoutineSidebarDataAtom);
  const setCreateTaskState = useSetAtom(createTaskInRoutineStateAtom);
  const setConnectedRoutines = useSetAtom(connectedRoutinesAtom);
  const [currentWidth, setCurrentWidth] = React.useState(window.innerWidth);
  const [headerMode, setHeaderMode] = useAtom(routineHeaderModeAtom);
  const findLongestLabelWidth = useSetAtom(ganttChartSetLongestLabelWidthAtom);

  const handleViewModeChange = () => {
    setDateMode(viewMode === "calendar" ? "Day" : "Month");
    setViewMode(viewMode === "calendar" ? "gantt" : "calendar");
    if (viewMode === "calendar") {
      findLongestLabelWidth();
    }
  }

  const handleCreateTask = () => {
    setCreateTaskState(initializeCreateTaskInRoutineState());
    setConnectedRoutines([]);
    openSidebar({
      type: "createTask",
      key: null,
      tasks: []
    })
  }

  React.useEffect(() => {
    const calculateWidth = () => {
      const containerElement = document.querySelector(".routine-header");
      const navigatorElement = document.querySelector(".date-navigator");
      const rightButtonsElement = document.querySelector(".right-buttons");
      if (containerElement && navigatorElement && rightButtonsElement) {
        setCurrentWidth(containerElement.clientWidth - navigatorElement.clientWidth - rightButtonsElement.clientWidth - 40);
      }
    }

    calculateWidth();
    window.addEventListener('resize', calculateWidth);
    return () => window.removeEventListener('resize', calculateWidth);
  }, []);

  React.useEffect(() => {
    const containerElement = document.querySelector(".routine-header");
    const navigatorElement = document.querySelector(".date-navigator");
    const rightButtonsElement = document.querySelector(".right-buttons");
    if (containerElement && navigatorElement && rightButtonsElement) {
      setCurrentWidth(containerElement.clientWidth - navigatorElement.clientWidth - rightButtonsElement.clientWidth - 40);
    }
  }, [viewMode]);

  React.useEffect(() => {
    if (currentWidth < 250) {
      setHeaderMode("small");
    } else {
      setHeaderMode("normal");
    }
  }, [currentWidth, setHeaderMode]);

  return (
    <S.Container $isCalendar={viewMode === "calendar"} className='routine-header'>
      <div className='date-navigator'>
        <DateNavigator calendarRef={calendarRef} />
      </div>
      {
        headerMode === "normal" &&
        <RoutineList width={currentWidth} />
      }
      <div className='right-buttons' style={{ display: "flex", flexDirection: "column", alignItems: "flex-end", justifyContent: "flex-end", gap: "10px", minWidth: viewMode === "gantt" ? "370px" : "250px" }}>
        <S.ButtonContainer>
          <S.CustomCreateTaskButton onClick={handleCreateTask} variant="contained">{t("plan.contents.routine.header.addTask")}</S.CustomCreateTaskButton>
          <Menu sx={{ width: "20px", height: "20px", cursor: "pointer" }} />
        </S.ButtonContainer>
        <S.CustomSelectContainer>
          {
            viewMode === "gantt" &&
            <>
              <S.CustomSwitch size="small" checked={expandList} onChange={() => setExpandList(!expandList)} />
              <div style={{ fontSize: "0.8rem", fontWeight: "bold" }}>{expandList ? t("plan.contents.gantt.hideList") : t("plan.contents.gantt.showList")}</div>
            </>
          }
          <FormControl size="small" sx={{ minWidth: '70px', fontWeight: 'bold' }}>
            <Select
              value={dateMode}
              onChange={handleDateModeChange}
              displayEmpty
              sx={S.CustomSelectStyle}
            >
              <MenuItem value="Month">{t("plan.contents.routine.header.month")}</MenuItem>
              <MenuItem value="Week">{t("plan.contents.routine.header.week")}</MenuItem>
              <MenuItem value="Day">{t("plan.contents.routine.header.day")}</MenuItem>
            </Select>
          </FormControl>
          {t("plan.contents.routine.header.gantt")}
          <S.CustomSwitch size="small" checked={viewMode === "calendar"} onChange={handleViewModeChange} />
          {t("plan.contents.routine.header.calendar")}
        </S.CustomSelectContainer>
      </div>
    </S.Container>
  )
}

export default RoutineHeader;
