import styled, { keyframes } from "styled-components";

// 애니메이션 정의
const slideIn = keyframes`
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
`;

const slideOut = keyframes`
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(100%);
  }
`;

// 사이드바 스타일
export const Container = styled.div<{ $isOpen: boolean }>`
  position: fixed;
  // top: 0;
  top: 57px;
  right: 0;
  width: 400px;
  height: calc(100% - 97px);  /* 상단바와 하단바 높이 제외 + padding */
  background-color: #fff;
  border-left: 1px solid #d9d9d9;
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
  z-index: 100;
  transform: translateX(100%); /* 기본적으로 화면 밖에 위치 */

  /* 애니메이션 */
  animation: ${({ $isOpen }) => ($isOpen ? slideIn : slideOut)} 0.3s forwards;
`;
