import styled from 'styled-components';

export const GridItem = {
  border: "1px solid #c0c0c0",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  flexDirection: "column",
  gap: "10px",
  borderRadius: "5px",
  padding: "5px",
  cursor: "pointer",

  "&:hover": {
    border: "1px solid #000",
    backgroundColor: "#f0f0f0",
  }
}

export const Image = styled.img`
  width: 90px;
  height: 90px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
