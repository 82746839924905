import { Box, styled as muiStyled } from "@mui/material";
import theme from "../../../../../../Styles/theme";

export const CircleBox = muiStyled(Box)(({ }) => ({
  width: "150px",
  height: "50px",
  borderRadius: "10px",
  padding: "20px",
  boxSizing: "border-box",
  background: "white",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  position: "relative",
  ".handle-right": {
    position: "absolute",
    right: "-5px", // 수정: right 값을 변경하여 border에 맞추기
    top: "50%",
    transform: "translateY(-50%)", // 수직 중앙 정렬
    background: theme.colors.primary,
    width: "10px",
    height: "10px",
    border: "3px solid white",
    borderRadius: "50%",
  },
  ".handle-left": {
    position: "absolute",
    left: "-5px", // 수정: left 값을 변경하여 border에 맞추기
    top: "50%",
    transform: "translateY(-50%)", // 수직 중앙 정렬
    background: theme.colors.primary,
    width: "10px",
    height: "10px",
    border: "3px solid white",
    borderRadius: "50%",
  },
}));
