import styled from "styled-components";

export const StoryItem = styled.div<{ $isMobile: boolean }>`
 width: ${({ $isMobile }) => ($isMobile ? "calc(95% - 40px)" : "calc(100% - 40px)")};
  padding: 20px;
  border: 1px solid #e0e0e0;
  border-radius: ${({ $isMobile }) => ($isMobile ? "6px" : "12px")};
  background-color: white;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  margin-top: 10px;
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export const StoryTitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 10px;
  border-bottom: 1px solid #e0e0e0;
`;

export const StoryTitle = styled.div`
  width: 95%;
  font-weight: 600;
  font-size: 0.9rem;
  display: flex;
  flex-direction: row;
  gap: 10px;
`;

export const StoryBottomContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: center;
`;

export const TagInputContainer = styled.div`
  display: flex;
  width: 80%;
  flex-wrap: wrap;
  gap: 0.5em;
  padding: 0.4rem 1rem;
  border-bottom: 1px solid black;
`;

export const TagItem = styled.div`
  display: inline-block;
  padding: 0.2em 0.5em;
  border-radius: 20px;
  color: white;
`;

export const TagInput = styled.input`
  flex-grow: 1;
  min-width: 30px;
  max-width: 150px;
  padding: 0.5em 0;
  border: none;
  outline: none;
  font-family: "Noto Sans KR", sans-serif;
`;

export const LectureElement = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
`;
