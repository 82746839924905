import styled from 'styled-components';

export const CustomInput = styled.input`
  width: calc(100% - 34px);
  min-height: 30px;
  padding: 5px 15px;
  border: 1px solid #999;
  border-radius: 10px;
  font-size: 14px;

  &:focus {
    outline: none;
    border: 1px solid #000;
  }

  &::placeholder {
    color: #999;
  }

  &:hover {
    border: 1px solid #000;
  }
`;

export const TagInputContainer = styled.div<{ $isPublic: boolean }>`
  display: flex;
  width: calc(100% - 34px);
  flex-wrap: wrap;
  gap: 0.5em;
  padding: 0.4rem 1rem;
  border: 1px solid #c0c0c0;
  border-radius: 4px;

  &:hover {
    border-color: ${({ $isPublic }) => ($isPublic ? "#000" : "#c0c0c0")};
  }
`;

export const TagItem = styled.div`
  display: flex;
  align-items: center;
  padding: 0.2em 0.5em;
  border-radius: 20px;
  color: white;
`;

export const TagInput = styled.input`
  flex-grow: 1;
  min-width: 30px;
  max-width: 150px;
  padding: 0.5em 0;
  border: none;
  outline: none;
  font-family: "Noto Sans KR", sans-serif;
`;

export const ImageContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 2;
  align-items: center;
`;

export const ImageInputContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2;
`;
