import cancelLectureDialogAtom from '@/Atoms/Dialogs/Cancel/Lecture';
import CustomBackgroundColorButton from '@/Components/Common/Buttons/CustomBackgroundColor';
import CustomColorButton from '@/Components/Common/Buttons/CustomColor';
import { cancelLectureDialogCancelDialogAtom } from '@/ViewModels/Dialogs/CancelLectureViewModel';
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { useAtom, useSetAtom } from 'jotai';
import React from 'react';
import { useTranslation } from 'react-i18next';

const CancelLectureDialog = () => {
  const { t } = useTranslation();
  const [dialog, setDialog] = useAtom(cancelLectureDialogAtom);
  const cancel = useSetAtom(cancelLectureDialogCancelDialogAtom)

  return (
    dialog &&
    <Dialog
      open={dialog !== null}
      onClose={() => setDialog(null)}
    >
      <DialogTitle sx={{ fontSize: "0.9rem", fontWeight: "bold" }}>{t("dialogs.lecture.cancel.title")}</DialogTitle>
      <DialogContent sx={{ fontSize: "0.8rem" }}>
        <div>{t("dialogs.lecture.cancel.content")}</div>
        {/* <div>취소 후에는 다시 신청할 수 있습니다.</div> */}
      </DialogContent>
      <DialogActions>
        <CustomColorButton onClick={() => setDialog(null)} text={t("dialogs.lecture.cancel.cancel")} color="gray" fontSize="0.8rem" />
        <CustomBackgroundColorButton onClick={() => cancel(t)} text={t("dialogs.lecture.cancel.confirm")} fontSize="0.8rem" />
      </DialogActions>
    </Dialog>
  )
}

export default CancelLectureDialog;
