import styled from "styled-components";
import { styled as muiStyled } from '@mui/material/styles';
import { lighten } from "polished";
import theme from "@/Styles/theme";
import { Button, Select, Switch } from "@mui/material";

export const CustomSwitch = muiStyled(Switch)(() => ({
  '& .MuiSwitch-switchBase.Mui-checked': {
    color: theme.colors.primary,
    '&:hover': {
      backgroundColor: lighten(0.1, theme.colors.primary),
    },
  },
  '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
    backgroundColor: theme.colors.primary,
  },
}));

export const Container = styled.div<{ $isCalendar: boolean }>`
  width: ${({ $isCalendar }) => $isCalendar ? "100%" : "calc(100% - 20px)"};
  height: 65px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 10px;
  padding-right: ${({ $isCalendar }) => $isCalendar ? "0px" : "20px"};

  @media (max-width: 900px) {
    flex-direction: column;
    gap: 10px;
    height: auto;
  }
`;

export const UpperContainer = styled.div<{ $isCalendar: boolean }>`
  width: 100%;
  height: 30px;
  display: flex;
  flex-direction: row;
  justify-content: ${({ $isCalendar }) => $isCalendar ? "space-between" : "flex-end"};
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
`;

export const CustomCreateTaskButton = muiStyled(Button)(() => ({
  backgroundColor: theme.colors.primary,
  color: "white",
  fontSize: "12px",
  fontWeight: "bold",
  padding: "5px 10px",

  '&:hover': {
    backgroundColor: lighten(0.1, theme.colors.primary),
  },
}));

export const LowerContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 10px;
  width: 100%;
  height: 25px;
  align-items: center;
  font-size: 12px;
  font-weight: bold;
`;

export const CustomSelectStyle = {
  fontSize: "10px",
  fontWeight: "bold",
  borderRadius: "5px",
  height: "25px",
}

// <div style={{ display: "flex", flexDirection: "row", gap: "10px", alignItems: "center", justifyContent: "flex-end" }}>
export const CustomSelectContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  justify-content: flex-end;
  font-size: 12px;

  @media (max-width: 768px) {
    gap: 5px;
    font-size: 10px;
  }
`;
