import { alertNoJobDataModalAtom } from '@/Atoms/Nodes/JobNodeAtom';
import CustomBackgroundColorButton from '@/Components/Common/Buttons/CustomBackgroundColor';
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { useAtom } from 'jotai';
import React from 'react';

const AlertNoJobDataModal = () => {
  const [modal, setModal] = useAtom(alertNoJobDataModalAtom);

  return (
    modal &&
    <Dialog open={modal !== null} onClose={() => setModal(null)} fullWidth maxWidth="xs">
      <DialogTitle sx={{ fontSize: "0.9rem", fontWeight: "bold" }}>알림</DialogTitle>
      <DialogContent sx={{ fontSize: "0.8rem", display: "flex", flexDirection: "column", gap: "10px", alignItems: "flex-start", justifyContent: "center" }}>
        <div>
          현재 사용중이신 플랜은 절약형 아카데미 플랜입니다.
        </div>
        <div>
          해당 직업 마인드맵에 대한 열람을 원하신다면 담임선생님 또는 진로선생님에게 문의해주세요.
        </div>
      </DialogContent>
      <DialogActions>
        <CustomBackgroundColorButton onClick={() => setModal(null)} text='확인' fontSize="0.8rem" />
      </DialogActions>
    </Dialog>
  )
}

export default AlertNoJobDataModal;
