import { createRoutineInRoutineModalAtom } from '@/Atoms/Plan/RoutineAtom';
import { Close } from '@mui/icons-material';
import { Dialog, DialogActions, DialogContent, DialogTitle, Popover } from '@mui/material';
import { useAtom, useSetAtom } from 'jotai';
import React from 'react';
import TitleValueInARow from '../../Contents/Routine/Sidebar/Common/TitleValueInARow';
import { ChromePicker } from 'react-color';
import * as S from './styled';
import CustomBackgroundColorButton from '@/Components/Common/Buttons/CustomBackgroundColor';
import { addRoutineInCreateRoutineInRoutineModalAtom } from '@/ViewModels/Plan/Routine/CreateRoutineViewModel';
import TitleValueInEachRow from '../../Contents/Routine/Sidebar/Common/TitleValueInEachRow';
import { useTranslation } from 'react-i18next';

const CreateRoutineInRoutine = () => {
  const { t } = useTranslation();
  const [modal, setModal] = useAtom(createRoutineInRoutineModalAtom);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const addRoutine = useSetAtom(addRoutineInCreateRoutineInRoutineModalAtom);

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const handleChangeColor = (color: string) => {
    if (!modal) return;
    const newModal = {
      ...modal,
      color,
    }
    setModal(newModal);
  }

  const handleChangeLabel = (label: string) => {
    if (!modal) return;
    const newModal = {
      ...modal,
      label,
    }
    setModal(newModal);
  }

  return (
    modal &&
    <Dialog open={true} onClose={() => setModal(null)} fullWidth maxWidth="xs">
      <DialogTitle sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between", fontSize: "0.9rem", fontWeight: "bold" }}>
        {t("plan.contents.routine.sidebar.title.createRoutine")}
        <Close sx={{ cursor: "pointer", width: "20px", height: "20px" }} onClick={() => setModal(null)} />
      </DialogTitle>
      <DialogContent sx={{ display: "flex", flexDirection: "column", gap: "20px" }}>
        <S.CustomInput placeholder={t("plan.contents.routine.sidebar.content.routineLabel")} value={modal.label} onChange={(e: any) => handleChangeLabel(e.target.value)} />
        <TitleValueInARow
          title={t("plan.contents.routine.sidebar.content.color")}
          value={
            <div
              style={{
                width: '40px',
                height: '40px',
                backgroundColor: modal.color,
                borderRadius: '50%',
                cursor: 'pointer',
              }}
              onClick={handlePopoverOpen}
            />
          }
        />
        <Popover
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handlePopoverClose}
        >
          <ChromePicker
            color={modal.color}
            onChangeComplete={(color) => handleChangeColor(color.hex)}
          />
        </Popover>
        <TitleValueInEachRow title={t("plan.contents.routine.sidebar.content.content")} value={
          <S.CustomInputField placeholder={t("plan.contents.routine.sidebar.content.content")} value={modal.content} onChange={(e) => setModal({ ...modal, content: e.target.value })} />
        } />
      </DialogContent>
      <DialogActions>
        <CustomBackgroundColorButton text={t("plan.contents.routine.sidebar.content.add2")} onClick={() => addRoutine(t)} fontSize="0.8rem" fontWeight='bold' disabled={modal.isLoading} />
      </DialogActions>
    </Dialog>
  );
};

export default CreateRoutineInRoutine;
