import { accordionSidebarAtom } from "@/Atoms/SidebarAtom";
import { atom } from "jotai";
import { planHeaderChangeViewTypeAtom } from "../Plan/HeaderViewModel";
import { usePlanDataAtom } from "@/Atoms/HookControllerAtom";
import GetIdFromQuerystring from "@/Utils/GetIdFromQuerystring";
import { planFitViewUpdatedAtom } from "@/Atoms/Plan";
import { NavigateFunction } from "react-router-dom";
import { clearRoutineSidebarDataAtom } from "../Plan/Routine/SidebarViewModel";

export const setIsAccordionOpenAtom = atom(null, (get, set) => {
  set(accordionSidebarAtom, !get(accordionSidebarAtom));
});

export const moveToPlanAtom = atom(null, (get, set, planId: number, innerTabId: number, navigate: NavigateFunction, planType: "routine" | "mindmap") => {
  const currentPath = window.location.pathname;
  const currentPlanId = GetIdFromQuerystring("id");
  const currentInnerTabId = GetIdFromQuerystring("inner_tab_id");
  if (currentPath.includes("plan") && currentPlanId === planId && currentInnerTabId === innerTabId) {
    return;
  }
  set(clearRoutineSidebarDataAtom);
  set(planHeaderChangeViewTypeAtom, planType === "routine" ? "routine" : "mindmap");
  set(usePlanDataAtom, true);
  set(planFitViewUpdatedAtom, false);
  navigate(`/plan?id=${planId}&inner_tab_id=${innerTabId}`);
});
