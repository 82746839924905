import CustomBackgroundColorButton from '@/Components/Common/Buttons/CustomBackgroundColor';
import CustomColorButton from '@/Components/Common/Buttons/CustomColor';
import { Dialog, DialogActions, DialogContent, DialogTitle, Divider, Drawer, TextField } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import { useAtomValue, useSetAtom } from 'jotai';
import * as S from './styled';
import React from 'react';
import getRandomColorWithText from '@/Utils/GetRandomColor';
import { useTranslation } from 'react-i18next';
import useIsMobile from '@/Hooks/useIsMobile';
import { addHashtagInFeedModalAtom, closeFeedModalAtom, createAndEditFeedInFeedModalAtom, editFeedInEditFeedModalAtom, removeHashtagInFeedModalAtom, updateLocationFeedModalAtom, updateStateFeedModalAtom } from '@/ViewModels/Plan/FeedViewModel';
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { devConsoleError } from '@/Utils/ConsoleLogInDevelopment';
import { Add, Remove } from '@mui/icons-material';
import editFeedModalAtom from '@/Atoms/Plan/Feed/EditModalAtom';
import { handleReactQueryApiResponse } from '@/Utils/APIUtil';
import { uploadTextEditorImage } from '@/Queries/PlanQueries';
import GetIdFromQuerystring from '@/Utils/GetIdFromQuerystring';
import { error401ModalAtom } from '@/Atoms/Dialogs/Error/401Atom';
import { uploadPlanFeedImage } from '@/Queries/PlanFeedQueries';

const EditFeedModal = () => {
  const { isMobile } = useIsMobile();
  const { t } = useTranslation();
  const modal = useAtomValue(editFeedModalAtom);
  const close = useSetAtom(closeFeedModalAtom);
  const updateState = useSetAtom(updateStateFeedModalAtom);
  const updateLocation = useSetAtom(updateLocationFeedModalAtom);
  const [inputValue, setInputValue] = React.useState("");
  const addHashtag = useSetAtom(addHashtagInFeedModalAtom);
  const removeHashtag = useSetAtom(removeHashtagInFeedModalAtom);
  const quillRef = React.useRef<ReactQuill | null>(null);
  const edit = useSetAtom(createAndEditFeedInFeedModalAtom);
  const innerTabId = GetIdFromQuerystring("inner_tab_id");
  const setError401Modal = useSetAtom(error401ModalAtom);
  const feedId = React.useMemo(() => modal?.id || 0, [modal]);
  // const setSchedule = useSetAtom(addAndRemoveScheduleInFeedModalAtom);
  // const setLocation = useSetAtom(addAndRemoveLocationInFeedModalAtom);
  // const setHashtag = useSetAtom(addAndRemoveHashtagInFeedModalAtom);
  // const [drawerState, setDrawerState] = React.useState(false);

  // const additionalInfo = [
  //   { key: "startDate", name: t("dialogs.feed.edit.setDate"), onClick: () => setSchedule("edit") },
  //   { key: "location", name: t("dialogs.feed.edit.location"), onClick: () => setLocation("edit") },
  //   { key: "hashtags", name: t("dialogs.feed.edit.hashtag"), onClick: () => setHashtag("edit") },
  // ]

  // 엔터키 입력 시 태그 추가
  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (!modal) return;
    if (!modal.hashtags) return;
    if (event.key === 'Enter' && inputValue.trim()) {
      event.preventDefault();
      const { backgroundColor, textColor } = getRandomColorWithText();
      if (!modal.hashtags.find((tag) => tag.value === inputValue.trim())) {
        addHashtag("edit", {
          backgroundColor,
          textColor,
          value: inputValue.trim(),
        });
      }
      setInputValue(""); // 입력창 초기화
    } else if (event.key === 'Backspace' && inputValue === "") {
      if (modal.hashtags.length > 0) {
        event.preventDefault();
        removeHashtag("edit");
      }
    }
  };

  const imageHandler = async () => {
    const input = document.createElement("input");
    input.setAttribute("type", "file");
    input.setAttribute("accept", "image/*");
    input.click();

    input.addEventListener("change", async () => {
      // input.files가 null이 아닌지 확인
      if (!input.files || input.files.length === 0) return;

      const file = input.files[0];
      try {
        const imgUrlResponse = await handleReactQueryApiResponse(uploadPlanFeedImage, () => setError401Modal(true), file, innerTabId, modal?.id || 0);
        if (!imgUrlResponse.ok) {
          devConsoleError(imgUrlResponse);
          return;
        }
        const imgUrlResponseJson = await imgUrlResponse.json();
        if (imgUrlResponseJson && quillRef.current) { // quillRef.current가 null이 아닌지 확인
          const imgUrl = process.env.REACT_APP_CAREER_PLANNER_PLAN_MEDIA_ADDRESS + imgUrlResponseJson.image_url.split("/media")[1];
          // const imgUrl = "http://10.10.112.148:8002/plan_images" + imgUrlResponse.image_url;
          const editor = quillRef.current.getEditor();
          const range = editor.getSelection();
          if (range) {
            editor.insertEmbed(range.index, "image", imgUrl);
            editor.setSelection((range.index + 1) as any);
          }
        }
      } catch (error) {
        devConsoleError(error);
      }
    });
  };

  // 모바일과 데스크톱 버전의 툴바 옵션 분리
  const modules = React.useMemo(() => {
    const desktopToolbar = [
      [{ header: "1" }, { header: "2" }, { font: [] }],
      [{ list: "ordered" }, { list: "bullet" }],
      ["bold", "italic", "underline", "strike"],
      [{ color: [] }, { background: [] }],
      [{ align: [] }],
      ["link", "image"],
      ["clean"],
    ];

    const mobileToolbar = [
      ["bold", "italic", "underline"],
      [{ list: "ordered" }, { list: "bullet" }],
      ["image"],
    ];

    return {
      toolbar: {
        container: isMobile ? mobileToolbar : desktopToolbar,
        handlers: { image: imageHandler },
      },
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMobile, feedId]);

  // Quill의 formats 설정
  const formats = [
    "header",
    "font",
    "list",
    "bullet",
    "bold",
    "italic",
    "underline",
    "strike",
    "color",
    "background",
    "align",
    "link",
    "image",
  ];


  return (
    modal &&
    <Dialog
      open={modal !== null}
      onClose={() => close("edit")}
      maxWidth="md"
      fullWidth
      fullScreen={isMobile}
    >
      <DialogTitle sx={{ fontSize: "0.9rem", fontWeight: "bold", display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
        {t("dialogs.feed.edit.title")}
        {/* {isMobile && <Add onClick={() => setDrawerState(true)} />} */}
      </DialogTitle>
      <DialogContent sx={{ display: "flex", flexDirection: "row", minHeight: "70vh" }}>
        <div style={{ display: "flex", flexDirection: "column", gap: "10px", width: isMobile ? "100%" : "100%", overflowY: "auto", paddingRight: isMobile ? "0px" : "0px" }}>
          <div style={{ fontSize: "0.8rem", fontWeight: "bold" }}>{t("dialogs.feed.edit.name")}</div>
          <S.CustomInput type="text" value={modal.name} onChange={(e) => updateState("name", "edit", e.target.value)} placeholder={t("dialogs.feed.edit.name")} $isMobile={isMobile} />

          <div style={{ fontSize: "0.8rem", fontWeight: "bold" }}>{t("dialogs.feed.edit.description")}</div>
          <div style={{ width: "100%", minHeight: isMobile ? "250px" : "350px" }}>
            <ReactQuill
              ref={quillRef}
              theme="snow"
              style={{ height: isMobile ? "200px" : "300px" }}
              modules={modules}
              formats={formats}
              value={modal.content}
              onChange={(content) => updateState("content", "edit", content)}
            />
          </div>

          <div style={{ fontSize: "0.8rem", fontWeight: "bold" }}>{t("dialogs.feed.edit.setDate")}</div>
          <div style={{ display: "flex", width: "100%", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
            <DatePicker
              label={t("dialogs.feed.edit.startDate")}
              sx={{ width: "47%" }}
              value={dayjs(modal.startDate)}
              onChange={(date) => updateState('startDate', "edit", date)}
              shouldDisableDate={modal.endDate ? (day) => day.isAfter(modal.endDate) : undefined}
              format="YYYY.MM.DD"
            />
            <div style={{ fontSize: "1.2rem", fontWeight: "600" }}>~</div>
            <DatePicker
              label={t("dialogs.feed.edit.endDate")}
              sx={{ width: "47%" }}
              value={dayjs(modal.endDate)}
              onChange={(date) => updateState('endDate', "edit", date)}
              shouldDisableDate={modal.startDate ? (day) => day.isBefore(modal.startDate) : undefined}
              format="YYYY.MM.DD"
            />
          </div>

          <div style={{ fontSize: "0.8rem", fontWeight: "bold" }}>{t("dialogs.feed.edit.location")}</div>
          <S.CustomInput type="text" value={modal.location} onChange={(e) => updateLocation("edit", e.target.value)} placeholder={t("dialogs.feed.edit.location")} $isMobile={isMobile} />

          <div style={{ fontSize: "0.8rem", fontWeight: "bold" }}>{t("dialogs.feed.edit.hashtag")}</div>
          <S.TagInputContainer $isMobile={isMobile}>
            {modal.hashtags.map((tag, idx) => {
              return <S.TagItem key={idx} style={{ backgroundColor: tag.backgroundColor, color: tag.textColor }}>
                <span>{tag.value}</span>
              </S.TagItem>
            })}
            <S.TagInput
              placeholder={modal.hashtags.length === 0 ? t("dialogs.feed.edit.hashtagPlaceholder") : ""}
              value={inputValue}
              onChange={(e) => setInputValue(e.target.value)}
              onKeyDown={handleKeyDown}
            />
          </S.TagInputContainer>
        </div>
        {/* {
          !isMobile &&
          <>
            <Divider orientation="vertical" flexItem />
            <div style={{ width: "20%", paddingLeft: "20px", display: "flex", flexDirection: "column", gap: "10px" }}>
              <div style={{ fontSize: "0.8rem", fontWeight: "bold" }}>추가 정보</div>
              {
                additionalInfo.map((info, idx) => (
                  <S.AdditionalButton key={idx} onClick={info.onClick}>
                    {info.name}
                    {modal[info.key as keyof typeof modal] ? <Remove /> : <Add />}
                  </S.AdditionalButton>
                ))
              }
            </div>
          </>
        } */}
      </DialogContent>
      <DialogActions>
        <CustomColorButton onClick={() => close("edit")} text={t("dialogs.feed.edit.buttons.cancel")} fontSize="0.8rem" />
        <CustomBackgroundColorButton onClick={() => edit("edit")} text={t("dialogs.feed.edit.buttons.edit")} fontSize="0.8rem" />
      </DialogActions>
      {/* {
        isMobile && <Drawer anchor='right' open={drawerState} onClose={() => setDrawerState(false)} sx={{ zIndex: 1300 }}>
          <div style={{ width: "250px", padding: "20px", display: "flex", flexDirection: "column", gap: "10px" }}>
            <div style={{ fontSize: "0.8rem", fontWeight: "bold" }}>추가 정보</div>
            {
              additionalInfo.map((info, idx) => (
                <S.AdditionalButton key={idx} onClick={info.onClick}>
                  {info.name}
                  {modal[info.key as keyof typeof modal] ? <Remove /> : <Add />}
                </S.AdditionalButton>
              ))
            }
          </div>
        </Drawer>
      } */}
    </Dialog>
  )
}

export default EditFeedModal;
