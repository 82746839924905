import { RequestStoryProps, StoryProps } from "@/Atoms/StoryAtom";

// story 리스트 받아오기
export const getStoryList = async (access: string, userId: number) => {
  const response = await fetch(`${process.env.REACT_APP_FEED_API_ADDRESS}/${userId}`, {
    method: "GET",
    headers: {
      "Authorization": `Bearer ${access}`,
      "Content-Type": "application/json",
    },
  });
  return response;
}

// story 작성하기
export const createStory = async (access: string, userId: number, newStory: RequestStoryProps) => {
  const response = await fetch(`${process.env.REACT_APP_FEED_API_ADDRESS}/${userId}`, {
    method: "POST",
    headers: {
      "Authorization": `Bearer ${access}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(newStory),
  });
  return response;
}

// story 삭제하기
export const deleteStory = async (access: string, id: number) => {
  const response = await fetch(`${process.env.REACT_APP_FEED_API_ADDRESS}/${id}`, {
    method: "DELETE",
    headers: {
      "Authorization": `Bearer ${access}`,
      'accept': '*/*',
    },
  });
  return response;
}

// story 수정하기
export const updateStory = async (access: string, userId: number, updatedStory: StoryProps) => {
  const request = {
    title: updatedStory.title,
    content: updatedStory.content,
    date: updatedStory.date,
    hashtags: updatedStory.hashtags,
  }
  const response = await fetch(`${process.env.REACT_APP_FEED_API_ADDRESS}/${userId}/${updatedStory.id}`, {
    method: "PUT",
    headers: {
      "Authorization": `Bearer ${access}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(request),
  });
  return response;
}
