import { TaskStatus } from "@/Types/Plan";
import dayjs from "dayjs";

// 새로운 진행률 계산 함수
export const getProgressPercentageForTaskStatus = (taskStatuses: TaskStatus[], taskId: number): number => {
  const taskStatusByTaskId = taskStatuses.filter((status) => status.taskId === taskId && status.routineId === taskId);
  if (taskStatusByTaskId.length === 0) return 0;
  const completedTaskStatus = taskStatusByTaskId.filter((status) => status.status === 1);
  return (completedTaskStatus.length / taskStatusByTaskId.length) * 100;
};

// 진행률 계산 함수
const getProgressPercentage = (startDate: string | null, endDate: string | null): number => {
  if (!startDate || !endDate) return 0;
  const totalDuration = dayjs(endDate).valueOf() - dayjs(startDate).valueOf();
  const timePassed = new Date().getTime() - dayjs(startDate).valueOf();
  return Math.min((timePassed / totalDuration) * 100, 100);
};

export default getProgressPercentage;
