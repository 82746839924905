import findCrewDialogAtom from '@/Atoms/Dialogs/FindCrew';
import { findCrewDialogRecommendAtom } from '@/ViewModels/Dialogs/FindCrewViewModel';
import { Chip, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { useAtom, useSetAtom } from 'jotai';
import CloseIcon from '@mui/icons-material/Close';
import React from 'react';
import CustomColorButton from '@/Components/Common/Buttons/CustomColor';
import CustomBackgroundColorButton from '@/Components/Common/Buttons/CustomBackgroundColor';
import { openSignatureDialogAtom } from '@/ViewModels/Signature/ViewModel';

const FindCrewDialog = () => {
  const [dialog, setDialog] = useAtom(findCrewDialogAtom);
  const openSignature = useSetAtom(openSignatureDialogAtom);
  const recommendCrew = useSetAtom(findCrewDialogRecommendAtom);

  const handleOpenSignatureDialog = () => {
    openSignature();
    setDialog(null);
  }

  // const extraUserInfoFields: { label: string; key: keyof InterestDialog; value: string }[] = dialog ? [
  //   { label: "좌우명", key: "motto", value: dialog.motto },
  //   { label: "꿈", key: "dream", value: dialog.dream },
  //   { label: "목표", key: "goal", value: dialog.goal },
  //   { label: "필살기", key: "ultimate", value: dialog.ultimate },
  //   { label: "선호 직업", key: "job", value: dialog.job },
  // ] : [];

  // const extraUserChipFields: { label: string; key: keyof InterestDialogState, chipColor: string, values: string[] }[] = dialog ? [
  //   { label: "관심사", key: "interest", chipColor: theme.colors.primary, values: dialog.interest },
  //   { label: "취향", key: "preference", chipColor: theme.colors.task, values: dialog.preference },
  //   { label: "성향", key: "tendency", chipColor: theme.colors.customPurple, values: dialog.tendency },
  //   { label: "진로진학 설정", key: "career", chipColor: theme.colors.customSky, values: dialog.career },
  //   { label: "너의 고민은?", key: "worry", chipColor: theme.colors.customPink, values: dialog.worry },
  // ] : [];


  return (dialog &&
    <Dialog open={dialog !== null} onClose={() => setDialog(null)} fullWidth maxWidth="sm">
      <DialogTitle sx={{ fontSize: "0.9rem", fontWeight: "bold", display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
        크루 찾기
        <CloseIcon onClick={() => setDialog(null)} sx={{ cursor: "pointer" }} />
      </DialogTitle>
      <DialogContent sx={{ fontSize: "0.8rem", display: "flex", flexDirection: "column", gap: "10px" }}>
        <div>현재 설정된 {dialog.nickname}님의 프로필 정보입니다. 아래의 정보를 통해 크루를 추천해드릴게요.</div>
        {/* {
          extraUserInfoFields.map((field, index) => (
            <div key={index} style={{ display: "flex", flexDirection: "row", gap: "10px" }}>
              <div style={{ display: "flex", flexDirection: "column", gap: "5px" }}>
                <div style={{ display: "flex", flexDirection: "row", gap: "5px" }}>
                  <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                    <span style={{ fontSize: "0.8rem", fontWeight: "bold" }}>{field.label}</span>
                  </div>
                  <span>{field.value}</span>
                </div>
              </div>
            </div>
          )
          )
        }
        {
          extraUserChipFields.map((field, index) => (
            <div key={index} style={{ display: "flex", flexDirection: "row", gap: "10px" }}>
              <div style={{ display: "flex", flexDirection: "column", gap: "5px" }}>
                <div style={{ display: "flex", flexDirection: "row", gap: "5px" }}>
                  <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                    <div style={{ width: "10px", height: "10px", borderRadius: "50%", backgroundColor: field.chipColor }}></div>
                    <span style={{ fontSize: "0.8rem", fontWeight: "bold" }}>{field.label}</span>
                  </div>
                </div>
                <div style={{ display: "flex", flexDirection: "row", gap: "5px", flexWrap: "wrap" }}>
                  {
                    field.values.map((value, index) => (
                      <Chip key={index} label={value} sx={{ backgroundColor: theme.colors.primary, color: "white" }} />
                    ))
                  }
                </div>
              </div>
            </div>
          ))
        } */}
      </DialogContent>
      <DialogActions>
        <CustomColorButton onClick={handleOpenSignatureDialog} text="시그니쳐 설정" fontSize="0.8rem" />
        <CustomBackgroundColorButton onClick={recommendCrew} text="크루 찾기" fontSize="0.8rem" />
      </DialogActions>
    </Dialog>
  )
}

export default FindCrewDialog;
