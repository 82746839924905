import { atom } from 'jotai';

export const editRoutineIntervalsDialogAtom = atom<{
  open: string | null;
  termType: number | null;
  termData: string[] | null;
}>({
  open: null,
  termType: null,
  termData: null,
});
