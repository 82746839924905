import { useQuery } from 'react-query';
import { getRecommendPlans } from '@/Queries/PlanQueries';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { getUserId, userLanguageAtom } from '@/ViewModels/UserViewModel';
import { useRecommendPlanAtom } from '@/Atoms/HookControllerAtom';
import { handleReactQueryApiResponse } from '@/Utils/APIUtil';
import { error401ModalAtom } from '@/Atoms/Dialogs/Error/401Atom';
import { recommendPlanListAtom } from '@/Atoms/RecommendPlan/Atom';

const useRecommendPlan = () => {
  const userId = useAtomValue(getUserId);
  const [controller, setController] = useAtom(useRecommendPlanAtom);
  const setError401Modal = useSetAtom(error401ModalAtom);
  const setRecommendPlanList = useSetAtom(recommendPlanListAtom);
  const userLanguage = useAtomValue(userLanguageAtom);

  return useQuery(['recommendPlans', userId], () => handleReactQueryApiResponse(getRecommendPlans, () => setError401Modal(true), userLanguage), {
    enabled: controller,
    onSuccess: async (data) => {
      if (!data.ok) return;
      setController(false);
      const jsonedData = await data.json();
      setRecommendPlanList(jsonedData.recommended_plans);
    },
  });
};

export default useRecommendPlan;
