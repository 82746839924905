import { useAtom } from "jotai";
import React from "react";
import { isOpenRecommendQuestionAtom } from "../../../Atoms/HelperAtom";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { Button } from "@mui/material";
import { useTranslation } from "react-i18next";

export function RecommendQuestionButton() {
  const [isOpenRecommendQuestion, setIsOpenRecommendQuestion] = useAtom(
    isOpenRecommendQuestionAtom
  );
  const { t } = useTranslation();

  return (
    <Button
      variant="contained"
      sx={{
        borderRadius: "20px",
        background: "#06C25E",
        "&:hover": {
          background: "#06C25E",
        },
      }}
      onClick={() => setIsOpenRecommendQuestion((current) => !current)}
    >
      {isOpenRecommendQuestion ? t("makeTextHelper.recommendQuestion.buttons.fold") : t("makeTextHelper.recommendQuestion.buttons.unfold")}
      {isOpenRecommendQuestion ? (
        <KeyboardArrowUpIcon />
      ) : (
        <KeyboardArrowDownIcon />
      )}
    </Button>
  );
}
