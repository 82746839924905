import React from "react";
import {
  Divider,
  IconButton,
  Stack,
} from "@mui/material";
import * as S from "../styled";
import { Settings } from "@mui/icons-material";
import { useAtomValue, useSetAtom } from "jotai";
import { dashboardMenteeJobSearchAtom } from "@/Atoms/Dashboard/MenteeAtom";
import KRImage from "@/Assets/Images/Countries/korea.jpg";
import USImage from "@/Assets/Images/Countries/usa.jpg";
import { useTranslation } from "react-i18next";
import { userLanguageAtom } from "@/ViewModels/UserViewModel";
import { openSignatureDialogAtom } from "@/ViewModels/Signature/ViewModel";
import CommonTable from "@/Components/Common/Table";
import LoadingContent from "../../Contents/Loading";

export function RecommendJob() {
  const openSignature = useSetAtom(openSignatureDialogAtom);
  const jobSearch = useAtomValue(dashboardMenteeJobSearchAtom);
  const language = useAtomValue(userLanguageAtom);
  const { t } = useTranslation();

  const header = [
    // t("dashboard.mentee.recommendedJob.image"),
    t("dashboard.mentee.recommendedJob.job"),
    t("dashboard.mentee.recommendedJob.workValues"),
    t("dashboard.mentee.recommendedJob.interests"),
    t("dashboard.mentee.recommendedJob.activities"),
    t("dashboard.mentee.recommendedJob.context"),
    t("dashboard.mentee.recommendedJob.hopefulness")
  ];

  const data = jobSearch.jobSearch.map((row, index) => ({
    cells: [
      // <img
      //   src={`${process.env.REACT_APP_DOKGABI_MEDIA_ADDRESS}/media/temp/${index + 1}.png`}
      //   alt="직업 이미지"
      //   style={{
      //     width: "50px",
      //     height: "50px",
      //     borderRadius: "50%",
      //   }}
      // />,
      row.job,
      row.major,
      row.qualifications,
      row.externalEducation,
      row.recommendedEducation,
      row.hopefulness,
    ]
  }));

  const extendContents = jobSearch.jobSearch.map((row, index) => (
    <div style={{ padding: "10px" }}>
      <p><strong>{t("dashboard.mentee.recommendedJob.job")}</strong> {row.job}</p>
      <p><strong>{t("dashboard.mentee.recommendedJob.workValues")}</strong> {row.major}</p>
      <p><strong>{t("dashboard.mentee.recommendedJob.interests")}</strong> {row.qualifications}</p>
      <p><strong>{t("dashboard.mentee.recommendedJob.activities")}</strong> {row.externalEducation}</p>
      <p><strong>{t("dashboard.mentee.recommendedJob.context")}</strong> {row.recommendedEducation}</p>
      <p><strong>{t("dashboard.mentee.recommendedJob.hopefulness")}</strong> {row.hopefulness}</p>
    </div>
  ));

  return (
    <S.CustomBox    >
      <Stack
        direction={"row"}
        sx={{
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <S.TitleTypo>{t("dashboard.mentee.recommendedJob.title")}</S.TitleTypo>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: "10px",
          }}
        >
          <img src={language === "KR" ? KRImage : USImage} alt="국기" style={{ width: "40px", height: `${(40 * 2) / 3}px` }} />
          <IconButton onClick={openSignature}>
            <Settings></Settings>
          </IconButton>
        </div>
      </Stack>
      <Divider sx={{ margin: "10px 0" }} />
      <S.DescTypo sx={{ marginBottom: "10px" }}>
        {t("dashboard.mentee.recommendedJob.description")}
      </S.DescTypo>
      {
        jobSearch.isLoading ?
          <LoadingContent /> :
          <CommonTable headers={header} rows={data} extendContents={extendContents} />
      }
    </S.CustomBox>
  );
}
