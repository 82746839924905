import { initialResetPasswordModalInfo, settingsResetPasswordModalAtom } from '@/Atoms/Settings';
import { atom } from 'jotai';
import { getUserEmailAtom } from '../UserViewModel';
import { login, resetPassword } from '@/Queries/AuthQueries';
import snackbarAtom from '@/Atoms/Snackbar';
import { TFunction } from 'i18next';

const passwordRegex = /^(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+[\]{};':"\\|,.<>/?]).{8,}$/;

export const ResetPasswordModalOpenAtom = atom(null, (_get, set) => {
  set(settingsResetPasswordModalAtom, initialResetPasswordModalInfo);
});

export const ResetPasswordModalCurrentPasswordAuthAtom = atom(null, async (get, set, t: TFunction) => {
  const modal = get(settingsResetPasswordModalAtom);
  if (!modal) return;
  const currentPassword = modal.currentPassword;
  const response = await login(get(getUserEmailAtom), currentPassword);
  if (response.data.results === 1) {
    set(settingsResetPasswordModalAtom, {
      ...modal,
      isCorrectPassword: true,
    });
    set(snackbarAtom, { open: true, message: t("settings.contents.resetPassword.confirmCurrentPasswordSuccess"), severity: "success" });
  } else {
    set(snackbarAtom, { open: true, message: t("settings.contents.resetPassword.confirmCurrentPasswordFail"), severity: "error" });
  }
});

export const ResetPasswordModalNewPasswordAuthAtom = atom((get) => {
  const modal = get(settingsResetPasswordModalAtom);
  if (!modal) return;
  const newPassword = modal.newPassword;
  return passwordRegex.test(newPassword) && newPassword !== modal.currentPassword;
});

export const ResetPasswordModalNewPasswordConfirmAuthAtom = atom((get) => {
  const modal = get(settingsResetPasswordModalAtom);
  if (!modal) return;
  const newPassword = modal.newPassword;
  const confirmPassword = modal.newPasswordConfirm;
  return get(ResetPasswordModalNewPasswordAuthAtom) && newPassword === confirmPassword;
});

export const ResetPasswordModalSubmitAtom = atom(null, async (get, set, t: TFunction) => {
  const modal = get(settingsResetPasswordModalAtom);
  if (!modal) return;
  if (!get(ResetPasswordModalNewPasswordConfirmAuthAtom)) {
    set(snackbarAtom, { open: true, message: t("settings.contents.resetPassword.submit.notMatch"), severity: "error" });
    return;
  }
  const response = await resetPassword(get(getUserEmailAtom), modal.newPassword);
  if (response.status === 200) {
    set(settingsResetPasswordModalAtom, null);
    set(snackbarAtom, { open: true, message: t("settings.contents.resetPassword.submit.success"), severity: "success" });
  } else {
    set(snackbarAtom, { open: true, message: t("settings.contents.resetPassword.submit.fail"), severity: "error" });
  }
});
