import createNewPlanModalAtom from '@/Atoms/Plan/CreateNewPlanModalAtom';
import useIsMobile from '@/Hooks/useIsMobile';
import { Dialog, DialogContent } from '@mui/material';
import { useAtom, useSetAtom } from 'jotai';
import React from 'react';
import * as S from './styled';
import theme from '@/Styles/theme';
import { AddCircle, Chat, Close, TableChart } from '@mui/icons-material';
import { createPlanInCreatePlanModalAtom } from '@/ViewModels/Plan/PlanViewModel';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const CreateNewPlanModal = () => {
  const [modal, setModal] = useAtom(createNewPlanModalAtom);
  const { isMobile } = useIsMobile();
  const create = useSetAtom(createPlanInCreatePlanModalAtom);
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    modal &&
    <Dialog
      open={modal !== null}
      onClose={() => setModal(null)}
      fullWidth
      maxWidth="md"
      fullScreen={isMobile}
      sx={{
        '& .MuiPaper-root': {
          borderRadius: '20px'
        },
      }}
    >
      <DialogContent sx={{ height: "500px", display: "flex", flexDirection: isMobile ? "column" : "row", position: "relative" }}>
        {
          isMobile &&
          <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-end", width: "100%" }}>
            <Close sx={{ width: "30px", height: "30px", cursor: "pointer" }} onClick={() => setModal(null)} />
          </div>
        }
        {
          !isMobile &&
          <Close sx={{ position: "absolute", top: "20px", right: "20px", cursor: "pointer" }} onClick={() => setModal(null)} />
        }
        <S.CustomLeftBox>
          <div style={{ fontSize: isMobile ? "24px" : "30px", fontWeight: "bold" }}>
            {
              modal.type === "routine" ?
                t("dialogs.createPlan.left.routineTitle") :
                t("dialogs.createPlan.left.careerTitle")
            }
          </div>
          <div style={{ fontSize: isMobile ? "14px" : "18px", color: "#aaa" }}>
            {
              modal.type === "routine" ?
                t("dialogs.createPlan.left.routineDescription") :
                t("dialogs.createPlan.left.careerDescription")
            }
          </div>
        </S.CustomLeftBox>
        <S.CustomRightBox>
          <S.CustomTypeSelectButtonContainer>
            <S.CustomTypeSelectButton $color={theme.colors.primary} $selected={modal.type === "routine"} onClick={() => setModal({ ...modal, type: "routine" })}>
              {t("dialogs.createPlan.right.routine")}
            </S.CustomTypeSelectButton>
            <S.CustomTypeSelectButton $color={theme.colors.customPurple} $selected={modal.type === "mindmap"} onClick={() => setModal({ ...modal, type: "mindmap" })}>
              {t("dialogs.createPlan.right.career")}
            </S.CustomTypeSelectButton>
          </S.CustomTypeSelectButtonContainer>
          <S.CustomCreateButton $color={theme.colors.primary} onClick={() => create(true, navigate)}>
            <AddCircle sx={{ width: "50px", height: "50px" }} />
            {t("dialogs.createPlan.right.selfCreate")}
          </S.CustomCreateButton>
          {/* <S.CustomCreateButton $color={theme.colors.customPurple} onClick={() => create(false, navigate)}>
            {
              modal.type === "routine" ?
                <>
                  <TableChart sx={{ width: "40px", height: "40px" }} />
                  {t("dialogs.createPlan.right.templateCreate")}
                </> :
                <>
                  <Chat sx={{ width: "40px", height: "40px" }} />
                  {t("dialogs.createPlan.right.chatbotCreate")}
                </>
            }
          </S.CustomCreateButton> */}
          {
            modal.type === "mindmap" &&
            <S.CustomCreateButton $color={theme.colors.customPurple} onClick={() => create(false, navigate)}>
              <Chat sx={{ width: "40px", height: "40px" }} />
              {t("dialogs.createPlan.right.chatbotCreate")}
            </S.CustomCreateButton>
          }
          {
            modal.type === "routine" &&
            <S.TempEmptyButton />
          }
        </S.CustomRightBox>
      </DialogContent>
    </Dialog>
  )
}

export default CreateNewPlanModal;
