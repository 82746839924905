import styled from 'styled-components';

export const CustomInput = styled.input`
  width: calc(100% - 34px);
  min-height: 30px;
  padding: 5px 15px;
  border: 1px solid #999;
  border-radius: 10px;
  font-size: 14px;

  &:focus {
    outline: none;
    border: 1px solid #000;
  }

  &::placeholder {
    color: #999;
  }

  &:hover {
    border: 1px solid #000;
  }
`;

export const CustomInputField = styled.textarea`
  width: calc(100% - 34px);
  min-height: 100px;
  max-height: 200px;
  padding: 5px 15px;
  border: 1px solid #999;
  border-radius: 10px;
  font-size: 14px;
  resize: none; /* 사용자가 크기를 조정하지 못하게 설정 */
  overflow-wrap: break-word; /* 긴 단어를 줄바꿈 */
  white-space: pre-wrap; /* 줄바꿈을 유지하고 공백을 표현 */

  &:focus {
    outline: none;
    border: 1px solid #000;
  }

  &::placeholder {
    color: #999;
  }

  &:hover {
    border: 1px solid #000;
  }
`;
