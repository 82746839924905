import { Signature } from '@/Atoms/Signature/Atom';
import { Node } from '@xyflow/react';
import { atom } from 'jotai';

export const feedDetailDialogAtom = atom<Node | null>(null);

export const feedDetailUserSignatureDialogAtom = atom<Signature | null>(null);

export interface FeedComment {
  id: number;
  userId: number;
  profileImage: string;
  nickname: string;
  time: string;
  content: string;
}
