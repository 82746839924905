import { deleteCrew, selfJoinCrew, selfLeaveCrew } from "@/Queries/CrewQueries";
import GetIdFromQuerystring from "@/Utils/GetIdFromQuerystring";
import { atom } from "jotai";
import { getUserId } from "../UserViewModel";
import snackbarAtom from "@/Atoms/Snackbar";
import { useCrewAtom, usePlanHipAtom } from "@/Atoms/HookControllerAtom";
import { NavigateFunction } from "react-router-dom";
import { handleReactQueryApiResponse } from "@/Utils/APIUtil";
import { error401ModalAtom } from "@/Atoms/Dialogs/Error/401Atom";
import { TFunction } from "i18next";

// 크루 입장
export const crewJoinAtom = atom(null, async (get, set, t: TFunction) => {
  const crewId = GetIdFromQuerystring("crew_id");
  const userId = get(getUserId);
  const handleError401Modal = () => set(error401ModalAtom, true);
  const response = await handleReactQueryApiResponse(selfJoinCrew, handleError401Modal, crewId, userId);
  if (response.ok) {
    set(snackbarAtom, { open: true, message: t("viewModel.snackbar.joinCrew"), severity: "success" });
    set(usePlanHipAtom, true);
    set(useCrewAtom, true);
  }
});

// 크루 퇴장
export const crewLeaveAtom = atom(null, async (get, set, navigate: NavigateFunction, t: TFunction) => {
  const crewId = GetIdFromQuerystring("crew_id");
  const userId = get(getUserId);
  const handleError401Modal = () => set(error401ModalAtom, true);
  const response = await handleReactQueryApiResponse(selfLeaveCrew, handleError401Modal, crewId, userId);
  if (response.ok) {
    set(snackbarAtom, { open: true, message: t("viewModel.snackbar.leaveCrew"), severity: "success" });
    set(usePlanHipAtom, true);
    navigate("/plan-hip");
  }
});

// 크루 폐쇄
export const crewCloseAtom = atom(null, async (get, set, navigate: NavigateFunction, t: TFunction) => {
  const crewId = GetIdFromQuerystring("crew_id");
  const handleError401Modal = () => set(error401ModalAtom, true);
  const response = await handleReactQueryApiResponse(deleteCrew, handleError401Modal, crewId);
  if (response.ok) {
    set(snackbarAtom, { open: true, message: t("viewModel.snackbar.closeCrew"), severity: "success" });
    set(usePlanHipAtom, true);
    navigate("/plan-hip");
  }
});
