import styled from 'styled-components';

export const CalenderEvent = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  white-space: nowrap; /* 텍스트를 한 줄로 유지 */
  overflow: hidden; /* 텍스트가 넘칠 경우 잘리도록 설정 */
  text-overflow: ellipsis; /* 넘친 텍스트에 '...' 추가 */
`;
