import { error401ModalAtom } from '@/Atoms/Dialogs/Error/401Atom';
import innerTabDataAtom from '@/Atoms/Plan/InnerTabDataAtom';
import { connectedRoutinesAtom, editTaskInRoutineEditRoutineAtom, editTaskInRoutineStateAtom, routineSidebarAtom } from '@/Atoms/Plan/RoutineAtom';
import snackbarAtom from '@/Atoms/Snackbar';
import { updateTaskV2 } from '@/Queries/PlanQueries';
import { handleReactQueryApiResponse } from '@/Utils/APIUtil';
import GetIdFromQuerystring from '@/Utils/GetIdFromQuerystring';
import { atom } from 'jotai';
import { setInnerTabDataAtom } from '../InnerTabViewModel';
import { clearRoutineSidebarDataAtom, popAndChangeKeySidebarDataAtom } from './SidebarViewModel';
import { Edge, Node } from '@xyflow/react';
import { TFunction } from 'i18next';

// 루틴 변경 시작
export const editTaskInRoutineEditRoutineStartAtom = atom(null, (get, set) => {
  const state = get(editTaskInRoutineStateAtom);
  if (!state) return;
  const termData = state.termData;
  set(editTaskInRoutineEditRoutineAtom, {
    termType: state.termType,
    termData: termData ? [...termData] : [],
  });
});

// 루틴 일자 초기화
export const editTaskInRoutineEditRoutineClearStateAtom = atom(null, (get, set) => {
  set(editTaskInRoutineEditRoutineAtom, {
    termType: null,
    termData: null,
  });
});

// 루틴 변경 적용
export const editTaskInRoutineEditRoutineApplyAtom = atom(null, (get, set) => {
  const { termType, termData } = get(editTaskInRoutineEditRoutineAtom);
  const state = get(editTaskInRoutineStateAtom);

  if (!termType || !termData || !state) return;

  // termType === 1 일 경우 [월, 화, 수, 목, 금, 토, 일] 순으로 정렬
  if (termType === 1) {
    termData.sort((a, b) => {
      const week = ["월", "화", "수", "목", "금", "토", "일"];
      return week.indexOf(a) - week.indexOf(b);
    });
  }
  // termType === 2 일 경우 숫자로 정렬
  else {
    termData.sort((a, b) => parseInt(a) - parseInt(b));
  }

  const newState = {
    ...state,
    termData: termData,
  }
  set(editTaskInRoutineStateAtom, newState);
  set(editTaskInRoutineEditRoutineClearStateAtom);
});

// 루틴 일자 변경
export const editTaskInRoutineEditRoutineDataAtom = atom((get) => get(editTaskInRoutineEditRoutineAtom), (get, set, day: string) => {
  const editRoutineData = get(editTaskInRoutineEditRoutineAtom);
  if (!editRoutineData.termData) return;
  if (editRoutineData.termData.includes(day)) {
    set(editTaskInRoutineEditRoutineAtom, { termType: editRoutineData.termType, termData: editRoutineData.termData.filter((interval) => interval !== day) });
  } else {
    set(editTaskInRoutineEditRoutineAtom, { termType: editRoutineData.termType, termData: [...editRoutineData.termData, day] });
  }
});

export const editTaskInRoutineUpdateTaskAtom = atom(null, async (get, set, t: TFunction) => {
  const editTaskData = get(editTaskInRoutineStateAtom);
  const connectedRoutines = get(connectedRoutinesAtom);
  const planData = get(innerTabDataAtom);
  const innerTabId = GetIdFromQuerystring("inner_tab_id");
  const plan = planData.find((data) => data.innerTabId === innerTabId);
  const sidebar = get(routineSidebarAtom);
  if (!plan || sidebar.length === 0 || !editTaskData) return;
  const data = sidebar[sidebar.length - 1];
  const prevData = sidebar[sidebar.length - 2];

  if (editTaskData.label === "") {
    set(snackbarAtom, { open: true, message: t("plan.contents.routine.snackbar.inputTaskName"), severity: "error" });
    return;
  }
  if (editTaskData.label.length > 50) {
    set(snackbarAtom, { open: true, message: t("plan.contents.routine.snackbar.taskNameLength"), severity: "error" });
    return;
  }
  const loadingState = {
    ...editTaskData,
    isLoading: true,
  }
  set(editTaskInRoutineStateAtom, loadingState);

  const requestData = {
    label: editTaskData.label,
    content: editTaskData.content,
    hashtags: editTaskData.hashtags,
    startDate: editTaskData.startDate.startOf('day').format("YYYY-MM-DDTHH:mm:ss"), // 로컬 시간으로 포맷팅
    endDate: editTaskData.endDate ? editTaskData.endDate.endOf('day').format("YYYY-MM-DDTHH:mm:ss") : null, // 로컬 시간으로 포맷팅
    termType: editTaskData.termType,
    termData: editTaskData.termData,
    color: editTaskData.color,
    times: editTaskData.times,
    routines: connectedRoutines.map((routine) => ({ routine_id: routine.id })),
    type: "task",
  }

  const response = await handleReactQueryApiResponse(updateTaskV2, () => set(error401ModalAtom, true), editTaskData.backendId, requestData);

  if (!response.ok) {
    set(snackbarAtom, { open: true, message: t("plan.contents.routine.snackbar.editTaskFail"), severity: "error" });
    set(editTaskInRoutineStateAtom, { ...editTaskData, isLoading: false });
    return;
  }

  const responseJson = await response.json();

  const { nodes, edges } = responseJson;

  const nodesIds = (nodes as Node[]).map((node) => node.id);
  const edgesIds = (edges as Edge[]).map((edge) => edge.id);

  const planNodes = plan.nodes;
  const planEdges = plan.edges;
  const planEdgesIds = planEdges.map((edge) => edge.id);

  const newNodes = planNodes.map((node) => {
    if (nodesIds.includes(node.id)) {
      return (nodes as Node[]).find((n) => n.id === node.id) as Node
    }
    return node;
  });
  console.log(nodes, nodesIds, newNodes);
  const newEdges = planEdges.map((edge) => {
    if (edgesIds.includes(edge.id)) {
      return (edges as Edge[]).find((e) => e.id === edge.id) as Edge
    }
    return edge;
  });
  const notIncludedEdges = (edges as Edge[]).filter((edge) => !planEdgesIds.includes(edge.id));
  const newEdgesWithNotIncluded = [...newEdges, ...notIncludedEdges];

  set(setInnerTabDataAtom, { innerTabId, nodes: newNodes, edges: newEdgesWithNotIncluded });
  set(snackbarAtom, { open: true, message: t("plan.contents.routine.snackbar.editTaskSuccess"), severity: "success" });
  const sidebarData = sidebar[sidebar.length - 1];
  if (sidebarData.type === "ganttEditTask") {
    console.log("ganttEditTask");
    set(clearRoutineSidebarDataAtom);
  } else {
    set(popAndChangeKeySidebarDataAtom, nodes[0]);
  }
});
