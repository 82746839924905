import { createTaskDialogAtom, createTaskDialogEditRoutineAtom, initCreateTaskDialogData } from "@/Atoms/Dialogs/Create/Task";
import { error401ModalAtom } from "@/Atoms/Dialogs/Error/401Atom";
import innerTabDataAtom from "@/Atoms/Plan/InnerTabDataAtom";
import snackbarAtom from "@/Atoms/Snackbar";
import { createTask } from "@/Queries/PlanQueries";
import { handleReactQueryApiResponse } from "@/Utils/APIUtil";
import GetIdFromQuerystring from "@/Utils/GetIdFromQuerystring";
import { setInnerTabDataAtom, updatePlanDataMindmapAtom } from "@/ViewModels/Plan/InnerTabViewModel";
import { getUserId } from "@/ViewModels/UserViewModel";
import { atom } from "jotai";

// 상태 초기화
export const createTaskDialogClearStateAtom = atom(null, (get, set) => {
  set(createTaskDialogAtom, initCreateTaskDialogData());
})

// 할 일 생성
export const createTaskDialogAddTaskAtom = atom(null, async (get, set) => {
  const planData = get(innerTabDataAtom);
  const innerTabId = GetIdFromQuerystring("inner_tab_id");
  const plan = planData.find((data) => data.innerTabId === innerTabId);
  const task = get(createTaskDialogAtom);
  if (!task.open || !plan) return;
  if (task.name === "") {
    set(snackbarAtom, (prev) => ({ ...prev, open: true, message: "이름을 입력해주세요.", severity: "error" }));
    return;
  }
  const firstNode = plan.nodes[0] ? plan.nodes[0] : {
    position: { x: 0, y: 0 },
  };
  const newNode = {
    id: task.open,
    position: { x: firstNode.position.x, y: firstNode.position.y + 200 },
    data: {
      id: task.open,
      backendId: 0,
      userId: get(getUserId),
      label: task.name,
      startDate: task.startDate ? task.startDate.toISOString() : null,
      endDate: task.endDate ? task.endDate.toISOString() : null,
      content: task.content,
      isNewCreated: true,
      termType: task.termType,
      termData: task.termData,
      location: task.location,
      taskStatus: [],
      hashtags: task.hashtags,
    },
    type: "task",
    measured: {
      width: 410,
      height: 176
    },
  }
  const response = await handleReactQueryApiResponse(createTask, () => set(error401ModalAtom, true), innerTabId, newNode);
  if (!response.ok) return;
  const jsonedResponse = await response.json();
  const updateNewNode = {
    ...newNode,
    data: {
      ...newNode.data,
      backendId: jsonedResponse.backendId,
    },
  }

  const newNodes = [...plan.nodes, updateNewNode];
  set(setInnerTabDataAtom, { innerTabId, nodes: newNodes, edges: plan.edges });
  // const newNodes = [...plan.nodes, newNode];
  // set(updatePlanDataMindmapAtom, innerTabId, newNodes, plan.edges);
  set(createTaskDialogClearStateAtom);
  set(snackbarAtom, (prev) => ({ ...prev, open: true, message: "할 일이 생성되었습니다.", severity: "success" }));
});

// 크루에서 할 일 생성
export const createTaskDialogAddTaskInCrewAtom = atom(null, async (get, set) => {
  const planData = get(innerTabDataAtom);
  const innerTabId = GetIdFromQuerystring("inner_tab_id");
  const plan = planData.find((data) => data.innerTabId === innerTabId);
  const task = get(createTaskDialogAtom);
  if (!task.open || !plan) return;
  if (task.name === "") {
    set(snackbarAtom, (prev) => ({ ...prev, open: true, message: "이름을 입력해주세요.", severity: "error" }));
    return;
  }
  const firstNode = plan.nodes[0] ? plan.nodes[0] : {
    position: { x: 0, y: 0 },
  };
  const newNode = {
    id: task.open,
    position: { x: firstNode.position.x, y: firstNode.position.y + 200 },
    data: {
      id: task.open,
      backendId: 0,
      userId: get(getUserId),
      label: task.name,
      startDate: task.startDate ? task.startDate.toISOString() : null,
      endDate: task.endDate ? task.endDate.toISOString() : null,
      content: task.content,
      isNewCreated: true,
      termType: task.termType,
      termData: task.termData,
      location: task.location,
      taskStatus: [],
      hashtags: task.hashtags,
      authorId: get(getUserId)
    },
    type: "task",
    measured: {
      width: 410,
      height: 176
    },
  }
  const response = await handleReactQueryApiResponse(createTask, () => set(error401ModalAtom, true), innerTabId, newNode);
  if (!response.ok) return;
  const jsonedResponse = await response.json();
  const updateNewNode = {
    ...newNode,
    data: {
      ...newNode.data,
      backendId: jsonedResponse.backendId,
    },
  }

  const newNodes = [...plan.nodes, updateNewNode];
  set(setInnerTabDataAtom, { innerTabId, nodes: newNodes, edges: plan.edges });
  // const newNodes = [...plan.nodes, newNode];
  // set(updatePlanDataMindmapAtom, innerTabId, newNodes, plan.edges);
  set(createTaskDialogClearStateAtom);
  set(snackbarAtom, (prev) => ({ ...prev, open: true, message: "할 일이 생성되었습니다.", severity: "success" }));
});

// key에 따라 상태 변경
export const createTaskDialogUpdateStateAtom = atom((get) => get(createTaskDialogAtom), (get, set, key: string, value: any) => {
  if (key === "termData") {
    const weekDays = ["월", "화", "수", "목", "금", "토", "일"];
    get(createTaskDialogAtom).termType === 1 ?
      value.sort((a: string, b: string) => weekDays.indexOf(a) - weekDays.indexOf(b)) : value.sort((a: string, b: string) => parseInt(a) - parseInt(b));
  }
  set(createTaskDialogAtom, (prev) => ({
    ...prev,
    [key]: value,
  }));
});

// 장소 변경
export const createTaskDialogEditLocationAtom = atom(null, (get, set, address: string) => {
  set(createTaskDialogAtom, (prev) => ({
    ...prev,
    location: { address: address, latitude: prev.location.latitude, longitude: prev.location.longitude },
  }));
})

// 루틴 옵션 변경
export const createTaskDialogSetRoutineOptionAtom = atom(null, (get, set, key: number) => {
  set(createTaskDialogAtom, (prev) => ({
    ...prev,
    termType: prev.termType === key ? null : key,
    termData: prev.termType === key ? null : [],
  }));
});

// 루틴 일자 변경
export const createTaskDialogEditRoutineDataAtom = atom((get) => get(createTaskDialogEditRoutineAtom), (get, set, day: string) => {
  const editRoutineData = get(createTaskDialogEditRoutineAtom);
  if (!editRoutineData.termData) return;
  if (editRoutineData.termData.includes(day)) {
    set(createTaskDialogEditRoutineAtom, { termType: editRoutineData.termType, termData: editRoutineData.termData.filter((interval) => interval !== day) });
  } else {
    set(createTaskDialogEditRoutineAtom, { termType: editRoutineData.termType, termData: [...editRoutineData.termData, day] });
  }
});

// 루틴 일자 초기화
export const createTaskDialogEditRoutineClearStateAtom = atom(null, (get, set) => {
  set(createTaskDialogEditRoutineAtom, {
    termType: null,
    termData: null,
  });
});

// 루틴 변경 적용
export const createTaskDialogEditRoutineApplyAtom = atom(null, (get, set) => {
  const editRoutineData = get(createTaskDialogEditRoutineAtom);
  set(createTaskDialogAtom, (prev) => ({
    ...prev,
    termData: editRoutineData.termData,
  }));
  set(createTaskDialogEditRoutineClearStateAtom);
});

// 루틴 변경 시작
export const createTaskDialogEditRoutineStartAtom = atom(null, (get, set) => {
  const termData = get(createTaskDialogAtom).termData;
  set(createTaskDialogEditRoutineAtom, {
    termType: get(createTaskDialogAtom).termType,
    termData: termData ? [...termData] : [],
  });
});

// 해시태그 추가
export const createTaskDialogAddHashtagAtom = atom(null, (get, set, hashtag: { backgroundColor: string, textColor: string, value: string }) => {
  set(createTaskDialogAtom, (prev) => ({
    ...prev,
    hashtags: [...prev.hashtags, hashtag],
  }));
});

// 해시태그 삭제(pop)
export const createTaskDialogRemoveHashtagAtom = atom(null, (get, set) => {
  set(createTaskDialogAtom, (prev) => ({
    ...prev,
    hashtags: prev.hashtags.slice(0, -1),
  }));
});
