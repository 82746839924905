import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 5px;
  align-items: flex-start;
`;

export const EachRowConatiner = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
  align-items: center;
`;

export const Title = styled.div`
  font-size: 1rem;
  font-weight: 700;
`;

export const MembersContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 2px;
  flex-wrap: wrap;
`;

export const Image = styled.img<{ $isOpacity: boolean }>`
  width: 30px;
  height: 30px;
  border-radius: 50%;
  opacity: ${({ $isOpacity }) => ($isOpacity ? "0.5" : "1")};
  margin: 0px;
`;

export const HashTagContainer = styled.div`
  display: flex;
  gap: 10px;
`;

export const HashTag = styled.div<{ $color: string, $backgroundColor: string }>`
  padding: 5px;
  border-radius: 10px;
  background-color: ${({ $backgroundColor }) => $backgroundColor};
  color: ${({ $color }) => $color};
`;
