import React from 'react';
import *  as S from './styled';
import { useAtom, useSetAtom } from 'jotai';
import { editRoutineInRoutineStateAtom, editTaskInRoutineStateAtom, RoutineSidebarProps } from '@/Atoms/Plan/RoutineAtom';
import { Button, Popover } from '@mui/material';
import { ChromePicker } from 'react-color';
import { useTranslation } from 'react-i18next';
import TitleValueInARow from '../Common/TitleValueInARow';
import theme from '@/Styles/theme';
import { handleReactQueryApiResponse } from '@/Utils/APIUtil';
import { uploadPlanFeedImage, uploadTaskImage } from '@/Queries/PlanFeedQueries';
import GetIdFromQuerystring from '@/Utils/GetIdFromQuerystring';
import { error401ModalAtom } from '@/Atoms/Dialogs/Error/401Atom';
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { devConsoleError } from '@/Utils/ConsoleLogInDevelopment';
import useIsMobile from '@/Hooks/useIsMobile';
import TitleValueInEachRow from '../Common/TitleValueInEachRow';
import { editRoutineInRoutineUpdateRoutineAtom } from '@/ViewModels/Plan/Routine/EditRoutineViewModel';

interface Props {
  data: RoutineSidebarProps;
}

const EditRoutineInRoutine = ({ data }: Props) => {
  // const sidebar = useAtomValue(routineSidebarAtom);
  // const data = sidebar[sidebar.length - 1];
  const { isMobile } = useIsMobile();
  const [editRoutineState, setEditRoutineState] = useAtom(editRoutineInRoutineStateAtom);
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const innerTabId = GetIdFromQuerystring("inner_tab_id");
  const setError401Modal = useSetAtom(error401ModalAtom);
  const quillRef = React.useRef<ReactQuill | null>(null);
  const backendId = React.useMemo(() => data.key?.data.backendId as number || 0, [data.key?.data.backendId]);
  const updateRoutine = useSetAtom(editRoutineInRoutineUpdateRoutineAtom);

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const handleInputChange = (field: string, value: any) => {
    if (!editRoutineState) return;
    const newTaskState = {
      ...editRoutineState,
      [field]: value,
    }
    setEditRoutineState(newTaskState);
  };

  const imageHandler = async () => {
    const input = document.createElement("input");
    input.setAttribute("type", "file");
    input.setAttribute("accept", "image/*");
    input.click();

    input.addEventListener("change", async () => {
      // input.files가 null이 아닌지 확인
      if (!input.files || input.files.length === 0) return;

      const file = input.files[0];
      try {
        const imgUrlResponse = await handleReactQueryApiResponse(uploadTaskImage, () => setError401Modal(true), file, innerTabId, backendId);
        if (!imgUrlResponse.ok) {
          devConsoleError(imgUrlResponse);
          return;
        }
        const imgUrlResponseJson = await imgUrlResponse.json();
        if (imgUrlResponseJson && quillRef.current) { // quillRef.current가 null이 아닌지 확인
          const imgUrl = process.env.REACT_APP_DEV_BACK_IMAGE_ADDRESS + imgUrlResponseJson.image_url;
          // const imgUrl = "http://10.10.112.148:8002/plan_images" + imgUrlResponse.image_url;
          const editor = quillRef.current.getEditor();
          const range = editor.getSelection();
          if (range) {
            editor.insertEmbed(range.index, "image", imgUrl);
            editor.setSelection((range.index + 1) as any);
          }
        }
      } catch (error) {
        devConsoleError(error);
      }
    });
  };

  // 모바일과 데스크톱 버전의 툴바 옵션 분리
  const modules = React.useMemo(() => {
    const desktopToolbar = [
      [{ header: "1" }, { header: "2" }, { font: [] }],
      [{ list: "ordered" }, { list: "bullet" }],
      ["bold", "italic", "underline", "strike"],
      [{ color: [] }, { background: [] }],
      [{ align: [] }],
      ["link", "image"],
      ["clean"],
    ];

    const mobileToolbar = [
      ["bold", "italic", "underline"],
      [{ list: "ordered" }, { list: "bullet" }],
      ["image"],
    ];

    return {
      toolbar: {
        container: isMobile ? mobileToolbar : desktopToolbar,
        handlers: { image: imageHandler },
      },
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMobile, backendId]);

  // Quill의 formats 설정
  const formats = [
    "header",
    "font",
    "list",
    "bullet",
    "bold",
    "italic",
    "underline",
    "strike",
    "color",
    "background",
    "align",
    "link",
    "image",
  ];

  return (
    editRoutineState &&
    <div style={{ width: "100%", height: "100%", overflowY: "auto", display: "flex", flexDirection: "column", gap: "20px", position: "relative" }}>
      {/* 할 일 이름 */}
      <S.CustomInput placeholder={t("plan.contents.routine.sidebar.content.routineLabel")} value={editRoutineState.label} onChange={(e) => handleInputChange('label', e.target.value)} />

      {/* 색상 선택 */}
      <TitleValueInARow
        title={t("plan.contents.routine.sidebar.content.color")}
        value={
          <div
            style={{
              width: '40px',
              height: '40px',
              backgroundColor: editRoutineState.color || theme.colors.primary,
              borderRadius: '50%',
              cursor: 'pointer',
            }}
            onClick={handlePopoverOpen}
          />
        }
      />
      <Popover
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handlePopoverClose}
      >
        <ChromePicker
          color={editRoutineState.color}
          onChangeComplete={(color) => handleInputChange('color', color.hex)}
        />
      </Popover>

      <TitleValueInEachRow title={t("plan.contents.routine.sidebar.content.content")} value={
        <S.CustomInputField placeholder={t("plan.contents.routine.sidebar.content.content")} value={editRoutineState.content} onChange={(e) => handleInputChange('content', e.target.value)} />
      } />

      {/* <TitleValueInEachRow title="설명" value={
        <div style={{ width: "100%", minHeight: isMobile ? "250px" : "350px" }}>
          <ReactQuill
            ref={quillRef}
            theme="snow"
            style={{ height: isMobile ? "200px" : "300px" }}
            modules={modules}
            formats={formats}
            value={editRoutineState.content}
            onChange={(content) => handleInputChange('content', content)}
          />
        </div>
      }
      /> */}

      <div style={{ width: "100%", minHeight: "40px" }} />

      {/* 버튼 - 하단 고정  */}
      <Button
        variant='contained'
        style={{ backgroundColor: editRoutineState.isLoading ? "#ccc" : theme.colors.primary, color: 'white', fontWeight: 'bold', cursor: 'pointer', width: "calc(100% - 40px)", position: "fixed", bottom: "20px" }}
        disabled={editRoutineState.isLoading}
        onClick={() => updateRoutine(t)}
      >
        {t("plan.contents.routine.sidebar.content.edit")}
      </Button>
    </div>
  )
}

export default EditRoutineInRoutine;
