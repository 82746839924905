import styled from "styled-components";

export const CreateTaskButton = styled.div`
  width: calc(100% - 3px);
  min-height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #999;
  font-size: 12px;
  cursor: pointer;
  border: 1px dashed #999;
  border-radius: 10px;
  font-weight: bold;

  &:hover {
    border: 1px dashed #000;
    color: #000;
  }
`;

export const TaskButton = styled.div<{ $isSelected: boolean }>`
  width: calc(100% - 3px);
  min-height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  cursor: pointer;
  border: ${({ $isSelected }) => $isSelected ? "2px solid #000" : "1px solid #d9d9d9"};
  border-radius: 10px;
  font-size: 12px;

  &:hover {
    border: ${({ $isSelected }) => $isSelected ? "2px solid #000" : "1px solid #000"};
  }
`;
