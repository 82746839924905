import React from 'react';
import { Dialog, DialogTitle, Box, Button, Checkbox, Menu, MenuItem, TextField, Typography, Alert, AlertTitle } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { Dayjs } from 'dayjs';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import StoryTabs from './tabs';
import theme from '../../../../../Styles/theme';
import dayjs from 'dayjs';
import TemplateMetadata from '../../../../../Metadata/template';
import { StoryDataProps, StoryProps, RequestStoryProps } from '../../../../../Atoms/StoryAtom';
import * as S from './styled';
import getRandomColorWithText from '../../../../../Utils/GetRandomColor';
import { useAtomValue, useSetAtom } from 'jotai';
import { createStoryAtom } from '../../../../../ViewModels/StoryViewModel';
import { getUserPosition } from '@/ViewModels/UserViewModel';

interface StoryDialogProps<T extends RequestStoryProps> {
  isEdit: boolean;
  isStoryDialogOpen: "template" | "story" | null;
  setIsStoryDialogOpen: React.Dispatch<React.SetStateAction<"template" | "story" | null>>;
  story: T;
  setStory: React.Dispatch<React.SetStateAction<T>>;
  editingStory: StoryProps | null;
  tabValue: number;
  setTabValue: React.Dispatch<React.SetStateAction<number>>;
}

const StoryDialog = <T extends RequestStoryProps | StoryProps>({ isEdit, isStoryDialogOpen, setIsStoryDialogOpen, story, setStory, editingStory, tabValue, setTabValue }: StoryDialogProps<T>) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [isToday, setIsToday] = React.useState(false);
  const [selectedDate, setSelectedDate] = React.useState<Dayjs | null>(null);
  const [template, setTemplate] = React.useState<{ title: string } | null>(null);
  const [tags, setTags] = React.useState<StoryDataProps[]>([]);
  const [inputValue, setInputValue] = React.useState("");
  const [errorMessage, setErrorMessage] = React.useState<string | null>(null); // 에러 메시지 상태 관리
  const addStory = useSetAtom(createStoryAtom);
  const userPosition = useAtomValue(getUserPosition);

  const clearState = () => {
    setStory((prev) => ({
      ...prev,
      title: "",
      content: "",
      date: null,
      hashtags: [],
    }));
    setTemplate(null);
    setTags([]);
    setInputValue("");
    setIsToday(false);
    setSelectedDate(null);
    setErrorMessage(null);
    setIsStoryDialogOpen(null)
  }

  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setTabValue(newValue);
    setIsStoryDialogOpen(newValue === 0 ? "story" : "template");
  };

  const updateStory = <K extends keyof T>(key: K, value: T[K]) => {
    setStory({ ...story, [key]: value });
  };

  const handleMenuItemClick = (item: { title: string }) => {
    setTemplate(item);
    setAnchorEl(null);
  };

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsToday(e.target.checked);
    if (e.target.checked) {
      setSelectedDate(dayjs());
    } else {
      setSelectedDate(null);
    }
  };

  const handleDateChange = (date: Dayjs | null) => {
    if (date) {
      if (date.isValid()) {
        setSelectedDate(date);
        setErrorMessage(null);
      } else if (date.year() && (date.month() === 0 || date.date() === 1)) {
        // 연도 또는 연도와 월만 입력했을 때 1일로 기본 설정
        const correctedDate = dayjs(`${date.year()}-${date.month() + 1}-01`);
        setSelectedDate(correctedDate);
      } else {
        setErrorMessage("올바른 날짜 형식을 입력해주세요. (YYYY.MM.DD)");
      }
    } else {
      setSelectedDate(null);
    }
  };

  const handleSubmit = () => {
    const { backgroundColor, textColor } = getRandomColorWithText();
    if (!isToday && !selectedDate) {
      setErrorMessage("올바른 날짜 형식을 입력해주세요. (YYYY.MM.DD)");
      return;
    }
    const newDate = story.date ? story.date : {
      background_color: backgroundColor,
      text_color: textColor,
      value: isToday
        ? dayjs().format('YYYY년 MM월 DD일')
        : dayjs(selectedDate?.toDate()).format('YYYY년 MM월 DD일'),
    };

    const storyData: T = {
      ...story,
      title: isStoryDialogOpen === "template" ? template?.title || "" : story.title,
      date: newDate,
      hashtags: tags,
    };

    // 서버 전송 전 검증 절차: title과 content가 비어있을 경우 오류 메시지 표시
    if (!storyData.title || !storyData.content) {
      setErrorMessage("제목과 내용을 모두 입력해주세요.");
      return; // 검증 실패 시 함수 종료
    }

    if (editingStory) {
      // editStory(storyData as StoryProps); // editStory는 StoryProps 사용
    } else {
      addStory(storyData as RequestStoryProps); // addStory는 RequestStoryProps 사용
    }

    clearState();
  };

  // 엔터키 입력 시 태그 추가
  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter' && inputValue.trim()) {
      event.preventDefault();
      const { backgroundColor, textColor } = getRandomColorWithText();
      if (!tags.find((tag) => tag.value === inputValue.trim())) {
        setTags([...tags, {
          background_color: backgroundColor,
          text_color: textColor,
          value: inputValue.trim(),
        }]);
      }
      setInputValue(""); // 입력창 초기화
    } else if (event.key === 'Backspace' && inputValue === "") {
      if (tags.length > 0) {
        event.preventDefault();
        const updatedTags = [...tags];
        updatedTags.pop();
        setTags(updatedTags);
      }
    }
  };

  return (
    <Dialog
      open={isStoryDialogOpen !== null}
      onClose={clearState}
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle>{isEdit ? "내 스토리 수정" : "내 스토리 작성"}</DialogTitle>

      {/* Tabs (글, 템플릿) */}
      {
        userPosition !== "멘토" &&
        <StoryTabs
          tabLabels={["글", "템플릿"]}
          tabValue={isStoryDialogOpen === "story" ? tabValue : 1}
          onTabChange={handleTabChange}
        />
      }

      {/* Content */}
      {/* 글 작성 */}
      {
        tabValue === 0 ?
          <Box padding={2}>
            <TextField
              variant="standard"
              fullWidth
              margin="normal"
              value={story.title}
              placeholder='제목을 입력하세요.'
              onChange={(e) => updateStory("title", e.target.value)}
            />
            <TextField
              placeholder='내용을 입력하세요.'
              variant="standard"
              fullWidth
              multiline
              rows={4}
              margin="normal"
              value={story.content}
              onChange={(e) => updateStory("content", e.target.value)}
            />
          </Box>
          :
          <Box padding={2}>
            <Button
              sx={{
                width: "100%",
                color: "black",
                padding: "0 0 8px 0",
                textTransform: "none",
                fontSize: "1rem",
                borderBottom: "1px solid" + theme.colors.darkBackground,
              }}
              aria-haspopup="true"
              onClick={(event) => setAnchorEl(event.currentTarget)}
              endIcon={<KeyboardArrowDownIcon />}
            >
              {template ? template.title : "템플릿 선택"}
            </Button>
            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={() => setAnchorEl(null)}
              MenuListProps={{
                'aria-labelledby': 'basic-button',
              }}
            >
              {TemplateMetadata.map((item, index) => (
                <MenuItem key={index} onClick={() => handleMenuItemClick(item)}>
                  {item.title}
                </MenuItem>
              ))}
            </Menu>
            {
              template &&
              <TextField
                placeholder='내용을 입력하세요.'
                variant="standard"
                fullWidth
                multiline
                rows={4}
                margin="normal"
                value={story.content}
                onChange={(e) => updateStory("content", e.target.value)}
              />
            }
          </Box>
      }

      {/* Footer */}
      <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
        <Box display="flex" justifyContent="space-between" padding={2} alignItems="center">
          <Typography variant="body2" style={{ marginRight: "8px" }}>
            해시태그
          </Typography>
          <S.TagInputContainer>
            {tags.map((tag, idx) => {
              return <S.TagItem key={idx} style={{ backgroundColor: tag.background_color, color: tag.text_color }}>
                <span>{tag.value}</span>
              </S.TagItem>
            })}
            <S.TagInput
              placeholder={tags.length === 0 ? "태그를 입력해보세요." : ""}
              value={inputValue}
              onChange={(e) => setInputValue(e.target.value)}
              onKeyDown={handleKeyDown}
            />
          </S.TagInputContainer>
        </Box>
        <Box display="flex" justifyContent="space-between" padding={2} alignItems="center">
          <Box display="flex" gap={1} alignItems="center">
            <Box display="flex" alignItems="center">
              <Typography variant="body2" style={{ marginRight: "8px" }}>
                오늘의 스토리
              </Typography>
              <Checkbox checked={isToday} onChange={handleCheckboxChange} />
            </Box>
            {isToday ?
              <Typography variant="body2">
                {selectedDate?.format('YYYY.MM.DD')}
              </Typography>
              :
              <DatePicker
                label="날짜 선택"
                sx={{ width: "200px" }}
                value={selectedDate}
                onChange={handleDateChange}
                format="YYYY.MM.DD"
              />
            }
          </Box>
          <Button
            variant="contained"
            // color="primary"
            size="large"
            onClick={handleSubmit}
            sx={{
              backgroundColor: `${theme.colors.primary}`,
            }}
          >
            등록
          </Button>
        </Box>
      </div>
      {errorMessage && (
        <Alert severity="error" onClose={() => setErrorMessage(null)}>
          <AlertTitle>입력 오류</AlertTitle>
          {errorMessage}
        </Alert>
      )}
    </Dialog>
  );
};

export default StoryDialog;
