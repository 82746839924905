import { atom } from "jotai";
import { PlanHipCrew } from "./PlanHip/Atom";

export interface CrewMember {
  userId: number;
  profileImageUrl: string;
  nickname: string;
  authority: number;
}

export interface Crew {
  crewInfo: PlanHipCrew;
  members: CrewMember[];
}

const crewAtom = atom<Crew | null>(null);

export default crewAtom;
