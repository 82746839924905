import React from 'react';
import dayjs from 'dayjs';
import * as S from './styled';
import { Button, Typography } from '@mui/material';
import { useAtom, useAtomValue } from 'jotai';
import { routineCurrentDateAtom, routineDateModeAtom } from '@/Atoms/Plan/RoutineAtom';
import FullCalendar from '@fullcalendar/react';
import { styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import weekOfYear from "dayjs/plugin/weekOfYear";
import isoWeek from "dayjs/plugin/isoWeek";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import isBetween from "dayjs/plugin/isBetween";
import utc from "dayjs/plugin/utc";
import theme from '@/Styles/theme';
import { lighten } from 'polished';

dayjs.extend(isBetween);
dayjs.extend(isSameOrBefore);
dayjs.extend(isSameOrAfter);
dayjs.extend(weekOfYear);
dayjs.extend(isoWeek);
dayjs.extend(utc);


const CustomButton = styled(Button)(() => ({
  minWidth: '20px',
  minHeight: '20px',
  padding: '0',
  borderColor: '#aaa', // 기본 border 색상
  color: 'black', // 텍스트 색상
  '&:hover': {
    borderColor: 'black', // hover 시 border 색상
  },
  '@media (max-width: 600px)': {
    minWidth: '15px',
    minHeight: '15px',
  },
}));

const CustomTodayButton = styled(Button)(() => ({
  backgroundColor: theme.colors.primary,
  color: 'white',
  '&:hover': {
    backgroundColor: lighten(0.1, theme.colors.primary),
  },
}));

interface DateNavigatorProps {
  calendarRef: React.RefObject<FullCalendar>;
}

const DateNavigator = ({ calendarRef }: DateNavigatorProps) => {
  const dateMode = useAtomValue(routineDateModeAtom);
  const [currentDate, setCurrentDate] = useAtom(routineCurrentDateAtom);
  const { t } = useTranslation();

  const handleDateChange = (newDate: Date) => {
    setCurrentDate(newDate);

    // FullCalendar API를 통해 날짜를 변경
    if (calendarRef.current) {
      const calendarApi = calendarRef.current.getApi();
      calendarApi.gotoDate(newDate); // FullCalendar의 날짜 변경
    }
  };

  // 이전 날짜로 이동
  const handlePrev = () => handleDateChange(dayjs(currentDate).subtract(1, dateMode === "Month" ? "month" : dateMode === "Week" ? "week" : "day").toDate());

  // 다음 날짜로 이동
  const handleNext = () => handleDateChange(dayjs(currentDate).add(1, dateMode === "Month" ? "month" : dateMode === "Week" ? "week" : "day").toDate());

  // 날짜 포맷 설정
  const getDateDisplay = () => {
    const dayjsDate = dayjs(currentDate);
    if (dateMode === 'Month') {
      return t('plan.contents.routine.dateNavigator.monthFormat', {
        year: dayjsDate.year(),
        month: dayjsDate.month() + 1,
        monthEn: dayjsDate.format('MMMM'),
      });
    } else if (dateMode === 'Week') {
      const startOfWeek = dayjsDate.startOf('isoWeek');
      const endOfWeek = dayjsDate.endOf('isoWeek');
      return t('plan.contents.routine.dateNavigator.weekFormat', {
        year: dayjsDate.year(),
        startMonth: startOfWeek.month() + 1,
        endMonth: endOfWeek.month() + 1,
        startDay: startOfWeek.date(),
        endDay: endOfWeek.date(),
        startMonthEn: startOfWeek.format('MMMM'),
        endMonthEn: endOfWeek.format('MMMM'),
      });
    } else {
      return t('plan.contents.routine.dateNavigator.dayFormat', {
        year: dayjsDate.year(),
        month: dayjsDate.month() + 1,
        date: dayjsDate.date(),
        day: dayjsDate.date(),
        monthEn: dayjsDate.format('MMMM'),
      });
    }
  };

  const isToday = React.useMemo(() => {
    return dayjs(currentDate).isSame(dayjs(), dateMode === "Month" ? "month" : dateMode === "Week" ? "week" : "day");
  }, [currentDate, dateMode]);

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'center',
        gap: '10px',
      }}
    >
      {/* 이전 버튼 */}
      <CustomButton
        onClick={handlePrev}
        variant="outlined"
        size="small"
      >
        &lt;
      </CustomButton>

      {/* 날짜 표시 */}
      <S.DateDisplay>
        {getDateDisplay()}
      </S.DateDisplay>
      {/* <Typography variant="body1" style={{ fontWeight: 'bold' }}>
        {getDateDisplay()}
      </Typography> */}

      {/* 다음 버튼 */}
      <CustomButton
        onClick={handleNext}
        variant="outlined"
        size="small"
      >
        &gt;
      </CustomButton>
      {
        !isToday &&
        <CustomTodayButton
          onClick={() => handleDateChange(new Date())}
          variant="contained"
          size="small"
        >{t("plan.contents.routine.header.today")}</CustomTodayButton>
      }
    </div>
  );
};

export default DateNavigator;
