interface UpdateUserInfoRequest {
  motto: string; // 좌우명
  dream: string; // 꿈
  goal: string; // 목표
  ultimate: string; // 필살기
  grade: number; // 성적
  job: string; // 선호 직업
  interest: string[]; // 관심사
  preference: string[]; // 취향
  tendency: string[]; // 성향
  career: string[]; // 진로진학 설정
  worry: string[]; // 고민
}

// update user information by user id
export const updateUserInfo = async (access: string, userId: number, data: UpdateUserInfoRequest) => {
  const response = await fetch(`${process.env.REACT_APP_BACK_INTEREST_ADDRESS}?user_id=${userId}`, {
    method: "PUT",
    headers: {
      "accept": "application/json",
      "Content-Type": "application/json",
      "Authorization": `Bearer ${access}`,
    },
    body: JSON.stringify(data),
  });
  return response;
}

// get user information by user id
export const getUserById = async (access: string, userId: number) => {
  // try {
  const response = await fetch(`${process.env.REACT_APP_USER_API_ADDRESS}/mysql/getUserInfoByIndex`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "accept": "application/json",
      "Authorization": `Bearer ${access}`,
    },
    body: JSON.stringify({ "user_id": userId }),
  });
  return response;
};

// update user nickname by user id
export const updateNickname = async (access: string, userId: number, nickname: string) => {
  const response = await fetch(`${process.env.REACT_APP_USER_API_ADDRESS}/mysql/updateNickname`, {
    method: "POST",
    headers: {
      "Authorization": `Bearer ${access}`,
      "Content-Type": "application/json",
      "accept": "application/json",
    },
    body: JSON.stringify({ "id": userId, "nickname": nickname }),
  });
  return response;
}

// upload user image
export const uploadUserImage = async (access: string, userId: number, file: File) => {
  const formData = new FormData();
  formData.append("id", userId.toString());
  formData.append("profile_image", file);
  const response = await fetch(`${process.env.REACT_APP_USER_API_ADDRESS}/mysql/updateImage`, {
    method: "POST",
    headers: {
      "Authorization": `Bearer ${access}`,
    },
    body: formData,
  });
  return response;
}

// update user language by user id
export const updateLanguage = async (access: string, userId: number, language: string) => {
  const response = await fetch(`${process.env.REACT_APP_USER_API_ADDRESS}/mysql/updateLanguage`, {
    method: "POST",
    headers: {
      "Authorization": `Bearer ${access}`,
      "Content-Type": "application/json",
      "accept": "application/json",
    },
    body: JSON.stringify({ "id": userId, "language": language }),
  });
  return response;
}
