import { useQuery } from 'react-query';
import { getPlans, parseGetPlansResponse } from '@/Queries/PlanQueries';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import planAtom from '@/Atoms/Plan';
import { getUserId } from '@/ViewModels/UserViewModel';
import { usePlansAtom } from '@/Atoms/HookControllerAtom';
import { handleReactQueryApiResponse } from '@/Utils/APIUtil';
import { error401ModalAtom } from '@/Atoms/Dialogs/Error/401Atom';

export const usePlans = () => {
  const setPlan = useSetAtom(planAtom);
  const userId = useAtomValue(getUserId);
  const [controller, setController] = useAtom(usePlansAtom);
  const setError401Modal = useSetAtom(error401ModalAtom);

  return useQuery(['plans', userId], () => handleReactQueryApiResponse(getPlans, () => setError401Modal(true), userId), {
    enabled: controller,
    onSuccess: async (data) => {
      if (!data.ok) return;
      setController(false);
      const clonedResponse = data.clone();

      try {
        const jsonedData = await clonedResponse.json(); // Read the cloned response
        const planData = parseGetPlansResponse(userId, jsonedData);
        setPlan(planData);
      } catch (error) {
        console.error("Error parsing JSON:", error);
      }
    },
  });
};
