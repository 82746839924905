import React from "react";
import {
  Box,
  CircularProgress,
  Divider,
  Stack,
} from "@mui/material";
import * as S from "../styled";
import theme from "../../../../Styles/theme";
import { useAtomValue } from "jotai";
import { dashboardMenteeContentSearchAtom } from "@/Atoms/Dashboard/MenteeAtom";
import KRImage from "@/Assets/Images/Countries/korea.jpg";
import USImage from "@/Assets/Images/Countries/usa.jpg";
import { useTranslation } from "react-i18next";
import { userLanguageAtom } from "@/ViewModels/UserViewModel";
import { ResponsiveContentGrid } from "./containers";

export function RecommendContents() {
  const contentSearch = useAtomValue(dashboardMenteeContentSearchAtom);
  const language = useAtomValue(userLanguageAtom);
  const { t } = useTranslation();

  return (
    <S.CustomBox sx={{ gap: "10px" }}>
      <Stack
        direction={"row"}
        sx={{ justifyContent: "space-between", alignItems: "center" }}
      >
        <S.TitleTypo>{t("dashboard.mentee.recommendedContents.title")}</S.TitleTypo>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: "10px",
          }}
        >
          <div
            style={{
              width: "40px",
              height: "40px",
              display: "flex",
              alignItems: "center",
            }}
          >
            {
              language === "KR" ?
                <img
                  src={KRImage}
                  alt="한국"
                  style={{ width: "40px", height: `${(40 * 2) / 3}px` }}
                /> :
                <img
                  src={USImage}
                  alt="미국"
                  style={{ width: "40px", height: `${(40 * 2) / 3}px` }}
                />
            }
          </div>
        </div>
      </Stack>
      <Divider />
      <S.DescTypo>{t("dashboard.mentee.recommendedContents.description")}</S.DescTypo>
      {contentSearch.isLoading ? (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            height: "100%",
            justifyContent: "center",
            alignItems: "center",
            gap: "50px",
          }}
        >
          <div style={{ fontSize: "0.9rem", fontWeight: "bold" }}>
            {t("dashboard.mentee.recommendedContents.loading")}
          </div>
          <CircularProgress />
        </div>
      ) : (
        // <div style={{ display: "flex", flexDirection: "row", flexWrap: "wrap", gap: "10px", flex: 1, overflowY: "auto" }}>
        //   {contentSearch.contentSearch.map((data, index) => (
        //     <div
        //       key={`stack-${index}`}
        //       style={{ display: "flex", flexDirection: "column", gap: "5px", alignItems: "center", justifyContent: "center", width: "200px" }}
        //       onClick={() => (window.location.href = data.link)}
        //     >
        //       {language === "KR" && <Box
        //         component="img"
        //         src={data.image !== "N/A" ? data.image : "https://img.lovepik.com/free-template/bg/20200421/bg/c5c7556e6a18f.png_list.jpg"}
        //         alt="book"
        //         sx={{
        //           width: 150,
        //           objectFit: "cover",
        //         }}
        //       />}
        //       <S.UnivTypo>{data.name}</S.UnivTypo>
        //       <S.DepartmentTypo>{data.author}</S.DepartmentTypo>
        //       <div style={{ display: "flex", flexDirection: language === "KR" ? "row" : "column", gap: "5px", alignItems: "center" }}>
        //         {data.keywords.map((keyword, index) => (
        //           <S.KeywordBox
        //             key={`keywordbox-${index}`}
        //             color={theme.colors.primary}
        //           >
        //             {keyword}
        //           </S.KeywordBox>
        //         ))}
        //       </div>
        //     </div>
        //   ))}
        // </div>
        <ResponsiveContentGrid contentSearch={contentSearch.contentSearch} language={language} />
      )}
    </S.CustomBox>
  );
}
