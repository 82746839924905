import { Edge, Node } from "@xyflow/react";
import { atom } from "jotai";

export interface InnerTabData {
  innerTabId: number;
  nodes: Node[];
  edges: Edge[];
};

const innerTabDataAtom = atom<InnerTabData[]>([]);

export default innerTabDataAtom;
