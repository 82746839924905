import styled from 'styled-components';

export const CustomInput = styled.input`
  width: calc(100% - 33px);
  min-height: 30px;
  padding: 5px 15px;
  border: 1px solid #999;
  border-radius: 10px;
  font-size: 14px;

  &:focus {
    outline: none;
    border: 1px solid #000;
  }

  &::placeholder {
    color: #999;
  }

  &:hover {
    border: 1px solid #000;
  }
`;
