import { Edge, Node } from '@xyflow/react';
import { atom } from 'jotai';

export const recommendPlanSearchInputValueAtom = atom<string>(''); // 검색어 입력값
export const recommendPlanSearchInputListAtom = atom<string[]>([]); // 검색어 리스트

export interface RecommendPlanItem {
  planId: number;
  innerTabId: number;
  userName: string;
  planName: string;
  hashtag: {
    backgroundColor: string;
    textColor: string;
    value: string;
  }[];
  usage: number;
  views: number;
  imageUrl: string;
  content: string;
}

export const recommendPlanListAtom = atom<RecommendPlanItem[]>([]); // 추천 플랜 리스트

export const recommendPlanModalStateAtom = atom<RecommendPlanItem | null>(null); // 추천 플랜 모달 상태

interface RecommendPlanModalData {
  nodes: Node[];
  edges: Edge[];
}

export const recommendPlanModalDataAtom = atom<RecommendPlanModalData | null>(null); // 추천 플랜 모달 데이터
