import { createUltimateModalAtom, createUltimateModalEditRoutineAtom, initCreateUltimateModalData } from "@/Atoms/Dialogs/Create/Ultimate";
import { error401ModalAtom } from "@/Atoms/Dialogs/Error/401Atom";
import innerTabDataAtom from "@/Atoms/Plan/InnerTabDataAtom";
import snackbarAtom from "@/Atoms/Snackbar";
import { createTask } from "@/Queries/PlanQueries";
import { createUltimate, CreateUltimateContent } from "@/Queries/RoutineQueries";
import { handleReactQueryApiResponse } from "@/Utils/APIUtil";
import GetIdFromQuerystring from "@/Utils/GetIdFromQuerystring";
import { setInnerTabDataAtom, updatePlanDataMindmapAtom } from "@/ViewModels/Plan/InnerTabViewModel";
import { getUserId } from "@/ViewModels/UserViewModel";
import { atom } from "jotai";
import { v4 as uuidv4 } from 'uuid';
import { textEditorCloseAtom } from "../TextEditorViewModel";

// 상태 초기화
export const createUltimateModalClearStateAtom = atom(null, (get, set) => {
  set(createUltimateModalAtom, null);
  set(textEditorCloseAtom);
})

export const createUltimateModalOpenAtom = atom(null, (get, set) => {
  const id = `ultimate-${uuidv4()}`;
  const initData = {
    ...initCreateUltimateModalData(),
    open: id,
  }
  set(createUltimateModalAtom, initData);
});


// 할 일 생성
export const createUltimateModalAddTaskAtom = atom(null, async (get, set) => {
  const planData = get(innerTabDataAtom);
  const innerTabId = GetIdFromQuerystring("inner_tab_id");
  const plan = planData.find((data) => data.innerTabId === innerTabId);
  const task = get(createUltimateModalAtom);
  if (!task || !plan) return;
  if (task.name === "") {
    set(snackbarAtom, (prev) => ({ ...prev, open: true, message: "이름을 입력해주세요.", severity: "error" }));
    return;
  }
  const ultimateContent: CreateUltimateContent = {
    label: task.name,
    content: task.content,
    location: task.location.address,
    hashtags: task.hashtags,
    startDate: task.startDate ? task.startDate.toISOString() : null,
    endDate: task.endDate ? task.endDate.toISOString() : null,
    termType: task.termType,
    termData: task.termData,
  }
  const response = await handleReactQueryApiResponse(createUltimate, () => set(error401ModalAtom, true), innerTabId, ultimateContent);
  if (!response.ok) {
    set(snackbarAtom, (prev) => ({ ...prev, open: true, message: "필살기 생성에 실패하였습니다.", severity: "error" }));
  }
  const responseJson = await response.json();
  // console.log("responseJson", responseJson);
  const newNode = responseJson.node;

  const newNodes = [...plan.nodes, newNode];
  set(setInnerTabDataAtom, { innerTabId, nodes: newNodes, edges: plan.edges });
  set(updatePlanDataMindmapAtom, innerTabId, newNodes, plan.edges);
  set(createUltimateModalClearStateAtom);
  set(snackbarAtom, (prev) => ({ ...prev, open: true, message: "필살기가 생성되었습니다.", severity: "success" }));
});

// 크루에서 할 일 생성
export const createUltimateModalAddTaskInCrewAtom = atom(null, async (get, set) => {
  const planData = get(innerTabDataAtom);
  const innerTabId = GetIdFromQuerystring("inner_tab_id");
  const plan = planData.find((data) => data.innerTabId === innerTabId);
  const task = get(createUltimateModalAtom);
  if (!task || !plan) return;
  if (task.name === "") {
    set(snackbarAtom, (prev) => ({ ...prev, open: true, message: "이름을 입력해주세요.", severity: "error" }));
    return;
  }
  const firstNode = plan.nodes[0] ? plan.nodes[0] : {
    position: { x: 0, y: 0 },
  };
  const newNode = {
    id: task.open,
    position: { x: firstNode.position.x, y: firstNode.position.y + 200 },
    data: {
      id: task.open,
      backendId: 0,
      userId: get(getUserId),
      label: task.name,
      startDate: task.startDate ? task.startDate.toISOString() : null,
      endDate: task.endDate ? task.endDate.toISOString() : null,
      content: task.content,
      isNewCreated: true,
      termType: task.termType,
      termData: task.termData,
      location: task.location,
      taskStatus: [],
      hashtags: task.hashtags,
      authorId: get(getUserId)
    },
    type: "task",
    measured: {
      width: 410,
      height: 176
    },
  }
  const response = await handleReactQueryApiResponse(createTask, () => set(error401ModalAtom, true), innerTabId, newNode);
  if (!response.ok) return;
  const jsonedResponse = await response.json();
  const updateNewNode = {
    ...newNode,
    data: {
      ...newNode.data,
      backendId: jsonedResponse.backendId,
    },
  }

  const newNodes = [...plan.nodes, updateNewNode];
  set(setInnerTabDataAtom, { innerTabId, nodes: newNodes, edges: plan.edges });
  // const newNodes = [...plan.nodes, newNode];
  // set(updatePlanDataMindmapAtom, innerTabId, newNodes, plan.edges);
  set(createUltimateModalClearStateAtom);
  set(snackbarAtom, (prev) => ({ ...prev, open: true, message: "할 일이 생성되었습니다.", severity: "success" }));
});

// key에 따라 상태 변경
export const createUltimateModalUpdateStateAtom = atom((get) => get(createUltimateModalAtom), (get, set, key: string, value: any) => {
  const modal = get(createUltimateModalAtom);
  if (!modal) return;
  if (key === "termData") {
    const weekDays = ["월", "화", "수", "목", "금", "토", "일"];
    modal.termType === 1 ?
      value.sort((a: string, b: string) => weekDays.indexOf(a) - weekDays.indexOf(b)) : value.sort((a: string, b: string) => parseInt(a) - parseInt(b));
  }
  const newModal = {
    ...modal,
    [key]: value,
  }
  set(createUltimateModalAtom, newModal);
});

// 장소 변경
export const createUltimateModalEditLocationAtom = atom(null, (get, set, address: string) => {
  const modal = get(createUltimateModalAtom);
  if (!modal) return;
  const newModal = {
    ...modal,
    location: { address: address, latitude: modal.location.latitude, longitude: modal.location.longitude },
  }
  set(createUltimateModalAtom, newModal);
})

// 루틴 옵션 변경
export const createUltimateModalSetRoutineOptionAtom = atom(null, (get, set, key: number) => {
  const modal = get(createUltimateModalAtom);
  if (!modal) return;
  const newModal = {
    ...modal,
    termType: modal.termType === key ? null : key,
    termData: modal.termType === key ? null : [],
  }
  set(createUltimateModalAtom, newModal);
});

// 루틴 일자 변경
export const createUltimateModalEditRoutineDataAtom = atom((get) => get(createUltimateModalEditRoutineAtom), (get, set, day: string) => {
  const editRoutineData = get(createUltimateModalEditRoutineAtom);
  if (!editRoutineData.termData) return;
  if (editRoutineData.termData.includes(day)) {
    set(createUltimateModalEditRoutineAtom, { termType: editRoutineData.termType, termData: editRoutineData.termData.filter((interval) => interval !== day) });
  } else {
    set(createUltimateModalEditRoutineAtom, { termType: editRoutineData.termType, termData: [...editRoutineData.termData, day] });
  }
});

// 루틴 일자 초기화
export const createUltimateModalEditRoutineClearStateAtom = atom(null, (get, set) => {
  set(createUltimateModalEditRoutineAtom, {
    termType: null,
    termData: null,
  });
});

// 루틴 변경 적용
export const createUltimateModalEditRoutineApplyAtom = atom(null, (get, set) => {
  const modal = get(createUltimateModalAtom);
  if (!modal) return;
  const editRoutineData = get(createUltimateModalEditRoutineAtom);
  const newModal = {
    ...modal,
    termData: editRoutineData.termData,
  }
  set(createUltimateModalAtom, newModal);
  set(createUltimateModalEditRoutineClearStateAtom);
});

// 루틴 변경 시작
export const createUltimateModalEditRoutineStartAtom = atom(null, (get, set) => {
  const modal = get(createUltimateModalAtom);
  if (!modal) return;
  const termData = modal.termData;
  set(createUltimateModalEditRoutineAtom, {
    termType: modal.termType,
    termData: termData ? [...termData] : [],
  });
});

// 해시태그 추가
export const createUltimateModalAddHashtagAtom = atom(null, (get, set, hashtag: { backgroundColor: string, textColor: string, value: string }) => {
  const modal = get(createUltimateModalAtom);
  if (!modal) return;
  const newModal = {
    ...modal,
    hashtags: [...modal.hashtags, hashtag],
  }
  set(createUltimateModalAtom, newModal);
});

// 해시태그 삭제(pop)
export const createUltimateModalRemoveHashtagAtom = atom(null, (get, set) => {
  const modal = get(createUltimateModalAtom);
  if (!modal) return;
  const newModal = {
    ...modal,
    hashtags: modal.hashtags.slice(0, -1),
  }
  set(createUltimateModalAtom, newModal);
});
