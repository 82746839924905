import { lighten } from "polished";
import styled from "styled-components";

export const CustomLeftBox = styled.div`
  width: 45%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 20px;
  gap: 20px;

  @media (max-width: 768px) {
    width: 100%;
    padding-left: 0px;
    height: 30%;
  }
`;

export const CustomRightBox = styled.div`
  width: 55%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;

  @media (max-width: 768px) {
    width: 100%;
    padding-left: 0px;
    height: 70%;
  }
`;

export const CustomTypeSelectButtonContainer = styled.div`
  width: 300px;
  height: 50px;
  display: flex;
  flex-direction: row;
  gap: 10px;
  justify-content: center;
  align-items: center;

  @media (max-width: 768px) {
    height: 40px;
    justify-content: space-between;
  }
`;

export const CustomTypeSelectButton = styled.div<{ $color: string, $selected: boolean }>`
  width: 145px;
  height: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  border: 1px solid ${({ $color }) => $color};
  cursor: pointer;
  background-color: ${({ $color, $selected }) => $selected ? $color : "white"};
  color: ${({ $color, $selected }) => $selected ? "white" : "black"};
  border-radius: 10px;
  font-size: 0.9rem;
  font-weight: bold;

  &:hover {
    background-color: ${({ $color }) => lighten(0.1, $color)};
    color: white;
  }

  @media (max-width: 768px) {
    font-size: 0.8rem;
  }
`

export const CustomCreateButton = styled.div<{ $color: string }>`
  width: 260px;
  height: 60px;
  display: flex;
  padding: 20px;
  flex-direction: row;
  gap: 20px;
  align-items: center;
  font-size: 16px;
  font-weight: bold;

  border: 1px solid ${({ $color }) => $color};
  border-radius: 10px;
  cursor: pointer;

  &:hover {
    background-color: ${({ $color }) => lighten(0.1, $color)};
    color: white;
  }

  @media (max-width: 768px) {
    height: 50px;
    font-size: 14px;
  }
`;

export const TempEmptyButton = styled.div`
  width: 260px;
  height: 60px;
  display: flex;
  padding: 20px;
  flex-direction: row;
  gap: 20px;
  align-items: center;
  font-size: 16px;
  font-weight: bold;
  border-radius: 10px;

  @media (max-width: 768px) {
    height: 50px;
    font-size: 14px;
  }
`;
