import { atom } from 'jotai';
import { dummyCareerPlanningData, dummyCareerProgressData, dummyDreamCardData } from './dummyData';
import { Signature } from '@/Atoms/Signature/Atom';

interface CareerProgress {
  userId: number;
  name: string;
  size: number; // 구체 크기
  x: number; // 필살기 수
  y: number; // 할일 수
};

export interface CareerPlanning {
  userId: number;
  crew: string;  // 크루명
  mentee: string; // 멘티명
  email: string; // 이메일
  content: string; // 진로콘텐츠명
  lastStudyDate: string; // 최근 접속일
  numberOfPlans: number; // 플랜 수
  numberOfTaskStatus: number; // 태스크 상태 수
  numberOfTaskStatusComplete: number; // 태스크 완료 수
  numberOfUltimateSkills: number; // 필살기 수
  numberOfTodos: number; // 할일 수
  careerAchievement: number; // 커리어 성장률
}

interface TeacherDashboardCrew {
  crewId: number;
  innerTabId: number;
  name: string;
  content: string;
  headCount: number;
  current: number;
  hashtags: { backgroundColor: string; textColor: string; value: string; }[];
  imageUrl: string;
  color: string;
  category: string;
}

export interface TeacherDashboard {
  signatures: Signature[]; // 시그니쳐 목록
  careerProgress: CareerProgress[]; // 멘티별 커리어 성장률
  crews: TeacherDashboardCrew[]; // 크루 관리
  careerPlanning: CareerPlanning[]; // 커리어 플래닝 현황
}

const teacherDashboardAtom = atom<TeacherDashboard>({
  signatures: [],
  careerProgress: [],
  crews: [],
  careerPlanning: [],
});

export default teacherDashboardAtom;
