import React, { useEffect } from 'react';
import { Dialog, DialogTitle, Box, Button, Checkbox, TextField, Typography, Alert, AlertTitle } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { Dayjs } from 'dayjs';
import theme from '../../../../../Styles/theme';
import dayjs from 'dayjs';
import { StoryDataProps, StoryProps } from '../../../../../Atoms/StoryAtom';
import * as S from './styled';
import getRandomColorWithText from '../../../../../Utils/GetRandomColor';
import { useSetAtom } from 'jotai';
import { updateStoryAtom } from '../../../../../ViewModels/StoryViewModel';

interface EditStoryDialogProps {
  editingStory: StoryProps | null;
  setEditingStory: React.Dispatch<React.SetStateAction<StoryProps | null>>;
  tabValue: number;
  setTabValue: React.Dispatch<React.SetStateAction<number>>;
}

const EditStoryDialog = ({ editingStory, setEditingStory }: EditStoryDialogProps) => {
  const [selectedDate, setSelectedDate] = React.useState<Dayjs | null>(null);
  const prevSelectedDate = editingStory?.date ? dayjs(editingStory.date.value) : dayjs();
  const [isToday, setIsToday] = React.useState(false);
  const [tags, setTags] = React.useState<StoryDataProps[]>([]);
  const [inputValue, setInputValue] = React.useState("");
  const [errorMessage, setErrorMessage] = React.useState<string | null>(null); // 에러 메시지 상태 관리
  const updateStory = useSetAtom(updateStoryAtom);

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsToday(e.target.checked);
    if (e.target.checked) {
      setSelectedDate(dayjs());
    } else {
      setSelectedDate(prevSelectedDate);
    }
  };

  const handleDateChange = (date: Dayjs | null) => {
    if (date) {
      if (date.isValid()) {
        setSelectedDate(date);
        setErrorMessage(null);
      } else if (date.year() && (date.month() === 0 || date.date() === 1)) {
        // 연도 또는 연도와 월만 입력했을 때 1일로 기본 설정
        const correctedDate = dayjs(`${date.year()}-${date.month() + 1}-01`);
        setSelectedDate(correctedDate);
      } else {
        setErrorMessage("올바른 날짜 형식을 입력해주세요. (YYYY.MM.DD)");
      }
    } else {
      setSelectedDate(null);
    }
  };

  const handleEdit = () => {
    if (!editingStory) return;
    if (!isToday && (!selectedDate || !selectedDate.isValid())) {
      setErrorMessage("올바른 날짜 형식을 입력해주세요. (YYYY.MM.DD)");
      return;
    }
    const { backgroundColor, textColor } = getRandomColorWithText();
    const newStory: StoryProps = {
      ...editingStory,
      date: isToday
        ? {
          background_color: editingStory.date ? editingStory.date.background_color : backgroundColor,
          text_color: editingStory.date ? editingStory.date.text_color : textColor,
          value: selectedDate?.format('YYYY년 MM월 DD일') || dayjs().format('YYYY년 MM월 DD일'),
        }
        : selectedDate
          ? {
            background_color: editingStory.date ? editingStory.date.background_color : backgroundColor,
            text_color: editingStory.date ? editingStory.date.text_color : textColor,
            value: selectedDate.format('YYYY년 MM월 DD일'),
          }
          : null,
      hashtags: tags,
    };
    if (!newStory.title) {
      setErrorMessage("제목을 입력하세요.");
      return;
    }
    if (!newStory.content) {
      setErrorMessage("내용을 입력하세요.");
      return;
    }
    if (!newStory.date) {
      setErrorMessage("날짜를 선택하세요.");
      return;
    }
    updateStory(newStory);
    setEditingStory(null);
  };

  // 엔터키 입력 시 태그 추가
  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter' && inputValue.trim()) {
      event.preventDefault();
      const { backgroundColor, textColor } = getRandomColorWithText();
      if (!tags.find((tag) => tag.value === inputValue.trim())) {
        setTags([...tags, {
          background_color: backgroundColor,
          text_color: textColor,
          value: inputValue.trim(),
        }]);
      }
      setInputValue(""); // 입력창 초기화
    } else if (event.key === 'Backspace' && inputValue === "") {
      if (tags.length > 0) {
        event.preventDefault();
        const updatedTags = [...tags];
        updatedTags.pop();
        setTags(updatedTags);
      }
    }
  };

  useEffect(() => {
    if (editingStory) {
      setTags(editingStory.hashtags);
      if (editingStory.date) {
        const storyDate = dayjs(editingStory.date.value, 'YYYY년 MM월 DD일'); // 날짜 변환
        setSelectedDate(storyDate);
        setIsToday(storyDate.isSame(dayjs(), 'day')); // 오늘인지 확인
      } else {
        setIsToday(false);
      }
    }
  }, [editingStory]);

  return (
    editingStory &&
    <Dialog
      open={editingStory !== null}
      onClose={() => setEditingStory(null)}
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle>스토리 수정</DialogTitle>

      {/* Content */}
      <Box padding={2}>
        <TextField
          variant="standard"
          fullWidth
          margin="normal"
          value={editingStory.title}
          placeholder='제목을 입력하세요.'
          onChange={(e) => setEditingStory({ ...editingStory, title: e.target.value })}
        />
        <TextField
          placeholder='내용을 입력하세요.'
          variant="standard"
          fullWidth
          multiline
          rows={4}
          margin="normal"
          value={editingStory.content}
          onChange={(e) => setEditingStory({ ...editingStory, content: e.target.value })}
        />
      </Box>

      {/* Footer */}
      <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
        <Box display="flex" justifyContent="space-between" padding={2} alignItems="center">
          <Typography variant="body2" style={{ marginRight: "8px" }}>
            해시태그
          </Typography>
          <S.TagInputContainer>
            {tags.map((tag, idx) => {
              return <S.TagItem key={idx} style={{ backgroundColor: tag.background_color, color: tag.text_color }}>
                <span>{tag.value}</span>
              </S.TagItem>
            })}
            <S.TagInput
              placeholder={tags.length === 0 ? "태그를 입력해보세요." : ""}
              value={inputValue}
              onChange={(e) => setInputValue(e.target.value)}
              onKeyDown={handleKeyDown}
            />
          </S.TagInputContainer>
        </Box>
        <Box display="flex" justifyContent="space-between" padding={2} alignItems="center">
          <Box display="flex" gap={1} alignItems="center">
            <Box display="flex" alignItems="center">
              <Typography variant="body2" style={{ marginRight: "8px" }}>
                오늘의 스토리
              </Typography>
              <Checkbox checked={isToday} onChange={handleCheckboxChange} />
            </Box>
            {isToday ?
              <Typography variant="body2">
                {selectedDate?.format('YYYY.MM.DD')}
              </Typography>
              :
              <DatePicker
                label="날짜 선택"
                sx={{ width: "200px" }}
                value={selectedDate}
                onChange={handleDateChange}
                format="YYYY.MM.DD"
              />
            }
          </Box>
          <Button
            variant="contained"
            // color="primary"
            size="large"
            onClick={handleEdit}
            sx={{
              backgroundColor: `${theme.colors.primary}`,
            }}
          >
            수정
          </Button>
        </Box>
      </div>
      {errorMessage && (
        <Alert severity="error" onClose={() => setErrorMessage(null)}>
          <AlertTitle>입력 오류</AlertTitle>
          {errorMessage}
        </Alert>
      )}
    </Dialog>
  );
};

export default EditStoryDialog;
