import CustomBackgroundColorButton from '@/Components/Common/Buttons/CustomBackgroundColor';
import { Autocomplete, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from '@mui/material';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import React from 'react';
import CloseIcon from '@mui/icons-material/Close';
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import * as S from './styled';
import convertDayToKorean from '@/Utils/ConvertDayToKorean';
import getRandomColorWithText from '@/Utils/GetRandomColor';
import CustomColorButton from '@/Components/Common/Buttons/CustomColor';
import editLectureDialogAtom from '@/Atoms/Dialogs/Edit/Lecture';
import { editLectureDialogAddHashtagAtom, editLectureDialogApplicationPeriodAtom, editLectureDialogApplicationPeriodDateAtom, editLectureDialogApplicationPeriodTimeAtom, editLectureDialogClassRoomAtom, editLectureDialogCloseAtom, editLectureDialogCoursePeriodAtom, editLectureDialogGradesAtom, editLectureDialogHeadcountAtom, editLectureDialogIsAbleToCancelAtom, editLectureDialogIsVisibleMemberAtom, editLectureDialogNameAtom, editLectureDialogOperatingPlanAtom, editLectureDialogOperatingTimesAtom, editLectureDialogOperatingTimesDayAtom, editLectureDialogOperatingTimesTimeAtom, editLectureDialogPeriodAtom, editLectureDialogRemoveHashtagAtom, editLectureDialogSaveAtom, editLectureDialogTeacherAtom, editLectureDialogTextbookAtom } from '@/ViewModels/Dialogs/Edit/LectureViewModel';
import { useTranslation } from 'react-i18next';

const EditLectureDialog = () => {
  const { t } = useTranslation();
  const dialog = useAtomValue(editLectureDialogAtom);
  const closeDialog = useSetAtom(editLectureDialogCloseAtom);
  const saveDialog = useSetAtom(editLectureDialogSaveAtom);
  const daysOfWeek = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];
  const times = Array.from({ length: 14 }, (_, i) => i); // 0 to 13 time slots
  const [teacher, editTeacher] = useAtom(editLectureDialogTeacherAtom);
  const [grades, editGrades] = useAtom(editLectureDialogGradesAtom);
  const [headcount, editHeadcount] = useAtom(editLectureDialogHeadcountAtom);
  const [period, editPeriod] = useAtom(editLectureDialogPeriodAtom);
  const [operatingPlan, editOperatingPlan] = useAtom(editLectureDialogOperatingPlanAtom);
  const [operatingTimes, editOperatingTimes] = useAtom(editLectureDialogOperatingTimesAtom);
  const editOperatingTimesDay = useSetAtom(editLectureDialogOperatingTimesDayAtom);
  const editOperatingTimesTime = useSetAtom(editLectureDialogOperatingTimesTimeAtom);
  const [classRoom, editClassRoom] = useAtom(editLectureDialogClassRoomAtom);
  const [textbook, editTextbook] = useAtom(editLectureDialogTextbookAtom);
  const [name, editName] = useAtom(editLectureDialogNameAtom);
  const applicationPeriod = useAtomValue(editLectureDialogApplicationPeriodAtom);
  const editApplicationPeriodDate = useSetAtom(editLectureDialogApplicationPeriodDateAtom);
  const editApplicationPeriodTime = useSetAtom(editLectureDialogApplicationPeriodTimeAtom);
  const [coursePeriod, editCoursePeriod] = useAtom(editLectureDialogCoursePeriodAtom);
  const [isVisibleMember, editIsVisibleMember] = useAtom(editLectureDialogIsVisibleMemberAtom);
  const [isAbleToCancel, editIsAbleToCancel] = useAtom(editLectureDialogIsAbleToCancelAtom);
  const [inputValue, setInputValue] = React.useState("");
  const addHashtag = useSetAtom(editLectureDialogAddHashtagAtom);
  const removeHashtag = useSetAtom(editLectureDialogRemoveHashtagAtom);
  const teacherOptions = ["김교사", "이교사", "박교사", "최교사", "정교사"];

  // 엔터키 입력 시 태그 추가
  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter' && inputValue.trim()) {
      event.preventDefault();
      const { backgroundColor, textColor } = getRandomColorWithText();
      if (!dialog.hashtags.find((tag) => tag.value === inputValue.trim())) {
        addHashtag({
          backgroundColor,
          textColor,
          value: inputValue.trim(),
        });
      }
      setInputValue(""); // 입력창 초기화
    } else if (event.key === 'Backspace' && inputValue === "") {
      if (dialog.hashtags.length > 0) {
        event.preventDefault();
        removeHashtag();
      }
    }
  };

  return (
    <Dialog
      open={dialog.open}
      onClose={closeDialog}
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between", fontSize: "0.9rem", fontWeight: "bold" }}>
        <div style={{ display: "flex", flexDirection: "row", gap: "10px", alignItems: "center" }}>
          강좌 수정
        </div>
        <CloseIcon onClick={closeDialog} sx={{ color: "black", cursor: "pointer" }} />
      </DialogTitle>
      <DialogContent sx={{ display: "flex", flexDirection: "column", gap: "15px" }}>
        <S.ContentContentRow>
          <S.ContentTitle>담당교사</S.ContentTitle>
          <Autocomplete
            options={teacherOptions}
            value={teacher}
            onChange={(event, newValue) => editTeacher(newValue || "")}
            freeSolo // Allow custom input
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder="이름"
                size="small"
                variant="outlined"
                sx={{
                  width: "300px",
                  borderRadius: "5px",
                  '& .MuiOutlinedInput-root': {
                    display: "flex",
                    alignItems: "center",
                    height: "50px",
                    '& fieldset': {
                      borderColor: '#c0c0c0',
                    },
                    '&:hover fieldset': {
                      borderColor: '#a0a0a0',
                    },
                  },
                }}
              />
            )}
            onInputChange={(event, newInputValue) => editTeacher(newInputValue)} // Update state on input change
          />
        </S.ContentContentRow>
        <S.ContentContentRow>
          <S.ContentTitle>강좌명</S.ContentTitle>
          <input type="text" style={S.ContentInputStyle(300)} placeholder='예) 국어: 소설특강, 영어: 문법특강 / 과목군 설정 시 과목 뒤에 콜론(:) 입력' value={name} onChange={(e) => editName(e.target.value)} />
        </S.ContentContentRow>
        <S.ContentContentRow>
          <S.ContentTitle>학년</S.ContentTitle>
          <S.ContentContentRow style={{ gap: "5px", cursor: "pointer" }} onClick={() => editGrades(0)}>
            <Checkbox checked={grades.includes(0)} sx={{ width: "16px", height: "16px", transform: "scale(1)", '& .MuiSvgIcon-root': { fontSize: 16 } }} />
            <div>전체</div>
          </S.ContentContentRow>
          {[1, 2, 3].map((grade, idx) => (
            <S.ContentContentRow style={{ gap: "5px", cursor: "pointer" }} onClick={() => editGrades(grade)} key={idx}>
              <Checkbox checked={grades.includes(grade)} sx={{ width: "16px", height: "16px", transform: "scale(1)", '& .MuiSvgIcon-root': { fontSize: 16 } }} />
              <div>{grade}학년</div>
            </S.ContentContentRow>
          ))}
        </S.ContentContentRow>
        <S.ContentContentRow>
          <S.ContentTitle>정원</S.ContentTitle>
          <S.ContentContentRow style={{ gap: "5px" }}>
            <input type="number" style={S.ContentInputStyle(80)} min={1} value={headcount} onChange={(e) => editHeadcount(parseInt(e.target.value))} />명(선착순)
          </S.ContentContentRow>
        </S.ContentContentRow>
        <S.ContentContentRow>
          <S.ContentTitle>총시수</S.ContentTitle>
          <S.ContentContentRow style={{ gap: "5px" }}>
            <input type="number" style={S.ContentInputStyle(80)} min={1} value={period} onChange={(e) => editPeriod(parseInt(e.target.value))} />차시
          </S.ContentContentRow>
        </S.ContentContentRow>
        <S.ContentContentRow>
          <S.ContentTitle>강의실</S.ContentTitle>
          <input type="text" style={S.ContentInputStyle(300)} value={classRoom} onChange={(e) => editClassRoom(e.target.value)} placeholder='예) 101, 302, 과학실, 수준별교실' />
        </S.ContentContentRow>
        <S.ContentContentRow>
          <S.ContentTitle>교재</S.ContentTitle>
          <input type="text" style={S.ContentInputStyle(400)} placeholder='예) EBS 수능특강 국어, 수능 영어 기본(**출판사), 유인물' value={textbook} onChange={(e) => editTextbook(e.target.value)} />
        </S.ContentContentRow>
        <S.ContentContentRow>
          <S.ContentTitle>신청기간</S.ContentTitle>
          <S.ContentContentColumn>
            <S.ContentContentRow style={{ gap: "5px" }}>

              <input
                type="date"
                style={S.ContentInputStyle(120)}
                value={applicationPeriod.start.date}
                onChange={(e) => editApplicationPeriodDate(new Date(e.target.value), "start")}
              />일

              <input
                type="number"
                style={S.ContentInputStyle(50)}
                value={applicationPeriod.start.hour}
                min="0"
                max="23" // Restricts hours from 0 to 23
                onChange={(e) => editApplicationPeriodTime(parseInt(e.target.value), "start", "hour")}
              />시

              <input
                type="number"
                style={S.ContentInputStyle(50)}
                value={applicationPeriod.start.minute}
                min="0"
                max="59" // Restricts minutes from 0 to 59
                onChange={(e) => editApplicationPeriodTime(parseInt(e.target.value), "start", "minute")}
              />분 부터
            </S.ContentContentRow>
            <S.ContentContentRow style={{ gap: "5px" }}>

              <input
                type="date"
                style={S.ContentInputStyle(120)}
                value={applicationPeriod.end.date}
                onChange={(e) => editApplicationPeriodDate(new Date(e.target.value), "end")}
              />일

              <input
                type="number"
                style={S.ContentInputStyle(50)}
                value={applicationPeriod.end.hour}
                min="0"
                max="23" // Restricts hours from 0 to 23
                onChange={(e) => editApplicationPeriodTime(parseInt(e.target.value), "end", "hour")}
              />시

              <input
                type="number"
                style={S.ContentInputStyle(50)}
                value={applicationPeriod.end.minute}
                min="0"
                max="59" // Restricts minutes from 0 to 59
                onChange={(e) => editApplicationPeriodTime(parseInt(e.target.value), "end", "minute")}
              />분 까지
            </S.ContentContentRow>
          </S.ContentContentColumn>
        </S.ContentContentRow>
        <S.ContentContentRow>
          <S.ContentTitle>수강기간</S.ContentTitle>
          <S.ContentContentRow style={{ gap: "5px" }}>
            <input type="date" style={S.ContentInputStyle(120)}
              value={coursePeriod.startDate} onChange={(e) => editCoursePeriod(new Date(e.target.value), "startDate")}
            />일 부터
            <input type="date" style={S.ContentInputStyle(120)}
              value={coursePeriod.endDate} onChange={(e) => editCoursePeriod(new Date(e.target.value), "endDate")}
            />일 까지
          </S.ContentContentRow>
        </S.ContentContentRow>
        <S.ContentContentRow>
          <S.ContentTitle>수강생 표시</S.ContentTitle>
          <S.ContentContentRow style={{ gap: "5px" }}>
            <Checkbox checked={isVisibleMember} onChange={() => editIsVisibleMember(!isVisibleMember)} sx={{ width: "16px", height: "16px", transform: "scale(1)", '& .MuiSvgIcon-root': { fontSize: 16 } }} />
            <div>{isVisibleMember ? "표시" : "표시 안함"}</div>
          </S.ContentContentRow>
        </S.ContentContentRow>
        <S.ContentContentRow>
          <S.ContentTitle>수강 취소 가능</S.ContentTitle>
          <S.ContentContentRow style={{ gap: "5px" }}>
            <Checkbox checked={isAbleToCancel} onChange={() => editIsAbleToCancel(!isAbleToCancel)} sx={{ width: "16px", height: "16px", transform: "scale(1)", '& .MuiSvgIcon-root': { fontSize: 16 } }} />
            <div>{isAbleToCancel ? "가능" : "불가능"}</div>
          </S.ContentContentRow>
        </S.ContentContentRow>
        <S.ContentContentRow>
          <S.ContentTitle>운영계획</S.ContentTitle>
          <TextField
            value={operatingPlan}
            onChange={(e) => editOperatingPlan(e.target.value)}
            multiline
            rows={4}
            placeholder='운영계획을 입력해주세요.'
            variant="outlined"
            sx={{
              width: "calc(100% - 100px)",
              '& .MuiOutlinedInput-root': {
                borderRadius: "5px",
                '& fieldset': {
                  borderColor: '#c0c0c0',
                },
                '&:hover fieldset': {
                  borderColor: '#a0a0a0',
                },
              },
            }}
          />
        </S.ContentContentRow>
        <S.ContentContentColumn style={{ gap: "0px" }}>
          <S.ContentTitle>운영시간</S.ContentTitle>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell align="center" sx={{ fontSize: "0.8rem", padding: "8px" }}>교시</TableCell>
                  {daysOfWeek.map((day) => (
                    <TableCell key={day} align="center" sx={{ fontSize: "0.8rem", padding: "8px" }} >
                      <S.ContentContentRow style={{ gap: "5px" }}>
                        <KeyboardDoubleArrowDownIcon
                          onClick={() => editOperatingTimesDay(day)}
                          sx={{ cursor: "pointer", border: "1px solid #c0c0c0", borderRadius: "5px", width: "0.8rem", height: "0.8rem" }} />
                        {convertDayToKorean(day)}
                      </S.ContentContentRow>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {times.map((time) => (
                  <TableRow key={time}>
                    <TableCell align="center" sx={{ padding: "8px" }}>
                      <S.ContentContentRow style={{ gap: "5px" }}>
                        {time}교시
                        <KeyboardDoubleArrowRightIcon
                          onClick={() => editOperatingTimesTime(time)}
                          sx={{ cursor: "pointer", border: "1px solid #c0c0c0", borderRadius: "5px", width: "0.8rem", height: "0.8rem" }} />
                      </S.ContentContentRow>
                    </TableCell>
                    {daysOfWeek.map((day, index) => (
                      <TableCell key={day + time} align="center" sx={{ padding: "8px" }}>
                        <Checkbox checked={operatingTimes[day] && operatingTimes[day].includes(time)} onChange={() => editOperatingTimes(day, time)} sx={{ width: "16px", height: "16px", transform: "scale(1)", '& .MuiSvgIcon-root': { fontSize: 16 } }} />
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </S.ContentContentColumn>
        <S.ContentContentRow>
          <S.ContentTitle>해시태그</S.ContentTitle>
          <S.TagInputContainer>
            {dialog.hashtags.map((tag, idx) => {
              return <S.TagItem key={idx} style={{ backgroundColor: tag.backgroundColor, color: tag.textColor }}>
                <span>{tag.value}</span>
              </S.TagItem>
            })}
            <S.TagInput
              placeholder={dialog.hashtags.length === 0 ? "태그를 입력해보세요." : ""}
              value={inputValue}
              onChange={(e) => setInputValue(e.target.value)}
              onKeyDown={handleKeyDown}
            />
          </S.TagInputContainer>
        </S.ContentContentRow>
      </DialogContent>
      <DialogActions>
        <CustomColorButton text="취소" onClick={closeDialog} fontSize="0.8rem" />
        <CustomBackgroundColorButton text="수정" onClick={() => saveDialog(t)} fontSize="0.8rem" />
      </DialogActions>
    </Dialog>
  )
}

export default EditLectureDialog;
