import { crewLecturesAtom } from "@/Atoms/Crew";
import { error401ModalAtom } from "@/Atoms/Dialogs/Error/401Atom";
import snackbarAtom from "@/Atoms/Snackbar";
import { deleteLecture } from "@/Queries/LectureQueries";
import { handleReactQueryApiResponse } from "@/Utils/APIUtil";
import GetIdFromQuerystring from "@/Utils/GetIdFromQuerystring";
import { TFunction } from "i18next";
import { atom } from "jotai";

// delete lecture atom
export const deleteLectureAtom = atom(null, async (get, set, lectureId: number, t: TFunction) => {
  const innerTabId = GetIdFromQuerystring("inner_tab_id");
  const crewLectures = get(crewLecturesAtom);
  const lectures = crewLectures.find((x) => x.innerTabId === innerTabId)?.lectures;
  if (!lectures) return;
  const handleError401Modal = () => set(error401ModalAtom, true);

  await handleReactQueryApiResponse(deleteLecture, handleError401Modal, lectureId).then((res) => {
    if (!res.ok) {
      set(snackbarAtom, { open: true, message: t("viewModel.snackbar.deleteLecture.fail"), severity: "error" });
      return;
    } else {
      const newLectures = lectures.filter((x) => x.id !== lectureId);
      set(crewLecturesAtom, crewLectures.map((x) => x.innerTabId === innerTabId ? { ...x, lectures: newLectures } : x));
      set(snackbarAtom, { open: true, message: t("viewModel.snackbar.deleteLecture.success"), severity: "success" });
    }
  })
});
