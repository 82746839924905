import React from "react";
import * as S from "./styled";
import SearchInput from "@/Components/RecommendPlan/Contents/SearchInput";
import { useSetAtom } from "jotai";
import useRecommendPlan from "@/Hooks/useRecommendPlan";
import { useTranslation } from "react-i18next";
import { waitingModalAtom } from "@/Atoms/RootAtom";
import RecommendPlanTable from "@/Components/RecommendPlan/Contents/Table";
import PlanInfoModal from "@/Components/RecommendPlan/Modals/PlanInfo";
import { ArrowBack } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";

const Modals = () => {
  return (
    <>
      <PlanInfoModal />
    </>
  )
}

const RecommendPlan: React.FC = () => {
  const { isLoading } = useRecommendPlan();
  const setWaitingModal = useSetAtom(waitingModalAtom);
  const { t } = useTranslation();
  const navigate = useNavigate();

  React.useEffect(() => {
    setWaitingModal({ state: isLoading, text: t("dialogs.common.waiting.loading") });
  }, [isLoading, t, setWaitingModal]);

  return (
    <S.Container>
      <S.InnerContainer>
        <div style={{ display: "flex", flexDirection: "row", alignItems: "center", gap: "20px" }}>
          <ArrowBack sx={{ fontSize: "1.5rem", cursor: "pointer" }} onClick={() => navigate(-1)} />
          <div style={{ fontSize: "1.2rem", fontWeight: "bold" }}>{t("recommendPlan.header.title")}</div>
        </div>
        <SearchInput />
        <RecommendPlanTable />
      </S.InnerContainer>
      <Modals />
    </S.Container>
  );
};

export default RecommendPlan;
