import { List } from '@mui/material';
import styled from 'styled-components';
import theme from '../../../../../Styles/theme';

export const StoryPostingContainer = styled.div<{ $isMobile: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 90%;
  min-height: 80px;
  flex-direction: column;
  background-color: white;
  border: 1px solid ${({ theme }) => theme.colors.lightBackground};
  border-radius: 10px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.5);
  cursor: pointer;
`;

export const TitleContainer = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  height: 40px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.background};
`;

export const TitleButton = styled.button`
  display: flex;
  flex-direction: row;
  gap: 10px;
  font-size: 0.8rem;
  font-weight: 600;
  border: none;
  background-color: transparent;
  cursor: pointer;
`;

export const DescriptionContainer = styled.div`
  display: flex;
  width: calc(100% - 40px);
  height: 40px;
  padding: 0 20px;
  align-items: center;
  font-size: 0.75rem;
  font-weight: 600;
`

export const StoryGroupingContainer = styled.div`
  display: flex;
  width: 90%;
  height: 50px;
  justify-content: flex-end;
  align-items: center;
`;

export const StoryContainer = styled.div<{ $isMobile: boolean }>`
  display: flex;
  flex-direction: column;
  width: ${({ $isMobile }) => ($isMobile ? "100%" : "50%")};
  height: ${({ $isMobile }) => ($isMobile ? "calc(100% - 56px)" : "100%")};
  align-items: center;
  border-right: ${({ $isMobile, theme }) => ($isMobile ? "none" : "1px solid" + theme.colors.darkBackground)};
  // overflow-y: auto;
  background-color: ${({ theme }) => theme.colors.lightBackground};
  // border-bottom: ${({ $isMobile, theme }) => ($isMobile ? "1px solid" + theme.colors.darkBackground : "none")};
`;

export const DesktopStoryTopContainer = styled.div`
  display: flex;
  width: 100%;
  height: 200px;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
`;

export const StoryList = styled(List) <{ $height: string }>`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: ${({ $height }) => $height};
  align-items: center;
  overflow-y: auto;
  overflow-x: hidden;
`

export const StoryItem = styled.div<{ $isMobile: boolean }>`
  width: ${({ $isMobile }) => ($isMobile ? "calc(95% - 40px)" : "calc(90% - 40px)")};
  padding: 20px;
  border: 1px solid #e0e0e0;
  border-radius: ${({ $isMobile }) => ($isMobile ? "6px" : "12px")};
  background-color: white;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  margin-top: 10px;
  margin-bottom: 10px;
`;

export const StoryTitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
`;

export const StoryTitle = styled.div`
  width: 95%;
  font-weight: 600;
  font-size: 0.9rem;
`;

export const StoryBottomContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: flex-start;
  gap: 20px;
  // align-items: center;
`;

export const TagInputContainer = styled.div`
  display: flex;
  width: 80%;
  flex-wrap: wrap;
  gap: 0.5em;
  padding: 0.4rem 1rem;
  border-bottom: 1px solid black;
`;

export const TagItem = styled.div`
  display: inline-block;
  padding: 0.2em 0.5em;
  border-radius: 20px;
  color: white;
`;

export const TagInput = styled.input`
  flex-grow: 1;
  min-width: 30px;
  max-width: 150px;
  padding: 0.5em 0;
  border: none;
  outline: none;
  font-family: "Noto Sans KR", sans-serif;
`;
