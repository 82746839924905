import React from "react";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import theme from "@/Styles/theme";
import { lighten } from "polished";

const generateExcel = () => {
  // Define the data
  const data = [
    ["학번", "이름", "자유수강권"],
    ["10101", "홍길동", 500000],
    ["10201", "홍길서", 350000],
    ["10301", "홍길남", "면제"],
    ["", "", ""],
    ["학생수", "강사비", ""],
    [5, 60000, ""],
    [6, 62000, ""],
    [7, 64000, ""],
    [8, 66000, ""],
    [9, 68000, ""],
    [10, 70000, ""],
    [11, 72000, ""],
    [12, 74000, ""],
    [13, 76000, ""],
    [14, 78000, ""],
    [15, 80000, ""],
    [16, 82000, ""],
    [17, 84000, ""],
    [18, 86000, ""],
    [19, 88000, ""],
    [20, 90000, ""]
  ];

  // Create a worksheet
  const worksheet = XLSX.utils.aoa_to_sheet(data);

  // Create a workbook and add the worksheet
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

  // Convert workbook to binary and save
  const excelBuffer = XLSX.write(workbook, { bookType: "xlsx", type: "array" });
  const blob = new Blob([excelBuffer], { type: "application/octet-stream" });
  saveAs(blob, "과정개설하기_지원금양식.xlsx");
};

const DownloadExcelButton = () => {
  const [isHovered, setIsHovered] = React.useState(false);

  const handleMouseEnter = () => setIsHovered(true);
  const handleMouseLeave = () => setIsHovered(false);
  return (
    <div
      style={{
        color: isHovered ? lighten(0.1, theme.colors.primary) : theme.colors.primary,
        marginLeft: "5px",
        cursor: "pointer"
      }}
      onClick={generateExcel}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      엑셀파일(저장)
    </div>
  );
};

export default DownloadExcelButton;
