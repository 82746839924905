import CustomBackgroundColorButton from '@/Components/Common/Buttons/CustomBackgroundColor';
import CustomColorButton from '@/Components/Common/Buttons/CustomColor';
import theme from '@/Styles/theme';
import { editTaskDialogAddHashtagAtom, editTaskDialogClearStateAtom, editTaskDialogEditLocationAtom, editTaskDialogEditRoutineApplyAtom, editTaskDialogEditRoutineClearStateAtom, editTaskDialogEditRoutineDataAtom, editTaskDialogEditRoutineStartAtom, editTaskDialogEditTaskAtom, editTaskDialogRemoveHashtagAtom, editTaskDialogSetRoutineOptionAtom, editTaskDialogUpdateStateAtom } from '@/ViewModels/Dialogs/Edit/TaskViewModel';
import { Dialog, DialogActions, DialogContent, DialogTitle, TextField, ToggleButton, ToggleButtonGroup } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import { useAtom, useSetAtom } from 'jotai';
import * as S from './styled';
import React from 'react';
import getRandomColorWithText from '@/Utils/GetRandomColor';
import { textEditorForCreateAndEditDialogAtom } from '@/Atoms/Dialogs/TextEditor';
import TextMarkdown from '@/Components/Common/Markdown';
import { useTranslation } from 'react-i18next';
import useIsMobile from '@/Hooks/useIsMobile';
import { useLocation } from 'react-router-dom';

const weekDays = ["월", "화", "수", "목", "금", "토", "일"];

const EditTaskDialog = () => {
  const { isMobile } = useIsMobile();
  const { t } = useTranslation();
  const [editTaskDialog, updateState] = useAtom(editTaskDialogUpdateStateAtom);
  const clearState = useSetAtom(editTaskDialogClearStateAtom);
  const setRoutineOption = useSetAtom(editTaskDialogSetRoutineOptionAtom);
  const editTask = useSetAtom(editTaskDialogEditTaskAtom);
  const [editRoutineData, setEditRoutineData] = useAtom(editTaskDialogEditRoutineDataAtom);
  const editRoutineStart = useSetAtom(editTaskDialogEditRoutineStartAtom);
  const editRoutineClearState = useSetAtom(editTaskDialogEditRoutineClearStateAtom);
  const applyRoutine = useSetAtom(editTaskDialogEditRoutineApplyAtom);
  const editLocation = useSetAtom(editTaskDialogEditLocationAtom);
  const [inputValue, setInputValue] = React.useState("");
  const addHashtag = useSetAtom(editTaskDialogAddHashtagAtom);
  const removeHashtag = useSetAtom(editTaskDialogRemoveHashtagAtom);
  const setTextEditorDialog = useSetAtom(textEditorForCreateAndEditDialogAtom);
  const location = useLocation();
  const currentPath = location.pathname;

  // 엔터키 입력 시 태그 추가
  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter' && inputValue.trim()) {
      event.preventDefault();
      const { backgroundColor, textColor } = getRandomColorWithText();
      if (!editTaskDialog.hashtags.find((tag) => tag.value === inputValue.trim())) {
        addHashtag({
          backgroundColor,
          textColor,
          value: inputValue.trim(),
        });
      }
      setInputValue(""); // 입력창 초기화
    } else if (event.key === 'Backspace' && inputValue === "") {
      if (editTaskDialog.hashtags.length > 0) {
        event.preventDefault();
        removeHashtag();
      }
    }
  };

  return (
    <Dialog
      open={editTaskDialog.open !== null}
      onClose={clearState}
      maxWidth="sm"
      fullWidth
      fullScreen={isMobile}
    >
      {
        editRoutineData.termType === null ?
          <>
            <DialogTitle>{t("dialogs.task.edit.title")}</DialogTitle>
            <DialogContent sx={{ display: "flex", flexDirection: "column", gap: "20px" }}>
              <TextField
                label={t("dialogs.task.edit.name")}
                variant="outlined"
                fullWidth
                value={editTaskDialog.name}
                onChange={(e) => updateState("name", e.target.value)}
                sx={{ marginTop: "10px" }}
              />
              <S.ContentContainer onClick={() => setTextEditorDialog((prev) => ({ ...prev, open: true, content: editTaskDialog.content }))}>
                {
                  editTaskDialog.content === "" ?
                    <div style={{ fontSize: "1rem" }}>{t("dialogs.task.edit.description")}</div> :
                    <TextMarkdown text={editTaskDialog.content} />
                }
              </S.ContentContainer>
              <div style={{ display: "flex", width: "100%", fontSize: "1rem", fontWeight: "bold" }}>{t("dialogs.task.edit.setDate")}</div>
              <div style={{ display: "flex", width: "100%", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
                <DatePicker
                  label={t("dialogs.task.edit.startDate")}
                  sx={{ width: "47%" }}
                  value={dayjs(editTaskDialog.startDate)}
                  onChange={(date) => updateState('startDate', date)}
                  shouldDisableDate={editTaskDialog.endDate ? (day) => day.isAfter(editTaskDialog.endDate) : undefined}
                  format="YYYY.MM.DD"
                />
                <div style={{ fontSize: "1.2rem", fontWeight: "600" }}>~</div>
                <DatePicker
                  label={t("dialogs.task.edit.endDate")}
                  sx={{ width: "47%" }}
                  value={dayjs(editTaskDialog.endDate)}
                  onChange={(date) => updateState('endDate', date)}
                  shouldDisableDate={editTaskDialog.startDate ? (day) => day.isBefore(editTaskDialog.startDate) : undefined}
                  format="YYYY.MM.DD"
                />
              </div>
              {
                currentPath === "/plan" &&
                <div style={{ display: "flex", flexDirection: "row", width: "100%", justifyContent: "space-between", alignItems: "center" }}>
                  <div style={{ fontSize: "1rem", fontWeight: "bold" }}>{t("dialogs.task.edit.routine.interval")}</div>
                  <ToggleButtonGroup
                    exclusive
                    value={editTaskDialog.termType}
                    onChange={(event, newViewType) => updateState("termType", newViewType)}
                    color="primary"
                  >
                    <ToggleButton value={0} selected={editTaskDialog.termType === 0} onClick={() => setRoutineOption(0)}>{t("dialogs.task.edit.routine.daily")}</ToggleButton>
                    <ToggleButton value={1} selected={editTaskDialog.termType === 1} onClick={() => setRoutineOption(1)}>{t("dialogs.task.edit.routine.weekly")}</ToggleButton>
                    <ToggleButton value={2} selected={editTaskDialog.termType === 2} onClick={() => setRoutineOption(2)}>{t("dialogs.task.edit.routine.monthly")}</ToggleButton>
                  </ToggleButtonGroup>
                </div>
              }

              {
                (editTaskDialog.termType === 1 || editTaskDialog.termType === 2) &&
                <>
                  <div style={{ display: "flex", flexDirection: "row", width: "100%", justifyContent: "space-between", alignItems: "center" }}>
                    <div style={{ fontSize: "1rem", fontWeight: "bold" }}>{t("dialogs.task.edit.routine.repeat")}</div>
                    <CustomBackgroundColorButton onClick={editRoutineStart} text={t("dialogs.task.edit.buttons.edit")} fontSize="0.8rem" />
                  </div>
                  <div style={{ display: "flex", flexDirection: "row", width: "100%", justifyContent: "space-between", alignItems: "center" }}>
                    <div style={{ fontSize: "1rem", fontWeight: "bold" }}>{editTaskDialog.termType === 1 ? t("dialogs.task.edit.routine.weekly") : t("dialogs.task.edit.routine.monthly")}</div>
                    <div style={{
                      display: "grid",
                      gridTemplateColumns: `repeat(${editTaskDialog.termData && editTaskDialog.termData.length < 7 ? editTaskDialog.termData.length : 7}, 1fr)`,
                      gap: "5px",
                    }}>
                      {editTaskDialog.termData?.map((interval: string) => (
                        <div key={interval} style={{ fontSize: "1rem", fontWeight: "bold" }}>
                          {editTaskDialog.termType === 1 ? t(`dialogs.task.edit.routine.${interval}`) : `${interval}일`}
                        </div>
                      ))}
                    </div>
                  </div>
                </>
              }
              <TextField
                label={t("dialogs.task.edit.location")}
                variant="outlined"
                fullWidth
                value={editTaskDialog.location.address}
                onChange={(e) => editLocation(e.target.value)}
              />
              <div style={{ display: "flex", flexDirection: "row", width: "100%", justifyContent: "space-between", alignItems: "center" }}>
                <div style={{ fontSize: "0.8rem", fontWeight: "bold" }}>{t("dialogs.task.edit.hashtag")}</div>
                <S.TagInputContainer>
                  {editTaskDialog.hashtags.map((tag, idx) => {
                    return <S.TagItem key={idx} style={{ backgroundColor: tag.backgroundColor, color: tag.textColor }}>
                      <span>{tag.value}</span>
                    </S.TagItem>
                  })}
                  <S.TagInput
                    placeholder={editTaskDialog.hashtags.length === 0 ? t("dialogs.task.edit.hashtagPlaceholder") : ""}
                    value={inputValue}
                    onChange={(e) => setInputValue(e.target.value)}
                    onKeyDown={handleKeyDown}
                  />
                </S.TagInputContainer>
              </div>
            </DialogContent>
            <DialogActions>
              <CustomColorButton onClick={clearState} text={t("dialogs.task.edit.buttons.cancel")} fontSize="0.8rem" />
              <CustomBackgroundColorButton onClick={editTask} text={t("dialogs.task.edit.buttons.edit")} fontSize="0.8rem" />
            </DialogActions>
          </>
          :
          <>
            <DialogTitle>{t("dialogs.task.edit.routine.setRepeat")}</DialogTitle>
            <DialogContent>
              {editTaskDialog.termType === 1 ? (
                <div style={{ display: 'flex', flexDirection: 'row', width: "100%", justifyContent: "space-between" }}>
                  {weekDays.map((day) => (
                    <div key={day} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', backgroundColor: editRoutineData.termData?.includes(day) ? theme.colors.primary : "white", borderRadius: "50%", color: editRoutineData.termData?.includes(day) ? "white" : "black", fontSize: "0.9rem", fontWeight: "bold", width: "30px", height: "30px", cursor: "pointer", justifyContent: "center" }} onClick={() => setEditRoutineData(day)}>
                      {t(`dialogs.task.edit.routine.${day}`)}
                    </div>
                  ))}
                </div>
              ) : (
                <div style={{ display: "grid", gridTemplateColumns: "repeat(7, 1fr)", gap: "5px" }}>
                  {
                    Array.from({ length: 31 }, (_, i) => i + 1).map((day) => (
                      <div key={day} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', backgroundColor: editRoutineData.termData?.includes(day.toString()) ? theme.colors.primary : "white", borderRadius: "50%", color: editRoutineData.termData?.includes(day.toString()) ? "white" : "black", fontSize: "0.9rem", fontWeight: "bold", width: "30px", height: "30px", cursor: "pointer", justifyContent: "center" }} onClick={() => setEditRoutineData(day.toString())}>
                        {day}
                      </div>
                    ))
                  }
                </div>
              )}
            </DialogContent>
            <DialogActions>
              <CustomColorButton onClick={editRoutineClearState} text={t("dialogs.task.edit.buttons.cancel")} fontSize="0.8rem" />
              <CustomBackgroundColorButton onClick={applyRoutine} text={t("dialogs.task.edit.buttons.confirm")} fontSize="0.8rem" />
            </DialogActions>
          </>
      }
    </Dialog>
  )
}

export default EditTaskDialog;
