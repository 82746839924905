import { atom } from 'jotai';
import { ApplicationPeriodInfo } from '../Course';
import dayjs from 'dayjs';

export interface MultipleLectureFormat {
  index: number; // 일련번호
  name: string; // 강좌명
  teacher: string; // 담당교사
  grades: number[]; // 학년
  headcount: number; // 정원
  period: number; // 차시
  operatingTimes: { [key: string]: number[] }; // 운영시간
  operatingPlan: string; // 운영계획
  classRoom: string; // 강의실
  textbook: string; // 교재
  isVisibleMember: boolean; // 수강생 표시 여부
  isAbleToCancel: boolean; // 수강 취소 가능 여부
  applicationPeriod: ApplicationPeriodInfo; // 신청기간
  coursePeriod: { // 수강기간
    startDate: string;  // yyyy-MM-dd
    endDate: string;  // yyyy-MM-dd
  };
  hashtags: {
    backgroundColor: string;
    textColor: string;
    value: string;
  }[];
}

interface CreateLectureDialog {
  open: boolean;
  mode: "menu" | "single" | "multi" | "confirm" | "edit"; // 메뉴, 단일, 일괄, 확인, 수정
  file: File | null; // 파일
  editIndex: number; // 수정 인덱스
  editLecture: MultipleLectureFormat | null; // 수정 강좌
  allMultipleLecture: MultipleLectureFormat[]; // 일괄 강좌 전체
  selectedMultipleLecture: number[]; // 일괄 강좌 선택
  name: string; // 강좌명
  teacher: string; // 담당교사
  grades: number[]; // 학년
  headcount: number; // 정원
  period: number; // 차시
  operatingTimes: { [key: string]: number[] }; // 운영시간
  operatingPlan: string; // 운영계획
  classRoom: string; // 강의실
  textbook: string; // 교재
  isVisibleMember: boolean; // 수강생 표시 여부
  isAbleToCancel: boolean; // 수강 취소 가능 여부
  applicationPeriod: ApplicationPeriodInfo; // 신청기간
  coursePeriod: { // 수강기간
    startDate: string;  // yyyy-MM-dd
    endDate: string;  // yyyy-MM-dd
  };
  hashtags: {
    backgroundColor: string;
    textColor: string;
    value: string;
  }[];
}

export const createLectureDialogInitializeState = (open: boolean): CreateLectureDialog => ({
  open: open,
  mode: "menu",
  file: null,
  editIndex: -1,
  editLecture: null,
  allMultipleLecture: [],
  selectedMultipleLecture: [],
  name: '',
  teacher: '',
  grades: [],
  headcount: 1,
  period: 1,
  operatingTimes: {
    Monday: [],
    Tuesday: [],
    Wednesday: [],
    Thursday: [],
    Friday: [],
    Saturday: [],
    Sunday: [],
  },
  operatingPlan: '',
  classRoom: '',
  textbook: '',
  isVisibleMember: true,
  isAbleToCancel: true,
  applicationPeriod: {
    start: {
      date: dayjs().format('YYYY-MM-DD'),
      hour: 0,
      minute: 0,
    },
    end: {
      date: dayjs().format('YYYY-MM-DD'),
      hour: 0,
      minute: 0,
    },
    detail: {
      option1: false,
      option2: false,
      option3: false,
      option4: false,
      option5: false,
    }
  },
  coursePeriod: {
    startDate: dayjs().format('YYYY-MM-DD'),
    endDate: dayjs().format('YYYY-MM-DD'),
  },
  hashtags: [],
});

const createLectureDialogAtom = atom<CreateLectureDialog>(createLectureDialogInitializeState(false));

export default createLectureDialogAtom;
