import styled from 'styled-components';

export const SignatureContainer = styled.div<{ $borderColor: string, $backgroundColor: string | undefined, $fontColor: string | undefined }>`
  width: 600px;
  height: ${600 * 5 / 9}px;
  border: ${({ $borderColor }) => `10px solid ${$borderColor}`};
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  cursor: pointer;
  padding: 20px;
  position: relative;
  background-color: ${({ $backgroundColor }) => $backgroundColor || "white"};
  color: ${({ $fontColor }) => $fontColor || "black"};
`

export const LoopIconStyle = {
  position: "absolute",
  top: "10px",
  right: "10px",
  fontSize: "24px"
};

export const SignatureProfileContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 50%;
  justify-content: flex-start;
`;

export const SignatureImage = styled.img`
  width: 150px;
  height: 150px;
  border-radius: 50%;
  padding: 0;
  margin: 0;
  object-fit: cover;
`;

export const SignatureProfileInfo = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: space-between;
  padding: 20px;
`;

export const SignatureGrid = styled.div<{ $height: string }>`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-auto-rows: 1fr;
  width: 100%;
  height: ${({ $height }) => $height};
  justify-content: flex-start;
`;

export const GridItem = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px;
`;

export const ItemTitle = styled.div`
  font-size: 1rem;
  font-weight: bold;
`;

export const ItemValue = styled.div`
  font-size: 1rem;
`;
