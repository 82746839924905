import styled from 'styled-components';

export const TagInputContainer = styled.div<{ $isMobile: boolean }>`
  display: flex;
  flex-wrap: wrap;
  gap: ${({ $isMobile }) => ($isMobile ? "0.4em" : "0.7em")};
  padding: ${({ $isMobile }) => ($isMobile ? "8px" : "12px")};
  font-size: ${({ $isMobile }) => ($isMobile ? "0.8rem" : "0.9rem")};
  width: calc(100% - ${({ $isMobile }) => ($isMobile ? "20px" : "28px")});
  border: 1px solid #c0c0c0;
  border-radius: 4px;
`;

export const TagItem = styled.div`
  display: inline-block;
  padding: 0.2em 0.5em;
  border-radius: 20px;
  color: white;
`;

export const TagInput = styled.input`
  flex-grow: 1;
  min-width: 30px;
  max-width: 150px;
  border: none;
  outline: none;
  font-family: "Noto Sans KR", sans-serif;
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: calc(100% - 24px); // padding 10px * 2 + border 1px * 2
  height: 200px;
  border: 1px solid #d9d9d9;
  border-radius: 5px;
  padding: 10px;
  cursor: pointer;
  overflow-y: auto;

  &:hover {
    border-color: black;
  }
`;

export const AdditionalButton = styled.div`
  font-size: 0.8rem;
  font-weight: bold;
  width: calc(100% - 20px);
  padding: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border: 1px solid #e0e0e0;
  border-radius: 5px;
  cursor: pointer;

  &:hover {
    border-color: black;
  }
`;

export const CustomInput = styled.input<{ $isMobile: boolean }>`
  padding: ${({ $isMobile }) => ($isMobile ? "8px" : "12px")};
  font-size: ${({ $isMobile }) => ($isMobile ? "0.8rem" : "0.9rem")};
  width: calc(100% - ${({ $isMobile }) => ($isMobile ? "20px" : "28px")});
  border: 1px solid #c0c0c0;
  border-radius: 4px;

  &:hover {
    border-color: black;
  }
`;
