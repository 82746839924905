import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: calc(100% - 40px);
  height: calc(100% - 40px);
  justify-content: center;
  align-items: center;

  overflow-y: auto;
  padding: 20px;

  @media (max-width: 768px) {
    flex-direction: column;
    height: calc(100vh - 96px);
  }
`;

export const ContentContainer = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  max-width: 1200px;
  height: 100%;
  gap: 20px;
`;
