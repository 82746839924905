import { Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { useAtom, useAtomValue, useSetAtom } from "jotai";
import CloseIcon from "@mui/icons-material/Close";
import React from "react";
import * as S from "./styled";
import createClassDialogAtom from "@/Atoms/Dialogs/Create/Class";
import { createClassDialogClassRoomAtom, createClassDialogCloseAtom, createClassDialogGradeAtom, createClassDialogHeadcountAtom, createClassDialogNameAtom, createClassDialogOperatingTimesAtom, createClassDialogOperatingTimesDayAtom, createClassDialogOperatingTimesTimeAtom, createClassDialogPeriodAtom, createClassDialogSaveAtom, createClassDialogTeacherAtom, createClassDialogTextbookAtom } from "@/ViewModels/Dialogs/Create/ClassViewModel";
import theme from "@/Styles/theme";
import afterSchoolCourseAtom from "@/Atoms/AfterSchoolCourse";
import KeyboardDoubleArrowDownIcon from "@mui/icons-material/KeyboardDoubleArrowDown";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import convertDayToKorean from "@/Utils/ConvertDayToKorean";
import CustomColorButton from "@/Components/Common/Buttons/CustomColor";
import CustomBackgroundColorButton from "@/Components/Common/Buttons/CustomBackgroundColor";

const RequiredMark = () => <span style={{ color: "red" }}>*</span>;

const CreateClassDialog = () => {
  const course = useAtomValue(afterSchoolCourseAtom);
  const dialog = useAtomValue(createClassDialogAtom);
  const closeDialog = useSetAtom(createClassDialogCloseAtom);
  const saveDialog = useSetAtom(createClassDialogSaveAtom);
  const [teacher, editTeacher] = useAtom(createClassDialogTeacherAtom);
  const [grade, editGrade] = useAtom(createClassDialogGradeAtom);
  const [headcount, editHeadcount] = useAtom(createClassDialogHeadcountAtom);
  const [period, editPeriod] = useAtom(createClassDialogPeriodAtom);
  const [operatingTimes, editOperatingTimes] = useAtom(createClassDialogOperatingTimesAtom);
  const editOperatingTimesDay = useSetAtom(createClassDialogOperatingTimesDayAtom);
  const editOperatingTimesTime = useSetAtom(createClassDialogOperatingTimesTimeAtom);
  const [classRoom, editClassRoom] = useAtom(createClassDialogClassRoomAtom);
  const [name, editName] = useAtom(createClassDialogNameAtom);
  const [textbook, editTextbook] = useAtom(createClassDialogTextbookAtom);
  const courseOperatingTimes = course?.operatingTimes || {};
  const uniqueTimes = Array.from(
    new Set(Object.values(courseOperatingTimes).flat())
  ).sort((a, b) => a - b);
  const daysOfWeek = Object.keys(courseOperatingTimes);


  return (
    course &&
    <Dialog open={dialog.open} fullScreen fullWidth>
      <DialogTitle sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between", fontSize: "0.9rem", fontWeight: "bold", backgroundColor: theme.colors.primary, color: "white" }}>
        <div>강좌 개설하기</div>
        <CloseIcon onClick={closeDialog} sx={{ color: "white", cursor: "pointer" }} />
      </DialogTitle>
      <DialogContent sx={{ paddingTop: "20px !important" }}>

        {/* 과정개설 1 */}
        <S.ContentContainer>
          <S.ContentTitle>담당교사<RequiredMark /></S.ContentTitle>
          <S.ContentContent>
            <input type="text" style={S.ContentInputStyle(120)} value={teacher} onChange={(e) => editTeacher(e.target.value)} />
            <div>*담당교사로 입력된 교사만 수정, 삭제, 출결입력이 가능합니다.</div>
            <div>*담당교사 이름을 '담당교사'라고 입력하면 모든 교사가 출결입력이 가능합니다.</div>
            <div>*공동으로 운영할 경우 쉼표로 구분하여 교사별 시수를 괄호 안에 입력하면 수당 계산에 반영됩니다. 예) 김교사(5),이교사(10)</div>
          </S.ContentContent>
        </S.ContentContainer>
        <S.ContentContainer>
          <S.ContentTitle>학년<RequiredMark /></S.ContentTitle>
          <S.ContentContent>
            <S.ContentContentRow>
              <Checkbox checked={grade === 0} onChange={() => editGrade(0)} />
              <div style={{ marginLeft: "-15px" }}>전체</div>
              <Checkbox checked={grade === 1} onChange={() => editGrade(1)} />
              <div style={{ marginLeft: "-15px" }}>1학년</div>
              <Checkbox checked={grade === 2} onChange={() => editGrade(2)} />
              <div style={{ marginLeft: "-15px" }}>2학년</div>
              <Checkbox checked={grade === 3} onChange={() => editGrade(3)} />
              <div style={{ marginLeft: "-15px" }}>3학년</div>
            </S.ContentContentRow>
          </S.ContentContent>
        </S.ContentContainer>
        <S.ContentContainer>
          <S.ContentTitle>정원<RequiredMark /></S.ContentTitle>
          <S.ContentContent>
            <S.ContentContentRow>
              <input type="number" style={S.ContentInputStyle(80)} min={1} value={headcount} onChange={(e) => editHeadcount(parseInt(e.target.value))} />명(선착순)
            </S.ContentContentRow>
          </S.ContentContent>
        </S.ContentContainer>
        <S.ContentContainer>
          <S.ContentTitle>총시수<RequiredMark /></S.ContentTitle>
          <S.ContentContent>
            <S.ContentContentRow>
              <input type="number" style={S.ContentInputStyle(80)} min={1} value={period} onChange={(e) => editPeriod(parseInt(e.target.value))} />차시
            </S.ContentContentRow>
            <div>*수업시간이 50분이 아닐 경우 괄호 안에 분으로 표시합니다. 예) 10(80분)</div>
          </S.ContentContent>
        </S.ContentContainer>
        <S.ContentContainer>
          <S.ContentTitle>운영시간</S.ContentTitle>
          <S.ContentContent>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell align="center">교시</TableCell>
                    {daysOfWeek.map((day) => (
                      <TableCell key={day} align="center">
                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: "10px", justifyContent: "center" }}>
                          <KeyboardDoubleArrowDownIcon sx={{ cursor: "pointer", border: "1px solid #c0c0c0", borderRadius: "5px" }} onClick={() => editOperatingTimesDay(day, courseOperatingTimes[day])} />
                          {convertDayToKorean(day)}
                        </div>
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {uniqueTimes.map((time) => (
                    <TableRow key={time}>
                      <TableCell align="center">
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: "10px" }}>
                          {time}교시
                          <KeyboardDoubleArrowRightIcon sx={{ cursor: "pointer", border: "1px solid #c0c0c0", borderRadius: "5px" }} onClick={() => editOperatingTimesTime(daysOfWeek, time)} />
                        </div>
                      </TableCell>
                      {daysOfWeek.map((day) => (
                        <TableCell key={`${day}-${time}`} align="center">
                          {courseOperatingTimes[day]?.includes(time) ? (
                            <Checkbox
                              checked={operatingTimes[day]?.includes(time) || false}
                              onChange={() => editOperatingTimes(day, time)}
                            />
                          ) : (
                            null
                          )}
                        </TableCell>
                      ))}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </S.ContentContent>
        </S.ContentContainer>
        <S.ContentContainer>
          <S.ContentTitle>강의실</S.ContentTitle>
          <S.ContentContent>
            <S.ContentContentRow>
              <input type="text" style={S.ContentInputStyle(300)} value={classRoom} onChange={(e) => editClassRoom(e.target.value)} />
              <div>예) 101, 302, 과학실, 수준별교실</div>
            </S.ContentContentRow>
          </S.ContentContent>
        </S.ContentContainer>
        <S.ContentContainer>
          <S.ContentTitle>강좌 이름<RequiredMark /></S.ContentTitle>
          <S.ContentContent>
            <input type="text" style={S.ContentInputStyle(500)} placeholder="과목: 강좌명" value={name} onChange={(e) => editName(e.target.value)} />
            <div>예) 국어: 소설특강, 영어: 문법특강 / 과목군 설정 시 과목 뒤에 콜론(:) 입력</div>
            <div>강좌 이름 맨 앞에 별표(*)를 붙이면 학생부자료에서 제외됩니다. 예) *대학생 멘토링</div>
          </S.ContentContent>
        </S.ContentContainer>
        <S.ContentContainer style={{ borderBottom: "1px solid #c0c0c0" }}>
          <S.ContentTitle>교재</S.ContentTitle>
          <S.ContentContent>
            <input type="text" style={S.ContentInputStyle(600)} value={textbook} onChange={(e) => editTextbook(e.target.value)} />
            <div>*예) EBS 수능특강 국어, 수능 영어 기본(**출판사), 유인물</div>
          </S.ContentContent>
        </S.ContentContainer>
        {/* <S.ContentContainer>
          <S.ContentTitle>과제방</S.ContentTitle>
          <S.ContentContent>

          </S.ContentContent>
        </S.ContentContainer> */}
      </DialogContent>
      <DialogActions>
        <CustomColorButton text="취소" onClick={closeDialog} />
        <CustomBackgroundColorButton text="개설" onClick={saveDialog} />
      </DialogActions>
    </Dialog >
  )
}

export default CreateClassDialog;
