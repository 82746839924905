import { CreateSignature, Signature } from "@/Atoms/Signature/Atom";

// 접속 시 사용 할 시그니쳐 트리거 API
export const emptySignature = async (access: string) => {
  const response = await fetch(`${process.env.REACT_APP_BACK_SIGNATURE_ADDRESS}/empty`, {
    method: 'POST',
    headers: {
      'Authorization': `Bearer ${access}`,
    }
  });
  return response;
}

// 시그니쳐 조회 API
export const getSignature = async (access: string) => {
  const response = await fetch(`${process.env.REACT_APP_BACK_SIGNATURE_ADDRESS}`, {
    method: 'GET',
    headers: {
      "Authorization": `Bearer ${access}`,
      'accept': 'application/json',
    },
  });
  return response;
}

// 시그니쳐 생성 및 수정 API
export const updateSignature = async (access: string, signature: CreateSignature) => {
  const newSignatureInfo = (signature.orderList).map((info, index) => ({
    ...info,
    orderBy: index,
  }));
  const formData = new FormData();
  formData.append('id', signature.id.toString());
  formData.append('representative', signature.representative.toString());
  formData.append('careerAdvancement', signature.careerAdvancement.toString());
  formData.append('name', signature.name);
  formData.append('nickname', signature.nickname);
  formData.append('email', signature.email);
  formData.append('tag', signature.tag);
  formData.append('isUserProfile', signature.isUserProfile.toString());
  if (!signature.isUserProfile && signature.image) {
    formData.append('image', signature.image);
  }
  formData.append('info', JSON.stringify(newSignatureInfo));
  formData.append('borderColor', signature.borderColor);
  formData.append('backgroundColor', signature.backgroundColor);
  formData.append('fontColor', signature.fontColor);
  const response = await fetch(`${process.env.REACT_APP_BACK_SIGNATURE_ADDRESS}`, {
    method: 'PUT',
    headers: {
      "Authorization": `Bearer ${access}`,
    },
    body: formData,
  });
  return response;
}

// 시그니쳐 삭제 API
export const deleteSignature = async (access: string, signatureId: number) => {
  const response = await fetch(`${process.env.REACT_APP_BACK_SIGNATURE_ADDRESS}/${signatureId}`, {
    method: 'DELETE',
    headers: {
      "Authorization": `Bearer ${access}`,
    },
  });
  return response;
}

// 대표 시그니쳐 설정 API
export const setDefaultSignature = async (access: string, signatureId: number) => {
  const response = await fetch(`${process.env.REACT_APP_BACK_SIGNATURE_ADDRESS}/${signatureId}/default`, {
    method: 'POST',
    headers: {
      "Authorization": `Bearer ${access}`,
    },
  });
  return response;
}

// 유저 시그니쳐 조회 API
export const getSignatureById = async (access: string, userId: number) => {
  const response = await fetch(`${process.env.REACT_APP_BACK_SIGNATURE_ADDRESS}/booster`, {
    method: 'POST',
    headers: {
      "accept": 'application/json',
      "Content-Type": 'application/json',
      "Authorization": `Bearer ${access}`,
    },
    body: JSON.stringify({
      user_ids: [userId],
    }),
  });
  return response;
}
