import { ViewMode } from 'gantt-task-react';
import { atom } from 'jotai';

interface GanttChartProps {
  viewMode: ViewMode;
  columnWidth: number;
  expandList: boolean;
}

const ganttChartAtom = atom<GanttChartProps>({
  viewMode: ViewMode.Day,
  columnWidth: 70,
  expandList: true,
});

export default ganttChartAtom;
