import React from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { useAtomValue, useSetAtom } from 'jotai';
import { currentChatRoomAtom } from '@/Atoms/ChatAtom';
import { dokgabiChatModalSidebarAtom } from '@/Atoms/DokgabiChat/Atom';
import { useTranslation } from 'react-i18next';

const DokgabiListHeader = () => {
  const { t } = useTranslation();
  const currentChatRoom = useAtomValue(currentChatRoomAtom);
  const setSidebar = useSetAtom(dokgabiChatModalSidebarAtom);

  return (
    currentChatRoom &&
    <div
      style={{
        position: "sticky",
        top: 0,
        zIndex: 1000,
        width: "cale(100% - 40px)",
        height: "40px",
        backgroundColor: "white",
        borderBottom: "1px solid #c0c0c0",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        padding: "10px 20px",
      }}
    >
      <div style={{ fontSize: "0.9rem", fontWeight: "bold", color: "black" }}>{t("chat.header.list")}</div>
      <CloseIcon style={{ cursor: "pointer" }} onClick={() => setSidebar(false)} />
    </div>
  )
}

export default DokgabiListHeader;
