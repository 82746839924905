import { useMediaQuery } from '@mui/material';
import { useEffect, useState } from 'react';

const useIsMobile = () => {
  const [isWidthBelowThreshold, setIsWidthBelowThreshold] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      const width = window.innerWidth;
      setIsWidthBelowThreshold(width - 250 < 768);
    };

    // 처음 마운트될 때와 창 크기가 변할 때마다 체크
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const isMobile = useMediaQuery('(max-width:768px)');

  const isTablet = !isMobile && isWidthBelowThreshold; // 데스크탑에서 사이드바가 차지하는 공간을 제외한 모바일 크기
  const isDesktop = !isMobile && !isWidthBelowThreshold; // 완전한 데스크탑 환경

  return { isMobile, isTablet, isDesktop };
};

export default useIsMobile;
