import { useAtomValue } from "jotai";
import React from "react";
import SettingsSidebar from "@/Components/Settings/Sidebar";
import { settingsSidebarOpenAtom } from "@/Atoms/Settings";
import SettingsContents from "@/Components/Settings/Contents";
import * as S from "./styled";
import SettingsEditProfileDialog from "@/Components/Settings/Dialogs/EditProfile";
import ResetPasswordModal from "@/Components/Settings/Dialogs/ResetPassword";

const Dialogs = () => {
  return (
    <>
      <SettingsEditProfileDialog />
      <ResetPasswordModal />
    </>
  )
}

const Settings = () => {
  const openSidebar = useAtomValue(settingsSidebarOpenAtom);

  return (
    <S.Container>
      {openSidebar && <SettingsSidebar />}
      <SettingsContents />
      <Dialogs />
    </S.Container>
  )
}

export default Settings;
