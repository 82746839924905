import React from 'react';
import * as S from './styled';
import { useAtomValue, useSetAtom } from 'jotai';
import afterSchoolCourseAtom from '@/Atoms/AfterSchoolCourse';
import { useNavigate } from 'react-router-dom';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, Paper, Box, Divider } from '@mui/material';
import convertDayToKorean from '@/Utils/ConvertDayToKorean';
import CreateClassDialog from '@/Components/Crew/Dialogs/Create/Class';
import { createClassDialogOpenAtom } from '@/ViewModels/Dialogs/Create/ClassViewModel';
import CustomColorButton from '@/Components/Common/Buttons/CustomColor';

const Dialogs = () => {
  return <>
    <CreateClassDialog />
  </>
}

const AfterSchoolCourse = () => {
  const course = useAtomValue(afterSchoolCourseAtom);
  const navigate = useNavigate();
  const openCreateClassDialog = useSetAtom(createClassDialogOpenAtom);

  React.useEffect(() => {
    if (!course) {
      navigate('/after-school');
    }
  }, [course, navigate]);

  return (course &&
    <S.Container>
      <S.ContentContainer>
        {/* Course Title */}
        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
          <Typography variant="h5" fontWeight="bold" gutterBottom>
            {course.courseName}
          </Typography>
        </div>
        <Divider sx={{ marginBottom: "10px" }} />

        {/* Basic Course Information */}
        <Box sx={{ marginBottom: "15px" }}>
          <Typography variant="subtitle1"><strong>담당교사:</strong> {course.teacher}</Typography>
          <Typography variant="subtitle1">
            <strong>신청 기간:</strong> {course.applicationPeriod.start.date} {course.applicationPeriod.start.hour}:{course.applicationPeriod.start.minute} ~ {course.applicationPeriod.end.date} {course.applicationPeriod.end.hour}:{course.applicationPeriod.end.minute}
          </Typography>
          <Typography variant="subtitle1">
            <strong>수강 기간:</strong> {course.coursePeriod.startDate} ~ {course.coursePeriod.endDate}
          </Typography>
          <Typography variant="subtitle1">
            <strong>신청 방법:</strong>
            {
              ["과목군별 택1 이내", "과목군별 택2 이내", "과목군별 택3 이내", "일괄신청 금지", "개별신청 금지"][course.applicationMethod]
            }
          </Typography>
          <Typography variant="subtitle1">
            <strong>보기 옵션:</strong> {course.viewOption.isVisible ? "보이기" : "숨기기"} ({["재학생", "외부강사", "선생님", "방과후담당"][course.viewOption.grade]} 이상)
          </Typography>
          <Typography variant="subtitle1">
            <strong>비용 공개:</strong> {course.isCostDisclosured ? "예" : "아니오"}
          </Typography>
          <Typography variant="subtitle1"><strong>선택군:</strong> {course.selectionGroup}</Typography>
        </Box>

        {/* Operating Times */}
        <Box sx={{ marginBottom: "15px" }}>
          <Typography variant="h6">운영시간</Typography>
          <TableContainer component={Paper}>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell align="center">요일</TableCell>
                  <TableCell align="center">교시</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {Object.entries(course.operatingTimes).map(([day, times]) => (
                  <TableRow key={day}>
                    <TableCell align="center">{convertDayToKorean(day)}</TableCell>
                    <TableCell align="center">{times.join(", ")} 교시</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>

        {/* Classes List */}
        <Box sx={{ marginBottom: "15px" }}>
          <div style={{ display: "flex", flexDirection: "row", gap: "20px", alignItems: "center" }}>
            <Typography variant="h6">강좌 목록</Typography>
            <CustomColorButton onClick={openCreateClassDialog} text="+ 강좌 개설" />
          </div>
          <TableContainer component={Paper}>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell align="center">강좌명</TableCell>
                  <TableCell align="center">담당교사</TableCell>
                  <TableCell align="center">학년</TableCell>
                  <TableCell align="center">정원</TableCell>
                  <TableCell align="center">차시</TableCell>
                  <TableCell align="center">운영시간</TableCell>
                  <TableCell align="center">강의실</TableCell>
                  <TableCell align="center">교재</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {course.classes.map((classItem) => (
                  <TableRow key={classItem.id} sx={{ "&:hover": { backgroundColor: "#e0e0e0", cursor: "pointer" } }}>
                    <TableCell align="center">{classItem.name}</TableCell>
                    <TableCell align="center">{classItem.teacher}</TableCell>
                    <TableCell align="center">{["전체", "1학년", "2학년", "3학년"][classItem.grade]}</TableCell>
                    <TableCell align="center">{classItem.headcount}</TableCell>
                    <TableCell align="center">{classItem.period}</TableCell>
                    <TableCell align="center">
                      {Object.entries(classItem.operatingTimes).map(([day, times]) => (
                        <div key={day}>
                          {convertDayToKorean(day)}: {times.join(", ")} 교시
                        </div>
                      ))}
                    </TableCell>
                    <TableCell align="center">{classItem.classRoom}</TableCell>
                    <TableCell align="center">{classItem.textbook}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>

        {/* Application Period Details */}
        <Box sx={{ marginBottom: "15px" }}>
          <Typography variant="h6">신청 기간 세부 사항</Typography>
          <ul>
            <li>대기 상태일 때 지도교사 비공개: {course.applicationPeriod.detail.option1 ? "예" : "아니오"}</li>
            <li>방과후 담당자 외 강좌수정 금지: {course.applicationPeriod.detail.option2 ? "예" : "아니오"}</li>
            <li>방과후 담당자 외 수강신청 금지: {course.applicationPeriod.detail.option3 ? "예" : "아니오"}</li>
            <li>강좌마다 신청기간 설정 허용: {course.applicationPeriod.detail.option4 ? "예" : "아니오"}</li>
            <li>강좌 운영시간 중복 시 수강신청 금지: {course.applicationPeriod.detail.option5 ? "예" : "아니오"}</li>
          </ul>
        </Box>

        {/* Subsidy Information */}
        <Box sx={{ marginBottom: "15px" }}>
          <Typography variant="h6">지원금 정보</Typography>
          <Typography variant="subtitle2" gutterBottom><strong>학생별 지원금</strong></Typography>
          <TableContainer component={Paper}>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell align="center">학번</TableCell>
                  <TableCell align="center">이름</TableCell>
                  <TableCell align="center">자유수강권</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {course.subsidy.students.map((student) => (
                  <TableRow key={student.studentId}>
                    <TableCell align="center">{student.studentId}</TableCell>
                    <TableCell align="center">{student.name}</TableCell>
                    <TableCell align="center">{student.freePass}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>

          <Typography variant="subtitle2" gutterBottom sx={{ marginTop: "10px" }}><strong>학생수에 따른 강사비</strong></Typography>
          <TableContainer component={Paper}>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell align="center">학생수</TableCell>
                  <TableCell align="center">강사비</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {course.subsidy.fees.map((fee, index) => (
                  <TableRow key={index}>
                    <TableCell align="center">{fee.studentsCount}</TableCell>
                    <TableCell align="center">{fee.fee}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>

        {/* Course Evaluation */}
        <Box sx={{ marginBottom: "15px" }}>
          <Typography variant="h6">강의 평가</Typography>
          <Typography variant="body1">{course.courseEvaluation}</Typography>
        </Box>

        {/* Additional Announcement */}
        <Box sx={{ marginBottom: "15px" }}>
          <Typography variant="h6">공지사항</Typography>
          <Typography variant="body1">{course.announcement}</Typography>
        </Box>
      </S.ContentContainer>
      <Dialogs />
    </S.Container>
  );
};

export default AfterSchoolCourse;
