import { settingsResetPasswordModalAtom } from "@/Atoms/Settings";
import useIsMobile from "@/Hooks/useIsMobile";
import { Dialog, DialogActions, DialogContent, DialogTitle, TextField, Tooltip } from "@mui/material";
import { useAtom, useAtomValue, useSetAtom } from "jotai";
import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import CustomBackgroundColorButton from "@/Components/Common/Buttons/CustomBackgroundColor";
import { ResetPasswordModalCurrentPasswordAuthAtom, ResetPasswordModalNewPasswordAuthAtom, ResetPasswordModalNewPasswordConfirmAuthAtom, ResetPasswordModalSubmitAtom } from "@/ViewModels/Settings/ResetPasswordViewModel";
import CheckCircleSharpIcon from "@mui/icons-material/CheckCircleSharp";
import WarningIcon from "@mui/icons-material/Warning";
import * as S from "./styled";
import { useTranslation } from "react-i18next";

const ResetPasswordModal = () => {
  const { isMobile } = useIsMobile();
  const { t } = useTranslation();
  const [modal, setModal] = useAtom(settingsResetPasswordModalAtom);
  const authCurrentPassword = useSetAtom(ResetPasswordModalCurrentPasswordAuthAtom);
  const isValidNewPassword = useAtomValue(ResetPasswordModalNewPasswordAuthAtom);
  const isValidNewPasswordConfirm = useAtomValue(ResetPasswordModalNewPasswordConfirmAuthAtom);
  const resetPassword = useSetAtom(ResetPasswordModalSubmitAtom);

  return (
    modal &&
    <Dialog open={modal !== null} onClose={() => setModal(null)} fullScreen={isMobile} fullWidth maxWidth="sm">
      <DialogTitle sx={{ fontSize: "0.9rem", fontWeight: "bold", display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
        {t("settings.contents.resetPassword.title")}
        <CloseIcon sx={{ cursor: "pointer" }} onClick={() => setModal(null)} />
      </DialogTitle>
      <DialogContent sx={{ fontSize: "0.8rem", display: "flex", flexDirection: "column", gap: "20px", justifyContent: "center", alignItems: "center" }}>
        <div style={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", gap: "10px" }}>
          <div style={{ width: "150px" }}>{t("settings.contents.resetPassword.currentPassword")}</div>
          <TextField variant="outlined" size="small" type="password" value={modal.currentPassword} onChange={(e) => setModal({ ...modal, currentPassword: e.target.value })} onKeyDown={(e) => {
            if (e.key === "Enter") {
              authCurrentPassword(t);
            }
          }} />
          <CustomBackgroundColorButton text={t("settings.contents.resetPassword.buttons.confirm")} fontSize="0.8rem" onClick={() => authCurrentPassword(t)} />
        </div>

        {
          modal.isCorrectPassword &&
          <>
            <div style={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", gap: "10px" }}>
              <div style={{ width: "150px" }}>{t("settings.contents.resetPassword.newPassword")}</div>
              <TextField variant="outlined" size="small" type="password" value={modal.newPassword} onChange={(e) => setModal({ ...modal, newPassword: e.target.value })} />
              <S.CustomIconButton tabIndex={-1}>
                {isValidNewPassword ? (
                  <CheckCircleSharpIcon
                    sx={{ color: process.env.REACT_APP_MAIN_COLOR }}
                  />
                ) : (
                  <Tooltip title={t("settings.contents.resetPassword.newPasswordTooltip")}>
                    <WarningIcon sx={{ color: "red" }} />
                  </Tooltip>
                )}
              </S.CustomIconButton>
            </div>
            <div style={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", gap: "10px" }}>
              <div style={{ width: "150px" }}>{t("settings.contents.resetPassword.confirmPassword")}</div>
              <TextField variant="outlined" size="small" type="password" value={modal.newPasswordConfirm} onChange={(e) => setModal({ ...modal, newPasswordConfirm: e.target.value })} />
              <S.CustomIconButton tabIndex={-1}>
                {isValidNewPasswordConfirm ? (
                  <CheckCircleSharpIcon
                    sx={{ color: process.env.REACT_APP_MAIN_COLOR }}
                  />
                ) : (
                  <Tooltip title={t("settings.contents.resetPassword.confirmPasswordTooltip")}>
                    <WarningIcon sx={{ color: "red" }} />
                  </Tooltip>
                )}
              </S.CustomIconButton>
            </div>
          </>
        }
      </DialogContent>
      {
        modal.isCorrectPassword &&
        <DialogActions>
          <CustomBackgroundColorButton text={t("settings.contents.resetPassword.buttons.resetPassword")} fontSize="0.8rem" onClick={() => resetPassword(t)} />
        </DialogActions>
      }
    </Dialog>
  )
}

export default ResetPasswordModal;
