import styled from "styled-components";

export const FullContainer = styled.div`
    position: absolute;
    right: 0;
    top: 0;
    left: 0;
    display: flex;
    min-height: 100%;
    flex-direction: row;

    @media (max-width: 768px) {
        flex-direction: column;
        height: 100vh;
    }
`;

export const ContentContainer = styled.div<{ $isMobile: boolean, $sidebar: boolean }>`
    display: flex;
    min-height: ${({ $isMobile }) => ($isMobile ? "calc(100vh - 59px)" : "100vh")};
    max-height: ${({ $isMobile }) => ($isMobile ? "calc(100vh - 59px)" : "100vh")};
    width: ${({ $sidebar, $isMobile }) => ($sidebar && !$isMobile ? "calc(100% - 250px)" : $isMobile ? "100%" : "calc(100% - 56px)")};
    margin-left: 0px;
    overflow-x: inherit;
    overflow-y: visible;
`;
