import theme from "@/Styles/theme";
import { Button, DialogContent, FormControlLabel, Tab, Tabs } from "@mui/material";
import styled from "styled-components";

export const GroupItem = styled.div<{ $isSelected: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  min-height: 40px;
  background-color: ${({ $isSelected }) => ($isSelected ? theme.colors.primary : "white")};
  color: ${({ $isSelected }) => ($isSelected ? "white" : "black")};
  cursor: pointer;

  &:hover {
    background-color: ${({ $isSelected }) => ($isSelected ? theme.colors.primary : "#d9d9d9")};
  }
`;

export const RecipientItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  min-height: 40px;
  background-color: white;
  position: relative;

  &:hover {
    background-color: #d9d9d9;
  }
`;

export const StyledFormControlLabel = styled(FormControlLabel)`
  display: flex;
  flex-direction: row;
  gap: 5px;
  align-items: center;
  background-color: white;
  color: black;
  cursor: pointer;
  min-height: 40px;
  width: 100%;
  margin: 0 !important; /* FormControlLabel 자체의 margin 제거 */

  & .MuiFormControlLabel-label {
    margin: 0; /* 내부 label의 margin 제거 */
  }

  &:hover {
    background-color: #d9d9d9;  // hover 시 배경색 변경
  }
`;

export const CustomTabs = styled(Tabs)`
  position: relative;
  padding: 0 24px;
  & .MuiTabs-indicator {
    background-color: ${theme.colors.primary}; // 선택된 탭 하이라이트 색상
  }
`
export const RefreshContainer = styled.div`
  position: absolute;
  right: 0;
  top: 50%;
  display: flex;
  align-items: center;
  gap: 5px;
`;

export const CustomDialogContent = styled(DialogContent)`
  font-size: 0.9rem;
  font-weight: bold;
  height: 400px;
  display: flex;
  flex-direction: row;
  gap: 10px;
`;

export const LeftContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  flex: 6;
`;

export const RightContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  flex: 4;
`;

export const GroupContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  flex: 5;
  position: relative;
`;

export const GroupList = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  border: 1px solid #d9d9d9;
  overflow-y: auto;
`;

export const RecipientContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  flex: 1;
`;

export const AddRecipientButton = {
  backgroundColor: "#e9e9e9",
  color: "black",
  fontSize: "0.8rem",
  padding: "3px 5px",
  width: "90px",
  justifyContent: "space-between",
}

export const RecipientList = styled.div`
  display: flex;
  width: calc(100% - 100px);
  height: 100%;
  flex-direction: column;
  overflow-y: auto;
  border: 1px solid #d9d9d9;
`;
