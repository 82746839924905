import ganttChartAtom from '@/Atoms/Components/GanttChartAtom';
import innerTabDataAtom from '@/Atoms/Plan/InnerTabDataAtom';
import convertNodesToGanttTasks from '@/Utils/ConvertNodesToGanttTasks';
import GetIdFromQuerystring from '@/Utils/GetIdFromQuerystring';
import { editTaskDialogLoadDataAtom } from '@/ViewModels/Dialogs/Edit/TaskViewModel';
import { Node } from '@xyflow/react';
import dayjs from 'dayjs';
import { Task, ViewMode } from 'gantt-task-react';
import { atom } from 'jotai';
import { setInnerTabDataAtom, updatePlanDataMindmapAtom } from './InnerTabViewModel';
import { debouncedUpdateTaskAtom } from './Mindmap/FlowViewModel';
import { pushRoutineSidebarDataAtom } from './Routine/SidebarViewModel';

const getStartEndDateForProject = (tasks: Task[], projectId: string): [Date, Date] => {
  const projectTasks = tasks.filter(t => t.project === projectId);
  let start = projectTasks[0].start;
  let end = projectTasks[0].end;

  for (let i = 0; i < projectTasks.length; i++) {
    const task = projectTasks[i];
    if (start.getTime() > task.start.getTime()) {
      start = task.start;
    }
    if (end.getTime() < task.end.getTime()) {
      end = task.end;
    }
  }
  return [start, end];
}

// nodes를 통해 tasks로 변환
export const ganttChartSetDataAtom = atom(null, (get, set, nodes: Node[]) => {
  set(ganttChartAtom, (prev) => ({ ...prev, tasks: [] }));
  const tasks = convertNodesToGanttTasks(nodes, [], []);
  set(ganttChartAtom, (prev) => ({ ...prev, tasks }));
});

// 노드 업데이트 후 gantt chart 업데이트
export const ganttChartUpdateAtom = atom(null, (get, set) => {
  const innerTabs = get(innerTabDataAtom);
  const innerTabId = GetIdFromQuerystring("inner_tab_id");
  const innerTabData = innerTabs.find((data) => data.innerTabId === innerTabId);
  if (!innerTabData) return;
  const nodes = innerTabData.nodes.filter((node) => node.id.includes("task"));
  const tasks = convertNodesToGanttTasks(nodes, innerTabData.edges, []);
  set(ganttChartAtom, (prev) => ({ ...prev, tasks }));
});

// view mode 변경
export const ganttChartChangeViewModeAtom = atom(null, (get, set, mode: ViewMode) => {
  let columnWidth = 70;
  switch (mode) {
    case ViewMode.Day:
      break;
    case ViewMode.Week:
      columnWidth = 200;
      break;
    case ViewMode.Month:
      columnWidth = 300;
      break;
    default:
      break;
  }
  set(ganttChartAtom, (prev) => ({ ...prev, viewMode: mode, columnWidth }));
});

// expand list 변경
export const ganttChartChangeExpandListAtom = atom((get) => get(ganttChartAtom).expandList, (get, set, expandList: boolean) => {
  set(ganttChartAtom, (prev) => ({ ...prev, expandList }));
});

// task의 변경사항에 따른 nodes 업데이트
const updateGanttTask = atom(null, (get, set, task: Task) => {
  const planData = get(innerTabDataAtom);
  const innerTabId = GetIdFromQuerystring("inner_tab_id");
  const plan = planData.find((data) => data.innerTabId === innerTabId);
  if (!plan) return;
  const taskNode = plan.nodes.find((n) => n.id === task.id);
  if (!taskNode) return;
  let newNode: Node = { ...taskNode };
  if (task.name !== "") {
    newNode = {
      ...newNode,
      data: {
        ...newNode.data,
        label: task.name,
        startDate: dayjs(task.start),
        endDate: dayjs(task.end),
      },
    }
  }
  const newNodes = plan.nodes.map((n) => (n.id === task.id ? newNode : n));

  // update
  set(setInnerTabDataAtom, { innerTabId, nodes: newNodes, edges: plan.edges });
  debouncedUpdateTaskAtom(get, set, newNode);
});

// task의 위치 변경
export const ganttChartChangeTaskPositionAtom = atom(null, (get, set, task: Task) => {
  const planData = get(innerTabDataAtom);
  const innerTabId = GetIdFromQuerystring("inner_tab_id");
  const plan = planData.find((data) => data.innerTabId === innerTabId);
  if (!plan) return;
  const tasks = convertNodesToGanttTasks(plan.nodes, plan.edges, []);
  let newTasks = tasks.map(t => (t.id === task.id ? task : t));
  if (task.project) {
    const [start, end] = getStartEndDateForProject(newTasks, task.project);
    const project = newTasks[newTasks.findIndex(t => t.id === task.project)];
    if (
      project.start.getTime() !== start.getTime() ||
      project.end.getTime() !== end.getTime()
    ) {
      const changedProject = { ...project, start, end };
      newTasks = newTasks.map(t =>
        t.id === task.project ? changedProject : t
      );
    }
  }
  set(updateGanttTask, task);

});

// expander 클릭
export const ganttChartExpanderClickAtom = atom(null, (get, set, task: Task) => {
  // set(ganttChartAtom, (prev) => ({ ...prev, tasks: prev.tasks.map((t) => (t.id === task.id ? task : t)) }));
});

// 더블클릭 이벤트
export const ganttChartDoubleClickAtom = atom(null, (get, set, task: Task) => {
  const planData = get(innerTabDataAtom);
  const innerTabId = GetIdFromQuerystring("inner_tab_id");
  const plan = planData.find((data) => data.innerTabId === innerTabId);
  if (!plan) return;
  const taskNode = plan.nodes.find((n) => n.id === task.id);
  if (!taskNode) return;
  set(editTaskDialogLoadDataAtom, taskNode);
});

// task 삭제
export const ganttChartDeleteTaskAtom = atom(null, (get, set, task: Task) => {
  const planData = get(innerTabDataAtom);
  const innerTabId = GetIdFromQuerystring("inner_tab_id");
  const currentPlanData = planData.find((data) => data.innerTabId === innerTabId);
  if (!currentPlanData) return;
  const nodes = currentPlanData.nodes.filter((n) => n.id !== task.id);
  set(updatePlanDataMindmapAtom, innerTabId, nodes, currentPlanData.edges);
});

export const getGanttChartDataAtom = atom((get) => {
  const planData = get(innerTabDataAtom);
  const innerTabId = GetIdFromQuerystring("inner_tab_id");
  const currentPlanData = planData.find((data) => data.innerTabId === innerTabId);

  if (!currentPlanData) return [];

  const tasks = convertNodesToGanttTasks(currentPlanData.nodes, currentPlanData.edges, []);

  return tasks;
});
