import styled from 'styled-components';

export const TaskTitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
`;

export const TaskColor = styled.div<{ $color: string }>`
  width: 40px;
  height: 40px;
  background-color: ${(props) => props.$color};
  border-radius: 50%;
  cursor: pointer;
`;

export const EachRowValueContainer = styled.div`
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
`;

export const EachRowValue = styled.div<{ $backgroundColor: string; $color: string }>`
  padding: 4px 8px;
  border-radius: 10px;
  background-color: ${(props) => props.$backgroundColor};
  font-size: 14px;
  font-weight: bold;
  color: ${(props) => props.$color};
`;

export const DeleteButton = styled.div`
  display: flex;
  position: fixed;
  bottom: 20px;
  font-size: 14px;
  width: 100%;
  height: 30px;
  justify-content: flex-start;
  background-color: white;
  align-items: flex-end;

  &:hover {
    cursor: pointer;
    font-weight: bold;
  }
`
