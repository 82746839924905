import { Edge, Node } from "@xyflow/react";

const isTaskIncludedRoutine = (task: Node, nodes: Node[], edges: Edge[]): boolean => {
  const connectedEdges = edges.filter((edge) => edge.target === task.id);
  if (connectedEdges.length === 0) return false;
  const routines = nodes.filter((node) => node.type === "routine");
  const connectedRoutines = connectedEdges.map((edge) => routines.find((routine) => routine.id === edge.source));
  return connectedRoutines.length > 0;
}

export default isTaskIncludedRoutine;
