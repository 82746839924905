import React from 'react';
import * as S from './styled';
import useIsMobile from '../../../../Hooks/useIsMobile';
import Timelapse from './Timelapse';
import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import useStory from '../../../../ViewModels/StoryViewModel';
import Story from './Story';

const TimelapseView = () => {
  const { isMobile, isTablet } = useIsMobile();
  const {
    storyProps,
    setStoryProps,
    editingStory,
    setEditingStory,
    startEditingStory,
    tabValue,
    setTabValue,
    selectedSort,
    setSelectedSort,
  } = useStory();

  const getHeight = () => {
    if (isMobile) return isTablet ? "calc(100% - 112px)" : "calc(100% - 136px)";
    return "100%";
  }

  const whetherToIncludeContents = () => {
    if (isMobile || isTablet) {
      return <>
        <ToggleButtonGroup
          exclusive
          value={tabValue}
          color="primary"
          sx={{
            minHeight: "56px"
          }}
        >
          <ToggleButton value={0} selected={tabValue === 0} onClick={() => setTabValue(0)}>스토리</ToggleButton>
          <ToggleButton value={1} selected={tabValue === 1} onClick={() => setTabValue(1)}>타임랩스</ToggleButton>
        </ToggleButtonGroup>
        {tabValue === 0 ? <Story
          setTabValue={setTabValue}
          storyProps={storyProps}
          setStoryProps={setStoryProps}
          selectedSort={selectedSort}
          setSelectedSort={setSelectedSort}
          startEditingStory={startEditingStory}
          editingStory={editingStory}
          setEditingStory={setEditingStory}
          tabValue={tabValue}
        /> : <Timelapse />}
      </>;
    }
    return (
      <S.Container $height={getHeight()}>
        <Story
          setTabValue={setTabValue}
          storyProps={storyProps}
          setStoryProps={setStoryProps}
          selectedSort={selectedSort}
          setSelectedSort={setSelectedSort}
          startEditingStory={startEditingStory}
          editingStory={editingStory}
          setEditingStory={setEditingStory}
          tabValue={tabValue}
        />
        <Timelapse />
      </S.Container>
    );
  }

  return (
    whetherToIncludeContents()
  );
}

export default TimelapseView;
