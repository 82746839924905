import styled from 'styled-components';

export const AddTaskButton = styled.div`
  width: calc(100% - 3px);
  min-height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #999;
  font-size: 12px;
  cursor: pointer;
  border: 1px dashed #999;
  border-radius: 10px;
  font-weight: bold;

  &:hover {
    border: 1px dashed #000;
    color: #000;
  }
`;

export const TaskItem = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  gap: 20px;
  padding: 10px 20px;
  background-color: #fff;
  border: 3px solid #999;
  border-radius: 10px;

  &:hover {
    border: 3px solid #000;
  }
`;

export const TaskItemTitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
`;

export const TaskItemTitleItem = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
`;

export const TaskItemDetail = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  font-size: 12px;
  font-weight: bold;
  cursor: pointer;
  text-align: center;
  line-height: 1;
`;

export const TaskList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
  height: auto;
  overflow-y: scroll;
  ms-overflow-style: none;
  scrollbar-width: none;
`;

export const RoutineTitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  height: auto;
  font-size: 16px;
`;

export const RoutineColor = styled.div<{ $color: string }>`
  width: 40px;
  height: 40px;
  background-color: ${(props) => props.$color};
  border-radius: 50%;
  // cursor: pointer;
`;

export const DeleteButton = styled.div`
  display: flex;
  position: fixed;
  bottom: 20px;
  font-size: 14px;
  width: 100%;
  height: 30px;
  justify-content: flex-start;
  background-color: white;
  align-items: flex-end;

  &:hover {
    cursor: pointer;
    font-weight: bold;
  }
`
