import styled from 'styled-components';
import { styled as muiStyled } from '@mui/material/styles';
import { TextField, ToggleButton, ToggleButtonGroup } from '@mui/material';
import theme from '@/Styles/theme';
import { lighten } from 'polished';

export const CustomInput = styled.input`
  width: calc(100% - 32px);
  min-height: 30px;
  padding: 5px 15px;
  border: 1px solid #999;
  border-radius: 10px;
  font-size: 14px;

  &:focus {
    outline: none;
    border: 1px solid #000;
  }

  &::placeholder {
    color: #999;
  }

  &:hover {
    border: 1px solid #000;
  }
`;

export const CustomInputField = styled.textarea`
  width: calc(100% - 32px);
  min-height: 100px;
  max-height: 200px;
  padding: 5px 15px;
  border: 1px solid #999;
  border-radius: 10px;
  font-size: 14px;
  resize: none; /* 사용자가 크기를 조정하지 못하게 설정 */
  overflow-wrap: break-word; /* 긴 단어를 줄바꿈 */
  white-space: pre-wrap; /* 줄바꿈을 유지하고 공백을 표현 */

  &:focus {
    outline: none;
    border: 1px solid #000;
  }

  &::placeholder {
    color: #999;
  }

  &:hover {
    border: 1px solid #000;
  }
`;

export const StyledToggleButtonGroup = muiStyled(ToggleButtonGroup)(() => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}));

export const StyledToggleButton = muiStyled(ToggleButton)(() => ({
  fontSize: '12px', // 텍스트 크기
  fontWeight: 'bold', // 텍스트 굵기
  padding: '4px 8px', // 내부 여백 조정
  borderRadius: '8px', // 버튼 모서리 둥글게
  border: `1px solid #999`, // 테두리 색상
  color: "#999", // 텍스트 색상
  '&.Mui-selected': {
    backgroundColor: theme.colors.primary, // 선택된 버튼 배경색
    color: '#fff', // 선택된 버튼 텍스트 색상
    border: `1px solid ${theme.colors.primary}`, // 선택된 버튼 테두리 색상
  },
  '&:hover': {
    backgroundColor: lighten(0.1, theme.colors.primary), // 호버 상태 배경색
  },
}));

export const TagInputContainer = styled.div`
  display: flex;
  width: calc(100% - 32px); /* CustomInput과 동일한 너비 계산 */
  flex-wrap: wrap;
  gap: 0.5em;
  padding: 5px 15px; /* CustomInput과 동일한 패딩 */
  border: 1px solid #999;
  border-radius: 10px;
  font-size: 14px; /* CustomInput과 동일한 폰트 크기 */

  &:hover {
    border: 1px solid #000; /* Hover 시 테두리 색상 */
  }
`;

export const TagItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 3px 6px;
  font-size: 0.8em;
  border-radius: 10px;
  color: white;
  font-weight: bold;
`;

export const TagInput = styled.input`
  flex-grow: 1;
  min-width: 30px;
  max-width: 150px;
  padding: 5px 0; /* CustomInput의 내부 여백 반영 */
  border: none;
  outline: none;
  font-family: "Noto Sans KR", sans-serif;
  font-size: 14px; /* CustomInput과 동일한 폰트 크기 */

  &::placeholder {
    color: #999; /* Placeholder 색상 */
  }
`;

export const CustomAddTaskButton = styled.div<{ $isLoading: boolean }>`
  display: flex;
  position: fixed;
  bottom: 20px;
  justify-content: center;
  align-items: center;
  width: calc(100% - 40px);
  height: 40px;
  background-color: ${({ $isLoading }) => ($isLoading ? "#ccc" : theme.colors.primary)};
  color: #fff;
  font-weight: bold;
  cursor: pointer;
  user-select: none;
  transition: background-color 0.3s;

  &:hover {
    background-color: ${lighten(0.1, theme.colors.primary)};
  }
`;

export const CustomTextField = muiStyled(TextField)(() => ({
  "& .MuiInputLabel-root": {
    fontSize: "14px",
    fontWeight: "bold",
    color: "#333",
  },
  "& .MuiInputBase-root": {
    borderBottom: "2px solid #ccc",
    "&:hover": {
      borderBottom: `2px solid ${theme.colors.primary}`,
    },
  },
  "& .MuiInputBase-input": {
    fontSize: "16px",
    color: "#000",
    padding: "5px 0",
  },
  "& .MuiInput-underline:before": {
    borderBottom: "1px solid #ccc",
  },
  "& .MuiInput-underline:after": {
    borderBottom: `2px solid ${theme.colors.primary}`,
  },
}));
