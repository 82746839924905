import deletePlanDialogAtom from "@/Atoms/Dialogs/Delete/PlanAtom";
import CustomColorButton from "@/Components/Common/Buttons/CustomColor";
import theme from "@/Styles/theme";
import { deletePlanAtom } from "@/ViewModels/Plan/PlanViewModel";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from "@mui/material";
import { useAtom, useSetAtom } from "jotai";
import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const DeletePlanDialog = () => {
  const { t } = useTranslation();
  const [deletePlanDialog, setDeletePlanDialog] = useAtom(deletePlanDialogAtom);
  const deletePlan = useSetAtom(deletePlanAtom);
  const navigate = useNavigate();

  const handleConfirm = () => {
    setDeletePlanDialog({ open: false, complete: false });
    navigate("/");
  }

  const handleClose = () => {
    if (deletePlanDialog.complete) {
      setDeletePlanDialog({ open: false, complete: false });
      navigate("/");
    } else {
      setDeletePlanDialog({ open: false, complete: false });
    }
  }

  return (
    <Dialog open={deletePlanDialog.open} onClose={handleClose} fullWidth maxWidth="xs">
      <DialogTitle sx={{ fontSize: "1rem", fontWeight: "bold" }}>{t("plan.modals.deletePlan.title")}</DialogTitle>
      <DialogContent>
        {
          deletePlanDialog.complete ?
            <>
              <Typography sx={{ fontSize: "0.9rem" }}>{t("plan.modals.deletePlan.complete")}</Typography>
            </> :
            <>
              <Typography sx={{ fontSize: "0.9rem" }}>{t("plan.modals.deletePlan.content")}</Typography>
              <Typography sx={{ fontSize: "0.9rem" }}>{t("plan.modals.deletePlan.content2")}</Typography>
            </>
        }
      </DialogContent>
      <DialogActions>
        {
          deletePlanDialog.complete ?
            <CustomColorButton onClick={handleConfirm} text={t("plan.modals.deletePlan.buttons.confirm")} fontSize="0.8rem" /> :
            <>
              <CustomColorButton onClick={handleClose} text={t("plan.modals.deletePlan.buttons.cancel")} fontSize="0.8rem" color="black" />
              <CustomColorButton onClick={deletePlan} text={t("plan.modals.deletePlan.buttons.delete")} fontSize="0.8rem" />
            </>
        }
      </DialogActions>
    </Dialog>
  )
}

export default DeletePlanDialog;
