import { Crew } from "@/Atoms/CrewAtom";
import { devConsoleLog } from "@/Utils/ConsoleLogInDevelopment";
import GetIdFromQuerystring from "@/Utils/GetIdFromQuerystring";

export const parseCrew = (data: any): Crew => {
  devConsoleLog("parseCrew", data);
  return {
    crewInfo: {
      isPublic: data.crew_info.isPublic || false,
      crewId: data.crew_info.crew_id,
      name: data.crew_info.name,
      content: data.crew_info.content,
      headCount: data.crew_info.headcount,
      current: data.crew_info.current,
      hashtags: data.crew_info.hashtags,
      imageUrl: data.crew_info.image_url,
      color: data.crew_info.color,
      category: data.crew_info.category,
      innerTabId: data.crew_info.innerTabId,
    },
    members: data.crew_member_list,
  }
}

// 크루 조회 api
export const getCrew = async (access: string, crewId: number) => {
  const response = await fetch(`${process.env.REACT_APP_CREW_ADDRESS}/${crewId}/inner-tab`, {
    method: "GET",
    headers: {
      "accept": "application/json",
      "Authorization": `Bearer ${access}`,
    },
  });
  return response;
};

// 크루 생성 시 이름 중복 체크 api
export const checkCrewName = async (access: string, name: string) => {
  const response = await fetch(`${process.env.REACT_APP_CREW_ADDRESS}/check-name?crew_name=${name}`, {
    method: "GET",
    headers: {
      "accept": "application/json",
      "Authorization": `Bearer ${access}`,
    },
  });
  return response;
};

// 크루 생성 api
export const createCrew = async (access: string, userId: number, formData: FormData) => {
  const response = await fetch(`${process.env.REACT_APP_CREW_ADDRESS}/empty?user_id=${userId}`, {
    method: "POST",
    headers: {
      'accept': 'application/json',
      'Authorization': `Bearer ${access}`,
    },
    body: formData,
  });
  return response;
};

// 크루 업데이트 api
export const updateCrew = async (access: string, crewId: number, crew: any) => {
  const response = await fetch(`${process.env.REACT_APP_CREW_ADDRESS}/${crewId}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${access}`,
    },
    body: JSON.stringify(crew),
  });
  return response;
}

// 크루 입장 신청 api
export const applyCrew = async (access: string, crewId: number) => {
  const response = await fetch(`${process.env.REACT_APP_CREW_ADDRESS}/${crewId}/apply`, {
    method: "GET",
    headers: {
      "accept": "application/json",
      "Authorization": `Bearer ${access}`,
    },
  });
  return response.ok;
};

// 크루 탈퇴 api
export const leaveCrew = async (crewId: number, userId: number) => {
  // const response = await fetch(`${process.env.REACT_APP_API_URL}/crews/${crewId}/leave`, {
  //   method: "POST",
  //   headers: {
  //     "Content-Type": "application/json",
  //   },
  //   body: JSON.stringify({ userId }),
  // });
  // return response.ok;
};

// 크루 셀프 입장 api
export const selfJoinCrew = async (access: string, crewId: number, userId: number) => {
  const response = await fetch(`${process.env.REACT_APP_CREW_ADDRESS}/${crewId}/self-in?user_id=${userId}`, {
    method: "GET",
    headers: {
      "accept": "application/json",
      "Authorization": `Bearer ${access}`,
    },
  });
  return response;
}

// 크루 셀프 퇴장 api
export const selfLeaveCrew = async (access: string, crewId: number, userId: number) => {
  const response = await fetch(`${process.env.REACT_APP_CREW_ADDRESS}/${crewId}/self-out?user_id=${userId}`, {
    method: "DELETE",
    headers: {
      "accept": "application/json",
      "Authorization": `Bearer ${access}`,
    },
  });
  return response;
}

// 크루 멤버 조회 api
export const getCrewMembers = async (access: string, crewId: number) => {
  const response = await fetch(`${process.env.REACT_APP_CREW_ADDRESS}/${crewId}/members`, {
    method: "GET",
    headers: {
      "accept": "application/json",
      "Authorization": `Bearer ${access}`,
    },
  });
  return response;
};

// 크루 이미지 업데이트 api
export const updateCrewImage = async (access: string, crewId: number, formData: FormData) => {
  const response = await fetch(`${process.env.REACT_APP_CREW_ADDRESS}/${crewId}/image`, {
    method: "POST",
    headers: {
      "Authorization": `Bearer ${access}`,
    },
    body: formData,
  });
  return response;
}

// 크루 폐쇄 api
export const deleteCrew = async (access: string, crewId: number) => {
  const response = await fetch(`${process.env.REACT_APP_CREW_ADDRESS}/${crewId}`, {
    method: "DELETE",
    headers: {
      "accept": "application/json",
      "Authorization": `Bearer ${access}`,
    },
  });
  return response;
}
