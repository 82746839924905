import { addTasksInRoutineAtom, routineSidebarAtom } from '@/Atoms/Plan/RoutineAtom';
import { atom } from 'jotai';
import { relateTasks } from '@/Queries/PlanQueries';
import { error401ModalAtom } from '@/Atoms/Dialogs/Error/401Atom';
import { handleReactQueryApiResponse } from '@/Utils/APIUtil';
import snackbarAtom from '@/Atoms/Snackbar';
import { clearRoutineSidebarDataAtom, popAndChangeTasksInRoutineAtom } from './SidebarViewModel';
import { setInnerTabDataAtom } from '../InnerTabViewModel';
import GetIdFromQuerystring from '@/Utils/GetIdFromQuerystring';
import innerTabDataAtom from '@/Atoms/Plan/InnerTabDataAtom';
import { TFunction } from 'i18next';

export const addTasksToRoutineAtom = atom(null, async (get, set, t: TFunction) => {
  const planData = get(innerTabDataAtom);
  const innerTabId = GetIdFromQuerystring("inner_tab_id");
  const currentPlanData = planData.find((data) => data.innerTabId === innerTabId);
  if (!currentPlanData) return [];
  const addTasks = get(addTasksInRoutineAtom);
  const sidebar = get(routineSidebarAtom);
  if (sidebar.length === 0) return;
  const data = sidebar[sidebar.length - 1];
  if (data.type !== "addTaskToRoutine" && data.type !== "ganttAddTaskToRoutine") return;
  const { key } = data;
  if (!key) return;

  try {
    const response = await handleReactQueryApiResponse(relateTasks, () => set(error401ModalAtom, true), key.data.backendId as number, addTasks.map((task) => task.data.backendId));

    if (!response.ok) {
      set(snackbarAtom, { open: true, message: t("plan.contents.routine.snackbar.addTaskFail"), severity: "error" });
      return;
    }
    const responseJson = await response.json();
    const edges = responseJson.edges;
    const newEdges = [...currentPlanData.edges, ...edges];
    set(setInnerTabDataAtom, { innerTabId, nodes: currentPlanData.nodes, edges: newEdges });

    if (data.type === "ganttAddTaskToRoutine") {
      set(clearRoutineSidebarDataAtom);
    } else {
      const prevData = sidebar[sidebar.length - 2];
      const prevTasks = prevData.tasks;
      const newPrevTasks = [...prevTasks, ...addTasks];
      set(popAndChangeTasksInRoutineAtom, newPrevTasks);
    }
    set(snackbarAtom, { open: true, message: t("plan.contents.routine.snackbar.addTaskSuccess"), severity: "success" });
    set(addTasksInRoutineAtom, []);
  } catch (e) {
    set(snackbarAtom, { open: true, message: t("plan.contents.routine.snackbar.addTaskError"), severity: "error" });
  }
})
