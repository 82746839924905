import dayjs from 'dayjs';
import { atom } from 'jotai';

interface DateInfo {
  date: string; // yyyy-MM-dd
  hour: number;
  minute: number;
}

export interface ApplicationPeriodInfo {
  start: DateInfo;
  end: DateInfo;
  detail: {
    option1: boolean; // 대기 상태일 때 지도교사 비공개
    option2: boolean; // 방과후 담당자 외 강좌수정 금지
    option3: boolean; // 방과후 담당자 외 수강신청 금지
    option4: boolean; // 강좌마다 신청기간 설정 허용(강좌별로 따로 신청받을 경우에만 사용)
    option5: boolean; // 강좌 운영시간(강좌 개설 시 체크한 시간) 중복 시 수강신청 금지
  }
}

export type ApplicationMethod = 0 | 1 | 2 | 3 | 4 | 5; // 신청방법 (0: -제한 조건 선택-, 1: 과목군별 택1 이내, 2: 과목군별 택2 이내, 3: 과목군별 택3 이내, 4: 일괄신청 금지, 5: 개별신청 금지)

export interface CreateCourseDialog {
  open: boolean;
  teacher: string; // 담당교사
  applicationPeriod: ApplicationPeriodInfo; // 신청기간
  applicationMethod: ApplicationMethod; // 신청방법
  coursePeriod: { // 수강기간
    startDate: string;  // yyyy-MM-dd
    endDate: string;  // yyyy-MM-dd
  };
  courseName: string; // 과정 이름
  viewOption: { // 보기 옵션
    isVisible: boolean; // 과정 보기 여부
    grade: 0 | 1 | 2 | 3 // 특정 등급 이상에게만 보이기 (0: 재학생, 1: 외부강사, 2: 선생님, 3: 방과후담당)
  };
  operatingTimes: { [day: string]: number[] }; // 운영시간
  isCostDisclosured: boolean; // 비용 공개 여부
  selectionGroup: string; // 선택군
  announcement: string; // 공지사항
  subsidyContent: string; // 지원금(옵션)
  courseEvaluation: string; // 강의평가
}

export const createCourseDialogInitializeState = (open: boolean): CreateCourseDialog => ({
  open: open,
  teacher: '',
  applicationPeriod: {
    start: {
      date: dayjs().format('YYYY-MM-DD'),
      hour: 0,
      minute: 0,
    },
    end: {
      date: dayjs().format('YYYY-MM-DD'),
      hour: 0,
      minute: 0,
    },
    detail: {
      option1: false,
      option2: false,
      option3: false,
      option4: false,
      option5: false,
    }
  },
  coursePeriod: {
    startDate: dayjs().format('YYYY-MM-DD'),
    endDate: dayjs().format('YYYY-MM-DD'),
  },
  courseName: '',
  viewOption: {
    isVisible: false,
    grade: 0,
  },
  operatingTimes: {
    '월': [],
    '화': [],
    '수': [],
    '목': [],
    '금': [],
    '토': [],
    '일': [],
  },
  isCostDisclosured: true,
  selectionGroup: '',
  applicationMethod: 0,
  announcement: '',
  subsidyContent: '',
  courseEvaluation: '',
});

const createCourseDialogAtom = atom<CreateCourseDialog>(createCourseDialogInitializeState(false));

export default createCourseDialogAtom;
