import { createSignatureModeAtom, getSignatureAtom } from '@/ViewModels/Signature/ViewModel';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import React from 'react';
import * as S from './styled';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import SignatureCard from '../Card';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { DialogContent } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { signatureCurrentIndexAtom } from '@/Atoms/Signature/Atom';
import useIsMobile from '@/Hooks/useIsMobile';

const SignatureList = () => {
  const { isMobile } = useIsMobile();
  const { t } = useTranslation();
  const [currentIndex, setCurrentIndex] = useAtom(signatureCurrentIndexAtom);
  const signatures = useAtomValue(getSignatureAtom);
  const startCreate = useSetAtom(createSignatureModeAtom);
  const handleLeftArrowClick = () => (currentIndex !== -1) && setCurrentIndex(currentIndex - 1);
  const handleRightArrowClick = () => (currentIndex !== signatures.length - 1) && setCurrentIndex(currentIndex + 1);

  React.useEffect(() => {
    if (signatures.length !== 0) {
      setCurrentIndex(0);
    }
  }, [signatures, setCurrentIndex]);

  return (
    <DialogContent sx={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", height: "550px", position: "relative", gap: isMobile ? "100px" : "0px" }}>
      <div style={{ display: "flex", position: "absolute", flexDirection: "column", gap: "4px", width: "auto", justifyContent: "center", alignItems: "center", top: "10px", left: "24px" }}>
        <div style={{ fontSize: "0.8rem", fontWeight: "bold", display: "flex", width: "100%", justifyContent: "flex-start" }}>
          {t("signature.list.description")}
        </div>
        {
          <div style={{ fontSize: "0.8rem", fontWeight: "bold", display: "flex", width: "100%", justifyContent: "flex-start" }}>
            {currentIndex !== -1 && signatures[currentIndex].careerAdvancement ? t("signature.list.description2") : " "}
          </div>
        }
      </div>
      {
        currentIndex === -1 ?
          <S.CreateSignatureContainer onClick={startCreate}>
            <AddCircleOutlineIcon sx={{ width: "100px", height: "100px", color: "#c0c0c0", "&:hover": { color: "black" } }} />
            <S.CreateText>{t("signature.list.addSignature")}</S.CreateText>
          </S.CreateSignatureContainer> :
          <SignatureCard key={signatures[currentIndex].id} signature={signatures[currentIndex]} />
      }
      <KeyboardArrowLeftIcon sx={S.ArrowIconStyle("left", currentIndex, signatures.length, isMobile)} onClick={handleLeftArrowClick} />
      <KeyboardArrowRightIcon sx={S.ArrowIconStyle("right", currentIndex, signatures.length, isMobile)} onClick={handleRightArrowClick} />
      <S.CircleContainer>
        {
          signatures[currentIndex] && signatures[currentIndex].careerAdvancement &&
          <div style={{ position: "absolute", top: isMobile ? "-20px" : "-30px", fontSize: isMobile ? "0.6rem" : "1rem", color: "black" }}>
            {t("signature.list.careerDescription")}
          </div>
        }
        <AddCircleOutlineIcon sx={{ width: "30px", height: "30px", color: currentIndex === -1 ? "black" : "#c0c0c0", cursor: "pointer" }} onClick={() => setCurrentIndex(-1)} />
        {
          Array(signatures.length).fill(0).map((_, index) => (
            <S.EachCircle key={`circle-${index}`} $index={index} $selected={currentIndex === index} onClick={() => setCurrentIndex(index)} />
          ))
        }
      </S.CircleContainer>
    </DialogContent>
  )
}

export default SignatureList;
