import afterSchoolAtom, { Class } from '@/Atoms/AfterSchool';
import afterSchoolCourseAtom from '@/Atoms/AfterSchoolCourse';
import createClassDialogAtom, { createClassDialogInitializeState } from '@/Atoms/Dialogs/Create/Class';
import snackbarAtom from '@/Atoms/Snackbar';
import { atom } from 'jotai';
import { v4 as uuidv4 } from 'uuid';

export const createClassDialogOpenAtom = atom(null, (get, set) => {
  set(createClassDialogAtom, createClassDialogInitializeState(true));
})

export const createClassDialogCloseAtom = atom(null, (get, set) => {
  set(createClassDialogAtom, createClassDialogInitializeState(false));
});

// save dialog
export const createClassDialogSaveAtom = atom(null, (get, set) => {
  const dialog = get(createClassDialogAtom);
  // Validation
  if (!dialog.teacher) {
    set(snackbarAtom, { open: true, message: '담당교사를 입력해주세요.', severity: 'error' });
    return;
  }

  if (!dialog.name) {
    set(snackbarAtom, { open: true, message: '강좌 이름을 입력해주세요.', severity: 'error' });
    return;
  }

  if (dialog.operatingTimes) {
    const emptyDays = Object.entries(dialog.operatingTimes).filter(
      ([, times]) => times.length !== 0
    );

    if (emptyDays.length === 0) {
      set(snackbarAtom, { open: true, message: '운영시간을 설정해주세요.', severity: 'error' });
      return;
    }
  } else {
    // If operatingTimes is missing or empty, show a different message
    set(snackbarAtom, { open: true, message: '운영시간을 설정해주세요.', severity: 'error' });
    return;
  }

  const newClass: Class = {
    id: uuidv4(),
    teacher: dialog.teacher,
    grade: dialog.grade,
    headcount: dialog.headcount,
    period: dialog.period,
    operatingTimes: dialog.operatingTimes,
    classRoom: dialog.classRoom,
    name: dialog.name,
    textbook: dialog.textbook,
    students: [],
  }

  // Save the class
  const afterSchoolCourse = get(afterSchoolCourseAtom);
  if (!afterSchoolCourse) {
    set(snackbarAtom, { open: true, message: '과정이 없습니다.', severity: 'error' });
  } else {
    const newAfterSchoolCourse = {
      ...afterSchoolCourse,
      classes: [...afterSchoolCourse.classes, newClass],
    };
    set(afterSchoolCourseAtom, newAfterSchoolCourse);
    set(afterSchoolAtom, (prev) => ({
      courses: prev.courses.map((course) => course.id === newAfterSchoolCourse.id ? newAfterSchoolCourse : course)
    }));
  }
  set(snackbarAtom, { open: true, message: '강좌가 개설되었습니다.', severity: 'success' });

  // Close the dialog
  set(createClassDialogAtom, (prev) => createClassDialogInitializeState(false));
});

// edit teacher
export const createClassDialogTeacherAtom = atom((get) => get(createClassDialogAtom).teacher, (get, set, teacher: string) => {
  set(createClassDialogAtom, (prev) => ({
    ...prev,
    teacher,
  }));
});

// edit headcount
export const createClassDialogHeadcountAtom = atom((get) => get(createClassDialogAtom).headcount, (get, set, headcount: number) => {
  set(createClassDialogAtom, (prev) => ({
    ...prev,
    headcount,
  }));
});

// edit period
export const createClassDialogPeriodAtom = atom((get) => get(createClassDialogAtom).period, (get, set, period: number) => {
  set(createClassDialogAtom, (prev) => ({
    ...prev,
    period,
  }));
});

// edit grade
export const createClassDialogGradeAtom = atom((get) => get(createClassDialogAtom).grade, (get, set, grade: 0 | 1 | 2 | 3) => {
  set(createClassDialogAtom, (prev) => ({
    ...prev,
    grade,
  }));
});

// edit operating times
export const createClassDialogOperatingTimesAtom = atom(
  (get) => get(createClassDialogAtom).operatingTimes,
  (get, set, day: string, time: number) => {
    set(createClassDialogAtom, (prev) => ({
      ...prev,
      operatingTimes: {
        ...prev.operatingTimes,
        [day]: prev.operatingTimes[day]
          ? prev.operatingTimes[day].includes(time)
            ? prev.operatingTimes[day].filter((t) => t !== time) // Remove the time if it exists
            : [...prev.operatingTimes[day], time] // Add the time if it doesn't exist
          : [time], // Initialize the day with the new time if it doesn't exist
      },
    }));
  }
);

// edit operating times (specific day)
export const createClassDialogOperatingTimesDayAtom = atom(
  null,
  (get, set, day: string, times: number[]) => {
    set(createClassDialogAtom, (prev) => ({
      ...prev,
      operatingTimes: {
        ...prev.operatingTimes,
        [day]: times || [], // If day doesn’t exist, initialize with empty array
      },
    }));
  }
);

// edit operating times (specific time)
export const createClassDialogOperatingTimesTimeAtom = atom(null, (get, set, days: string[], time: number) => {
  set(createClassDialogAtom, (prev) => {
    const updatedOperatingTimes = { ...prev.operatingTimes };

    days.forEach((day) => {
      if (updatedOperatingTimes[day]) {
        updatedOperatingTimes[day] = updatedOperatingTimes[day].includes(time)
          ? updatedOperatingTimes[day].filter((t) => t !== time)
          : [...updatedOperatingTimes[day], time];
      } else {
        updatedOperatingTimes[day] = [time];
      }
    });

    return {
      ...prev,
      operatingTimes: updatedOperatingTimes,
    };
  });
});

// edit class room
export const createClassDialogClassRoomAtom = atom((get) => get(createClassDialogAtom).classRoom, (get, set, classRoom: string) => {
  set(createClassDialogAtom, (prev) => ({
    ...prev,
    classRoom,
  }));
});

// edit name
export const createClassDialogNameAtom = atom((get) => get(createClassDialogAtom).name, (get, set, name: string) => {
  set(createClassDialogAtom, (prev) => ({
    ...prev,
    name,
  }));
});

// edit textbook
export const createClassDialogTextbookAtom = atom((get) => get(createClassDialogAtom).textbook, (get, set, textbook: string) => {
  set(createClassDialogAtom, (prev) => ({
    ...prev,
    textbook,
  }));
});
