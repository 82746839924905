import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  width: calc(100% - 40px);
  min-height: 56px;
  padding: 0 20px;
  gap: 20px;
  justify-content: space-between;
  border-bottom: 1px solid ${({ theme }) => theme.colors.darkBackground};
  align-items: center;
`;

export const TabItem = styled.div<{ $selected: boolean, $isMobile: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${({ $isMobile }) => ($isMobile ? "5px" : "10px")};
  cursor: pointer;
  font-size: 0.9rem;
  font-weight: bold;
  border-radius: 5px;
  color: ${({ theme, $selected }) => ($selected ? theme.colors.primary : "#999")};
  transition: color 0.2s;
  &:hover {
    color: ${({ theme }) => theme.colors.primary};
  }
`;
