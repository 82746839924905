import { DialogTitle } from "@mui/material";
import styled from "styled-components";
import { styled as muiStyled } from "@mui/system";

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 70vh;

  @media screen and (max-width: 768px) {
    height: 100%;
    flex-direction: column;
    overflow-y: auto;
  }
`;

export const ContentMindmap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  height: calc(100% - 90px);
  width: calc(100% - 40px);
  border-bottom: 1px solid #e0e0e0;

  @media screen and (max-width: 768px) {
    width: calc(100% - 16px);
    min-height: 400px;
    padding: 8px;
  }
`;

export const ContentInfo = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
  height: auto;
  width: calc(100% - 40px);
  gap: 20px;

  @media screen and (max-width: 768px) {
    width: calc(100% - 16px);
    gap: 8px;
    padding: 8px;
  }
`;

export const CustomModalTitle = muiStyled(DialogTitle)`
  font-size: 0.9rem;
  font-weight: bold;
  border-bottom: 1px solid #e0e0e0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  @media screen and (max-width: 768px) {
    padding: 8px;
  }
`
