export const getLatestVersion = async (access: string) => {
  const response = await fetch(
    `${process.env.REACT_APP_VERSION_ADDRESS}`,
    {
      method: "GET",
      headers: {
        "accept": "application/json",
        'Authorization': `Bearer ${access}`,
      }
    }
  );
  return response;
}
