import applyLectureDialogAtom from '@/Atoms/Dialogs/ApplyLecture';
import CustomBackgroundColorButton from '@/Components/Common/Buttons/CustomBackgroundColor';
import CustomColorButton from '@/Components/Common/Buttons/CustomColor';
import { applyLectureDialogApplyAtom } from '@/ViewModels/Dialogs/ApplyLectureViewModel';
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { useAtom, useSetAtom } from 'jotai';
import React from 'react';
import { useTranslation } from 'react-i18next';

const ApplyLectureDialog = () => {
  const { t } = useTranslation();
  const [dialog, setDialog] = useAtom(applyLectureDialogAtom);
  const apply = useSetAtom(applyLectureDialogApplyAtom);

  return (
    dialog &&
    <Dialog open={dialog !== null} onClose={() => setDialog(null)}>
      <DialogTitle sx={{ fontSize: "0.9rem", fontWeight: "bold" }}>{t("dialogs.lecture.apply.title")}</DialogTitle>
      <DialogContent sx={{ fontSize: "0.8rem" }}>
        <div>{t("dialogs.lecture.apply.content")}</div>
        {dialog.isAbleToCancel ? <div>
          {t("dialogs.lecture.apply.content2")}
        </div> : <div>
          {t("dialogs.lecture.apply.content3")}
        </div>}
      </DialogContent>
      <DialogActions>
        <CustomColorButton onClick={() => setDialog(null)} text={t("dialogs.lecture.apply.cancel")} color="gray" fontSize="0.8rem" />
        <CustomBackgroundColorButton onClick={() => apply(t)} text={t("dialogs.lecture.apply.apply")} fontSize="0.8rem" />
      </DialogActions>
    </Dialog>
  )
}

export default ApplyLectureDialog;
