import React from "react";
import { Box, Divider, Stack } from "@mui/material";
import * as S from "../styled";
import { useAtomValue } from "jotai";
import { useNavigate } from "react-router-dom";
import planHipAtom from "@/Atoms/PlanHip/Atom";
import ieumLogo from "@/Assets/Images/ieum_logo.png";
import { useTranslation } from "react-i18next";

const colors = ["#ff0400", "#ff7f00", "#ffbf00", "#bfff00", "#00ff80"];

export function PopularPlan() {
  const navigate = useNavigate();
  const planHip = useAtomValue(planHipAtom);
  const crews = [...planHip.myCrews, ...planHip.notMyCrews];
  const { t } = useTranslation();

  return (
    <S.CustomBox>
      <S.TitleTypo>{t("dashboard.mentor.planHip.title")}</S.TitleTypo>
      <Divider sx={{ margin: "10px 0" }} />
      <S.DescTypo>{t("dashboard.mentor.planHip.description")}</S.DescTypo>
      <Stack direction={"row"} sx={{ flexGrow: 1, display: "grid", gridTemplateColumns: "repeat(4, 1fr)", gap: "10px" }}>
        {crews.map((data, index) => (
          <Stack
            key={`stack-${index}`}
            sx={{
              alignItems: "center",
              justifyContent: "center",
              gap: "5px",
            }}
            direction={"column"}
          >
            <Box
              component="img"
              src={data.imageUrl ? `${process.env.REACT_APP_CAREER_PLANNER_PLAN_MEDIA_ADDRESS}${data.imageUrl.split("/media")[1]}` : ieumLogo}
              alt="Profile Image"
              sx={{
                width: 50,
                height: 50,
                borderRadius: "50%",
                objectFit: "cover",
                cursor: "pointer",
              }}
              onClick={() => navigate(`/crew?crew_id=${data.crewId}&inner_tab_id=${data.innerTabId}`)}
            />
            <S.UnivTypo style={{ cursor: "pointer", fontSize: "0.9rem" }} onClick={() => navigate(`/crew?crew_id=${data.crewId}&inner_tab_id=${data.innerTabId}`)} >{data.name}</S.UnivTypo>
            {/* <S.DepartmentTypo style={{ fontSize: "0.8rem" }}>{crewOwners[index].name}</S.DepartmentTypo> */}
            <S.LevelBox color={colors[index]}>{index + 1}위</S.LevelBox>
          </Stack>
        ))}
      </Stack>
    </S.CustomBox>
  );
}
