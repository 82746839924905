import createRoutineDialogAtom, { CreateRoutineDialogOpenAtomProps } from '@/Atoms/Dialogs/Create/Routine';
import { error401ModalAtom } from '@/Atoms/Dialogs/Error/401Atom';
import planAtom from '@/Atoms/Plan';
import innerTabDataAtom from '@/Atoms/Plan/InnerTabDataAtom';
import snackbarAtom from '@/Atoms/Snackbar';
import { createRoutine } from '@/Queries/RoutineQueries';
import { handleReactQueryApiResponse } from '@/Utils/APIUtil';
import GetIdFromQuerystring from '@/Utils/GetIdFromQuerystring';
import { getPlansAtom } from '@/ViewModels/Plan/PlanViewModel';
import { getUserId } from '@/ViewModels/UserViewModel';
import { Node } from '@xyflow/react';
import { TFunction } from 'i18next';
import { atom } from 'jotai';

const initialSetting: CreateRoutineDialogOpenAtomProps = {
  dialogTitle: "",
  currentSelectedTask: null,
  selectedRoutines: [],
  selectedUnconnectedTasks: [],
  isLoading: false,
};

// 루틴 생성 dialog 열기
export const createRoutineDialogOpenAtom = atom(null, (get, set) => {
  set(createRoutineDialogAtom, initialSetting);
});

// 루틴 생성에 사용할 데이터 세팅
export const createRoutineDialogGetTasksAtom = atom((get) => {
  const plans = get(planAtom);
  const planId = GetIdFromQuerystring("id");
  const plan = plans.find((plan) => plan.id === planId);
  const modal = get(createRoutineDialogAtom);
  if (!plan || !modal) return {
    routines: [],
    unconnectedTasks: [],
  }
  const filteredPlanInnerTabs = plan.innerTabs.filter((innerTab) => innerTab.viewType !== "feed");
  const firstInnerTab = filteredPlanInnerTabs[0];
  const planData = get(innerTabDataAtom);
  const innerTabData = planData.find((data) => data.innerTabId === firstInnerTab.id);
  if (!innerTabData) return {
    routines: [],
    unconnectedTasks: [],
  }
  const routines = innerTabData.nodes.filter((node) => node.type === "routine").filter((node) => modal.selectedRoutines.every((selectedRoutine) => selectedRoutine.key.id !== node.id));
  const tasks = innerTabData.nodes.filter((node) => node.type === "task").filter((node) => modal.selectedUnconnectedTasks.every((selectedTask) => selectedTask.id !== node.id)).filter((node) => modal.selectedRoutines.every((selectedRoutine) => selectedRoutine.tasks.every((task) => task.id !== node.id)));
  const edges = innerTabData.edges;

  const routinesWithTasks = routines.map((routine) => {
    const routineTasks = edges
      .filter((edge) => edge.source === routine.id) // 루틴의 연결된 Edge를 필터링
      .map((edge) => tasks.find((task) => task.id === edge.target)) // 연결된 Edge에 따른 Task 가져오기
      .filter((task): task is Node => task !== undefined); // 유효한 Task만 필터링
    return {
      id: routine.id,
      label: routine.data.label as string,
      type: "routine" as "routine" | "task",
      tasks: routineTasks,
    };
  });

  // 루틴에 속하지 않은 Task 추출
  const routineTaskIds = routinesWithTasks.flatMap((routine) => routine.tasks.map((task) => task.id));
  const unconnectedTasks = tasks.filter((task) => !routineTaskIds.includes(task.id)).map((task) => ({ id: task.id, label: task.data.label as string, type: "task" as "routine" | "task" }));

  return {
    routines: routinesWithTasks,
    unconnectedTasks: unconnectedTasks,
  }
});

// routineDialogState 업데이트
export const createRoutineDialogUpdateAtom = atom(null, (get, set, key: string, value: any) => {
  const routineDialogState = get(createRoutineDialogAtom);
  if (!routineDialogState) return;
  set(createRoutineDialogAtom, { ...routineDialogState, [key]: value });
});

// routineDialog 할 일 추가
export const createRoutineDialogAddTaskAtom = atom(null, (get, set, id: string, type: "routine" | "task") => {
  const routineDialogState = get(createRoutineDialogAtom);
  if (!routineDialogState) return;
  const plans = get(planAtom);
  const planId = GetIdFromQuerystring("id");
  const plan = plans.find((plan) => plan.id === planId);
  if (!plan) return;
  const filteredPlanInnerTabs = plan.innerTabs.filter((innerTab) => innerTab.viewType !== "feed");
  const firstInnerTab = filteredPlanInnerTabs[0];
  const planData = get(innerTabDataAtom);
  const innerTabData = planData.find((data) => data.innerTabId === firstInnerTab.id);
  if (!innerTabData) return;
  const nodes = innerTabData.nodes;
  const edges = innerTabData.edges;
  if (type === "routine") {
    const routine = nodes.find((node) => node.id === id);
    if (!routine) return;
    const routineTasks = edges
      .filter((edge) => edge.source === routine.id) // 루틴의 연결된 Edge를 필터링
      .map((edge) => nodes.find((node) => node.id === edge.target)) // 연결된 Edge에 따른 Task 가져오기
      .filter((task): task is Node => task !== undefined); // 유효한 Task만 필터링
    const connectedEdges = edges.filter((edge) => edge.source === routine.id);
    set(createRoutineDialogAtom, {
      ...routineDialogState,
      selectedRoutines: [...routineDialogState.selectedRoutines, { key: routine, tasks: routineTasks, connectedEdges }],
    });
  } else {
    const task = nodes.find((node) => node.id === id);
    if (!task) return;
    set(createRoutineDialogAtom, {
      ...routineDialogState,
      selectedUnconnectedTasks: [...routineDialogState.selectedUnconnectedTasks, task],
    });
  }
})

// routineDialog 할 일 삭제
export const createRoutineDialogRemoveTaskAtom = atom(null, (get, set, id: string, type: "routine" | "task") => {
  const routineDialogState = get(createRoutineDialogAtom);
  if (!routineDialogState) return;
  if (type === "routine") {
    set(createRoutineDialogAtom, {
      ...routineDialogState,
      selectedRoutines: routineDialogState.selectedRoutines.filter((routine) => routine.key.id !== id),
    });
  } else {
    set(createRoutineDialogAtom, {
      ...routineDialogState,
      selectedUnconnectedTasks: routineDialogState.selectedUnconnectedTasks.filter((task) => task.id !== id),
    });
  }
});

// 루틴 생성 요청
export const createRoutineDialogCreateRoutineAtom = atom(null, async (get, set, t: TFunction) => {
  const planId = GetIdFromQuerystring("id");
  const routineDialogState = get(createRoutineDialogAtom);
  if (!routineDialogState) return;
  if (!routineDialogState.dialogTitle) {
    set(snackbarAtom, (prev) => ({ ...prev, open: true, message: t("plan.modals.createRoutine.snackbar.inputName"), severity: "error" }));
    return;
  }
  set(snackbarAtom, { open: true, message: t("plan.modals.createRoutine.snackbar.loading"), severity: "error" })
  set(createRoutineDialogAtom, { ...routineDialogState, isLoading: true });
  const selectedRoutines = routineDialogState.selectedRoutines.map((routine) => {
    return [routine.key, ...routine.tasks];
  }).flat();
  const selectedEdges = routineDialogState.selectedRoutines.map((routine) => routine.connectedEdges).flat();
  const selectedTasks = [...selectedRoutines, ...routineDialogState.selectedUnconnectedTasks];
  const request = {
    plan_id: planId,
    name: routineDialogState.dialogTitle,
    view_type: "routine",
    content: "",
    tasks: selectedTasks,
    edges: selectedEdges.map((edge) => ({
      ...edge,
      data: { backendId: 0 }, // backendId를 0으로 설정해야 서버에서 새로운 Edge로 인식
    })),
  }
  const handleError401Modal = () => set(error401ModalAtom, true);
  const response = await handleReactQueryApiResponse(createRoutine, handleError401Modal, request);
  if (!response.ok) {
    set(snackbarAtom, (prev) => ({ ...prev, open: true, message: t("plan.modals.createRoutine.snackbar.failCreate"), severity: "error" }));
    set(createRoutineDialogAtom, { ...routineDialogState, isLoading: false });
    return;
  }
  set(snackbarAtom, (prev) => ({ ...prev, open: true, message: t("plan.modals.createRoutine.snackbar.completeCreate"), severity: "success" }));
  set(getPlansAtom);
  set(createRoutineDialogAtom, null);
});
