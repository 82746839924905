import React from 'react';
import { Dialog, DialogTitle, DialogActions, DialogContent, ListSubheaderProps, ListSubheader, Select, MenuItem, Chip } from '@mui/material';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import createRoutineDialogAtom from '@/Atoms/Dialogs/Create/Routine';
import { createRoutineDialogAddTaskAtom, createRoutineDialogCreateRoutineAtom, createRoutineDialogGetTasksAtom, createRoutineDialogRemoveTaskAtom, createRoutineDialogUpdateAtom } from '@/ViewModels/Dialogs/Create/RoutineViewModel';
import theme from '@/Styles/theme';
import CustomColorButton from '@/Components/Common/Buttons/CustomColor';
import CustomBackgroundColorButton from '@/Components/Common/Buttons/CustomBackgroundColor';
import { useTranslation } from 'react-i18next';
import { Node } from '@xyflow/react';
import * as S from './styled';
import TitleValueInEachRow from '../../Contents/Routine/Sidebar/Common/TitleValueInEachRow';
import { getTextColorBasedOnBackground } from '@/Utils/GetRandomColor';
import { lighten } from 'polished';

interface TaskOption {
  id: string;
  label: string;
  type: "routine" | "task";
  tasks?: Node[]; // 루틴일 경우 하위 작업 리스트
}

interface Option {
  routines: TaskOption[];
  unconnectedTasks: TaskOption[];
}

const MyListSubheader = (
  props: ListSubheaderProps & { muiSkipListHighlight: boolean },
) => {
  const { muiSkipListHighlight, ...other } = props;
  return <ListSubheader {...other} />;
}

const CreateRoutineDialog = () => {
  const { t } = useTranslation();
  const [dialog, setDialog] = useAtom(createRoutineDialogAtom);
  const updateState = useSetAtom(createRoutineDialogUpdateAtom);
  const addTask = useSetAtom(createRoutineDialogAddTaskAtom);
  const removeTask = useSetAtom(createRoutineDialogRemoveTaskAtom);
  const createRoutine = useSetAtom(createRoutineDialogCreateRoutineAtom);
  const taskOptions: Option = useAtomValue(createRoutineDialogGetTasksAtom);

  return (
    dialog &&
    <Dialog
      open={dialog !== null}
      onClose={() => !dialog.isLoading && setDialog(null)}
      maxWidth="xs"
      fullWidth
    >
      <DialogTitle sx={{ fontSize: "0.9rem", fontWeight: "bold" }}>{t("plan.modals.createRoutine.title")}</DialogTitle>
      <DialogContent sx={{ display: "flex", flexDirection: "column", gap: "20px" }}>
        <S.CustomInput value={dialog.dialogTitle} onChange={(e) => updateState("dialogTitle", e.target.value)} placeholder={t("plan.modals.createRoutine.name")} />
        {/* <TitleValueInEachRow title={t("plan.modals.createRoutine.add")} value={
          <Select
            variant="standard"
            fullWidth
          >
            <MyListSubheader muiSkipListHighlight>{t("plan.modals.createRoutine.option.routine")}</MyListSubheader>
            {taskOptions.routines.map((routine) => (
              <MenuItem key={routine.id} value={routine.id} onClick={() => addTask(routine.id, 'routine')} >
                {routine.label}
              </MenuItem>
            ))}
            <MyListSubheader muiSkipListHighlight>{t("plan.modals.createRoutine.option.unconnected")}</MyListSubheader>
            {taskOptions.unconnectedTasks.map((task) => (
              <MenuItem key={task.id} value={task.id} onClick={() => addTask(task.id, 'task')} >
                {task.label}
              </MenuItem>
            ))}
          </Select>
        } /> */}
        {
          dialog.selectedRoutines.length > 0 &&
          <TitleValueInEachRow title={t("plan.modals.createRoutine.routineList")} value={
            <div style={{ display: "flex", flexDirection: "row", gap: "10px", flexWrap: "wrap" }}>
              {
                dialog.selectedRoutines.map((task) => {
                  const textColor = getTextColorBasedOnBackground(task.key.data.color as string || theme.colors.primary);
                  return <Chip
                    key={task.key.id}
                    label={task.key.data.label as string}
                    onDelete={() => removeTask(task.key.id, "routine")}
                    sx={{
                      backgroundColor: task.key.data.color as string || theme.colors.primary, // 배경색
                      color: textColor, // 텍스트 색상
                      fontWeight: "bold", // 텍스트 굵기
                      "& .MuiChip-deleteIcon": {
                        color: textColor, // 삭제 아이콘 색상
                        "&:hover": {
                          color: lighten(0.2, task.key.data.color as string || theme.colors.primary), // 삭제 아이콘 hover 색상
                        },
                      },
                    }}
                  />
                })
              }
            </div>
          } />
        }
        {
          dialog.selectedUnconnectedTasks.length > 0 &&
          <TitleValueInEachRow title={t("plan.modals.createRoutine.taskList")} value={
            <div style={{ display: "flex", flexDirection: "row", gap: "10px", flexWrap: "wrap" }}>
              {
                dialog.selectedUnconnectedTasks.map((task) => {
                  const textColor = getTextColorBasedOnBackground(task.data.color as string || theme.colors.primary);
                  return <Chip
                    key={task.id}
                    label={task.data.label as string}
                    onDelete={() => removeTask(task.id, "task")}
                    sx={{
                      backgroundColor: task.data.color as string || theme.colors.primary, // 배경색
                      color: textColor, // 텍스트 색상
                      fontWeight: "bold", // 텍스트 굵기
                      "& .MuiChip-deleteIcon": {
                        color: textColor, // 삭제 아이콘 색상
                        "&:hover": {
                          color: lighten(0.2, task.data.color as string || theme.colors.primary), // 삭제 아이콘 hover 색상
                        },
                      },
                    }}
                  />
                })
              }
            </div>
          } />
        }
      </DialogContent>
      <DialogActions>
        <CustomColorButton onClick={() => setDialog(null)} text={t("plan.modals.createRoutine.cancel")} fontSize="0.8rem" />
        <CustomBackgroundColorButton onClick={() => createRoutine(t)} text={t("plan.modals.createRoutine.create")} fontSize="0.8rem" disabled={dialog.isLoading} />
      </DialogActions>
    </Dialog>
  );
};

export default CreateRoutineDialog;
