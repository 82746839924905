import { lighten } from "polished";
import styled from "styled-components";

export const Container = styled.div`
  flex: 1;
  min-height: 55px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
`;

export const GroupContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
  overflow: hidden;
`;

export const GroupItem = styled.div<{ $backgroundColor: string }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 120px;
  height: 25px;
  padding: 5px 10px;
  border: 2px solid ${({ $backgroundColor }) => $backgroundColor};
  background-color: ${({ $backgroundColor }) => $backgroundColor};
  border-radius: 10px;
  color: white;
  font-weight: bold;
  font-size: 12px;
  cursor: pointer;

  &:hover {
    background-color: ${({ $backgroundColor }) => lighten(0.1, $backgroundColor)};
  }
`;
