import React from 'react';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import * as S from './styled';
import { addSignatureInfoCreateSignatureAtom, createSignatureCreateSignatureAtom, editCareerAdvancementCreateSignatureAtom, editImageCreateSignatureAtom, editNameCreateSignatureAtom, editNicknameCreateSignatureAtom, editPreviewImageCreateSignatureAtom, editRepresentativeCreateSignatureAtom, editSignatureColorCreateSignatureAtom, editSignatureInfoLabelCreateSignatureAtom, editSignatureInfoStringCreateSignatureAtom, editTagCreateSignatureAtom, removeSignatureInfoCreateSignatureAtom, saveSignatureInfoOrderListCreateSignatureAtom, setDefaultImageCreateSignatureAtom, toggleVisibleSignatureInfoCreateSignatureAtom } from '@/ViewModels/Signature/CreateSignatureViewModel';
import { createSignatureAtom, createSignatureMobileModeAtom, signatureAtom, SignatureInfo } from '@/Atoms/Signature/Atom';
import { Button, Checkbox, Dialog, DialogActions, DialogContent, Divider, ToggleButtonGroup, Tooltip } from '@mui/material';
import { lighten } from 'polished';
import theme from '@/Styles/theme';
import { PhotoCamera } from '@mui/icons-material';
import { CSS } from '@dnd-kit/utilities';
import MenuIcon from '@mui/icons-material/Menu';
import { useTranslation } from 'react-i18next';
import { getUserImage } from '@/ViewModels/UserViewModel';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import useIsMobile from '@/Hooks/useIsMobile';
import CustomColorButton from '@/Components/Common/Buttons/CustomColor';
import SignatureCardPreview from '../CardPreview';
import CustomBackgroundColorButton from '@/Components/Common/Buttons/CustomBackgroundColor';
import { changeModeSignatureDialogAtom } from '@/ViewModels/Signature/ViewModel';
import { arrayMove, SortableContext, useSortable, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { closestCenter, DndContext } from '@dnd-kit/core';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import RemoveIcon from '@mui/icons-material/Remove';
import { SketchPicker } from 'react-color';

export const ImageUploader = () => {
  const { isMobile } = useIsMobile();
  const { t } = useTranslation();
  const userProfile = useAtomValue(getUserImage);
  const createSignature = useAtomValue(createSignatureAtom);
  const setImage = useSetAtom(editImageCreateSignatureAtom);
  const setDefaultImage = useSetAtom(setDefaultImageCreateSignatureAtom);
  const setPreviewImage = useSetAtom(editPreviewImageCreateSignatureAtom);
  const [imagePath, setImagePath] = React.useState<string | null>(null);

  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file && file.type.startsWith("image/")) {
      // URL.createObjectURL을 사용하여 로컬 URL 생성
      const imageUrl = URL.createObjectURL(file);
      setPreviewImage(imageUrl); // 미리보기 이미지 URL 설정
      setImagePath(imageUrl);
      setImage(file); // File 객체를 상태로 설정
    }
  };

  const handleDefaultImage = () => {
    if (createSignature) {
      const path = createSignature.isUserProfile ? userProfile :
        process.env.REACT_APP_CAREER_PLANNER_USER_MEDIA_ADDRESS + createSignature.previewImage || userProfile;
      setImagePath(path);
    }
    setDefaultImage();
  }

  React.useEffect(() => {
    if (createSignature && imagePath === null) {
      const path = createSignature.isUserProfile ? userProfile :
        process.env.REACT_APP_CAREER_PLANNER_USER_MEDIA_ADDRESS + createSignature.previewImage || userProfile;
      setImagePath(path);
    }
  }, [createSignature, imagePath, userProfile]);

  return (
    createSignature &&
    <S.ContentRow>
      {
        !isMobile &&
        <S.ContentTitle style={{ width: "110px" }}>{t("signature.create.image")}</S.ContentTitle>
      }
      <S.ContentRow>
        <img
          src={imagePath || userProfile}
          alt="프로필 이미지"
          style={{ width: "100px", height: "100px", margin: 0, borderRadius: "50%" }}
        />
        <div style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
          <input
            accept="image/*"
            id="image-upload"
            type="file"
            style={{ display: 'none' }} // input을 숨김
            onChange={handleImageChange} // 파일 선택 시 updateState 함수 호출
          />
          <label htmlFor="image-upload">
            <Button
              variant="contained"
              component="span" // span을 사용하여 클릭 이벤트만 처리
              startIcon={<PhotoCamera sx={{ width: "16px", height: "16px" }} />} // 원하는 아이콘 추가
              sx={{
                padding: "6px 12px", // 원하는 스타일 추가
                fontSize: "0.7rem",
                backgroundColor: theme.colors.primary, // 원하는 스타일 추가
                color: '#fff',
                '&:hover': {
                  backgroundColor: lighten(0.05, theme.colors.primary), // hover시 원하는 스타일 추가
                },
              }}
            >
              {t("signature.create.uploadImage")}
            </Button>
          </label>
          {
            createSignature.isUploadImage &&
            <Button
              variant="contained"
              onClick={handleDefaultImage} // 기본 이미지로 변경하는 함수 호출
              sx={{
                padding: "6px 12px",
                fontSize: "0.7rem",
                backgroundColor: theme.colors.primary,
                color: '#fff',
                '&:hover': {
                  backgroundColor: lighten(0.05, theme.colors.primary),
                },
              }}
            >
              {t("signature.create.defaultImage")}
            </Button>
          }
        </div>
      </S.ContentRow>
    </S.ContentRow>
  );
};

interface DraggableItemProps {
  info: SignatureInfo;
}

export const DraggableItem = ({ info }: DraggableItemProps) => {
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id: info.key });
  const toggle = useSetAtom(toggleVisibleSignatureInfoCreateSignatureAtom);
  const { t } = useTranslation();

  const style = {
    transform: transform ? CSS.Transform.toString(transform) : undefined,
    transition,
    width: 'calc(100% - 23px)',
    height: '30px',
    display: 'flex',
    flexDirection: 'row' as const,
    justifyContent: 'space-between',
    fontSize: "0.8rem",
    fontWeight: "600",
    alignItems: 'center',
    border: '1px solid #c0c0c0',
    borderRadius: '10px',
    padding: '5px 10px',
    marginBottom: '5px',
    color: info.visible ? 'black' : '#c0c0c0',
  };

  return (
    <div ref={setNodeRef} style={style}>
      <div>{info.label}</div>
      <div style={{ display: "flex", flexDirection: "row", gap: "10px" }}>
        {
          info.visible ?
            <Tooltip title={t("signature.create.tooltips.hide")} placement="left" arrow>
              <VisibilityOutlinedIcon sx={{ cursor: "pointer" }} onClick={() => toggle(info.key)} />
            </Tooltip> :
            <Tooltip title={t("signature.create.tooltips.show")} placement="left" arrow>
              <VisibilityOffOutlinedIcon sx={{ cursor: "pointer" }} onClick={() => toggle(info.key)} />
            </Tooltip>
        }
        <Tooltip title={t("signature.create.tooltips.changeOrder")} placement="right" arrow>
          <MenuIcon {...attributes} {...listeners} sx={{ cursor: "grab" }} />
        </Tooltip>
      </div>
    </div>
  );
};

export const DesktopContents = () => {
  const { t } = useTranslation();
  const createSignature = useAtomValue(createSignatureAtom);
  const editName = useSetAtom(editNameCreateSignatureAtom);
  const editNickname = useSetAtom(editNicknameCreateSignatureAtom);
  const editInfoString = useSetAtom(editSignatureInfoStringCreateSignatureAtom);
  const editInfoLabel = useSetAtom(editSignatureInfoLabelCreateSignatureAtom);
  const editTag = useSetAtom(editTagCreateSignatureAtom);
  const addAdditionalInfo = useSetAtom(addSignatureInfoCreateSignatureAtom);
  const deleteInfo = useSetAtom(removeSignatureInfoCreateSignatureAtom);
  const [items, setItems] = useAtom(saveSignatureInfoOrderListCreateSignatureAtom);
  const editRepresentative = useSetAtom(editRepresentativeCreateSignatureAtom);
  const addSignature = useSetAtom(createSignatureCreateSignatureAtom);
  const numOfSignatures = useAtomValue(signatureAtom).length;
  const mode = useAtomValue(changeModeSignatureDialogAtom);

  // handleDragEnd 함수의 타입 정의
  const handleDragEnd = (event: { active: any; over: any }, items: SignatureInfo[], setItems: Function) => {
    const { active, over } = event;
    if (active.id !== over.id) {
      const oldIndex = items.findIndex((item) => item.key === active.id);
      const newIndex = items.findIndex((item) => item.key === over.id);
      const newItems = arrayMove(items, oldIndex, newIndex);
      setItems(newItems);
    }
  };

  return (
    createSignature &&
    <>
      <DialogContent sx={{ display: "flex", flexDirection: "row", alignItems: "center", height: "500px", position: "relative", gap: "15px", overflow: "hidden" }}>
        <div style={{ width: "70%", height: "100%", display: "flex", flexDirection: "column", gap: "10px", overflowY: "auto" }}>
          <S.ContentRow>
            <S.ContentTitle style={{ width: "110px" }} >{t("signature.create.cardName")}</S.ContentTitle>
            <input type="text" style={S.ContentInputStyle(350)} value={createSignature.name} onChange={(e) => editName(e.target.value)} />
          </S.ContentRow>
          <S.ContentRow>
            <S.ContentTitle style={{ width: "110px" }}>{t("signature.create.nickname")}</S.ContentTitle>
            <input type="text" style={S.ContentInputStyle(350)} value={createSignature.nickname} onChange={(e) => editNickname(e.target.value)} />
          </S.ContentRow>
          <ImageUploader />
          <S.ContentRow>
            <S.ContentTitle style={{ width: "110px" }}>{t("signature.create.tag")}</S.ContentTitle>
            <input type="text" style={S.ContentInputStyle(350)} value={createSignature.tag} onChange={(e) => editTag(e.target.value)} />
          </S.ContentRow>
          <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", width: "100%", fontSize: "0.8rem", fontWeight: "bold", alignItems: "center" }}>
            {t("signature.create.wantToAdd")}
            <CustomBackgroundColorButton text={t("signature.create.type.addItem")} fontSize="0.8rem" onClick={addAdditionalInfo} />
          </div>
          {
            // (createSignature.careerAdvancement ? createSignature.careerInfo : createSignature.customInfo)
            createSignature.info
              .map((info) => {
                return (
                  <S.ContentRow key={info.key}>
                    <input type="text" style={S.ContentInputStyle(100)} value={info.label} onChange={(e) => editInfoLabel(info.key, e.target.value)} />
                    <input type="text" style={S.ContentInputStyle(350)} value={info.value} onChange={(e) => editInfoString(info.key, e.target.value)} />
                    <RemoveIcon sx={{ cursor: "pointer" }} onClick={() => deleteInfo(info.key)} />
                  </S.ContentRow>
                )
              })
          }
        </div>
        <Divider orientation="vertical" flexItem />
        <div style={{ width: "30%", height: "100%", display: "flex", flexDirection: "column", gap: "5px", alignItems: "center", overflow: "hidden" }}>
          <S.ContentTitle style={{ width: "100%" }}>{t("signature.create.sort")}</S.ContentTitle>
          <div style={{ width: "100%", fontSize: "0.8rem" }}>{t("signature.create.sortDescription")}</div>
          <div style={{ width: "100%", height: "70%", display: "flex", flexDirection: "column", gap: "3px", overflowY: "auto", overflowX: "hidden" }}>
            <Divider style={{ width: "100%" }} />
            <DndContext collisionDetection={closestCenter} onDragEnd={(event) => handleDragEnd(event, items, setItems)} modifiers={[restrictToVerticalAxis]}>
              <SortableContext items={Array.isArray(items) ? items.map((item) => item.key) : []} strategy={verticalListSortingStrategy}>
                {Array.isArray(items) && items.length > 0 && items.map((info) => (
                  <DraggableItem key={info.key} info={info} />
                ))}
              </SortableContext>
            </DndContext>
            <Divider style={{ width: "100%" }} />
          </div>
          <div style={{ width: "100%", height: "25%", display: "flex", flexDirection: "column", gap: "5px", alignItems: "center" }}>
            <SignatureCardPreview signature={createSignature} />
          </div>
        </div>
      </DialogContent>
      <DialogActions sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
        <div style={{ display: "flex", flexDirection: "row", gap: "10px", alignItems: "center" }}>
          <Checkbox color="primary" checked={createSignature.representative} onChange={(e) => editRepresentative(e.target.checked)} disabled={mode === "edit" && numOfSignatures === 1} />
          <span style={{ fontSize: "0.8rem", cursor: (mode === "edit" && numOfSignatures === 1) ? "default" : "pointer" }} onClick={() => (mode === "create" || numOfSignatures !== 1) && editRepresentative(!createSignature.representative)}>{t("signature.create.representative")}</span>
        </div>
        <CustomBackgroundColorButton text={t("signature.create.save")} fontSize="0.8rem" onClick={addSignature} />
      </DialogActions>
    </>
  );
}

export const MobileEditContents = () => {
  const { t } = useTranslation();
  const createSignature = useAtomValue(createSignatureAtom);
  const editName = useSetAtom(editNameCreateSignatureAtom);
  const editNickname = useSetAtom(editNicknameCreateSignatureAtom);
  const editInfoString = useSetAtom(editSignatureInfoStringCreateSignatureAtom);
  const editInfoLabel = useSetAtom(editSignatureInfoLabelCreateSignatureAtom);
  const editTag = useSetAtom(editTagCreateSignatureAtom);
  const addAdditionalInfo = useSetAtom(addSignatureInfoCreateSignatureAtom);
  const deleteInfo = useSetAtom(removeSignatureInfoCreateSignatureAtom);
  const editRepresentative = useSetAtom(editRepresentativeCreateSignatureAtom);
  const numOfSignatures = useAtomValue(signatureAtom).length;
  const mode = useAtomValue(changeModeSignatureDialogAtom);
  const setMobileMode = useSetAtom(createSignatureMobileModeAtom);

  return (
    createSignature &&
    <>
      <DialogContent sx={{ display: "flex", flexDirection: "column", alignItems: "center", flex: 1, position: "relative", gap: "5px", overflow: "hidden", overflowY: "auto", padding: "10px" }}>
        <S.MobileTextFieldStyle
          label={t("signature.create.cardName")}
          value={createSignature.name}
          onChange={(e) => editName(e.target.value)}
          style={{ width: "100%", marginTop: "10px" }}
          helperText={t("signature.create.mobile.inputName")}
        />
        <S.MobileTextFieldStyle
          label={t("signature.create.nickname")}
          value={createSignature.nickname}
          onChange={(e) => editNickname(e.target.value)}
          style={{ width: "100%", marginTop: "10px" }}
          helperText={t("signature.create.mobile.inputNickname")}
        />
        <div style={{ width: "100%", fontSize: "0.9rem", fontWeight: "bold" }}>{t("signature.create.image")}</div>
        <ImageUploader />
        <S.MobileTextFieldStyle
          label={t("signature.create.tag")}
          value={createSignature.tag}
          onChange={(e) => editTag(e.target.value)}
          style={{ width: "100%", marginTop: "10px" }}
          helperText={t("signature.create.mobile.inputTag")}
        />
        {
          !createSignature.careerAdvancement &&
          <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", width: "100%", fontSize: "0.8rem", fontWeight: "bold", alignItems: "center" }}>
            {t("signature.create.wantToAdd")}
            <CustomBackgroundColorButton text={t("signature.create.type.addItem")} fontSize="0.8rem" onClick={addAdditionalInfo} />
          </div>
        }
        <div style={{ display: "flex", flexDirection: "column", gap: "5px", width: "calc(100% - 10px)", padding: "5px", border: "1px solid #c0c0c0", borderRadius: "5px", minHeight: "200px", height: "300px", overflowY: "auto" }}>
          {
            // (createSignature.careerAdvancement ? createSignature.careerInfo : createSignature.customInfo)
            createSignature.info
              .map((info, index) => {
                return (
                  <React.Fragment key={index}>
                    <S.ContentRow key={info.key}>
                      <S.MobileContentInputTitleStyle type="text" value={info.label} onChange={(e) => editInfoLabel(info.key, e.target.value)} />
                      <S.MobileContentInputValueStyle type="text" value={info.value} onChange={(e) => editInfoString(info.key, e.target.value)} />
                      <RemoveIcon sx={{ cursor: "pointer" }} onClick={() => deleteInfo(info.key)} />

                    </S.ContentRow>
                    {index !== (createSignature.info).length - 1 && <Divider />}
                  </React.Fragment>
                )
              })
          }
        </div>
      </DialogContent>
      <DialogActions sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between", padding: "10px" }}>
        <div style={{ display: "flex", flexDirection: "row", gap: "5px", alignItems: "center" }}>
          <Checkbox color="primary" checked={createSignature.representative} onChange={(e) => editRepresentative(e.target.checked)} disabled={mode === "edit" && numOfSignatures === 1} />
          <span style={{ fontSize: "0.8rem", cursor: (mode === "edit" && numOfSignatures === 1) ? "default" : "pointer" }} onClick={() => (mode === "create" || numOfSignatures !== 1) && editRepresentative(!createSignature.representative)}>{t("signature.create.representative")}</span>
        </div>
        <CustomBackgroundColorButton text={t("signature.create.next")} fontSize="0.8rem" onClick={() => setMobileMode("style")} />
      </DialogActions>
    </>
  )
}

export const MobileStyleContents = () => {
  const { t } = useTranslation();
  const createSignature = useAtomValue(createSignatureAtom);
  const editColor = useSetAtom(editSignatureColorCreateSignatureAtom);
  const [items, setItems] = useAtom(saveSignatureInfoOrderListCreateSignatureAtom);
  const editRepresentative = useSetAtom(editRepresentativeCreateSignatureAtom);
  const addSignature = useSetAtom(createSignatureCreateSignatureAtom);
  const numOfSignatures = useAtomValue(signatureAtom).length;
  const mode = useAtomValue(changeModeSignatureDialogAtom);
  const [colorPickerDialog, setColorPickerDialog] = React.useState<string | null>(null);

  // handleDragEnd 함수의 타입 정의
  const handleDragEnd = (event: { active: any; over: any }, items: SignatureInfo[], setItems: Function) => {
    const { active, over } = event;
    if (active.id !== over.id) {
      const oldIndex = items.findIndex((item) => item.key === active.id);
      const newIndex = items.findIndex((item) => item.key === over.id);
      const newItems = arrayMove(items, oldIndex, newIndex);
      setItems(newItems);
    }
  };

  return (
    createSignature &&
    <>
      <DialogContent sx={{ display: "flex", flexDirection: "column", alignItems: "center", flex: 1, position: "relative", gap: "5px", overflow: "hidden", overflowY: "auto", padding: "10px" }}>
        <S.ContentTitle style={{ width: "100%" }}>{t("signature.create.sort")}</S.ContentTitle>
        <div style={{ width: "100%", fontSize: "0.8rem" }}>{t("signature.create.sortDescription")}</div>
        <div style={{ width: "100%", height: "70%", display: "flex", flexDirection: "column", gap: "3px", overflowY: "auto", overflowX: "hidden" }}>
          <Divider style={{ width: "100%" }} />
          <DndContext collisionDetection={closestCenter} onDragEnd={(event) => handleDragEnd(event, items, setItems)} modifiers={[restrictToVerticalAxis]}>
            <SortableContext items={Array.isArray(items) ? items.map((item) => item.key) : []} strategy={verticalListSortingStrategy}>
              {Array.isArray(items) && items.length > 0 && items.map((info) => (
                <DraggableItem key={info.key} info={info} />
              ))}
            </SortableContext>
          </DndContext>
          <Divider style={{ width: "100%" }} />
        </div>
        <div style={{ width: "100%", display: "flex", flexDirection: "column", gap: "5px", alignItems: "center" }}>
          <SignatureCardPreview signature={createSignature} />
          <div style={{ display: "flex", flexDirection: "row", gap: "10px", alignItems: "center" }}>
            <CustomBackgroundColorButton fontSize="0.6rem" text={t("signature.style.changeBackgroundColor")} onClick={() => setColorPickerDialog("backgroundColor")} />
            <CustomBackgroundColorButton fontSize="0.6rem" text={t("signature.style.changeFontColor")} onClick={() => setColorPickerDialog("fontColor")} />
            <CustomBackgroundColorButton fontSize="0.6rem" text={t("signature.style.changeBorderColor")} onClick={() => setColorPickerDialog("borderColor")} />
          </div>
        </div>
      </DialogContent>
      <DialogActions sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between", padding: "10px" }}>
        <div style={{ display: "flex", flexDirection: "row", gap: "5px", alignItems: "center" }}>
          <Checkbox color="primary" checked={createSignature.representative} onChange={(e) => editRepresentative(e.target.checked)} disabled={mode === "edit" && numOfSignatures === 1} />
          <span style={{ fontSize: "0.8rem", cursor: (mode === "edit" && numOfSignatures === 1) ? "default" : "pointer" }} onClick={() => (mode === "create" || numOfSignatures !== 1) && editRepresentative(!createSignature.representative)}>{t("signature.create.representative")}</span>
        </div>
        <CustomBackgroundColorButton text={t("signature.create.save")} fontSize="0.8rem" onClick={addSignature} />
      </DialogActions>
      {
        colorPickerDialog &&
        <Dialog
          open={colorPickerDialog !== null}
          onClose={() => setColorPickerDialog(null)}
          maxWidth="xs"
        >
          {
            colorPickerDialog !== null &&
            <SketchPicker
              color={colorPickerDialog === "backgroundColor" ? createSignature.backgroundColor : colorPickerDialog === "fontColor" ? createSignature.fontColor : createSignature.borderColor}
              onChange={(color) => editColor(color.hex, colorPickerDialog as "backgroundColor" | "fontColor" | "borderColor")}
            />
          }
        </Dialog>
      }
    </>
  )
}
