import { atom } from 'jotai';

interface CancleLectureDialog {
  lectureId: number;
  userId: number;
}

const cancelLectureDialogAtom = atom<CancleLectureDialog | null>(null);

export default cancelLectureDialogAtom;
