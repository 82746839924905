import {
  AppBar,
  Box,
  Divider,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import CloseIcon from "@mui/icons-material/Close";
import { useAtomValue, useSetAtom } from "jotai";
import { fetchMoreChattingListInCurrentChatRoomAtom, sendMessageAtom } from "@/ViewModels/ChatViewModel";
import { chatDialogAtom, ChatRoomProps, currentChatRoomAtom, isChatbotTypingAtom } from "@/Atoms/ChatAtom";
import theme from "@/Styles/theme";
import CustomBackgroundColorButton from "@/Components/Common/Buttons/CustomBackgroundColor";
import RenderMessage from "../Messages/TypingMessage";

interface ChattingRoomProps {
  isChatListOpen: boolean;
  setIsChatListOpen: React.Dispatch<React.SetStateAction<boolean>>;
  chatRoomList: ChatRoomProps[];
}

const ChattingRoom = ({
  isChatListOpen,
  setIsChatListOpen,
  chatRoomList,
}: ChattingRoomProps) => {
  const setChatDialogOpen = useSetAtom(chatDialogAtom);
  const currentChatRoom = useAtomValue(currentChatRoomAtom);
  const [newMessage, setNewMessage] = React.useState<string>(""); // 새 메시지 입력 값
  const chatBoxRef = React.useRef<HTMLDivElement>(null);
  const [currentChatRoomId, setCurrentChatRoomId] = React.useState<number | undefined>(undefined);
  const sendMessage = useSetAtom(sendMessageAtom);
  const [isSending, setIsSending] = React.useState(false); // 메시지 전송 중 플래그 (중단점 이슈)
  const isTyping = useAtomValue(isChatbotTypingAtom);
  const fetchMoreChattingList = useSetAtom(fetchMoreChattingListInCurrentChatRoomAtom);

  React.useEffect(() => {
    if (currentChatRoom) {
      setCurrentChatRoomId(
        chatRoomList.findIndex((chatRoom) => chatRoom.id === currentChatRoom.id)
      );
    }
  }, [currentChatRoom, chatRoomList]);

  React.useEffect(() => {
    // 새로운 메시지가 추가되거나 처음 채팅창을 열 때 스크롤을 맨 아래로 설정
    const scrollToBottom = () => {
      if (chatBoxRef.current) {
        chatBoxRef.current.scrollTop = chatBoxRef.current.scrollHeight;
      }
    };

    // DOM 업데이트가 완료된 이후에 스크롤 설정
    const observer = new MutationObserver(() => {
      scrollToBottom();
    });

    if (chatBoxRef.current) {
      observer.observe(chatBoxRef.current, {
        childList: true, // 자식 요소 변화 감지
        subtree: true, // 모든 하위 요소 감지
      });
    }

    // 클린업: MutationObserver 해제
    return () => {
      observer.disconnect();
    };
  }, [currentChatRoom?.messages]);

  const handleSidebarToggle = () => {
    setIsChatListOpen(!isChatListOpen); // 사이드바 열고/닫기
  };

  const handleSendMessage = () => {
    if (!isTyping && newMessage && !isSending) {
      setIsSending(true); // 전송 시작 시 플래그 설정
      sendMessage(newMessage); // 첫 번째 메시지 전송
      setNewMessage(""); // 입력값 초기화

      // 일정 시간 동안 추가 메시지 차단 (예: 300ms)
      setTimeout(() => {
        setIsSending(false); // 일정 시간 후 다시 전송 가능하도록 플래그 해제
        setNewMessage(""); // 입력값 초기화
      }, 50);
    }
  };

  const handleScroll = () => {
    if (chatBoxRef.current) {
      const container = chatBoxRef.current;

      // 스크롤이 맨 위에 도달했을 때
      if (container.scrollTop === 0) {
        const previousScrollHeight = container.scrollHeight; // 대화 가져오기 전의 전체 높이

        // 대화를 더 가져오고
        fetchMoreChattingList().then(() => {
          setTimeout(() => {
            const newScrollHeight = container.scrollHeight; // 새로운 전체 높이
            const scrollDifference = newScrollHeight - previousScrollHeight;

            // 대화가 추가된 만큼 스크롤 위치를 조정하여 이전 위치 유지
            container.scrollTop = scrollDifference;
          }, 0);
        });
      }
    }
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      width={`${isChatListOpen ? "calc(100% - 300px)" : "100%"}`}
    >
      {/* 채팅창 헤더 */}
      <AppBar
        position="static"
        sx={{
          backgroundColor: `${theme.colors.primary}`,
          height: "60px",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          width: "100%",
          padding: "0 20px",
          justifyContent: "space-between",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            width: "90%",
            alignItems: "center",
          }}
        >
          <IconButton
            edge="start"
            color="inherit"
            aria-label="toggle sidebar"
            onClick={handleSidebarToggle}
          >
            {isChatListOpen ? <ChevronLeftIcon /> : <ChevronRightIcon />}
          </IconButton>
          <Typography
            variant="h6"
            sx={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              maxWidth: "80%",
            }}
          >
            {chatRoomList[currentChatRoomId || 0]?.title}
          </Typography>
        </div>
        <CloseIcon
          sx={{ cursor: "pointer", color: "white" }}
          onClick={() => setChatDialogOpen(false)}
        />
      </AppBar>

      {/* 채팅 메시지 표시 */}
      <Box
        flexGrow={1}
        p={2}
        sx={{
          flexGrow: 1,
          height: "calc(100% - 140px)",
          overflowY: "auto", // 채팅창에 스크롤 적용
          gap: "10px",
          display: "flex",
          flexDirection: "column",
        }}
        ref={chatBoxRef}
        onScroll={handleScroll}
      >
        {currentChatRoom?.messages.map((message, index) =>
          <React.Fragment key={index}>
            <RenderMessage message={message} currentChatRoomId={currentChatRoomId} chatRoomList={chatRoomList} />
          </React.Fragment>
        )}
      </Box>

      {/* 메시지 입력 */}
      <Divider sx={{ backgroundColor: `${theme.colors.darkBackground}` }} />
      <Box display="flex" p="20px">
        <TextField
          fullWidth
          variant="standard"
          value={newMessage}
          onChange={(e) => setNewMessage(e.target.value)}
          onKeyDown={(e) => e.key === "Enter" && handleSendMessage()}
          placeholder={
            isTyping
              ? "Chatbot이 입력 중입니다. 잠시만 기다려주세요..."
              : "메시지를 입력하세요..."
          }
          disabled={isTyping} // `chatbot`이 작성 중이면 입력 비활성화
        />
        <CustomBackgroundColorButton onClick={handleSendMessage} text="전송" disabled={isTyping} />
      </Box>
    </Box>
  );
};

export default ChattingRoom;
