import { createTaskInRoutineModalAtom, createTaskInRoutineModalEditRoutineAtom, initCreateTaskInRoutineModalData } from "@/Atoms/Dialogs/Create/TaskInRoutine";
import { error401ModalAtom } from "@/Atoms/Dialogs/Error/401Atom";
import innerTabDataAtom from "@/Atoms/Plan/InnerTabDataAtom";
import snackbarAtom from "@/Atoms/Snackbar";
import { createTaskInRoutine, CreateTaskInRoutineContent } from "@/Queries/RoutineQueries";
import { handleReactQueryApiResponse } from "@/Utils/APIUtil";
import GetIdFromQuerystring from "@/Utils/GetIdFromQuerystring";
import { setInnerTabDataAtom, updatePlanDataMindmapAtom } from "@/ViewModels/Plan/InnerTabViewModel";
import { atom } from "jotai";
import { textEditorCloseAtom } from "../TextEditorViewModel";

// 상태 초기화
export const createTaskInRoutineModalClearStateAtom = atom(null, (get, set) => {
  set(createTaskInRoutineModalAtom, null);
  set(textEditorCloseAtom);
})

export const createTaskInRoutineModalOpenAtom = atom(null, (get, set, backendId: number) => {
  const initData = {
    ...initCreateTaskInRoutineModalData(),
    open: backendId,
  }
  set(createTaskInRoutineModalAtom, initData);
});

// 할 일 생성
export const createTaskInRoutineModalAddTaskAtom = atom(null, async (get, set) => {
  const planData = get(innerTabDataAtom);
  const innerTabId = GetIdFromQuerystring("inner_tab_id");
  const plan = planData.find((data) => data.innerTabId === innerTabId);
  const task = get(createTaskInRoutineModalAtom);
  if (!task || !plan) return;
  if (task.name === "") {
    set(snackbarAtom, (prev) => ({ ...prev, open: true, message: "이름을 입력해주세요.", severity: "error" }));
    return;
  }
  const taskContent: CreateTaskInRoutineContent = {
    label: task.name,
    content: task.content,
    location: task.location.address,
    hashtags: task.hashtags,
    startDate: task.startDate ? task.startDate.toISOString() : null,
    endDate: task.endDate ? task.endDate.toISOString() : null,
    termType: task.termType,
    termData: task.termData,
  }
  const response = await handleReactQueryApiResponse(createTaskInRoutine, () => set(error401ModalAtom, true), task.open, taskContent);
  if (!response.ok) {
    set(snackbarAtom, (prev) => ({ ...prev, open: true, message: "할 일 생성에 실패하였습니다.", severity: "error" }));
  }
  const responseJson = await response.json();
  const newNode = responseJson.node;
  const newEdge = responseJson.edge;

  const newNodes = [...plan.nodes, newNode];
  const newEdges = [...plan.edges, newEdge];
  set(setInnerTabDataAtom, { innerTabId, nodes: newNodes, edges: newEdges });
  set(updatePlanDataMindmapAtom, innerTabId, newNodes, newEdges);
  set(createTaskInRoutineModalClearStateAtom);
  set(snackbarAtom, (prev) => ({ ...prev, open: true, message: "할 일이 생성되었습니다.", severity: "success" }));
});

// key에 따라 상태 변경
export const createTaskInRoutineModalUpdateStateAtom = atom((get) => get(createTaskInRoutineModalAtom), (get, set, key: string, value: any) => {
  const modal = get(createTaskInRoutineModalAtom);
  if (!modal) return;
  if (key === "termData") {
    const weekDays = ["월", "화", "수", "목", "금", "토", "일"];
    modal.termType === 1 ?
      value.sort((a: string, b: string) => weekDays.indexOf(a) - weekDays.indexOf(b)) : value.sort((a: string, b: string) => parseInt(a) - parseInt(b));
  }
  const newModal = {
    ...modal,
    [key]: value,
  }
  set(createTaskInRoutineModalAtom, newModal);
});

// 장소 변경
export const createTaskInRoutineModalEditLocationAtom = atom(null, (get, set, address: string) => {
  const modal = get(createTaskInRoutineModalAtom);
  if (!modal) return;
  const newModal = {
    ...modal,
    location: { address: address, latitude: modal.location.latitude, longitude: modal.location.longitude },
  }
  set(createTaskInRoutineModalAtom, newModal);
})

// 루틴 옵션 변경
export const createTaskInRoutineModalSetRoutineOptionAtom = atom(null, (get, set, key: number) => {
  const modal = get(createTaskInRoutineModalAtom);
  if (!modal) return;
  const newModal = {
    ...modal,
    termType: modal.termType === key ? null : key,
    termData: modal.termType === key ? null : [],
  }
  set(createTaskInRoutineModalAtom, newModal);
});

// 루틴 일자 변경
export const createTaskInRoutineModalEditRoutineDataAtom = atom((get) => get(createTaskInRoutineModalEditRoutineAtom), (get, set, day: string) => {
  const editRoutineData = get(createTaskInRoutineModalEditRoutineAtom);
  if (!editRoutineData.termData) return;
  if (editRoutineData.termData.includes(day)) {
    set(createTaskInRoutineModalEditRoutineAtom, { termType: editRoutineData.termType, termData: editRoutineData.termData.filter((interval) => interval !== day) });
  } else {
    set(createTaskInRoutineModalEditRoutineAtom, { termType: editRoutineData.termType, termData: [...editRoutineData.termData, day] });
  }
});

// 루틴 일자 초기화
export const createTaskInRoutineModalEditRoutineClearStateAtom = atom(null, (get, set) => {
  set(createTaskInRoutineModalEditRoutineAtom, {
    termType: null,
    termData: null,
  });
});

// 루틴 변경 적용
export const createTaskInRoutineModalEditRoutineApplyAtom = atom(null, (get, set) => {
  const editRoutineData = get(createTaskInRoutineModalEditRoutineAtom);
  const modal = get(createTaskInRoutineModalAtom);
  if (!modal) return;
  const newModal = {
    ...modal,
    termData: editRoutineData.termData,
  }
  set(createTaskInRoutineModalAtom, newModal);
  set(createTaskInRoutineModalEditRoutineClearStateAtom);
});

// 루틴 변경 시작
export const createTaskInRoutineModalEditRoutineStartAtom = atom(null, (get, set) => {
  const modal = get(createTaskInRoutineModalAtom);
  if (!modal) return;
  const termData = modal.termData;
  set(createTaskInRoutineModalEditRoutineAtom, {
    termType: modal.termType,
    termData: termData ? [...termData] : [],
  });
});

// 해시태그 추가
export const createTaskInRoutineModalAddHashtagAtom = atom(null, (get, set, hashtag: { backgroundColor: string, textColor: string, value: string }) => {
  const modal = get(createTaskInRoutineModalAtom);
  if (!modal) return;
  const newModal = {
    ...modal,
    hashtags: [...modal.hashtags, hashtag],
  }
  set(createTaskInRoutineModalAtom, newModal);
});

// 해시태그 삭제(pop)
export const createTaskInRoutineModalRemoveHashtagAtom = atom(null, (get, set) => {
  const modal = get(createTaskInRoutineModalAtom);
  if (!modal) return;
  const newModal = {
    ...modal,
    hashtags: modal.hashtags.slice(0, -1),
  }

  set(createTaskInRoutineModalAtom, newModal);
});
