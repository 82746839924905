import EditPlanNameDialogAtom from '@/Atoms/Dialogs/Edit/PlanNameAtom';
import { error401ModalAtom } from '@/Atoms/Dialogs/Error/401Atom';
import { drawerOpenAtom } from '@/Atoms/HeaderAtom';
import { currentPlanAtom } from '@/Atoms/Plan';
import snackbarAtom from '@/Atoms/Snackbar';
import { updatePlanName } from '@/Queries/PlanQueries';
import { handleReactQueryApiResponse } from '@/Utils/APIUtil';
import GetIdFromQuerystring from '@/Utils/GetIdFromQuerystring';
import { openDokgabiChatModalAtom } from '@/ViewModels/Chat/ModalViewModel';
import { getPlansAtom } from '@/ViewModels/Plan/PlanViewModel';
import { atom } from 'jotai';

export const editPlanNameDialogOpenAtom = atom(null, (get, set) => {
  const plan = get(currentPlanAtom);
  if (!plan) return;
  set(EditPlanNameDialogAtom, {
    open: !plan.name,
    id: !plan.name ? plan.id : 0,
    name: "",
  });
});

export const editPlanNameDialogUpdateAtom = atom(null, async (get, set) => {
  const editPlanName = get(EditPlanNameDialogAtom);
  const isNew = GetIdFromQuerystring("new_plan") === 1;
  if (!editPlanName.open) return;
  if (!editPlanName.name) {
    set(snackbarAtom, (prev) => ({ ...prev, open: true, message: "플랜 이름을 입력해주세요." }));
    return;
  }
  const handleError401Modal = () => set(error401ModalAtom, true);
  const response = await handleReactQueryApiResponse(updatePlanName, handleError401Modal, editPlanName.id, editPlanName.name);
  if (response.ok) {
    set(EditPlanNameDialogAtom, { open: false, name: "", id: 0 });
    set(getPlansAtom);
    if (isNew) { // 새로운 플랜 생성 시 채팅창 열기
      set(drawerOpenAtom, false);
      // set(chatDialogAtom, true);
      set(openDokgabiChatModalAtom);
      const currentPlan = get(currentPlanAtom);
      if (currentPlan) {
        set(currentPlanAtom, { ...currentPlan, name: editPlanName.name });
      }
    }
  } else {
    set(snackbarAtom, (prev) => ({ ...prev, open: true, message: "플랜 이름 변경에 실패했습니다." }));
  }
})
