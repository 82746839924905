import React from "react";
import { DndContext, DragEndEvent, Modifier, closestCenter } from "@dnd-kit/core";
import { SortableContext, arrayMove, verticalListSortingStrategy } from "@dnd-kit/sortable";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { addTasksInRoutineAtom, deleteRoutineModalOpenAtom, editRoutineInRoutineStateAtom, prevTaskOrderAtom, routineSidebarAtom, RoutineSidebarProps, TaskOrderProps, tasksInRoutineAtom } from "@/Atoms/Plan/RoutineAtom";
import { ArrowForward, Check, Delete, Edit, ExpandLess, ExpandMore, Menu, Remove, RemoveCircle } from "@mui/icons-material";
import { useAtom, useAtomValue, useSetAtom } from "jotai";
import * as S from "./styled";
import TitleValueInARow from "../Common/TitleValueInARow";
import { Node } from "@xyflow/react";
import { TaskStatus } from "@/Types/Plan";
import { Divider, LinearProgress, Tooltip } from "@mui/material";
import { getProgressPercentageForTaskStatus } from "@/Utils/GetProgressPercentage";
import theme from "@/Styles/theme";
import { pushRoutineSidebarDataAtom } from "@/ViewModels/Plan/Routine/SidebarViewModel";
import { deleteRoutineInRoutineAtom, requestChangeTaskStatusInRoutineAtom, requestExcludeTaskInRoutineAtom } from "@/ViewModels/Plan/Routine/RoutineViewModel";
import { error401ModalAtom } from "@/Atoms/Dialogs/Error/401Atom";
import { handleReactQueryApiResponse } from "@/Utils/APIUtil";
import { getTaskOrderInRoutine } from "@/Queries/RoutineQueries";
import { getNotConnectedTasksInCurrentRoutineAtom } from "@/ViewModels/Plan/Routine/DataViewModel";
import TitleValueInEachRow from "../Common/TitleValueInEachRow";
import { useTranslation } from "react-i18next";
import { requestChangeTaskStatusInRoutineByIdAtom } from "@/ViewModels/Plan/Routine/TaskViewModel";

interface SortableTaskItemProps {
  task: Node;
  index: number;
  taskStatus: TaskStatus | undefined;
}

const restrictToVerticalAxis: Modifier = ({ transform }) => {
  return {
    ...transform,
    x: 0, // x축 이동을 0으로 고정
  };
};

const getProgress = (task: Node) => {
  const taskStatuses = task.data.taskStatus as TaskStatus[];
  const totalTaskCount = taskStatuses.length;
  const completedTaskCount = taskStatuses.filter((status) => status.status === 1).length;

  return totalTaskCount === 0 ? 0 : Math.round((completedTaskCount / totalTaskCount) * 100);
}

const getRepeatCycle = (task: Node) => {
  const termType = task.data.termType as number | null;
  const termData = task.data.termData as string[] | number[] | null;

  if (!termType || !termData) return null;
  if (termType === 0) return "매일";
  if (termType === 1) {
    const days = termData as string[];
    return `매주 ${days.join(", ")}`;
  }
  const days = termData as number[];
  return `매월 ${days.join(", ")}일`;
}

const getTime = (time: string) => {
  const newDate = new Date(time);
  return `${newDate.getFullYear()}년 ${newDate.getMonth() + 1}월 ${newDate.getDate()}일`;
}

const SortableTaskItem: React.FC<SortableTaskItemProps> = ({ task, index, taskStatus }) => {
  const { t } = useTranslation();
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id: task.id });
  const [isExpanded, setIsExpanded] = React.useState(false);
  const handleChangeIsExpanded = () => setIsExpanded((prev) => !prev);
  const openSidebar = useSetAtom(pushRoutineSidebarDataAtom);
  const [isHover, setIsHover] = React.useState(false);
  const taskStatuses = task.data.taskStatus as TaskStatus[];
  const filteredMyTaskStatus = taskStatuses.filter((status) => status.routineId === status.taskId); // taskId와 routineId가 같은 경우만 필터링
  const changeTaskStatus = useSetAtom(requestChangeTaskStatusInRoutineByIdAtom);
  const removeTaskFromRoutine = useSetAtom(requestExcludeTaskInRoutineAtom);

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  const handleDetailClick = () => {
    // 자세히 보기 클릭 시 처리
    openSidebar({
      type: "taskInRoutine",
      key: task,
      tasks: [],
      taskStatus: taskStatus,
    })
  }

  const handleMouseEnter = () => {
    setIsHover(true);
  }

  const handleMouseLeave = () => {
    setIsHover(false);
  }

  return (
    <S.TaskItem ref={setNodeRef} style={style} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
      <S.TaskItemTitleContainer>
        <S.TaskItemTitleItem>
          {
            isExpanded ?
              <ExpandLess sx={{ width: "20px", height: "20px", cursor: "pointer" }} onClick={handleChangeIsExpanded} /> :
              <ExpandMore sx={{ width: "20px", height: "20px", cursor: "pointer" }} onClick={handleChangeIsExpanded} />
          }
          <div style={{ fontSize: "12px", fontWeight: "bold" }}>
            {index + 1}
          </div>
          <div style={{ flex: 1, textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap", fontSize: "12px" }}>
            {task.data.label as string}
          </div>
        </S.TaskItemTitleItem>
        <S.TaskItemTitleItem style={{ color: "#999" }}>
          {
            (isHover && taskStatus !== undefined) && taskStatus.status === 0 &&
            <div style={{ fontSize: "14px", fontWeight: "bold", color: "black", cursor: "pointer" }} onClick={() => changeTaskStatus
              (task, taskStatus.taskStatusId, t)
            }>{t("plan.contents.routine.sidebar.content.complete")}</div>
          }
          {
            (isHover && taskStatus !== undefined) && taskStatus.status === 1 &&
            <div style={{ fontSize: "14px", fontWeight: "bold", color: "black", cursor: "pointer" }} onClick={() => changeTaskStatus
              (task, taskStatus.taskStatusId, t)
            }>{t("plan.contents.routine.sidebar.content.cancel")}</div>
          }
          {/* 드래그를 메뉴 아이콘에서만 동작하도록 제한 */}
          <Tooltip title={t("plan.contents.routine.sidebar.content.changeOrder")} placement="top" arrow>
            <Menu sx={{ width: "20px", height: "20px", cursor: "grab" }} {...listeners} {...attributes} />
          </Tooltip>
        </S.TaskItemTitleItem>
      </S.TaskItemTitleContainer>
      {
        isExpanded &&
        <React.Fragment>
          {
            typeof task.data.startDate === 'string' &&
            <TitleValueInARow title={t("plan.contents.routine.sidebar.content.startDate")} value={getTime(task.data.startDate)} />
          }
          {
            typeof task.data.endDate === 'string' &&
            <TitleValueInARow title={t("plan.contents.routine.sidebar.content.endDate")} value={getTime(task.data.endDate as string)} />
          }
          {
            // times: string[]
            typeof task.data.times === 'object' && task.data.times && (task.data.times as string[]).length > 0 &&
            <TitleValueInEachRow
              title={t("plan.contents.routine.sidebar.content.time")}
              value={
                <div style={{ width: "100%", height: "50px", overflowX: "auto", display: "flex", flexDirection: "row" }}>
                  {Array.from({ length: 24 }).map((_, index) => (
                    <div
                      key={index}
                      style={{
                        minWidth: '30px',
                        height: '100%',
                        fontSize: "10px",
                        fontWeight: "bold",
                        backgroundColor: (task.data.times as string[]).includes(index.toString()) ? theme.colors.primary : '#e9e9e9',
                        color: (task.data.times as string[]).includes(index.toString()) ? 'white' : 'black',
                      }}
                    >
                      {index}
                    </div>
                  ))}
                </div>
              }
            />
          }
          {
            getRepeatCycle(task) &&
            <TitleValueInARow title={t("plan.contents.routine.sidebar.content.repeat")} value={getRepeatCycle(task)} />
          }
          {
            filteredMyTaskStatus.length > 0 &&
            <>
              <TitleValueInARow title={t("plan.contents.routine.sidebar.content.progress")} value={<div style={{ fontSize: "14px", fontWeight: "bold" }}>{`${getProgress(task)}%`}</div>} />
              <LinearProgress variant="determinate" value={getProgressPercentageForTaskStatus(filteredMyTaskStatus, task.data.backendId as number)} sx={{
                width: "100%", height: "20px", borderRadius: "5px", backgroundColor: "#e0e0e0", '& .MuiLinearProgress-bar': {
                  backgroundColor: theme.colors.primary, // 커스텀 색상 적용
                },
              }} />
            </>
          }
          {
            typeof task.data.content === 'string' && task.data.content.length !== 0 &&
            <TitleValueInEachRow title={t("plan.contents.routine.sidebar.content.content")} value={task.data.content} />
          }
          <Divider sx={{ width: "100%" }} />
          <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", width: "100%" }}>
            <S.TaskItemDetail onClick={() => removeTaskFromRoutine(task, t)} style={{ color: "#111" }}>
              {t("plan.contents.routine.sidebar.content.removeTaskFromRoutine")}
            </S.TaskItemDetail>
            <S.TaskItemDetail onClick={handleDetailClick}  >
              {t("plan.contents.routine.sidebar.content.detail")}
              <ArrowForward
                sx={{
                  width: "20px",
                  height: "20px",
                  verticalAlign: "middle", // 아이콘을 텍스트의 중간에 정렬
                }}
              />
            </S.TaskItemDetail>
          </div>
        </React.Fragment>
      }
    </S.TaskItem>
  );
};

interface Props {
  data: RoutineSidebarProps;
}

const MyRoutineInRoutine = ({ data }: Props) => {
  const { t } = useTranslation();
  const [tasks, setTasks] = useAtom(tasksInRoutineAtom);
  const [editLabel, setEditLabel] = React.useState(false);
  const setError401Modal = useSetAtom(error401ModalAtom);
  const [isFetch, setIsFetch] = React.useState(false);
  const setPrevTaskOrder = useSetAtom(prevTaskOrderAtom);
  const openSidebar = useSetAtom(pushRoutineSidebarDataAtom);
  const setEditRoutineState = useSetAtom(editRoutineInRoutineStateAtom);
  const notConnectedTasks = useAtomValue(getNotConnectedTasksInCurrentRoutineAtom);
  const setAddTasksToRoutine = useSetAtom(addTasksInRoutineAtom);
  const openDeleteRoutineModal = useSetAtom(deleteRoutineModalOpenAtom);
  const taskStatuses = data.taskStatuses as TaskStatus[] || [];

  const handleEditRoutine = () => {
    if (!data.key) return;
    setEditRoutineState({
      label: data.key.data.label as string,
      color: data.key.data.color as string,
      isLoading: false,
      content: data.key.data.content as string,
      backendId: data.key.data.backendId as number,
    });
    openSidebar({
      type: "editRoutine",
      key: data.key,
      tasks: [],
    });
  };

  const handleAddTask = () => {
    if (!data.key) return;
    setAddTasksToRoutine([]);
    openSidebar({
      type: "addTaskToRoutine",
      key: data.key,
      tasks: notConnectedTasks,
    });
  }

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;

    if (!over || active.id === over.id) return;

    const oldIndex = tasks.findIndex((task) => task.id === active.id);
    const newIndex = tasks.findIndex((task) => task.id === over.id);

    setTasks((prev) => arrayMove(prev, oldIndex, newIndex));
  };

  const getTaskOrder = async (backendId: number) => {
    const response = await handleReactQueryApiResponse(getTaskOrderInRoutine, () => setError401Modal(true), backendId as number);
    if (!response.ok) return null;
    const data = await response.json();
    return data;
  }

  React.useEffect(() => {
    setTasks(data.tasks);
    setIsFetch(false);

    // eslint-disable-next-line
  }, [data.tasks]);

  React.useEffect(() => {
    if (isFetch) return;
    const fetchTaskOrder = async () => {
      if (data.key) {
        try {
          const order = await getTaskOrder(data.key.data.backendId as number);

          // 데이터를 상태에 업데이트하거나 추가 작업 수행
          if (order) {
            const tasksOrder = order.tasks as TaskOrderProps[] || [];
            setPrevTaskOrder(tasksOrder);
            const orderedTasks = tasksOrder
              .map((taskOrder) =>
                data.tasks.find((task) => task.id === taskOrder.id)
              )
              .filter((task) => task !== undefined) as Node[];

            // order 순으로 정렬
            orderedTasks.sort((a, b) => {
              const aOrder = tasksOrder.find((taskOrder) => taskOrder.id === a.id)?.order as number;
              const bOrder = tasksOrder.find((taskOrder) => taskOrder.id === b.id)?.order as number;
              return aOrder - bOrder;
            });

            const notOrderedTasks = data.tasks.filter((task) => !orderedTasks.includes(task));
            setTasks([...orderedTasks, ...notOrderedTasks]);
          }
          setIsFetch(true);
        } catch (error) {
          console.error("Error fetching task order:", error);
        }
      }
    };

    fetchTaskOrder();
  }, [data.key, isFetch]); // 의존성 배열은 필요한 값만 포함

  return (
    data.key && (
      <div style={{ width: "100%", height: "100%", overflowY: "auto", display: "flex", flexDirection: "column", gap: "20px", position: "relative" }}>
        <S.RoutineTitleContainer>
          {data.key.data.label as string}
          <div style={{ display: "flex", flexDirection: "row", gap: "10px", alignItems: "center", justifyContent: "flex-end" }}>
            <Tooltip title={t("plan.contents.routine.sidebar.content.edit2")} placement="top" arrow>
              <Edit sx={{ cursor: "pointer", width: "20px", height: "20px" }} onClick={handleEditRoutine} />
            </Tooltip>
            <Tooltip title={t("plan.contents.routine.sidebar.content.delete")} placement="top" arrow>
              <Delete sx={{ cursor: "pointer", width: "20px", height: "20px" }} onClick={() => openDeleteRoutineModal((data.key?.id as string || null))} />
            </Tooltip>
          </div>
        </S.RoutineTitleContainer>
        {
          typeof data.key.data.content === 'string' && data.key.data.content.length !== 0 &&
          <TitleValueInEachRow title={t("plan.contents.routine.sidebar.content.content")} value={data.key.data.content} />
        }
        <TitleValueInARow
          title={t("plan.contents.routine.sidebar.content.color")}
          value={
            <S.RoutineColor $color={data.key.data.color as string} onClick={() => { }} />
          }
        />
        <TitleValueInARow title={t("plan.contents.routine.sidebar.content.taskList")} value={null} />
        <S.AddTaskButton onClick={handleAddTask}>{t("plan.contents.routine.sidebar.content.addTask")}</S.AddTaskButton>
        <S.TaskList>
          <DndContext collisionDetection={closestCenter} onDragEnd={handleDragEnd} modifiers={[restrictToVerticalAxis]} autoScroll={false}>
            <SortableContext items={tasks} strategy={verticalListSortingStrategy}>
              {tasks.map((task, index) => (
                <SortableTaskItem key={task.id} task={task} index={index} taskStatus={taskStatuses.find((taskStatus) => taskStatus.taskId === task.data.backendId as number)} />
              ))}
            </SortableContext>
          </DndContext>
        </S.TaskList>
      </div>
    )
  );
};

export default MyRoutineInRoutine;
