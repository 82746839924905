const convertRelativeTime = (date: Date, language: "KR" | "US") => {
  if (typeof date === 'string') {
    date = new Date(date);
  }

  // date가 유효한 Date 객체인지 확인
  if (!(date instanceof Date) || isNaN(date.getTime())) {
    // return "알 수 없음";  // 유효하지 않은 날짜에 대한 기본값 처리
    return language === "KR" ? "알 수 없음" : "Unknown";
  }

  const now = new Date();
  const diff = now.getTime() - date.getTime();

  const seconds = Math.floor(diff / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);
  const months = Math.floor(days / 30);
  const years = Math.floor(months / 12);

  if (seconds < 60) {
    return language === "KR" ? "방금 전" : "Just now";
  } else if (minutes < 60) {
    return language === "KR" ? `${minutes}분 전` : `${minutes} minutes ago`;
  } else if (hours < 24) {
    return language === "KR" ? `${hours}시간 전` : `${hours} hours ago`;
  } else if (days < 30) {
    return language === "KR" ? `${days}일 전` : `${days} days ago`;
  } else if (months < 12) {
    return language === "KR" ? `${months}달 전` : `${months} months ago`;
  } else {
    return language === "KR" ? `${years}년 전` : `${years} years ago`;
  }
};

export default convertRelativeTime;
