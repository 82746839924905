import React from 'react';
import * as S from './styled';
import { useAtomValue, useSetAtom } from 'jotai';
import { routineSidebarAtom, RoutineSidebarProps } from '@/Atoms/Plan/RoutineAtom';
import { clearRoutineSidebarDataAtom, popRoutineSidebarDataAtom } from '@/ViewModels/Plan/Routine/SidebarViewModel';
import { ArrowBackIos, Close } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';

interface SidebarTitleProps {
  data: RoutineSidebarProps;
}

const SidebarTitle = ({ data }: SidebarTitleProps) => {
  const { t } = useTranslation();
  const prev = useSetAtom(popRoutineSidebarDataAtom);
  const close = useSetAtom(clearRoutineSidebarDataAtom);
  const isPrev = ["addTaskToRoutine", "addNewTaskToRoutine", "taskInRoutine"].includes(data.type);
  const both = ["editTask", "editRoutine"].includes(data.type);

  const getTitle = () => {
    switch (data.type) {
      case "routine":
        return t("plan.contents.routine.sidebar.title.routine");
      case "task":
        return t("plan.contents.routine.sidebar.title.task");
      case "taskInRoutine":
        return t("plan.contents.routine.sidebar.title.taskInRoutine");
      case "template":
        return t("plan.contents.routine.sidebar.title.template");
      case "createTask":
        return t("plan.contents.routine.sidebar.title.createTask");
      case "createRoutine":
        return t("plan.contents.routine.sidebar.title.createRoutine");
      case "editTask":
        return t("plan.contents.routine.sidebar.title.editTask");
      case "ganttEditTask":
        return t("plan.contents.routine.sidebar.title.editTask");
      case "editRoutine":
        return t("plan.contents.routine.sidebar.title.editRoutine");
      case "ganttEditRoutine":
        return t("plan.contents.routine.sidebar.title.editRoutine");
      case "addTaskToRoutine":
        return t("plan.contents.routine.sidebar.title.addTaskToRoutine");
      case "ganttAddTaskToRoutine":
        return t("plan.contents.routine.sidebar.title.addTaskToRoutine");
      case "addNewTaskToRoutine":
        return t("plan.contents.routine.sidebar.title.addNewTaskToRoutine");
      case "calendarTask":
        return t("plan.contents.routine.sidebar.title.calendarTask");
      case "calendarTodayTask":
        return t("plan.contents.routine.sidebar.title.calendarTodayTask");
      default:
        return "";
    }
  }

  const handleClose = () => {
    // 루틴 조회에서 할 일에 대한 순서 변경이 일어났다면 API 호출
    close();
  }

  return (
    <S.Container $isPrev={isPrev}>
      <div style={{ display: "flex", flexDirection: "row", alignItems: "center", gap: "10px" }}>
        {
          (isPrev || both) &&
          <ArrowBackIos sx={{ width: "20px", height: "20px", cursor: "pointer" }} onClick={prev} />
        }
        {
          <div style={{ fontSize: "0.9rem", fontWeight: "bold" }}>{getTitle()}</div>
        }
      </div>
      {
        (!isPrev || both) &&
        <Close sx={{ width: "20px", height: "20px", cursor: "pointer" }} onClick={handleClose} />
      }
    </S.Container>
  )
}

export default SidebarTitle;
