import { debounce } from "lodash";
import { devConsoleError } from "./ConsoleLogInDevelopment";

/**
 * 비동기 함수에 debounce를 적용하는 공통 함수
 * @param asyncFunction - 호출할 비동기 함수
 * @param wait - 디바운스 대기 시간 (ms)
 * @returns 디바운스된 비동기 함수
 */
export const debounceAsync = <T extends (...args: any[]) => Promise<void>>(
  asyncFunction: T,
  wait: number = 500
): ((...args: Parameters<T>) => void) => {
  const debounced = debounce((...args: Parameters<T>) => {
    asyncFunction(...args).catch((err) => {
      devConsoleError("Debounced async function error:", err);
    });
  }, wait);

  // 타입 캐스팅을 통해 반환 타입 정리
  return debounced as (...args: Parameters<T>) => void;
};
