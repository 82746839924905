import { editRoutineInRoutineStateAtom, routineSidebarAtom } from '@/Atoms/Plan/RoutineAtom';
import { atom } from 'jotai';
import { clearRoutineSidebarDataAtom, popAndChangeKeySidebarDataAtom } from './SidebarViewModel';
import { handleReactQueryApiResponse } from '@/Utils/APIUtil';
import { updateTaskV2 } from '@/Queries/PlanQueries';
import { error401ModalAtom } from '@/Atoms/Dialogs/Error/401Atom';
import snackbarAtom from '@/Atoms/Snackbar';
import { Edge, Node } from '@xyflow/react';
import innerTabDataAtom from '@/Atoms/Plan/InnerTabDataAtom';
import GetIdFromQuerystring from '@/Utils/GetIdFromQuerystring';
import { setInnerTabDataAtom } from '../InnerTabViewModel';
import { TFunction } from 'i18next';

export const editRoutineInRoutineUpdateRoutineAtom = atom(null, async (get, set, t: TFunction) => {
  const editRoutineData = get(editRoutineInRoutineStateAtom);
  const planData = get(innerTabDataAtom);
  const innerTabId = GetIdFromQuerystring("inner_tab_id");
  const plan = planData.find((data) => data.innerTabId === innerTabId);
  const sidebar = get(routineSidebarAtom);
  if (!plan || sidebar.length === 0 || !editRoutineData) return;


  if (editRoutineData.label === "") {
    set(snackbarAtom, { open: true, message: t("plan.contents.routine.snackbar.inputRoutineName"), severity: "error" });
    return;
  }
  if (editRoutineData.label.length > 50) {
    set(snackbarAtom, { open: true, message: t("plan.contents.routine.snackbar.editNameLength"), severity: "error" });
    return;
  }
  const loadingState = {
    ...editRoutineData,
    isLoading: true,
  }
  set(editRoutineInRoutineStateAtom, loadingState);

  const requestData = {
    label: editRoutineData.label,
    content: editRoutineData.content,
    color: editRoutineData.color,
    type: "routine",
  }

  const response = await handleReactQueryApiResponse(updateTaskV2, () => set(error401ModalAtom, true), editRoutineData.backendId, requestData);

  if (!response.ok) {
    set(snackbarAtom, { open: true, message: t("plan.contents.routine.snackbar.editRoutineFail"), severity: "error" });
    set(editRoutineInRoutineStateAtom, { ...editRoutineData, isLoading: false });
    return;
  }

  const responseJson = await response.json();

  const { nodes, edges } = responseJson;

  const nodesIds = (nodes as Node[]).map((node) => node.id);
  const edgesIds = (edges as Edge[]).map((edge) => edge.id);

  const planNodes = plan.nodes;
  const planEdges = plan.edges;
  const planEdgesIds = planEdges.map((edge) => edge.id);

  const newNodes = planNodes.map((node) => {
    if (nodesIds.includes(node.id)) {
      return (nodes as Node[]).find((n) => n.id === node.id) as Node
    }
    return node;
  });
  const newEdges = planEdges.map((edge) => {
    if (edgesIds.includes(edge.id)) {
      return (edges as Edge[]).find((e) => e.id === edge.id) as Edge
    }
    return edge;
  });
  const notIncludedEdges = (edges as Edge[]).filter((edge) => !planEdgesIds.includes(edge.id));
  const newEdgesWithNotIncluded = [...newEdges, ...notIncludedEdges];

  set(setInnerTabDataAtom, { innerTabId, nodes: newNodes, edges: newEdgesWithNotIncluded });
  set(snackbarAtom, { open: true, message: t("plan.contents.routine.snackbar.editRoutineSuccess"), severity: "success" });
  const sidebarData = sidebar[sidebar.length - 1];
  if (sidebarData.type === "ganttEditRoutine") {
    set(clearRoutineSidebarDataAtom);
  } else {
    set(popAndChangeKeySidebarDataAtom, nodes[0]);
  }
  set(editRoutineInRoutineStateAtom, null);
});
