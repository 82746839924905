import { Node } from "@xyflow/react";
import { atom } from "jotai";

interface InnerTab {
  id: number;
  name: string | null;
  viewType: "mindmap" | "todo" | "routine" | "gantt" | "feed";
}

export interface PlanFeed {
  id: number;
  createdAt: string;
  name: string;
  content: string;
  startDate: string | null;
  endDate: string | null;
  location: string;
  hashtags: {
    backgroundColor: string;
    textColor: string;
    value: string;
  }[];
}

export interface Plan {
  id: number;
  userId: number;
  name: string | null;
  isPublic: boolean;
  content: string | null;
  imageUrl: string | null;
  type: "mindmap" | "routine";
  hashtags: {
    backgroundColor: string;
    textColor: string;
    value: string;
  }[];
  innerTabs: InnerTab[];
  feeds: PlanFeed[];
}

const planAtom = atom<Plan[]>([]);
export const planFitViewUpdatedAtom = atom<boolean>(false); // plan 페이지 입장 시 fitView를 업데이트하기 위한 atom

export const mobilePlanAtom = atom<string>(""); // 모바일에서 사용하는 제목 표시를 위한 atom
export const mobileEditPlanNameAtom = atom<string | null>(null); // 모바일에서 사용하는 제목 수정을 위한 atom

export const currentPlanAtom = atom<Plan | null>(null); // 현재 선택된 plan을 저장하기 위한 atom

// node 별 debounce를 처리하기 위한 타이머 관리 atom
export const nodeDebounceTimersAtom = atom<Map<string, NodeJS.Timeout>>(new Map());

// 복수의 task를 업데이트 하기위한 atom
export const updatePartialNodesAtom = atom<Node[]>([]);

// 삭제된 노드의 id를 저장하기 위한 atom
export const deletedNodesAtom = atom<Set<number>>(new Set<number>());

export default planAtom;
