import { notificationAtom, notificationDataAtom } from '@/Atoms/Notification/atom';
import { Popover, Tabs } from '@mui/material';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import React from 'react';
import { useTranslation } from 'react-i18next';
import CloseIcon from '@mui/icons-material/Close';
import * as S from './styled';
import theme from '@/Styles/theme';
import NotificationItem from './NotificationItem';
import { allReadNotificationAtom } from '@/ViewModels/Notification/ViewModel';

const NotificationPopover = () => {
  const [notification, setNotification] = useAtom(notificationAtom);
  const { t } = useTranslation();
  const notificationData = useAtomValue(notificationDataAtom);
  const unreadNotification = notificationData.filter((notification) => notification.unread);
  const sortedNotification = notificationData.sort((a, b) => b.date.getTime() - a.date.getTime());
  const [tab, setTab] = React.useState(unreadNotification.length > 0 ? "unread" : "total");
  const allRead = useSetAtom(allReadNotificationAtom);

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setTab(newValue);
  };

  return (
    <Popover
      id={"notification-popover"}
      open={Boolean(notification.anchorEl)}
      anchorEl={notification.anchorEl}
      onClose={() => setNotification((prev) => ({ ...prev, anchorEl: null }))}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
    >
      <div style={{ padding: "15px", display: "flex", flexDirection: "column", gap: "10px", width: "500px" }}>
        <div style={{ fontSize: "0.9rem", fontWeight: "bold", display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
          {t("dialogs.common.notification.title")}
          <CloseIcon onClick={() => setNotification((prev) => ({ ...prev, anchorEl: null }))} sx={{ cursor: "pointer" }} />
        </div>
        <div style={{ fontSize: "0.8rem", display: "flex", justifyContent: "space-between", alignItems: "center", borderBottom: "1px solid rgba(0, 0, 0, 0.12)" }}>
          <Tabs onChange={handleChange} aria-label="lab API tabs example" indicatorColor={theme.colors.primary} value={tab}>
            <S.CustomTab label={t("dialogs.common.notification.unread")} value="unread" />
            <S.CustomTab label={t("dialogs.common.notification.all")} value="total" />
          </Tabs>
          <div onClick={allRead} style={{ cursor: "pointer" }}>
            {t("dialogs.common.notification.allRead")}
          </div>
        </div>
        <div style={{ display: "flex", flexDirection: "column", gap: "10px", width: "100%", height: "400px", overflowY: "auto", overflowX: "hidden" }}>
          {
            tab === "unread" ?
              unreadNotification.length > 0 ?
                unreadNotification.map((notification) => (
                  <NotificationItem key={notification.id} notification={notification} />
                )) :
                <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%" }}>
                  {t("dialogs.common.notification.noUnreadNotification")}
                </div> :
              sortedNotification.length > 0 ?
                sortedNotification.map((notification) => (
                  <NotificationItem key={notification.id} notification={notification} />
                )) :
                <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%" }}>
                  {t("dialogs.common.notification.noNotification")}
                </div>
          }
        </div>
      </div>
    </Popover>
  )
}

export default NotificationPopover;
