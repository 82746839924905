import { signatureDialogAtom } from '@/Atoms/Signature/Atom';
import { Dialog, DialogTitle } from '@mui/material';
import { useAtomValue, useSetAtom } from 'jotai';
import React from 'react';
import { changeModeSignatureDialogAtom, closeSignatureDialogAtom, openNotSavedSignatureDialogAtom } from '@/ViewModels/Signature/ViewModel';
import SignatureList from '../Contents/List';
import CreateSignature from '../Contents/Create';
import { useTranslation } from 'react-i18next';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import NotSaveSignatureDialog from './NotSave';
import EditCardStyle from '../Contents/EditStyle';
import useIsMobile from '@/Hooks/useIsMobile';
import CloseIcon from '@mui/icons-material/Close';

const SignatureDialog: React.FC = () => {
  const { t } = useTranslation();
  const { isMobile } = useIsMobile();
  const dialog = useAtomValue(signatureDialogAtom);
  const mode = useAtomValue(changeModeSignatureDialogAtom);
  const handleClickPrevButton = useSetAtom(openNotSavedSignatureDialogAtom);
  const close = useSetAtom(closeSignatureDialogAtom);

  const ContentRender = () => {
    switch (mode) {
      case "list":
        return <SignatureList />;
      case "create":
        return <CreateSignature />;
      case "style":
        return <EditCardStyle />;
      case "edit":
        return <CreateSignature />;
      default:
        return <></>;
    }
  }

  return (
    <>
      <Dialog open={dialog.open} onClose={close} fullWidth maxWidth="md" fullScreen={isMobile}>
        <DialogTitle sx={{ display: "flex", flexDirection: "row", fontSize: "0.9rem", fontWeight: "bold", alignItems: "center", justifyContent: "space-between", padding: isMobile ? "10px" : "16px 24px" }}>
          <div style={{ display: "flex", flexDirection: "row", gap: "20px", alignItems: "center" }}>
            {mode !== "list" && <ArrowBackIcon onClick={() => handleClickPrevButton(isMobile)} sx={{ cursor: "pointer" }} />}
            {t(`signature.index.${mode}Title`)}
          </div>
          {
            isMobile &&
            <CloseIcon onClick={close} sx={{ cursor: "pointer" }} />
          }
        </DialogTitle>
        <ContentRender />
      </Dialog>
      <NotSaveSignatureDialog />
    </>
  )
}

export default SignatureDialog;
