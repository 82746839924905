import React from "react";
import { Button } from "@mui/material";
import * as S from "../Components/Error/styled";

export function Error() {
  return (
    <S.ErrorFullContainer>
      <S.TitleText>커리어플래너</S.TitleText>
      <S.SubText>로그인 후에 이용해주세요.</S.SubText>
      <S.CustomButton
        variant={"outlined"}
        onClick={() =>
          (window.location.href = process.env.REACT_APP_PARENT_LOGIN_ADDRESS)
        }
      >
        로그인 페이지로
      </S.CustomButton>
    </S.ErrorFullContainer>
  );
}
