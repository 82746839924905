import { error401ModalAtom } from '@/Atoms/Dialogs/Error/401Atom';
import innerTabDataAtom from '@/Atoms/Plan/InnerTabDataAtom';
import snackbarAtom from '@/Atoms/Snackbar';
import { completeTasksInRoutine } from '@/Queries/RoutineQueries';
import { TaskStatus } from '@/Types/Plan';
import { handleReactQueryApiResponse } from '@/Utils/APIUtil';
import GetIdFromQuerystring from '@/Utils/GetIdFromQuerystring';
import { Node } from '@xyflow/react';
import { atom } from 'jotai';
import { setInnerTabDataAtom } from '../InnerTabViewModel';
import { deleteRoutineModalOpenAtom, routineSidebarAtom } from '@/Atoms/Plan/RoutineAtom';
import { deleteEdge, deleteNodesAndEdges } from '@/Queries/PlanQueries';
import { TFunction } from 'i18next';
import { clearRoutineSidebarDataAtom } from './SidebarViewModel';
import { getUserId } from '@/ViewModels/UserViewModel';

// 세부 할 일 완료 요청
export const requestChangeTaskStatusInRoutineAtom = atom(null, async (get, set, task: Node, t: TFunction) => {
  const planData = get(innerTabDataAtom);
  const innerTabId = GetIdFromQuerystring("inner_tab_id");
  const currentPlanData = planData.find((data) => data.innerTabId === innerTabId);
  const sidebar = get(routineSidebarAtom);
  if (sidebar.length === 0) return;
  if (!currentPlanData) return;

  const upperTaskId = task.data?.backendId as number;
  const lowerTaskIds = [task.data?.backendId as number];
  const sidebarData = sidebar[sidebar.length - 1];
  const sidebarTasks = sidebarData.tasks as Node[];

  try {

    const response = await handleReactQueryApiResponse(completeTasksInRoutine, () => set(error401ModalAtom, true), upperTaskId, lowerTaskIds);

    if (!response.ok) {
      set(snackbarAtom, { open: true, message: t("plan.contents.routine.snackbar.taskStatusChangeFail"), severity: "error" });
    }

    const responseJson = await response.json();
    const updatedTaskStatus = responseJson.lower_task_status_response as TaskStatus[];

    // 완료한 세부 할 일 상태 업데이트
    const prevTaskStatus = task.data?.taskStatus as TaskStatus[];

    const newTaskStatus = prevTaskStatus.map((prevStatus) => {
      // 기존 상태에 해당하는 업데이트가 있는지 확인
      const updatedStatus = updatedTaskStatus.find(
        (update) => update.taskStatusId === prevStatus.taskStatusId
      );

      // 업데이트가 있으면 대체, 없으면 기존 상태 유지
      return updatedStatus ? updatedStatus : prevStatus;
    });

    // 새로 추가된 상태를 필터링하여 병합
    const addedTaskStatus = updatedTaskStatus.filter(
      (update) =>
        !prevTaskStatus.some(
          (prevStatus) => prevStatus.taskStatusId === update.taskStatusId
        )
    );

    // 병합된 상태 배열
    const finalTaskStatus = [...newTaskStatus, ...addedTaskStatus];

    // 상태 업데이트
    const newTask = {
      ...task,
      data: {
        ...task.data,
        taskStatus: finalTaskStatus,
      },
    }

    let newSidebarData;
    if (task.id === sidebarData.key?.id) {
      // 상세 뷰의 경우
      newSidebarData = { ...sidebarData, key: newTask };
    } else {
      const newSidebarTasks = sidebarTasks.map((sidebarTask) => sidebarTask.id === task.id ? newTask : sidebarTask);
      newSidebarData = { ...sidebarData, tasks: newSidebarTasks };
    }
    const newSidebar = [...sidebar.slice(0, sidebar.length - 1), newSidebarData];

    const newNodes = currentPlanData.nodes.map((node) => node.id === task.id ? newTask : node);

    set(setInnerTabDataAtom, { innerTabId, nodes: newNodes, edges: currentPlanData.edges });
    set(routineSidebarAtom, newSidebar);

  } catch (error) {
    set(snackbarAtom, { open: true, message: t("plan.contents.routine.snackbar.taskStatusChangeFail"), severity: "error" });
  }
});

// 루틴에서 제외
export const requestExcludeTaskInRoutineAtom = atom(null, async (get, set, task: Node, t: TFunction) => {
  const planData = get(innerTabDataAtom);
  const innerTabId = GetIdFromQuerystring("inner_tab_id");
  const currentPlanData = planData.find((data) => data.innerTabId === innerTabId);
  const sidebar = get(routineSidebarAtom);
  if (sidebar.length === 0) return;
  if (!currentPlanData) return;
  const sidebarData = sidebar[sidebar.length - 1];
  const key = sidebarData.key;
  if (!key) return;

  try {
    const edges = currentPlanData.edges;
    const deleteEdge = edges.find((edge) => edge.source === key.id && edge.target === task.id);
    if (!deleteEdge) return;
    const response = await handleReactQueryApiResponse(deleteNodesAndEdges, () => set(error401ModalAtom, true), [], [deleteEdge.data?.backendId as number], innerTabId, get(getUserId));

    if (!response.ok) {
      throw new Error(t("plan.contents.routine.snackbar.excludeTaskFail"));
    }

    const newEdges = edges.filter((edge) => edge.id !== deleteEdge.id);
    const sidebarTasks = sidebarData.tasks as Node[];
    const newSidebarTasks = sidebarTasks.filter((sidebarTask) => sidebarTask.id !== task.id);
    const newSidebarData = { ...sidebarData, tasks: newSidebarTasks };
    const newSidebar = [...sidebar.slice(0, sidebar.length - 1), newSidebarData];
    set(routineSidebarAtom, newSidebar)
    set(setInnerTabDataAtom, { innerTabId, nodes: currentPlanData.nodes, edges: newEdges });
    set(snackbarAtom, { open: true, message: t("plan.contents.routine.snackbar.excludeTaskSuccess"), severity: "success" });
  } catch (error) {
    set(snackbarAtom, { open: true, message: t("plan.contents.routine.snackbar.excludeTaskFail"), severity: "error" });
  }
});

// 루틴 삭제
export const deleteRoutineInRoutineAtom = atom(null, async (get, set, t: TFunction) => {
  const planData = get(innerTabDataAtom);
  const innerTabId = GetIdFromQuerystring("inner_tab_id");
  const currentPlanData = planData.find((data) => data.innerTabId === innerTabId);
  if (!currentPlanData) return;
  const deleteRoutineModal = get(deleteRoutineModalOpenAtom);
  if (!deleteRoutineModal) return;
  const deleteNode = currentPlanData.nodes.find((node) => node.id === deleteRoutineModal);
  if (!deleteNode) return;

  try {
    const edges = currentPlanData.edges;
    const deleteEdges = edges.filter((edge) => edge.source === deleteNode.id);

    const response = await handleReactQueryApiResponse(deleteNodesAndEdges, () => set(error401ModalAtom, true), [deleteNode.data.backendId as number], deleteEdges.map((edge) => edge.data?.backendId as number), innerTabId, get(getUserId));

    if (!response.ok) {
      throw new Error(t("plan.contents.routine.modals.deleteRoutine.fail"));
    }
    const newNodes = currentPlanData.nodes.filter((node) => node.id !== deleteNode.id);
    const newEdges = edges.filter((edge) => edge.source !== deleteNode.id);
    set(snackbarAtom, { open: true, message: t("plan.contents.routine.modals.deleteRoutine.complete"), severity: "success" });
    set(setInnerTabDataAtom, { innerTabId, nodes: newNodes, edges: newEdges });
    set(clearRoutineSidebarDataAtom);
  } catch (error) {
    set(snackbarAtom, { open: true, message: t("plan.contents.routine.modals.deleteRoutine.fail"), severity: "error" });
  }
  set(deleteRoutineModalOpenAtom, null);
});
