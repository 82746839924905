import { Box, Button, styled, Typography } from "@mui/material";
import theme from "../../Styles/theme";

export const ErrorFullContainer = styled(Box)({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  width: "100vw",
  height: "100vh",
  backgroundBlendMode: "screen",
});

export const TitleText = styled(Typography)({
  fontSize: "4em",
  fontWeight: "bold",
  color: theme.colors.primary,

  "@media (max-width: 768px)": {
    fontSize: "2em",
  },
});

export const SubText = styled(Typography)({
  fontSize: "1em",
  color: theme.colors.primary,
});

export const CustomButton = styled(Button)({
  marginTop: "20px",
  color: theme.colors.primary,
  border: "1px solid white",
  transition: "transform 0.3s ease-in-out", // 크기 변화를 부드럽게 하기 위한 transition 추가
  "&:hover": {
    border: `1px solid ${theme.colors.primary}`,
    transform: "scale(1.05)", // hover 시 크기를 1.05배로 확대
  },
});
