import { editCrewModalAtom, editCrewModalCheckNameStatusAtom } from '@/Atoms/Crew';
import { editCrewModalCheckDuplicationAtom, editCrewModalCloseAtom, editCrewModalUpdateCrewAtom, editCrewModalUpdateStateAtom } from '@/ViewModels/Crew/EditCrewModalViewModel';
import { Box, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Typography } from '@mui/material';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import React from 'react';
import * as S from './styled';
import { useTranslation } from 'react-i18next';
import CustomBackgroundColorButton from '@/Components/Common/Buttons/CustomBackgroundColor';
import { Check } from '@mui/icons-material';
import theme from '@/Styles/theme';
import colorPickerDialogAtom from '@/Atoms/Dialogs/ColorPicker';
import CustomColorButton from '@/Components/Common/Buttons/CustomColor';
import useIsMobile from '@/Hooks/useIsMobile';

const EditCrewModal = () => {
  const modal = useAtomValue(editCrewModalAtom);
  const close = useSetAtom(editCrewModalCloseAtom);
  const { t } = useTranslation();
  const updateState = useSetAtom(editCrewModalUpdateStateAtom);
  const checkNameStatus = useAtomValue(editCrewModalCheckNameStatusAtom);
  const checkCrewName = useSetAtom(editCrewModalCheckDuplicationAtom);
  const [inputTagValue, setInputTagValue] = React.useState("");
  const [colorPicker, setColorPicker] = useAtom(colorPickerDialogAtom);
  const updateCrew = useSetAtom(editCrewModalUpdateCrewAtom);
  const { isMobile } = useIsMobile();

  const handleTagKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter' && inputTagValue.trim()) {
      event.preventDefault();
      if (!modal?.hashtags.find((tag) => tag === inputTagValue.trim())) {
        updateState("hashtags", [...(modal?.hashtags || []), inputTagValue.trim()]);
      }
      setInputTagValue(""); // 입력창 초기화
    } else if (event.key === 'Backspace' && inputTagValue === "") {
      if (modal && modal.hashtags.length > 0) {
        event.preventDefault();
        const updatedTags = [...(modal?.hashtags || [])];
        updatedTags.pop();
        updateState("hashtags", updatedTags);
      }
    }
  };

  return (
    <Dialog
      open={modal !== null}
      onClose={close}
      sx={{ display: "flex", flexDirection: "column", gap: "20px" }}
      fullWidth
      maxWidth="sm"
      fullScreen={isMobile}
    >
      {
        modal &&
        <>
          <DialogTitle sx={{ fontSize: isMobile ? "0.8rem" : "0.9rem", fontWeight: "bold" }}>{t("crew.editCrew.title")}</DialogTitle>
          <DialogContent sx={{ display: "flex", flexDirection: "column", gap: "20px" }}>
            <S.ElementContainer>
              <S.Typography>{t("dialogs.createCrew.name")}</S.Typography>
              <S.NameFieldContainer>
                <TextField variant="standard" size="small" placeholder={t("dialogs.createCrew.namePlaceholder")} sx={{ width: "80%" }} value={modal.name} onChange={(e) => updateState("name", e.target.value)}
                  helperText={
                    checkNameStatus === "success"
                      ? t("dialogs.createCrew.nameSuccess")
                      : checkNameStatus === "fail"
                        ? t("dialogs.createCrew.nameFail")
                        : checkNameStatus === "invalid"
                          ? t("crew.editCrew.checkNameInvalid")
                          : ""
                  }
                  FormHelperTextProps={{
                    sx: {
                      color:
                        checkNameStatus === "success"
                          ? "green" // 성공일 때 녹색
                          : checkNameStatus === "fail" || checkNameStatus === "invalid"
                            ? "red" // 실패일 때 빨간색
                            : "inherit", // 기본 색상
                    },
                  }}
                  onKeyDown={(e) => e.key === "Enter" && checkCrewName()}
                />
                {
                  (checkNameStatus !== "success" && checkNameStatus !== "init") &&
                  <CustomBackgroundColorButton onClick={checkCrewName} text={t("crew.editCrew.confirm")} fontSize="0.8rem" disabled={modal.name === "" || (checkNameStatus === "loading" || checkNameStatus === "fail")} />
                }
                {
                  (checkNameStatus === "success" || checkNameStatus === "init") &&
                  <Check style={{ color: theme.colors.primary }} />
                }
              </S.NameFieldContainer>
            </S.ElementContainer>
            <div style={{ display: "flex", flexDirection: "row", width: "100%", justifyContent: "space-between", alignItems: "center" }}>
              <div style={{ display: "flex", flexDirection: "column", gap: "5px", width: "calc(100% - 100px)" }}>
                <S.Typography>
                  {t("crew.editCrew.isPublic")}
                </S.Typography>
                <div style={{ fontSize: isMobile ? "0.6rem" : "0.7rem", color: "#666666" }}>
                  {t("crew.editCrew.isPublicDescription")}
                </div>
              </div>
              <div style={{ display: "flex", flexDirection: "row", gap: "5px", alignItems: "center" }}>
                {
                  modal.isPublic ?
                    <span style={{ color: "#666666", fontSize: isMobile ? "0.6rem" : "0.7rem" }}>{t("crew.editCrew.isPublicY")}</span> :
                    <span style={{ color: "#666666", fontSize: isMobile ? "0.6rem" : "0.7rem" }}>{t("crew.editCrew.isPublicN")}</span>
                }
                <Checkbox checked={modal.isPublic} onChange={() => updateState("isPublic", !modal.isPublic)} />
              </div>
            </div>
            <S.Typography>{t("dialogs.createCrew.description")}</S.Typography>
            <TextField
              variant="standard"
              size="small"
              placeholder={t("dialogs.createCrew.descriptionPlaceholder")}
              multiline
              rows={2}
              fullWidth
              sx={{ marginTop: "-15px" }}
              value={modal.content}
              onChange={(e) => updateState("content", e.target.value)}
            />
            <S.ElementContainer>
              <S.Typography>{t("dialogs.createCrew.headcount")}</S.Typography>
              <TextField variant="standard" size="small" type="number" inputProps={{ min: 1, style: { textAlign: "center", fontSize: isMobile ? "0.6rem" : "0.7rem" } }} value={modal.headCount} onChange={(e) => updateState("headCount", parseInt(e.target.value))} />
            </S.ElementContainer>
            <Box display="flex" justifyContent="space-between" alignItems="center">
              <S.Typography>
                {t("dialogs.createCrew.category")}
              </S.Typography>
              <TextField variant="standard" size="small" placeholder={t("dialogs.createCrew.categoryPlaceholder")} sx={{ width: "50%" }} value={modal.category} onChange={(e) => updateState("category", e.target.value)} />
            </Box>
            <S.ElementContainer>
              <S.Typography>
                {t("dialogs.createCrew.hashtag")}
              </S.Typography>
              <S.TagInputContainer>
                {modal.hashtags.map((tag, idx) => {
                  return <S.TagItem key={idx} style={{ backgroundColor: theme.colors.primary, color: "white" }}>
                    <span>{tag}</span>
                  </S.TagItem>
                })}
                <S.TagInput
                  placeholder={modal.hashtags.length === 0 ? t("dialogs.createCrew.hashtagPlaceholder") : ""}
                  value={inputTagValue}
                  onChange={(e) => setInputTagValue(e.target.value)}
                  onKeyDown={handleTagKeyDown}
                />
              </S.TagInputContainer>
            </S.ElementContainer>
            <S.ElementContainer>
              <S.Typography>{t("dialogs.createCrew.color")}</S.Typography>
              <S.ColorPickerContainer>
                <S.SelectedColor $color={colorPicker.color} />
                <CustomColorButton text={t("dialogs.createCrew.changeColor")} onClick={() => setColorPicker((prev) => ({ ...prev, open: true }))} fontSize="0.8rem" />
              </S.ColorPickerContainer>
            </S.ElementContainer>
          </DialogContent>
          <DialogActions>
            <CustomColorButton onClick={close} text={t("dialogs.createCrew.cancel")} fontSize="0.8rem" color="black" />
            {
              (checkNameStatus === "success" || checkNameStatus === "init") &&
              <CustomBackgroundColorButton onClick={updateCrew} text={t("crew.editCrew.edit")} fontSize="0.8rem" />
            }
          </DialogActions>
        </>
      }
    </Dialog >
  )
}

export default EditCrewModal;
