import theme from "@/Styles/theme";
import styled from "styled-components";
import { styled as muiStyled } from "@mui/material/styles";
import { Button, TableCell } from "@mui/material";

export const SelectButton = styled.div`
  display: flex;
  flex-direction: column;
  width: 45%;
  height: 120px;
  justify-content: space-around;
  align-items: center;
  border: 1px solid #c0c0c0;
  border-radius: 10px;
  cursor: pointer;
  text-align: center;
  font-size: 0.8rem;

  &:hover {
    border: 2px solid ${theme.colors.primary};
  }
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: row;
  min-height: 50px;
`;

export const ContentContent = styled.div`
  display: flex;
  width: 85%;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  border-top: 1px solid #c0c0c0;
  border-right: 1px solid #c0c0c0;
  padding: 10px;
  gap: 10px;
  font-size: 0.8rem;
`;

export const ContentContentRow = styled.div`
  display: flex;
  font-size: 0.8rem;
  flex-direction: row;
  gap: 20px;
  align-items: center;
`;

export const ContentContentColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const ContentInputStyle = (width: number) => ({ width: `${width}px`, padding: "5px", border: "1px solid #c0c0c0", borderRadius: "5px" });

export const ContentTitle = styled.div`
  width: 80px;
  font-size: 0.8rem;
  font-weight: bold;
`;

export const TagInputContainer = styled.div`
  display: flex;
  width: 80%;
  flex-wrap: wrap;
  gap: 0.5em;
  padding: 0.4rem 1rem;
  border: 1px solid #c0c0c0;
  border-radius: 4px;
`;

export const TagItem = styled.div`
  display: inline-block;
  padding: 0.2em 0.5em;
  border-radius: 20px;
  color: white;
`;

export const TagInput = styled.input`
  flex-grow: 1;
  min-width: 30px;
  max-width: 150px;
  padding: 0.5em 0;
  border: none;
  outline: none;
  font-family: "Noto Sans KR", sans-serif;
`;

export const DropZoneContainer = styled.div<{ $isDragActive: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: calc(100% - 5px);
  height: 200px;
  border: 2px dashed ${({ $isDragActive }) => ($isDragActive ? "#2196f3" : "#cccccc")};
  border-radius: 10px;
  background-color: ${({ $isDragActive }) => ($isDragActive ? "#e3f2fd" : "#fafafa")};
  transition: background-color 0.3s ease;
  cursor: pointer;
`;

export const UploadButton = muiStyled(Button)({
  marginTop: "10px",
  fontSize: "0.8rem",
});

export const FileInfo = styled.div`
  font-size: 0.8rem;
  color: #666;
  margin-top: 10px;
`;

export const CustomTableCell = muiStyled(TableCell)`
  margin: 4px;
  padding: 4px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const CustomSelectButton = muiStyled(Button)({
  color: theme.colors.primary,
  width: "100px",
  fontSize: "0.8rem",
  "&:hover": {
    color: theme.colors.primary,
  },
});
