import styled from "styled-components";
import { styled as muiStyled } from "@mui/material/styles";
import { TextField, ToggleButton } from "@mui/material";
import theme from "@/Styles/theme";
import { lighten } from "polished";

export const ContentRow = styled.div`
  display: flex;
  font-size: 0.8rem;
  flex-direction: row;
  gap: 20px;
  align-items: center;
  width: 100%;

  @media screen and (max-width: 768px) {
    gap: 10px;
  }
`;

export const ContentColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const ContentInputStyle = (width: number) => ({ width: `${width}px`, padding: "5px", border: "1px solid #c0c0c0", borderRadius: "5px" });

export const MobileContentInputTitleStyle = styled.input`
  width: 100px;
  padding: 5px;
  border: 1px solid #c0c0c0;
  border-radius: 5px;
  font-size: 0.8rem;
`

export const MobileContentInputValueStyle = styled.input`
  padding: 5px;
  border: 1px solid #c0c0c0;
  border-radius: 5px;
  font-size: 0.8rem;
  flex: 1;
`

export const MobileTextFieldStyle = muiStyled(TextField)(() => ({
  "& .MuiOutlinedInput-input": {
    padding: "10px", // 내부 input 요소의 padding 설정
    fontSize: "0.8rem",
    display: "flex",
    alignItems: "center", // 수직 정렬 추가
  },
  "& .MuiInputLabel-root": {
    fontSize: "0.9rem", // label의 폰트 크기 설정
    transform: "translate(14px, 10px) scale(1)", // 초기 위치 수정
  },
  "& .MuiInputLabel-shrink": {
    transform: "translate(14px, -6px) scale(0.75)", // 축소 위치 수정
  },
  "& .MuiFormHelperText-root": {
    fontSize: "0.7rem", // helperText의 폰트 크기 설정
  },
}))

export const ContentTitle = styled.div`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  font-size: 0.8rem;
  font-weight: bold;
`;

export const CustomToggleButton = muiStyled(ToggleButton)(() => ({
  paddingTop: "4px",
  paddingBottom: "4px",
  paddingLeft: "10px",
  paddingRight: "10px",
  fontSize: "0.8rem",
  color: "#555",
  backgroundColor: "#f0f0f0",
  '&:hover': {
    backgroundColor: "#e0e0e0",
    color: "#333",
  },
  '&.Mui-selected': {
    backgroundColor: theme.colors.primary,
    color: "white",
    '&:hover': {
      backgroundColor: lighten(0.1, theme.colors.primary),
    },
  },
}));
