import React from "react";

const TitleValueInEachRow = ({ title, value }: { title: string; value: any }) => {
  return (
    <div style={{ display: "flex", flexDirection: "column", gap: "10px", width: "100%", height: "auto", fontSize: "12px", alignItems: "flex-start" }}>
      <div style={{ fontWeight: "bold" }}>{title}</div>
      {value}
    </div>
  )
}

export default TitleValueInEachRow;
