import { useQuery } from 'react-query';
import { getPlanData, getPlans, parseGetPlanResponse } from '@/Queries/PlanQueries';
import GetIdFromQuerystring from '@/Utils/GetIdFromQuerystring';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import planAtom, { currentPlanAtom, mobilePlanAtom } from '@/Atoms/Plan';
import { usePlanDataAtom, usePlansAtom } from '@/Atoms/HookControllerAtom';
import { handleReactQueryApiResponse } from '@/Utils/APIUtil';
import { Node } from '@xyflow/react';
import { error401ModalAtom } from '@/Atoms/Dialogs/Error/401Atom';
import { setInnerTabDataAtom } from '@/ViewModels/Plan/InnerTabViewModel';
import { useNavigate } from 'react-router-dom';
import { planHeaderChangeViewTypeAtom } from '@/ViewModels/Plan/HeaderViewModel';
import { getUserId } from '@/ViewModels/UserViewModel';
import { devConsoleError } from '@/Utils/ConsoleLogInDevelopment';
import innerTabDataAtom from '@/Atoms/Plan/InnerTabDataAtom';

export const usePlanData = () => {
  const plans = useAtomValue(planAtom);
  const planId = GetIdFromQuerystring("id");
  const plan = plans.find((plan) => plan.id === planId);
  const planData = useAtomValue(innerTabDataAtom);
  const setInnerTabData = useSetAtom(setInnerTabDataAtom);
  const innerTabId = GetIdFromQuerystring("inner_tab_id");
  const [controller, setController] = useAtom(usePlanDataAtom);
  const setError401Modal = useSetAtom(error401ModalAtom);
  const setMobilePlanName = useSetAtom(mobilePlanAtom);
  const planController = useAtomValue(usePlansAtom);
  const navigate = useNavigate();
  const setCurrentPlan = useSetAtom(currentPlanAtom);
  const changeViewType = useSetAtom(planHeaderChangeViewTypeAtom);
  const userId = useAtomValue(getUserId);

  return useQuery(['planData', innerTabId], () => handleReactQueryApiResponse(getPlanData, () => setError401Modal(true), innerTabId), {
    // enabled: !!plan && controller,
    enabled: controller && !planController && !!plan,
    onSuccess: async (data) => {
      setController(false);
      if (!data.ok) {
        navigate("/");
        return;
      };
      const jsonedData = await data.json();
      setMobilePlanName(plan?.name || "-");
      const planInnerTabs = plan?.innerTabs || [];
      const filteredInnerTabs = planInnerTabs.filter((innerTab) => innerTab.viewType !== "feed");
      if (filteredInnerTabs.length === 0) {
        navigate("/");
        return;
      }
      const firstInnerTab = filteredInnerTabs[0];
      const firstInnerTabData = planData.find((data) => data.innerTabId === firstInnerTab.id);
      if (firstInnerTab.id !== innerTabId && !firstInnerTabData) {
        const firstInnerTabData = await handleReactQueryApiResponse(getPlanData, () => setError401Modal(true), firstInnerTab.id);
        if (!firstInnerTabData.ok) {
          navigate("/");
          return;
        }
        const jsonedFirstInnerTabData = await firstInnerTabData.json();
        setInnerTabData({ innerTabId: firstInnerTab.id, nodes: jsonedFirstInnerTabData.nodes, edges: jsonedFirstInnerTabData.edges });
      }

      try {
        const groupNodes = jsonedData.nodes.filter((node: Node) => node.type === "group");
        const nonGroupNodes = jsonedData.nodes.filter((node: Node) => node.type !== "group");
        const nodes = [...groupNodes, ...nonGroupNodes].map((node: Node) => ({
          ...node,
          data: {
            ...node.data,
            hashtags: node.data.hashtags || [],
          }
        }));
        setInnerTabData({ innerTabId, nodes: nodes, edges: jsonedData.edges });
        const plan = jsonedData.plan;
        if (!plan) {
          navigate("/");
          return;
        }
        const innerTabs = plan.innerTabs;
        if (innerTabs.length === 0) {
          navigate("/");
          return;
        }
        const innerTab = innerTabs.find((innerTab: any) => innerTab.inner_tab_id === innerTabId);
        if (!innerTab) {
          navigate("/");
          return;
        }
        changeViewType(innerTab.viewType);
        const plans = await handleReactQueryApiResponse(getPlans, () => setError401Modal(true), userId);
        if (!plans.ok) return;
        const jsonedPlans = await plans.json();
        const currentPlan = jsonedPlans.find((pl: any) => pl.id === planId);
        if (!currentPlan) return;
        setCurrentPlan(parseGetPlanResponse(currentPlan));
      } catch (e) {
        devConsoleError(e);
        navigate("/");
      }
    }
  },
  );
};
