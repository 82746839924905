import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import { dokgabiChatModalAtom, dokgabiChatModalSidebarAtom } from "@/Atoms/DokgabiChat/Atom";
import { useAtom, useAtomValue, useSetAtom } from "jotai";
import useIsMobile from "@/Hooks/useIsMobile";
import DokgabiListHeader from "../../Header/List";
import { Box, Divider, List, ListItem, ListItemText } from "@mui/material";
import theme from "@/Styles/theme";
import { chatRoomListAtom, currentChatRoomAtom } from "@/Atoms/ChatAtom";
import { dokgabiChatMoveChatRoomAtom } from "@/ViewModels/Chat/ModalViewModel";
import { useTranslation } from "react-i18next";

const DokgabiDesktopSidebar = () => {
  const { t } = useTranslation();
  const { isMobile } = useIsMobile();
  const [openSidebar, setOpenSidebar] = useAtom(dokgabiChatModalSidebarAtom);
  const chatRoomList = useAtomValue(chatRoomListAtom);
  const currentChatRoom = useAtomValue(currentChatRoomAtom);
  const setModal = useSetAtom(dokgabiChatModalAtom);
  const handleCreateChatRoom = () => setModal((prev) => ({ ...prev, mode: "create" }))
  const moveChatRoom = useSetAtom(dokgabiChatMoveChatRoomAtom)

  const handleMoveChatRoom = (chatRoomId: number) => {
    moveChatRoom(chatRoomId);
    setOpenSidebar(false);
  }

  return (
    openSidebar && !isMobile &&
    <div style={{ width: "199px", height: "100%", borderLeft: "1px solid #c0c0c0", display: "flex", flexDirection: "column" }}>
      <DokgabiListHeader />
      <List
        sx={{
          flexGrow: 1,
          padding: 0,
          height: "calc(100% - 60px)",
          overflowY: "auto", // 채팅방 목록에 스크롤 적용
        }}
      >
        {chatRoomList.map((chatRoom, index) => (
          <div key={index}>
            <ListItem
              button
              onClick={() => handleMoveChatRoom(chatRoom.id)}
              sx={{
                minHeight: "70px",
                position: "relative",
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <Box
                sx={{
                  position: "relative",
                  minWidth: "50px",
                  height: "40px",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                {chatRoom.chatbots.length > 0 &&
                  chatRoom.chatbots.slice(0, 2).map((chatbot, idx) => (
                    chatbot.image && // 이미지가 없는 챗봇은 표시하지 않음
                    <img
                      key={idx}
                      src={`${process.env.REACT_APP_DOKGABI_MEDIA_ADDRESS}${chatbot.image}`}
                      alt="chatbot"
                      style={{
                        width: "40px",
                        height: "40px",
                        borderRadius: "50%",
                        position: "absolute",
                        left: `${idx * 15}px`, // 각 이미지의 위치를 조정하여 겹치게 표시
                        zIndex: chatRoom.chatbots.length + idx,
                      }}
                    />
                  ))}
                {/* 이미지가 3개 이상일 경우 남은 수를 표시 */}
                {chatRoom.chatbots.length > 2 && (
                  <Box
                    sx={{
                      width: "40px",
                      height: "40px",
                      borderRadius: "50%",
                      backgroundColor: theme.colors.customPurple,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      position: "absolute",
                      left: `${2 * 15}px`, // 세 번째 이미지의 다음 위치에 둠
                      fontSize: "0.9rem",
                      color: "white",
                      fontWeight: "bold",
                      zIndex: chatRoom.chatbots.length + 2,
                    }}
                  >
                    {`+${chatRoom.chatbots.length - 2}`}
                  </Box>
                )}
              </Box>
              <ListItemText
                primary={chatRoom.title}
                sx={{
                  maxWidth: "170px",
                  color:
                    currentChatRoom?.id === chatRoom.id
                      ? theme.colors.primary
                      : "black",
                  whiteSpace: "nowrap", // 텍스트를 한 줄로 표시
                  overflow: "hidden", // 넘치는 텍스트는 숨김 처리
                  textOverflow: "ellipsis", // 넘치는 부분에 '...' 표시
                }}
              />
            </ListItem>
            <Divider />
          </div>
        ))}
        <ListItem
          button
          sx={{
            minHeight: "50px",
            color: "white",
            backgroundColor: theme.colors.primary,
            "&:hover": {
              backgroundColor: `${theme.colors.primary + "CC"}`, // 80% 투명도
            },
          }}
          onClick={handleCreateChatRoom}
        >
          <ListItemText primary={t("chat.sidebar.createChatRoom")} />
        </ListItem>
      </List>
    </div>
  )
}

export default DokgabiDesktopSidebar;
