import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  IconButton,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useAtom, useAtomValue } from "jotai";
import {
  fetchMoreChattingListAtom,
  initializeAtom,
  whenChatbotChangeAtom,
} from "../../ViewModels/HelperViewModel";
import { ChatInput } from "../../Components/Helper/ChatInput";
import { RecommendQuestionButton } from "../../Components/Helper/RecommendQuestionButton";
import { RecommendQuestion } from "../../Components/Helper/RecommendQuestion";
import {
  chattingListAtom,
  currentChatbotAtom,
  scrollStateAtom,
} from "../../Atoms/HelperAtom";
import { NoChatbot } from "../../Components/Helper/NoChatbot";
import { Balloon } from "../../Components/Helper/Balloon";
import { CustomSidebar } from "@/Components/Helper/Drawer";
import { drawerOpenAtom } from "@/Atoms/HeaderAtom";
import { ArrowBackIosNew, Menu } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import GetIdFromQuerystring from "@/Utils/GetIdFromQuerystring";
import planHipAtom, { PlanHipCrew } from "@/Atoms/PlanHip/Atom";
import useHelper from "@/Hooks/useHelper";

export function MakeTextHelper() {
  const { t } = useTranslation();
  const [currentChatbot] = useAtom(currentChatbotAtom);
  const [chattingList] = useAtom(chattingListAtom);
  const [drawerOpen, setDrawerOpen] = useAtom(drawerOpenAtom);
  const [scrollState, setScrollState] = useAtom(scrollStateAtom);
  const [, initialize] = useAtom(initializeAtom);
  const [, whenChatbotChange] = useAtom(whenChatbotChangeAtom);
  const [isPageGettingHistory, fetchMoreChattingList] = useAtom(
    fetchMoreChattingListAtom
  );
  const { isLoading } = useHelper();
  const crews: PlanHipCrew[] = useAtomValue(planHipAtom).myCrews;
  const [time, setTime] = useState<NodeJS.Timeout | null>(null);

  const chatEndRef = useRef<HTMLDivElement | null>(null);
  const chatContainerRef = useRef<HTMLDivElement | null>(null);

  const navigate = useNavigate();

  const isMobile = useMediaQuery("(max-width:1280px)");

  const handleScroll = () => {
    if (chatContainerRef.current) {
      const container = chatContainerRef.current;

      // 스크롤이 맨 위에 도달했을 때
      if (container.scrollTop === 0) {
        const previousScrollHeight = container.scrollHeight; // 대화 가져오기 전의 전체 높이

        // 대화를 더 가져오고
        fetchMoreChattingList().then(() => {
          setTimeout(() => {
            const newScrollHeight = container.scrollHeight; // 새로운 전체 높이
            const scrollDifference = newScrollHeight - previousScrollHeight;

            // 대화가 추가된 만큼 스크롤 위치를 조정하여 이전 위치 유지
            container.scrollTop = scrollDifference;
          }, 0);
        });
      }
    }
  };

  useEffect(() => {
    return () => initialize();
  }, []);

  useEffect(() => {
    if (!isLoading) {
      const crew_id = GetIdFromQuerystring("crew_id");
      const inner_tab_id = GetIdFromQuerystring("inner_tab_id");

      if (!crew_id || !inner_tab_id) {
        navigate("/plan-hip");
        return;
      }

      const crew = crews.find((crew) => crew.crewId === crew_id);
      if (!crew) {
        navigate("/plan-hip");
        return;
      }

      const inner_tab = crews.find((tab) => tab.innerTabId === inner_tab_id);
      if (!inner_tab) {
        navigate("/plan-hip");
        return;
      }

      if (time) {
        clearTimeout(time);
      }

      const timeout = setTimeout(() => {
        setDrawerOpen(true);
      }, 100);

      setTime(timeout);

      return () => clearTimeout(timeout);
    }
  }, [isLoading]);

  useEffect(() => {
    whenChatbotChange(chatEndRef.current);
  }, [currentChatbot]);

  useEffect(() => {
    if (chatEndRef.current && chatContainerRef.current) {
      const container = chatContainerRef.current;
      const distanceFromBottom =
        container.scrollHeight - container.scrollTop - container.clientHeight;

      // 밑에서 50px 이내일 경우 자동 스크롤
      if (distanceFromBottom <= 100) {
        chatEndRef.current.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [chattingList]);

  useEffect(() => {
    if (scrollState && chatEndRef.current) {
      chatEndRef.current.scrollIntoView({ behavior: "smooth" });
      setScrollState(false);
    }
  }, [scrollState]);

  return (
    <Stack direction={"row"} sx={{ width: "100%", height: "100%" }}>
      {currentChatbot === null ? (
        <Stack
          sx={{
            height: "100%",
            width: isMobile ? "100%" : drawerOpen ? "80%" : "100%",
            display: "flex",
            flexDirection: "column",
            position: "relative",
          }}
        >
          <NoChatbot />
        </Stack>
      ) : (
        <Stack
          sx={{
            height: "100%",
            width: isMobile ? "100%" : drawerOpen ? "80%" : "100%",
            display: "flex",
            flexDirection: "column",
            position: "relative",
          }}
        >
          <Stack
            direction={"row"}
            width="100%"
            sx={{
              boxSizing: "border-box",
              padding: "5px 10px",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <IconButton onClick={() => navigate(-1)}>
              <ArrowBackIosNew />
            </IconButton>

            <IconButton onClick={() => setDrawerOpen((current) => !current)}>
              <Menu />
            </IconButton>
          </Stack>

          <Box
            sx={{
              position: "absolute",
              zIndex: 10,
              top: 0,
              width: "100%",
              left: 0,
              height: isPageGettingHistory ? "auto" : "0",
              background: isPageGettingHistory ? "#06C25E" : "transparent",
              transition: "box-shadow 0.3s ease",
              textAlign: "center",
              color: "white",
              borderRadius: isPageGettingHistory ? "0 0 15px 15px" : null,
            }}
          >
            {isPageGettingHistory ? t("makeTextHelper.getChatList") : ""}
          </Box>
          <Stack
            sx={{
              flexGrow: 1,
              overflowY: "auto",
              padding: 2,
            }}
            ref={chatContainerRef}
            onScroll={handleScroll}
          >
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <Stack
                sx={{
                  width: "90%",
                  gap: `20px`,
                  "@media (max-width: 1024px)": {
                    width: "90%",
                  },
                  "@media (max-width: 768px)": {
                    width: "calc(100% - 40px)",
                  },
                }}
              >
                {chattingList.map((chat, index) => (
                  <Balloon
                    key={`chatting-${index}`}
                    name={chat.name}
                    image={chat.image}
                    text={chat.text}
                    date={chat.date}
                    chattingUser={chat.chattingUser}
                  />
                ))}
                <div ref={chatEndRef} />
              </Stack>
            </Box>
          </Stack>
          <Stack
            sx={{
              width: "100%",
              background: "rgba(255, 255, 255,0)",
              position: "sticky",
              bottom: "0",
              paddingBottom: `20px`,
              justifyContent: "center",
              alignItems: "center",
              backdropFilter: `blur(10px)`,
              WebkitBackdropFilter: `blur(10px)`,
            }}
          >
            <Stack
              sx={{
                width: "90%",
                height: "100%",
                marginBottom: `10px`,
                alignItems: "center",
                justifyContent: "center",
                gap: `5px`,
                "@media (max-width: 1024px)": {
                  width: "90%",
                },
                "@media (max-width: 768px)": {
                  width: "calc(100% - 40px)",
                },
              }}
              direction="column"
            >
              <ChatInput />
              <Typography sx={{ color: "#8f8f8f", fontSize: "13px" }}>
                {t("makeTextHelper.warningAI")}
              </Typography>
              <RecommendQuestion />
              <RecommendQuestionButton />
            </Stack>
          </Stack>
        </Stack>
      )}
      <Stack sx={{ width: isMobile ? "0%" : drawerOpen ? "20%" : "0%" }}>
        <CustomSidebar />
      </Stack>
    </Stack>
  );
}
