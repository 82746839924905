import { atom } from 'jotai';

interface JobNodeState {
  // id: string;
  status: number; // 0: not started, 1: in progress, 2: done
  // jobList: string[];
  jobList: {
    job: string;
    rank: number;
    status: boolean;
  }[];
  currentPage: number;
}

interface JobNodeProps {
  id: string;
  state: JobNodeState;
}

const jobNodeAtom = atom<JobNodeProps[]>([]);

export const alertNoJobDataModalAtom = atom<string | null>(null);

export default jobNodeAtom;
