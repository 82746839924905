import React from "react";
import {
  Box,
  List,
  ListItem,
  ListItemText,
  AppBar,
  Divider,
  Dialog,
} from "@mui/material";
import { useAtom, useAtomValue, useSetAtom } from "jotai";
import ChattingRoom from "./ChattingRoom";
import CreateChatRoom from "./CreateRoom";
import useIsMobile from "@/Hooks/useIsMobile";
import { chatDialogAtom, chatRoomListAtom, createChatRoomSectionAtom, currentChatRoomAtom, isChatListOpenAtom } from "@/Atoms/ChatAtom";
import { createChatRoomAtom, moveChatRoomAtom } from "@/ViewModels/ChatViewModel";
import theme from "@/Styles/theme";

const Chat = () => {
  const { isMobile, isTablet, isDesktop } = useIsMobile();
  const [isChatListOpen, setIsChatListOpen] = useAtom(isChatListOpenAtom);
  const [createChatRoomSection, setCreateChatRoomSection] = useAtom(createChatRoomSectionAtom);
  const createChatRoom = useSetAtom(createChatRoomAtom);
  const moveChatRoom = useSetAtom(moveChatRoomAtom);
  const currentChatRoom = useAtomValue(currentChatRoomAtom);
  const chatRoomList = useAtomValue(chatRoomListAtom);
  const chatDialogOpen = useAtomValue(chatDialogAtom);

  const handleMoveChatRoom = (chatRoomId: number) => {
    moveChatRoom(chatRoomId);
    setIsChatListOpen(false);
    setCreateChatRoomSection(false);
  };

  const handleCreateChatRoom = (selectedMentorList: number[]) => {
    createChatRoom(selectedMentorList);
    setIsChatListOpen(false);
  };

  let dialogWidth: number | string = 950; // 기본 데스크탑 너비
  if (isTablet) {
    dialogWidth = "90vw"; // 태블릿은 화면 너비의 90%
  }

  return (
    <Dialog
      open={chatDialogOpen}
      maxWidth="lg"
      fullWidth
      PaperProps={{
        sx: {
          width: dialogWidth,
          maxWidth: isDesktop ? 950 : "90vw",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          overflow: "hidden",
        },
      }}
    >
      <Box display="flex" height="90vh" width="100%" flexDirection="row">
        {/* 채팅리스트 */}
        {isChatListOpen && (
          <>
            <Box minWidth={300} height="100%" maxWidth={300}>
              <AppBar
                position="static"
                sx={{
                  backgroundColor: `${theme.colors.primary}`,
                  height: "60px",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  width: "100%",
                  padding: "0 20px",
                }}
              >
                <div style={{ fontSize: "1rem" }}>채팅목록</div>
              </AppBar>
              <List
                sx={{
                  flexGrow: 1,
                  padding: 0,
                  height: "calc(100% - 60px)",
                  overflowY: "auto", // 채팅방 목록에 스크롤 적용
                }}
              >
                {chatRoomList.map((chatRoom, index) => (
                  <div key={index}>
                    <ListItem
                      button
                      onClick={() => handleMoveChatRoom(chatRoom.id)}
                      sx={{
                        minHeight: "70px",
                        position: "relative",
                        display: "flex",
                        justifyContent: "space-between",
                        width: "100%",
                      }}
                    >
                      <Box
                        sx={{
                          position: "relative",
                          minWidth: "50px",
                          height: "40px",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        {chatRoom.chatbots.length > 0 &&
                          chatRoom.chatbots.slice(0, 2).map((chatbot, idx) => (
                            chatbot.image && // 이미지가 없는 챗봇은 표시하지 않음
                            <img
                              key={idx}
                              src={`${process.env.REACT_APP_DOKGABI_MEDIA_ADDRESS}${chatbot.image}`}
                              alt="chatbot"
                              style={{
                                width: "40px",
                                height: "40px",
                                borderRadius: "50%",
                                position: "absolute",
                                left: `${idx * 15}px`, // 각 이미지의 위치를 조정하여 겹치게 표시
                                zIndex: chatRoom.chatbots.length + idx,
                              }}
                            />
                          ))}
                        {/* 이미지가 3개 이상일 경우 남은 수를 표시 */}
                        {chatRoom.chatbots.length > 2 && (
                          <Box
                            sx={{
                              width: "40px",
                              height: "40px",
                              borderRadius: "50%",
                              backgroundColor: theme.colors.customPurple,
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              position: "absolute",
                              left: `${2 * 15}px`, // 세 번째 이미지의 다음 위치에 둠
                              fontSize: "0.9rem",
                              color: "white",
                              fontWeight: "bold",
                              zIndex: chatRoom.chatbots.length + 2,
                            }}
                          >
                            {`+${chatRoom.chatbots.length - 2}`}
                          </Box>
                        )}
                      </Box>
                      <ListItemText
                        primary={chatRoom.title}
                        sx={{
                          maxWidth: "170px",
                          color:
                            currentChatRoom?.id === chatRoom.id
                              ? theme.colors.primary
                              : "black",
                          whiteSpace: "nowrap", // 텍스트를 한 줄로 표시
                          overflow: "hidden", // 넘치는 텍스트는 숨김 처리
                          textOverflow: "ellipsis", // 넘치는 부분에 '...' 표시
                        }}
                      />
                    </ListItem>
                    <Divider />
                  </div>
                ))}
                <ListItem
                  button
                  sx={{
                    minHeight: "50px",
                    color: "white",
                    backgroundColor: theme.colors.primary,
                    "&:hover": {
                      backgroundColor: `${theme.colors.primary + "CC"}`, // 80% 투명도
                    },
                  }}
                  onClick={() => setCreateChatRoomSection(true)}
                >
                  <ListItemText primary="+ 채팅방 추가" />
                </ListItem>
              </List>
            </Box>
            <Divider orientation="vertical" flexItem />
          </>
        )}
        {/* 채팅창 or 채팅 생성 */}
        {createChatRoomSection ? (
          <CreateChatRoom
            isChatListOpen={isChatListOpen}
            setIsChatListOpen={setIsChatListOpen}
            createChatRoom={handleCreateChatRoom}
          />
        ) : (
          <ChattingRoom
            isChatListOpen={isChatListOpen}
            setIsChatListOpen={setIsChatListOpen}
            chatRoomList={chatRoomList}
          />
        )}
      </Box>
    </Dialog>
  );
};

export default Chat;
