import React from "react";
import {
  Divider,
  IconButton,
  Stack,
} from "@mui/material";
import * as S from "../styled";
import { Settings } from "@mui/icons-material";
import { useAtomValue, useSetAtom } from "jotai";
import { dashboardMenteeCareerSearchAtom } from "@/Atoms/Dashboard/MenteeAtom";
import USImage from "@/Assets/Images/Countries/usa.jpg";
import KRImage from "@/Assets/Images/Countries/korea.jpg";
import { useTranslation } from "react-i18next";
import { userLanguageAtom } from "@/ViewModels/UserViewModel";
import LoadingContent from "../../Contents/Loading";
import { openSignatureDialogAtom } from "@/ViewModels/Signature/ViewModel";
import CommonTable from "@/Components/Common/Table";

const enHeader = ["uni", "department", "region", "desc"];
const koHeader = [
  "학교",
  "",
  "학과",
  "지역",
  "전형",
  "경쟁률",
  "모집인원",
  "50%(등급)",
  "70%(등급)",
];

export function CareerSearch() {
  const openSignature = useSetAtom(openSignatureDialogAtom);
  const careerSearch = useAtomValue(dashboardMenteeCareerSearchAtom);
  const language = useAtomValue(userLanguageAtom);
  const { t } = useTranslation();

  const koData = careerSearch.careerSearch.map((row) => {
    const url = row.url.includes("http") ? row.url : `https://${row.url}`;
    return {
      cells: [
        <a href={`${url}`} target="_blank" rel="noreferrer">
          <img
            src={row.image || ""}
            alt="학교 로고 이미지"
            style={{ width: "50px", height: "50px", borderRadius: "50%", cursor: "pointer" }}
          />
        </a>,
        row.school,
        row.major,
        row.region,
        row.type.split(">"),
        row.competition,
        row.recruit,
        row.grade50 || "-",
        row.grade70 || "-",
      ],
    }
  });

  const koExtendContents = careerSearch.careerSearch.map((row) => (
    <div style={{ padding: "10px" }}>
      <p><strong>학교:</strong> {row.school}</p>
      <p><strong>학과:</strong> {row.major}</p>
      <p><strong>지역:</strong> {row.region}</p>
      <p><strong>전형:</strong> {row.type}</p>
      <p><strong>경쟁률:</strong> {row.competition}</p>
      <p><strong>모집인원:</strong> {row.recruit}</p>
      <p><strong>50%(등급):</strong> {row.grade50 || "-"}</p>
      <p><strong>70%(등급):</strong> {row.grade70 || "-"}</p>
    </div>
  ));

  // Data preparation for English headers (enHeader)
  const enData = careerSearch.careerSearch.map((row) => ({
    cells: [
      row.school,
      row.major,
      row.region,
      row.type,
    ],
  }));

  const enExtendContents = careerSearch.careerSearch.map((row) => (
    <div style={{ padding: "10px" }}>
      <p><strong>University:</strong> {row.school}</p>
      <p><strong>Department:</strong> {row.major}</p>
      <p><strong>Region:</strong> {row.region}</p>
      <p><strong>Description:</strong> {row.type}</p>
    </div>
  ));

  return (
    <S.CustomBox>
      <Stack
        direction={"row"}
        sx={{ justifyContent: "space-between", alignItems: "center" }}
      >
        <S.TitleTypo>{t("dashboard.mentee.careerSearch.title")}</S.TitleTypo>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: "10px",
          }}
        >
          <img src={language === "KR" ? KRImage : USImage} alt="국기" style={{ width: "40px", height: `${(40 * 2) / 3}px` }} />
          <IconButton onClick={openSignature}>
            <Settings></Settings>
          </IconButton>
        </div>
      </Stack>

      <Divider sx={{ margin: "10px 0" }} />
      <S.DescTypo>
        {t("dashboard.mentee.careerSearch.description")}
      </S.DescTypo>
      {
        careerSearch.isLoading ?
          <LoadingContent /> :
          language === "KR" ?
            <CommonTable headers={koHeader} rows={koData} extendContents={koExtendContents} /> :
            <CommonTable headers={enHeader} rows={enData} extendContents={enExtendContents} />
      }
    </S.CustomBox>
  );
}
