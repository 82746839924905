import React from 'react';
import * as S from './styled';
import useIsMobile from '../../../Hooks/useIsMobile';
import AddIcon from '@mui/icons-material/Add';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import { Dialog, DialogActions, DialogContent, DialogTitle, Tooltip } from '@mui/material';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { createRoutineDialogOpenAtom } from '@/ViewModels/Dialogs/Create/RoutineViewModel';
import { planHeaderChangeViewTypeAtom } from '@/ViewModels/Plan/HeaderViewModel';
import GetIdFromQuerystring from '@/Utils/GetIdFromQuerystring';
import { useNavigate } from 'react-router-dom';
import { checkPlanUserIdAtom, syncPlanAtom } from '@/ViewModels/Plan/PlanViewModel';
import deleteRoutineDialogAtom from '@/Atoms/Dialogs/Delete/RoutineAtom';
import { useTranslation } from 'react-i18next';
import { Settings } from '@mui/icons-material';
import { planSettingsModalOpenAtom } from '@/ViewModels/Plan/PlanSettingsModalViewModel';
import CustomBackgroundColorButton from '@/Components/Common/Buttons/CustomBackgroundColor';
import CustomColorButton from '@/Components/Common/Buttons/CustomColor';
import { currentPlanAtom } from '@/Atoms/Plan';
import { clearRoutineSidebarDataAtom } from '@/ViewModels/Plan/Routine/SidebarViewModel';


const PlanHeader = () => {
  const { isMobile } = useIsMobile();
  const [planHeader, changeViewType] = useAtom(planHeaderChangeViewTypeAtom);
  const createRoutineDialogOpen = useSetAtom(createRoutineDialogOpenAtom);
  const planId = GetIdFromQuerystring("id");
  const innerTabId = GetIdFromQuerystring("inner_tab_id");
  const plan = useAtomValue(currentPlanAtom);
  // const routines = plan?.innerTabs.slice(1) || [];
  const routines = plan?.innerTabs.slice(2) || [];
  const navigate = useNavigate();
  const syncPlan = useSetAtom(syncPlanAtom);
  const setDeleteRoutine = useSetAtom(deleteRoutineDialogAtom);
  const { t } = useTranslation();
  const openModal = useSetAtom(planSettingsModalOpenAtom);
  const isMine = useAtomValue(checkPlanUserIdAtom);
  const [modal, setModal] = React.useState(false);
  const clearRoutineSidebar = useSetAtom(clearRoutineSidebarDataAtom);

  const routineTab = [
    {
      id: "feed",
      name: t("plan.header.feed"),
    },
    {
      id: "routine",
      name: t("plan.header.routine"),
    }
  ]

  const mindmapTab = [
    {
      id: "feed",
      name: t("plan.header.feed"),
    },
    {
      id: "mindmap",
      name: t("plan.header.mindmap"),
    },
  ]

  return (
    <S.Container>
      <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", overflowX: "auto", gap: "20px", flex: 1 }}>
        {plan && (plan.type === "routine" ? routineTab : mindmapTab).map((tab) => (
          <S.TabItem
            key={tab.id}
            $selected={tab.id === planHeader.currentViewType && innerTabId === plan?.innerTabs[0].id}
            $isMobile={isMobile}
            onClick={() => {
              setTimeout(() => {
                clearRoutineSidebar();
                navigate(`/plan?id=${planId}&inner_tab_id=${plan?.innerTabs[0].id}`);
                changeViewType(tab.id as "mindmap" | "todo" | "routine" | "gantt")
              }, 100);
            }}
            style={{ whiteSpace: "nowrap" }}
          >
            {tab.name}
          </S.TabItem>
        ))}
        {routines.map((routine) => (
          <S.TabItem
            key={routine.id}
            $selected={routine.id === innerTabId}
            $isMobile={isMobile}
            onClick={() => {
              clearRoutineSidebar();
              changeViewType(routine.viewType as "mindmap" | "todo" | "routine" | "gantt" | "feed");
              navigate(`/plan?id=${planId}&inner_tab_id=${routine.id}`);
              syncPlan(routine.viewType as "mindmap" | "todo" | "routine" | "gantt");
            }}
            style={{ whiteSpace: "nowrap" }}
          >
            {routine.name}
          </S.TabItem>
        ))}
        {
          isMine &&
          <Tooltip title={t("plan.header.createRoutine")} placement="bottom" arrow>
            <AddIcon onClick={createRoutineDialogOpen} sx={{ color: "#999" }} />
          </Tooltip>
        }
        {
          routines && routines.find((routine) => routine.id === innerTabId) && isMine &&
          <Tooltip title={t("plan.header.deleteRoutine")} placement="bottom" arrow >
            <DeleteOutlinedIcon sx={{ color: "#999" }} onClick={() => setDeleteRoutine({ open: true, complete: false })} />
          </Tooltip>
        }
      </div>
      {/* 로딩 중임을 표시하는 컴포넌트 */}
      <div style={{ display: "flex", flexDirection: "row", overflowX: "auto", gap: "20px", alignItems: "center" }}>
        {
          !isMine &&
          <div style={{ display: "flex", flexDirection: "row", alignItems: "center", gap: "10px" }}>
            <CustomColorButton text="복사하기" onClick={() => setModal(true)} fontSize="0.8rem" />
          </div>
        }
        {
          isMine &&
          <Settings sx={{ cursor: "pointer" }} onClick={openModal} />
        }
      </div>
      <Dialog open={modal} onClose={() => setModal(false)} fullWidth maxWidth="xs">
        <DialogTitle sx={{ fontSize: "0.9rem", fontWeight: "bold" }}>복사하기</DialogTitle>
        <DialogContent sx={{ fontSize: "0.8rem", display: "flex", flexDirection: "column", gap: "10px" }}>
          <div>플랜을 복사하시겠습니까?</div>
          <div>확인을 누르시면 복사된 플랜으로 이동합니다.</div>
        </DialogContent>
        <DialogActions>
          <CustomColorButton text="취소" onClick={() => setModal(false)} fontSize="0.8rem" color="gray" />
          <CustomBackgroundColorButton text="확인" onClick={() => setModal(false)} fontSize="0.8rem" />
        </DialogActions>
      </Dialog>
    </S.Container >
  );
}

export default PlanHeader;
