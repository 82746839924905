import React, { useEffect, useRef, useState } from "react";
import * as d3 from "d3";

interface DataType {
  key: string;
  value: number;
}

interface PieChartProps {
  data: DataType[];
}

export function PieChart({ data }: PieChartProps) {
  const svgRef = useRef<SVGSVGElement | null>(null);
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });

  useEffect(() => {
    const resizeObserver = new ResizeObserver((entries) => {
      if (entries.length === 0 || !entries[0].contentRect) {
        return;
      }
      const { width, height } = entries[0].contentRect;
      setDimensions({ width, height });
    });

    if (svgRef.current) {
      resizeObserver.observe(svgRef.current);
    }

    return () => {
      resizeObserver.disconnect();
    };
  }, [svgRef]);

  useEffect(() => {
    if (svgRef.current && dimensions.width && dimensions.height) {
      const svgElement = svgRef.current;
      const width = dimensions.width * 0.8; // Adjust width to leave space for legend
      const height = dimensions.height;
      const radius = Math.min(width, height) / 2;
      const innerRadius = radius / 2;

      // Clear previous chart 겹치는 문제 해결
      d3.select(svgElement).selectAll("*").remove();

      const svg = d3
        .select(svgElement)
        .attr("width", dimensions.width)
        .attr("height", height)
        .append("g")
        .attr("transform", `translate(${width / 2}, ${height / 2})`);

      const pie = d3
        .pie<DataType>()
        .value((d) => d.value)
        .sort(null);

      const arc = d3
        .arc<d3.PieArcDatum<DataType>>()
        .innerRadius(innerRadius)
        .outerRadius(radius);

      const color = d3
        .scaleOrdinal<string>()
        .domain(data.map((d) => d.key))
        .range(d3.schemeCategory10);

      const pieData = pie(data);

      svg
        .selectAll("path")
        .data(pieData)
        .enter()
        .append("path")
        .attr("d", arc)
        .attr("fill", (d) => color(d.data.key) as string)
        .attr("stroke", "white")
        .style("stroke-width", "2px");

      svg
        .selectAll("text")
        .data(pieData)
        .enter()
        .append("text")
        .attr("transform", (d) => `translate(${arc.centroid(d)})`)
        .attr("dy", "0.35em")
        .attr("text-anchor", "middle")
        .text(
          (d) =>
            `${Math.round(
              (d.data.value / d3.sum(data, (d) => d.value)) * 100
            )}%`
        )
        .style("font-size", "12px")
        .style("font-family", "NanumSquareNeoBrg")
        .style("fill", "white");

      // Legend
      const legend = d3
        .select(svgElement)
        .append("g")
        .attr(
          "transform",
          `translate(${width + 20}, ${height / 2 - data.length * 10})`
        );

      legend
        .selectAll("rect")
        .data(data)
        .enter()
        .append("rect")
        .attr("x", 0)
        .attr("y", (d, i) => i * 30)
        .attr("width", 20)
        .attr("height", 20)
        .attr("fill", (d) => color(d.key) as string);

      legend
        .selectAll("text")
        .data(data)
        .enter()
        .append("text")
        .attr("x", 30)
        .attr("y", (d, i) => i * 30 + 13)
        .text((d) => d.key)
        .style("font-size", "14px")
        .style("font-family", "NanumSquareNeoBrg")
        .attr("text-anchor", "start");

      svg.selectAll("path");
    }
  }, [dimensions, data]);

  return <svg ref={svgRef} style={{ width: "100%", height: "95%" }}></svg>;
}
