import { dashboardMenteeDailyPlanAtom, dashboardMenteeMyLevelAtom } from '@/Atoms/Dashboard/MenteeAtom';
import { LinePlotProps } from '@/Components/Dashboard/Charts/LinePlot';
import { Node } from '@xyflow/react';
import dayjs from 'dayjs';
import { atom } from 'jotai';

// 현재 나의 수준은 가져오기
export const dashboardMenteeGetMyLevelAtom = atom((get) => {
  const myLevel = get(dashboardMenteeMyLevelAtom);
  if (!myLevel) return [] as LinePlotProps[];
  // 성장 곡선
  const plotData: LinePlotProps[] = [
    {
      name: "",
      data: myLevel.myLevel.notUsing.map((data) => ({
        key: data.year.toString(),
        value: data.money,
      })),
    },
    {
      name: "예상 성장 곡선",
      data: myLevel.myLevel.usingOurService.map((data) => ({
        key: data.year.toString(),
        value: data.money,
      })),
    },
  ];
  return [plotData, myLevel.isLoading];
});

// 일정관리에 필요한 데이터 가져오기
export const dashboardMenteeGetDailyPlanByYearAndMonthAtom = atom(
  (get) => (year: number, month: number) => {
    const planData = get(dashboardMenteeDailyPlanAtom);
    if (!planData) return [];

    const events: { title: string, date: string, extendedProps: Node }[] = [];

    planData.dailyPlan.forEach((plan: Node) => {
      const taskStatus = plan.data.taskStatus as { taskStatusId: number, taskId: number, date: string, status: number }[]
      if (taskStatus && taskStatus.length > 0) {
        taskStatus.forEach((status: { taskStatusId: number, taskId: number, date: string, status: number }) => {
          events.push({ title: plan.data.label as string, date: status.date, extendedProps: plan as Node });
        });
      }
    });

    return events.filter((event) => {
      const eventDate = dayjs(event.date);
      return eventDate.year() === year && eventDate.month() === month - 1;
    });
  }
);
