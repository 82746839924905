import { editRoutineIntervalsDialogAtom } from "@/Atoms/Dialogs/Edit/RoutineOption";
import CustomBackgroundColorButton from "@/Components/Common/Buttons/CustomBackgroundColor";
import CustomColorButton from "@/Components/Common/Buttons/CustomColor";
import theme from "@/Styles/theme";
import { editRoutineIntervalsInNodeAtom } from '@/ViewModels/Plan/Mindmap/FlowViewModel';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { useAtom, useSetAtom } from "jotai";
import React from "react";

const weekDays = ["월", "화", "수", "목", "금", "토", "일"];

const EditRoutineIntervalsDialog = () => {
  const [editRoutineDialog, setEditRoutineDialog] = useAtom(editRoutineIntervalsDialogAtom);
  const editRoutineIntervalsInNode = useSetAtom(editRoutineIntervalsInNodeAtom);


  const handleWeeklyRoutine = (day: string) => {
    if (!editRoutineDialog.termData) return;
    if (editRoutineDialog.termData.includes(day)) {
      setEditRoutineDialog({ open: editRoutineDialog.open, termType: editRoutineDialog.termType, termData: editRoutineDialog.termData.filter((interval) => interval !== day) });
    } else {
      setEditRoutineDialog({ open: editRoutineDialog.open, termType: editRoutineDialog.termType, termData: [...editRoutineDialog.termData, day] });
    }
  }


  return (
    <Dialog open={editRoutineDialog.open !== null} onClose={() => setEditRoutineDialog({ open: null, termType: null, termData: null })} fullWidth maxWidth="xs">
      <DialogTitle>반복 설정</DialogTitle>
      <DialogContent>
        {editRoutineDialog.termType === 1 ? (
          <Box sx={{ display: 'flex', flexDirection: 'row', width: "100%", justifyContent: "space-between" }}>
            {weekDays.map((day) => (
              <div key={day} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', backgroundColor: editRoutineDialog.termData?.includes(day) ? theme.colors.primary : "white", borderRadius: "50%", color: editRoutineDialog.termData?.includes(day) ? "white" : "black", fontSize: "0.9rem", fontWeight: "bold", width: "30px", height: "30px", cursor: "pointer", justifyContent: "center" }} onClick={() => handleWeeklyRoutine(day)}>
                {day}
              </div>
            ))}
          </Box>
        ) : (
          <Box sx={{ display: "grid", gridTemplateColumns: "repeat(7, 1fr)", gap: "5px" }}>
            {
              Array.from({ length: 31 }, (_, i) => i + 1).map((day) => (
                <div key={day} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', backgroundColor: editRoutineDialog.termData?.includes(day.toString()) ? theme.colors.primary : "white", borderRadius: "50%", color: editRoutineDialog.termData?.includes(day.toString()) ? "white" : "black", fontSize: "0.9rem", fontWeight: "bold", width: "30px", height: "30px", cursor: "pointer", justifyContent: "center" }} onClick={() => handleWeeklyRoutine(day.toString())}>
                  {day}
                </div>
              ))
            }
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        <CustomColorButton onClick={() => setEditRoutineDialog({ open: null, termType: null, termData: null })} text="취소" fontSize="0.8rem" />
        <CustomBackgroundColorButton onClick={editRoutineIntervalsInNode} text="확인" fontSize="0.8rem" />
      </DialogActions>
    </Dialog>
  )
}

export default EditRoutineIntervalsDialog;
