import { atom } from "jotai";

export interface UserGroupProps {
  id: number;
  groupName: string;
  groupCode: string;
  currentHeadCount: number;
  maxHeadCount: number;
  subscriptionEndDate: Date;
  updateDate: Date;
}

export interface UserProps {
  id: number;
  nickname: string;
  membership: string;
  image: string | null;
  membership_end_date: Date;
  email: string;
  language: "KR" | "US";
  birth_date?: Date;
  groups: UserGroupProps[];
}

export interface TaskUserProps {
  id: number;
  nickname: string;
  image: string;
}

export const userAtom = atom<UserProps | null>(null);
