import styled from 'styled-components';

export const TimelapseContainer = styled.div<{ $isMobile: boolean }>`
  display: flex;
  flex-direction: column;
  width: ${({ $isMobile }) => ($isMobile ? "100%" : "50%")};
  height: calc(100% - 20px);
  padding: 10px 0;
  overflow-y: auto;
  gap: 30px;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.lightBackground};
`;

export const VideoContainer = styled.div<{ $aspectRatio: '16:9' | '9:16' }>`
  position: relative;
  padding-top: ${(props) => (props.$aspectRatio === '16:9' ? '56.25%' : '177.78%')}; /* 16:9 is 56.25% and 9:16 is 177.78% */
  height: 0;
  overflow: hidden;
  background: #000;
  border-radius: 8px;
  max-height: 80vh;

  & iframe, & video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`;

export const CardsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
  height: 500px;
  padding: 10px 0;
  align-items: center;
  overflow-y: auto;
`;
