import { deleteRoutineModalOpenAtom } from '@/Atoms/Plan/RoutineAtom';
import CustomBackgroundColorButton from '@/Components/Common/Buttons/CustomBackgroundColor';
import CustomColorButton from '@/Components/Common/Buttons/CustomColor';
import { deleteRoutineInRoutineAtom } from '@/ViewModels/Plan/Routine/RoutineViewModel';
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { useAtom, useSetAtom } from 'jotai';
import React from 'react';
import { useTranslation } from 'react-i18next';

const DeleteRoutineModal = () => {
  const [modal, setModal] = useAtom(deleteRoutineModalOpenAtom);
  const { t } = useTranslation();
  const deleteRoutine = useSetAtom(deleteRoutineInRoutineAtom);

  return (
    modal &&
    <Dialog open={modal !== null} onClose={() => setModal(null)} fullWidth maxWidth="xs">
      <DialogTitle sx={{ fontSize: "0.9rem", fontWeight: "bold" }}>{t("plan.contents.routine.modals.deleteRoutine.title")}</DialogTitle>
      <DialogContent sx={{ fontSize: "0.8rem", display: "flex", flexDirection: "column", gap: "10px" }}>
        <div>
          {t("plan.contents.routine.modals.deleteRoutine.description")}
        </div>
        <div>
          {t("plan.contents.routine.modals.deleteRoutine.description2")}
        </div>
      </DialogContent>
      <DialogActions>
        <CustomColorButton onClick={() => setModal(null)} text={t("plan.contents.routine.modals.deleteRoutine.cancel")} fontSize="0.8rem" color="black" />
        <CustomBackgroundColorButton onClick={() => deleteRoutine(t)} text={t("plan.contents.routine.modals.deleteRoutine.delete")} fontSize="0.8rem" />
      </DialogActions>
    </Dialog>
  )
}

export default DeleteRoutineModal;
